/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotifService } from '@libs/services/src';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';
import { environment } from '../../../environments/environment';
import { BarTypeService } from '../services/bar-type.service';
import { BarType } from '../types/bar-type.type';

@Component({
  selector: 'eros-front-glossary',
  templateUrl: './inspections-glossary.component.html',
  styleUrls: ['./inspections-glossary.component.scss'],
})
export class InspectionsGlossaryComponent implements OnInit, OnDestroy {

  private _glossary: any[];
  public _glossary_filtered: any[];
  public form: FormGroup;
  private _isVisible: boolean;
  private _name: string;
  public nameId: string;
  public rank: number;
  private _keywords: string;
  private _kizeotags: any[];
  private _id: string;
  public locked: boolean;
  public isVisibleCreationIndexModal: boolean = false;
  public barTypes: BarType[] = [];
  private _barTypes$: Subscription;
  public isLabel: boolean = false;
  public doNotRound: boolean = false;
  public inputSearchValue = '';
  public total = 0;
  public searchableFields: string[];
  public refresh = false;

  /**
   * Constructor of Glossary
   * @param _http HttpClient
   * @param _notifService NotifService
   * @param _barTypeService BarTypeService
   */
  constructor(
    private _http: HttpClient,
    private _notifService: NotifService,
    private _barTypeService: BarTypeService,
    private titleService: Title,
  ) {
    this._barTypes$ = this._barTypeService.barTypes$
      .subscribe({
        next: (barTypes: BarType[]) => {
          this.barTypes = barTypes;
        },
      });
    this.searchableFields = ['name', 'key_words'];
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Liste des index${tabSuffix}`);
    this._http.get<any[]>(`${environment.serviceEUrl}/glossary`).subscribe({
      next: (data) => {
        this._glossary = data;
        this._glossary_filtered = this._glossary;
        this._barTypeService.getAll();
      },
    });
  }

  ngOnDestroy(): void {
    this._barTypes$.unsubscribe();
  }

  /**
   * Show modal to create an index
   */
  public showCreationIndexModal(): void {
    this._name = null;
    this._keywords = null;
    this._id = null;
    this._kizeotags = [];
    this.barTypes.forEach((barType) => {
      const item: any = {};
      item[barType.name] = '';
      this._kizeotags.push(item);
    });
    this.locked = false;
    this.isLabel = false;
    this.isVisibleCreationIndexModal = true;
  }

  /**
   * Delete an index in the glossary
   * @param data to delete
   */
  public delete(data: any): void {
    if (data.is_updatable) {
      this._http.delete<any>(`${environment.serviceEUrl}/glossary/${data._id}`).subscribe({
        next: (_) => {
          for (let i = 0; i < this._glossary.length; i++) {
            if (this._glossary[i]._id === data._id) {
              this._glossary.splice(i, 1);
              this._glossary = [...this._glossary];
            }
          }
          this._notifService.showSuccessNotif('L\'index a bien été supprimé');
        },
        error: (err) => {
          this._notifService.showErrorNotif(err.error.message);
        },
      });
    }
  }

  /**
   * Return the value of _isVisible
   */
  public get isVisible(): boolean {
    return this._isVisible;
  }

  /**
   * Handle when user clicks on cancel button
   */
  public handleCancel(): void {
    this._isVisible = false;
    this.isVisibleCreationIndexModal = false;
  }

  /**
   * Show the modal to create an index
   */
  public showModal(data: any): void {
    const clonedData = JSON.parse(JSON.stringify(data));
    this._name = clonedData.name;
    this.nameId = clonedData.name_id;
    this.rank = clonedData.rank;
    this._keywords = clonedData.key_words;
    this._id = clonedData._id;
    this._kizeotags = [];
    this.barTypes.forEach((barType) => {
      const newItem: any = {};
      const clonedItem = clonedData.kizeo_tags.find((tag) => { return Object.keys(tag)[0] === barType.name; });
      if (clonedItem != null) {
        newItem[barType.name] = Object.values(clonedItem)[0];
      } else {
        newItem[barType.name] = '';
      }
      this._kizeotags.push(newItem);
    });
    this.locked = clonedData.locked;
    this.isLabel = clonedData.is_label;
    this.doNotRound = clonedData.do_not_round;
    this._isVisible = true;
  }

  /**
   * Return the value of _name
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Set the value of _name
   * @param name value to set
   */
  public set name(name: string) {
    this._name = name;
  }

  /**
   * Return the value of _kizeotag
   */
  public get kizeotags(): any[] {
    return this._kizeotags;
  }

  /**
   * Sets the value of _kizeotag
   * @param newtags
   */
  public set kizeotags(newtags: any[]) {
    this._kizeotags = newtags;
  }

  /**
   * Return the list of keywords
   */
  public get keywords(): string {
    return this._keywords;
  }

  /**
   * Set the list of keywords
   * @param keywords
   */
  public set keywords(keywords: string) {
    this._keywords = keywords;
  }

  /**
   * Return the value of _id
   */
  public get id(): string {
    return this._id;
  }

  /**
   * close the modal when the user clicks on Ok and create the index
   */
  public handleOk(isCreateMode: boolean): void {
    this._update(isCreateMode);
    this._isVisible = false;
    this.isVisibleCreationIndexModal = false;
  }

  /**
   * update an index of the glossary
   * @private
   */
  private _update(isCreateMode: boolean): void {
    // set keywords to lowercase
    if (this._keywords) {
      this._keywords = this._keywords.toLowerCase();
      this._keywords.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    const body = {
      name: this.name,
      nameId: this.nameId,
      rank: this.rank,
      key_words: this.keywords,
      kizeo_tags: this.kizeotags,
      locked: this.locked,
      is_label: this.isLabel,
      do_not_round: this.doNotRound,
    };

    if (isCreateMode) {
      // Is create index
      this._http.post<any>(`${environment.serviceEUrl}/glossary`, body).subscribe({
        next: (_) => {
          this._http.get<any>(`${environment.serviceEUrl}/glossary`).subscribe({
            next: (data) => {
              this._glossary = [...data];
              this._glossary_filtered = this._glossary;
              this._notifService.showSuccessNotif('L\'index a bien été ajouté');
              this._name = null;
              this.nameId = null;
              this.rank = null;
              this._keywords = null;
              this._kizeotags = [];
              this.locked = false;
              this.isLabel = false;
              this.doNotRound = false;
            },
          });
        },
        error: (err) => {
          this._notifService.showErrorNotif(err.error.message);
          this._name = null;
          this.nameId = null;
          this.rank = null;
          this._keywords = null;
          this._kizeotags = [];
          this.locked = false;
          this.isLabel = false;
          this.doNotRound = false;
        },
      });
    } else {
      // Is edit index
      this._http.patch<any>(`${environment.serviceEUrl}/glossary/${this._id}`, body).subscribe({
        next: (data) => {
          for (let i = 0; i < this._glossary.length; i++) {
            if (this._glossary[i]._id === data._id) {
              this._glossary[i] = data;
              this._glossary = [...this._glossary];
              this.refresh = !this.refresh;
            }
          }
          this._notifService.showSuccessNotif('L\'index a bien été modifié');
          this._name = null;
          this.nameId = null;
          this.rank = null;
          this._keywords = null;
          this._kizeotags = [];
          this.locked = false;
          this.isLabel = false;
          this.doNotRound = false;
        },
        error: (err) => {
          this._notifService.showErrorNotif(err.error.message);
          this._name = null;
          this.nameId = null;
          this.rank = null;
          this._keywords = null;
          this._kizeotags = [];
          this.locked = false;
          this.isLabel = false;
          this.doNotRound = false;
        },
      });
    }
  }

  /**
   * Return the glossary
   */
  public get glossary(): any[] {
    return this._glossary;
  }

  /**
   * Return the glossary
   */
  public get glossary_filtered(): any[] {
    return this._glossary_filtered;
  }

  /**
   * Return true if the set is loading, otherwise false
   */
  public get isLoading(): boolean {
    return this._glossary === undefined;
  }

  /**
   * Display Kizéo tags in html view
   */
  public displayKizeoTags(data: any): string {
    if (data.kizeo_tags == null || !Array.isArray(data.kizeo_tags)) {
      return 'Aucun';
    }
    let template = '';
    const that = this;
    data.kizeo_tags.forEach((element) => {
      Object.entries(element).forEach((entry) => {
        const [key, value] = entry;
        if (value !== null) {
          template += `<label class="${that.getLabelClass(key)}">${key} : </label>`;
          const stringValue: string = `${value}`;
          const array = stringValue.split(',');
          for (let i = 0; i < array.length; i++) {
            template += `<li>${array[i]}</li>`;
          }
          template += '</br>';
        }
      });
    });
    return template;
  }

  /**
   * Get the value associated to a key in _kizeotags on input change
   */
  public getKizeoTagValue(barTypeName: string): string {
    for (const tag of this._kizeotags) {
      if (Object.keys(tag)[0] === barTypeName) {
        return tag[barTypeName];
      }
    }
    return '';
  }

  /**
   * Edit the key-value array _kizeotags on input change
   */
  public onKizeoTagChange(barTypeName: string, event: any): void {
    for (const tag of this._kizeotags) {
      if (Object.keys(tag)[0] === barTypeName) {
        tag[barTypeName] = event.target.value;
        break;
      }
    }
  }

  onFilteredData(data): void {
    this._glossary_filtered = data;
  }

  public getLabelClass(key: string): string {
    switch (key) {
      case 'BAR-EN-101': return 'label label-danger';
      case 'BAR-EN-102': return 'label label-primary';
      case 'BAR-EN-103': return 'label label-success';
      case 'BAT-EN-101': return 'label label-warning';
      case 'BAT-EN-103': return 'label label-info';
      default: return 'label label-default';
    }

  }

}
