/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotifService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { PeriodBatch } from '../types/periodbatch.type';
import { Period } from '../types/period.type';
import { InspectionsPeriodEditModalComponent } from './inspections-period-edit-modal/inspections-period-edit-modal.component';
import { BarTypeService } from '../services/bar-type.service';

@Component({
  selector: 'eros-front-period',
  templateUrl: './inspections-period.component.html',
  styleUrls: ['./inspections-period.component.css'],
})
export class InspectionsPeriodComponent implements OnInit {

  private _periods: Period[];
  private _periodBatch: PeriodBatch[];
  public date: Date = null;
  public nameCreate: string = null;
  public nameCreateBatch: string = null;
  private _barTypeCreateBatch: string = null;
  private _datesCreate: Date[] = null;
  public isVisible: boolean = false;
  public currentSelectedBatch: PeriodBatch;
  public periodsToCheck: string[] = [];
  public satisfactionRate: number;

  /**
   * Constructor of Glossary
   * @param _http HttpClient
   * @param _notifService NotifService
   */
  constructor(
    private _http: HttpClient,
    private _notifService: NotifService,
    private _barTypeService: BarTypeService,
    private modalService: NzModalService,
  ) { }

  ngOnInit(): void {
    this._http.get<Period[]>(`${environment.serviceEUrl}/period`).subscribe({
      next: (data) => {
        this._periods = data;
        this._http.get<PeriodBatch[]>(`${environment.serviceEUrl}/period-batch`).subscribe({
          next: (periodData) => {
            this._periodBatch = periodData;
            this._barTypeService.getAll();
          },
        });
      },
    });
  }

  /**
   * Add a period to the list of periods to check if not already in array, otherwise remove from array
   * @param periodId id of the period
   */
  public checkPeriod(periodId: string): void {
    if (this.periodsToCheck.includes(periodId)) {
      this.periodsToCheck.splice(this.periodsToCheck.indexOf(periodId), 1);
    } else {
      this.periodsToCheck.push(periodId);
    }
  }

  /**
   * Return the value of _barTypeCreateBatch
   */
  public get barTypeCreateBatch(): string {
    return this._barTypeCreateBatch;
  }

  public set barTypeCreateBatch(val: string) {
    // @ts-ignore
    val = val.replaceAll(' ', '-');
    this._barTypeCreateBatch = val.toUpperCase();
  }

  /**
   * Show modal to update a period batch
   */
  public showModal(data: PeriodBatch): void {
    this.currentSelectedBatch = data;
    this.periodsToCheck = this.currentSelectedBatch.period_ids;
    this.isVisible = true;
  }

  /**
   * Return true if the id of the period in parameter is contained in the list of ids of the current selected batch period
   * @param id id of the period
   */
  public isChecked(id: string): boolean {
    for (let i = 0; i < this.currentSelectedBatch.period_ids.length; i++) {
      if (this.currentSelectedBatch.period_ids[i] === id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Close modal to update a period batch and proceed update
   */
  public handleOk(): void {
    const payload = {
      period_ids: this.periodsToCheck,
    };
    this._http.patch<PeriodBatch>(`${environment.serviceEUrl}/period-batch/${this.currentSelectedBatch._id}`, payload).subscribe({
      next: (data) => {
        const updatedPeriodBatch = this.periodBatch.filter((item) => { return item._id === this.currentSelectedBatch._id; });
        this.periodBatch[this.periodBatch.indexOf(updatedPeriodBatch[0])] = data;
        this._notifService.showSuccessNotif('Le groupe de périodes a bien été mit à jour.');
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });

    this.isVisible = false;
  }

  /**
   * Close modal to update a period batch
   */
  public handleCancel(): void {
    this.isVisible = false;
  }

  /**
   * Delete a specific period and update the list of periods
   * @param id id of the period to delete
   */
  public deletePeriod(id: string): void {
    this._http.delete<any>(`${environment.serviceEUrl}/period/${id}`).subscribe({
      next: (_) => {
        for (let i = 0; i < this._periods.length; i++) {
          if (this._periods[i]._id === id) {
            this._periods.splice(i, 1);
            this._periods = [...this._periods];
          }
        }
        this._notifService.showSuccessNotif('La période a bien été supprimée');
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });
  }

  /**
   * Delete a specific batch of periods and update the list of period batch
   * @param id id of the period batch to delete
   */
  public deletePeriodBatch(id: string): void {
    this._http.delete<any>(`${environment.serviceEUrl}/period-batch/${id}`).subscribe({
      next: (_) => {
        for (let i = 0; i < this._periodBatch.length; i++) {
          if (this._periodBatch[i]._id === id) {
            this._periodBatch.splice(i, 1);
            this._periodBatch = [...this._periodBatch];
          }
        }
        this._notifService.showSuccessNotif('Le groupe a bien été supprimée');
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });
  }

  /**
   * Return the value of _datesCreate
   */
  public get dates(): Date[] {
    return this._datesCreate;
  }

  /**
   * Set the value of _datesCreate
   * @param dates Date[]
   */
  public set dates(dates: Date[]) {
    this._datesCreate = dates;
  }

  /**
   * Set the value of _datesCreate
   * @param dates Date[]
   */
  public assignDates(dates: Date[]): void {
    this._datesCreate = dates;
  }

  /**
   * Create a new period
   */
  public createPeriod(): void {
    const payload = {
      name: this.nameCreate,
      from: this._datesCreate[0],
      to: this._datesCreate[1],
      sat_rate: this.satisfactionRate,
    };
    this._http.post<Period>(`${environment.serviceEUrl}/period`, payload).subscribe({
      next: (data) => {
        this._periods = [...this._periods, data];
        this._notifService.showSuccessNotif('La période a bien été créée');
        // reset the form after creation of the period
        this.nameCreate = null;
        this._datesCreate = null;
        this.satisfactionRate = null;
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });
  }

  /**
   * Create a group of periods
   */
  public createPeriodBatch(): void {
    const payload = {
      name: this.nameCreateBatch,
      bar_type: this.barTypeCreateBatch,
      periods_ids: [],
    };

    this._http.post<PeriodBatch>(`${environment.serviceEUrl}/period-batch`, payload).subscribe({
      next: (data) => {
        this._periodBatch.push(data);
        this._periodBatch = [...this._periodBatch];
        this._notifService.showSuccessNotif('Le groupe a bien été créé');
        this.nameCreateBatch = null;
        this._barTypeCreateBatch = null;
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });
  }

  /**
   * Show edit period modal
   */
  public showEditPeriodModal(period: Period): void {
    this.modalService.create({
      nzComponentParams: {
        period: period,
      },
      nzContent: InspectionsPeriodEditModalComponent,
      nzOnOk: (comp: InspectionsPeriodEditModalComponent) => {
        const payload = {
          name: comp.name,
          from: comp.dates[0],
          to: comp.dates[1],
          sat_rate: comp.satisfactionRate,
        };
        this._http.patch<Period>(`${environment.serviceEUrl}/period/${period._id}`, payload).subscribe({
          next: (_) => {
            this._http.get<Period[]>(`${environment.serviceEUrl}/period`).subscribe({
              next: (data) => {
                this._periods = data;
                this._notifService.showSuccessNotif('La période a bien été modifiée');
              },
            });
          },
          error: (err) => {
            this._notifService.showErrorNotif(err.error.message);
          },
        });
      },
    });

  }

  /**
   * Enable the button to create a period
   */
  public enabledCreationButton(): boolean {
    return this.nameCreate != null && this._datesCreate != null && this.satisfactionRate != null;
  }

  /**
   * Enable the button to create a batch of periods
   */
  public enabledCreationBatchButton(): boolean {
    return this.nameCreateBatch != null && this.barTypeCreateBatch != null;
  }

  /**
   * Return the list of all existing periods
   */
  public get periods(): Period[] {
    return this._periods;
  }

  /**
   * Return the value of _periodBatch
   */
  public get periodBatch(): PeriodBatch[] {
    return this._periodBatch;
  }

  /**
   * Return the value of _periods
   */
  public get isLoading(): boolean {
    return this._periods === undefined;
  }

}
