import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { AffairContact, AffairProduct, Contact, Group, Intervention, MlSociety, SelectModel, SubmitButton, User } from '@libs/models/src';
import { AffairContactService, ContactSelectParameters, ContactService, InterventionService, NotifService, SelectService, SettingService, SubmitButtonService, SwalService, UnsubscribeOnDestroy, UserService } from '@libs/services/src';
import { Subscription, throwError, zip } from 'rxjs';
import { AffairMarketType, InterventionStatusEnum } from '@eros-front/enum';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ContactsCallModalComponent } from '@libs/components/src/lib/contacts-call-modal/contacts-call-modal.component';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthentificationService } from '@libs/auth/src';
import { InterventionInskyService } from '@libs/services/src/lib/intervention-insky.service';
import { InterventionEditProgramModalComponent } from './intervention-edit-program-modal/intervention-edit-program-modal.component';
import { InterventionEditShiftModalComponent } from './intervention-edit-shift-modal/intervention-edit-shift-modal.component';
import { InterventionEditDeprogramModalComponent } from './intervention-edit-deprogram-modal/intervention-edit-deprogram-modal.component';
import { InterventionEditCancelModalComponent } from './intervention-edit-cancel-modal/intervention-edit-cancel-modal.component';
import { InterventionUpdateStatusModalComponent } from '../intervention-update-status-modal/intervention-update-status-modal.component';

export interface InterventionCardAffairProduct {
  id: number;
  checked: boolean;
  productId: number;
  productName: string;
  quantity: number;
  price: number;
}

@Component({
  selector: 'app-intervention-edit-informations',
  templateUrl: './intervention-edit-informations.component.html',
  styleUrls: ['./intervention-edit-informations.component.scss'],
})

export class InterventionEditInformationsComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy, OnChanges {

  @Input() intervention: Intervention;
  @Output() interventionUpdated = new EventEmitter<Intervention>();

  private generatedDescription$: Subscription;
  private generatedName$: Subscription;
  public form: FormGroup;
  public technicians: SelectModel[];
  public assistants: SelectModel[];
  public conformities: SelectModel[];
  public contacts: Contact[];
  public worksSupervisors: any;
  public worksSupervisorsSelect = [];
  public shiftOrigins: any;
  public buildings: SelectModel[];
  public settings: any;
  public indicatorsInterventionsTypes: any = [];
  public interventionStatusEnum = InterventionStatusEnum;
  public marketTypeEnum = AffairMarketType;
  public selectContactsParams: ContactSelectParameters;
  public housings: any;
  public isSelectsInit = false;
  public submitButton: SubmitButton;
  public housingsSelected = [];
  public housingsFiltered = [];
  public worksSupervisorSelected = null;
  public affairProductsToProgram: AffairProduct[] = [];
  public affairProducts = [];
  public affairsStatuses = [];
  public documentsCustomersAttached$: Subscription;
  public documentsCustomersAttached: any;
  public affairsContacts: AffairContact[];
  public affairContactsSelect: SelectModel[] = [];
  public mlSocieties: MlSociety[] = [];
  public professions: SelectModel[] = [];
  public group: Group = null;
  public shiftForm: FormGroup;
  public deprogramForm: FormGroup;
  public programForm: FormGroup;
  public cancelForm: FormGroup;
  public standardsResponsesErrors: any = [];
  public isAdministrator: boolean = false;
  public isSyncInsky = false;

  constructor(
    private contactService: ContactService,
    private interventionService: InterventionService,
    private settingService: SettingService,
    private swalService: SwalService,
    private router: Router,
    private modalService: NzModalService,
    private notifService: NotifService,
    private affairContactService: AffairContactService,
    private submitButtonService: SubmitButtonService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthentificationService,
    private interventionInskyService: InterventionInskyService,
  ) {
    super();
    this.initSubscriptions();
  }

  ngOnInit(): void {

    if (this.intervention != null) {
      this.initSettings();
      this.getSelectsEdit(this.intervention.id);
      this.interventionService.getDocumentCustomersAttached(this.intervention.id);
      if (this.intervention.affair != null && this.intervention.affair.marketTypeId === AffairMarketType.STANDALONE_HOUSE) {
        this.interventionInskyService.isSyncInsky(this.intervention.id)
          .pipe(
            takeUntil(this.isDestroyed$),
          ).subscribe((response) => {
            this.isSyncInsky = response;
          });
      }

    }
    this.determinateSubmitButton();
    this.isAdministrator = this.authService.isAdministrator();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.intervention && changes.intervention.previousValue !== undefined && changes.intervention.previousValue !== changes.intervention.currentValue) {
      if (this.isSelectsInit) {
        this.form = this.interventionService.getEditForm(this.intervention, this.technicians, this.contacts);
        this.getAffairsProductToProgram();
        this.interventionService.initHousingsCheckboxes(this.form, this.housings, this.intervention.housings);
        if (this.buildings.length > 0) {
          this.housingsFiltered = this.interventionService.filterHousings(this.intervention.buildings, this.housings);
          this.interventionService.initHousingsCheckboxes(this.form, this.housingsFiltered, this.intervention.housings);
        }
        this.interventionInskyService.isSyncInsky(this.intervention.id)
          .pipe(
            takeUntil(this.isDestroyed$),
          ).subscribe((response) => {
            this.isSyncInsky = response;
          });
      }
      this.interventionUpdated.emit(this.intervention);
    }
  }

  ngOnDestroy(): void {
    this.generatedDescription$?.unsubscribe();
    this.generatedName$?.unsubscribe();
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  onSubmit(): void {
    if (this.isValidForm()) {
      this.submitButtonService.setDisabled(this.submitButton);
      this.interventionService.update(this.intervention.id, this.form)
        .pipe(
          takeUntil(this.isDestroyed$),
          finalize(() => {
            this.submitButtonService.setEnabled(this.submitButton);
          }),
        ).subscribe((response) => {
          this.notifService.showSuccessNotif(response);
          this.refreshIntervention();
        });
    }
  }

  showShiftModal(): void {
    this.shiftForm = this.interventionService.initShiftForm(this.intervention, this.technicians);
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditShiftModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.shiftForm,
        users: this.technicians,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.refreshIntervention();
    });
  }

  showProgramModal(): void {
    this.programForm = this.interventionService.initProgramForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditProgramModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        form: this.programForm,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.refreshIntervention();
    });
  }

  showDeprogramModal(): void {
    this.deprogramForm = this.interventionService.initDeprogramForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditDeprogramModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.deprogramForm,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.refreshIntervention();
    });
  }

  showCancelModal(): void {
    this.cancelForm = this.interventionService.initCancelForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditCancelModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.cancelForm,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.refreshIntervention();
    });

  }

  onOpenCallModalClicked(): void {
    const modalInstance = this.modalService.create({
      nzWidth: '80%',
      nzContent: ContactsCallModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        affairContacts: this.affairsContacts,
      },
      nzStyle: { top: '2vh' },
    });
  }

  confirm(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut confirmé.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.confirm(that.intervention.id)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
              this.submitButtonService.setEnabled(this.submitButton);
            }),
          ).subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.refreshIntervention();
          });
      }
    });
  }

  markAsFree(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut clôturée et offerte.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.markAsFree(that.intervention.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.refreshIntervention();
          });
      }
    });
  }

  markAsWaitingConfirmation(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en attente de confirmation client.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.markAsWaitingConfirmation(that.intervention.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.refreshIntervention();
          });
      }
    });
  }

  markAsBilled(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut clôturée et facturée.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.markAsBilled(that.intervention.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.refreshIntervention();
          });
      }
    });
  }

  onSendYzierClicked(): void {
    this.interventionService.pushToYsier(this.intervention.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      )
      .subscribe((response) => {
        this.notifService.showSuccessNotif(response);
      });
  }

  getSettingValue(key: string): void {
    return this.settings.find((x) => { return x.key === key; }).value;
  }

  goToAffairClicked(): void {
    this.router.navigateByUrl(`affairs/${this.intervention.affairId}/show`);
  }

  markAsReportsSent(): void {
    this.spinnerService.show('load-intervention');
    this.interventionService.markAsReportsSent(this.intervention.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.spinnerService.hide('load-intervention');
        }),
      ).subscribe((response) => {
        this.notifService.showSuccessNotif(response);
        this.refreshIntervention();
      });
  }

  markAsReportsNotSent(): void {
    this.spinnerService.show('load-intervention');
    this.interventionService.markAsReportsNotSent(this.intervention.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.spinnerService.hide('load-intervention');
        }),
      ).subscribe((response) => {
        this.notifService.showSuccessNotif(response);
        this.refreshIntervention();
      });
  }

  onOpenUpdateStatusModalClicked(): void {
    const modalInstance = this.modalService.create({
      nzContent: InterventionUpdateStatusModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
      },
      nzStyle: { top: '2vh' },
      nzOnOk: () => {
        this.refreshIntervention();
      },
    });
  }

  onCreateForInskyClicked(): void {
    this.spinnerService.show('load-intervention');
    this.interventionService.createForInsky(this.intervention.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.spinnerService.hide('load-intervention');
        }),
      ).subscribe((response) => {
        this.notifService.showSuccessNotif(response);
        this.refreshIntervention();
      });
  }

  get affairsProductsArray(): FormArray {
    return this.form.get('affairProducts') as FormArray;
  }

  private initSubscriptions(): void {
    this.generatedName$ = this.interventionService.generatedName$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ name: data });
          }
        }
      },
    );
    this.generatedDescription$ = this.interventionService.generatedDescription$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ generatedDescription: data });
          }
        }
      },
    );
    this.interventionService.documentsCustomersAttached$.subscribe(
      (data) => {
        if (data) {
          this.documentsCustomersAttached = data;

        }
      },
    );
  }

  private onChanges(): void {
    this.form.get('buildingsIds').valueChanges.subscribe((value) => {
      this.housingsFiltered = this.interventionService.filterHousings(value, this.housings, true);
      this.interventionService.initHousingsCheckboxes(this.form, this.housingsFiltered, this.intervention.housings);
    });
  }

  private determinateSubmitButton(): void {
    let text = '';
    let icon = null;
    icon = faSave;
    text = 'Valider les changements';
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true,
    };
    this.interventionService.setSubmitButton(this.submitButton);
  }

  private getAffairProducts(affairProductsToProgram, interventionAffairProducts) {
    let affairProducts = [];
    affairProducts = interventionAffairProducts;
    affairProductsToProgram.forEach((affairProduct) => {
      const found = interventionAffairProducts.find((x) => { return x.id === affairProduct.id; });
      if (!found) {
        affairProducts.push(affairProduct);
      }
    });
    return affairProducts;
  }

  private initSettings(): void {
    this.settingService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (settings) => {
          this.settings = settings;
        },
      );
  }

  private getSelectsEdit(id: number) {
    this.interventionService.getSelectsForEdit(id)
      .pipe(
        takeUntil(this.isDestroyed$),
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      ).subscribe(
        (selects) => {
          this.contacts = selects.contacts;
          this.conformities = selects.conformities;
          this.shiftOrigins = selects.shiftOrigins;
          this.indicatorsInterventionsTypes = selects.indicatorsInterventionsTypes;
          this.technicians = selects.technicians;
          this.housings = selects.housings;
          this.buildings = selects.buildings;
          this.affairsContacts = selects.affairsContacts;
          this.affairsStatuses = selects.affairsStatuses;
          this.professions = selects.professions;
          this.mlSocieties = selects.mlSocieties;
          this.assistants = selects.assistants;
          this.group = selects.group;
          this.form = this.interventionService.getEditForm(this.intervention, this.technicians, this.contacts);
          this.interventionService.initHousingsCheckboxes(this.form, this.housings, this.intervention.housings);
          this.getAffairsProductToProgram();
          if (this.buildings.length > 0) {
            this.housingsFiltered = this.interventionService.filterHousings(this.intervention.buildings, this.housings);
            this.interventionService.initHousingsCheckboxes(this.form, this.housingsFiltered, this.intervention.housings);
          }
          this.onChanges();
          this.affairContactsSelect = this.affairContactService.valueToFormSelectMultiple(this.affairsContacts);
          this.isSelectsInit = true;
        },
      );
  }

  private getAffairsProductToProgram() {
    this.interventionService.getAffairsProductsToProgram(null, this.intervention.affair.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      )
      .subscribe(
        (data) => {
          this.affairProductsToProgram = data;
          this.interventionService.initAffairProductsCheckboxes(this.form, this.affairProductsToProgram, this.intervention.affairProducts, this.intervention.affair.affairProducts);
          this.affairProducts = this.getAffairProducts(this.affairProductsToProgram, this.intervention.affairProducts);
        },
      );

  }

  private refreshIntervention() {
    this.interventionService.get(this.intervention.id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.intervention = data;
          this.interventionUpdated.emit(data);
        },
      );
  }

}
