import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlideInOutAnimation } from '@animations/slide-in-out';
import { Affair } from '@libs/models/src';
import { EventService, NotifService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-overview-affair-events-list',
  templateUrl: './overview-affair-events-list.component.html',
  styleUrls: ['./overview-affair-events-list.component.scss'],
  animations: [SlideInOutAnimation],
})
export class OverviewAffairEventsListComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() affair: Affair;
  @Input() loadingName: string;
  @Output() actionDone = new EventEmitter<any>();
  public isHoverId = null;
  public isDivVisible = true;

  constructor(
    private eventService: EventService,
    private spinnerService: NgxSpinnerService,
    private swalService: SwalService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onMouseEnter(event: any): void {
    this.isHoverId = event.id;
  }

  onMouseLeave(): void {
    this.isHoverId = null;
  }

  markAsClosed(event: any): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'évènement sera en clôturé.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.spinnerService.show(this.loadingName);
        this.eventService.markAsClosed(event.id)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
              this.spinnerService.hide(this.loadingName);
            }),
          )
          .subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.actionDone.emit();
          });
      }
    });
  }

  markAsCompleted(event: any): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'évènement sera en statut réalisé.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.spinnerService.show(this.loadingName);
        this.eventService.markAsCompleted(event.id)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
              this.spinnerService.hide(this.loadingName);
            }),
          )
          .subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.actionDone.emit();
          });
      }
    });
  }

  toggleDiv(): void {
    this.isDivVisible = !this.isDivVisible;
  }

}
