import { SettingsInvoicingComponent, SettingsProductsComponent, SettingsInterventionsComponent, SettingsConnectionsComponent } from '@eros-front/settings';
import { UserCreateComponent, UserEditComponent, UsersIndexComponent } from '@eros-front/users';
import { Routes } from '@angular/router';
import { AuthGuardService } from 'libs/auth/src/lib/auth-guard.service';
import { AdminGuardService } from 'libs/auth/src/lib/admin-guard.service';
import { CustomersSearchComponent } from 'libs/customers/src/lib/customers-search/customers-search.component';
/* import { CustomersConsultGraphComponent } from '@libs/customers/src/lib/customers-consult/customers-consult-graph/customers-consult-graph.component'; */
import { AffairsTabsComponent } from '@libs/affairs/src/lib/affairs-tabs/affairs-tabs.component';
import { AffairCreateComponent } from '@libs/affairs/src/lib/affair-create/affair-create.component';
import { AffairsMylinkIndexComponent } from '@libs/affairs/src/lib/affairs-mylink-index/affairs-mylink-index.component';
import { AffairDocumentCustomerImportComponent } from '@libs/affairs/src/lib/affair-document-customer-import/affair-document-customer-import.component';
import { AffairDocumentDeliveredImportComponent } from '@libs/affairs/src/lib/affair-document-delivered-import/affair-document-delivered-import.component';
import { AppWithoutSidebarComponent } from '@libs/layouts/src/lib/app-without-sidebar/app-without-sidebar.component';
import { CustomersShowComponent } from '@libs/customers/src/lib/customers-show/customers-show.component';
import { InvoicesTabsComponent } from '@libs/invoices/src/lib/invoices-tabs/invoices-tabs.component';
import { InvoiceManualCreateComponent } from '@libs/invoices/src/lib/invoice-manual-create/invoice-manual-create.component';
import { InvoiceShowComponent } from '@libs/invoices/src/lib/invoice-show/invoice-show.component';
import { SettingsCustomerRelationshipComponent } from '@libs/settings/src/lib/settings-customer-relationship/settings-customer-relationship.component';
import { SettingsAffairsComponent } from '@libs/settings/src/lib/settings-affairs/settings-affairs.component';
import { QuotationsIndexComponent } from '@libs/quotations/src/lib/quotations-index/quotations-index.component';
import { QuotationManualCreateComponent } from '@libs/quotations/src/lib/quotation-manual-create/quotation-manual-create.component';
import { QuotationShowComponent } from '@libs/quotations/src/lib/quotation-show/quotation-show.component';
import { CustomersCreateComponent } from '@libs/customers/src/lib/customers-create/customers-create.component';

// Configs
import { PaymentsIndexComponent } from '@libs/payments/src/lib/payments-index/payments-index.component';
import { PaymentCreateComponent } from '@libs/payments/src/lib/payment-create/payment-create.component';
import { LoginComponent } from '@libs/auth/src/lib/login/login.component';
import { RedirectComponent } from '@libs/auth/src/lib/redirect/redirect.component';

import { InvoicesExportGenerateComponent } from '@libs/invoices/src/lib/invoices-export-generate/invoices-export-generate.component';
import { AccountingExportsIndexComponent } from '@libs/invoices/src/lib/accounting-exports-index/accounting-exports-index.component';
import { SettingsAccountingComponent } from '@libs/settings/src/lib/settings-accounting/settings-accounting.component';
import { ExpensesAccountsListComponent } from '@libs/expenses-accounts/src/lib/expenses-accounts-list/expenses-accounts-list.component';
import { DocumentViewerComponent } from '@libs/components/src/lib/document-viewer/document-viewer.component';
import { AffairsExportsComponent } from '@libs/affairs/src/lib/affairs-exports/affairs-exports.component';
import { ProgrammedStandardsResponsesListComponent } from '@libs/customer-relationship/src/lib/programmed-standards-responses/programmed-standards-responses-list/programmed-standards-responses-list.component';
import { MailsSendListComponent } from '@libs/customer-relationship/src/lib/mails-send/mails-send-list/mails-send-list.component';
import { AffairCreateDqComponent } from '@libs/affairs/src/lib/affair-create-dq/affair-create-dq.component';
import { PurchasesOrdersCreateComponent } from '@libs/purchase-orders/src/lib/purchases-orders-create/purchases-orders-create.component';
import { PurchasesOrdersIndexComponent } from '@libs/purchase-orders/src/lib/purchases-orders-index/purchases-orders-index.component';
import { AffairShowAnalysisRpComponent } from '@libs/insky/src/_components/affairs-analysis-rp/affair-show-analysis-rp/affair-show-analysis-rp.component';
import { AffairsListAnalysisRpComponent } from '@libs/insky/src/_components/affairs-list-analysis-rp/affairs-list-analysis-rp.component';
import { DiagnosisEquipmentsIndexComponent } from '@libs/users/src/_components/diagnosis-equipments/diagnosis-equipments-index/diagnosis-equipments-index.component';
import { TechnicalSheetsIndexComponent } from '@libs/users/src/_components/technical-sheets/technical-sheets-index/technical-sheets-index.component';
import { EtransactionsIndexComponent } from '@libs/purchase-orders/src/lib/etransactions-index/etransactions-index.component';
import { InterventionInskyWaitingValidationListComponent } from '@libs/insky/src/_components/intervention-insky-list/intervention-insky-waiting-validation-list/intervention-insky-waiting-validation-list.component';
import { InterventionInskyNotValidListComponent } from '@libs/insky/src/_components/intervention-insky-list/intervention-insky-not-valid-list/intervention-insky-not-valid-list.component';
import { AffairCreateFakeComponent } from '@libs/affairs/src/_components/affair-create-fake/affair-create-fake.component';
import { RegenerateDocumentDeliveredComponent } from '@libs/insky/src/_components/regenerate-document-delivered/regenerate-document-delivered.component';
import { SettingsDashboardComponent } from '@libs/settings/src/lib/settings-dashboard/settings-dashboard/settings-dashboard.component';
import { SmsSendListComponent } from '@libs/customer-relationship/src/_components/sms/sms-send-list/sms-send-list.component';
import { InterventionsTabsComponent } from '@libs/interventions/src/_components/interventions/interventions-tabs/interventions-tabs.component';
import { InterventionsPreparationComponent } from '@libs/interventions/src/_components/interventions/interventions-preparation/interventions-preparation.component';
import { InterventionCreateComponent } from '@libs/interventions/src/_components/interventions/intervention-create/intervention-create.component';
import { InterventionEditComponent } from '@libs/interventions/src/_components/interventions/intervention-edit/intervention-edit.component';
import { CalendarComponent } from '@libs/interventions/src/_components/calendar/calendar.component';
import { EventsIndexComponent } from '@libs/interventions/src/_components/events/events-index/events-index.component';
import { UnauthorizedComponent } from '@libs/auth/src/lib/unauthorized/unauthorized.component';
import { InterventionInskyAbmDocumentNeededListComponent } from '@libs/insky/src/_components/intervention-insky-list/intervention-insky-abm-document-needed-list/intervention-insky-abm-document-needed-list.component';
import { AffairsXmlInspectionByAffairComponent } from '@libs/affairs/src/_components/affairs-xml-inspections/affairs-xml-inspection-by-affair/affairs-xml-inspection-by-affair.component';
import { AffairsXmlInspectionsListComponent } from '@libs/affairs/src/_components/affairs-xml-inspections/affairs-xml-inspections-list/affairs-xml-inspections-list.component';
import { IncomingCallSheetComponent } from './incoming-call-sheet/incoming-call-sheet.component';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { environment } from '../environments/environment';
import { StatisticsRevenuesComponent } from './statistics/statistics-revenues/statistics-revenues.component';
import { StatisticsVolumesComponent } from './statistics/statistics-volumes/statistics-volumes.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RolesIndexComponent } from '../../../../libs/users/src/lib/roles-index/roles-index.component';
import { PermissionsIndexComponent } from '../../../../libs/users/src/lib/permissions-index/permissions-index.component';
import { PermissionCreateEditComponent } from '../../../../libs/users/src/lib/permission-create-edit/permission-create-edit.component';
import { RoleCreateEditComponent } from '../../../../libs/users/src/lib/role-create-edit/role-create-edit.component';
import { AffairShowComponent } from './affairs/affair-show/affair-show.component';
import { InspectionsSetCreateComponent } from './inspections/set/create/inspections-set-create.component';
import { InspectionsCustomerComponent } from './inspections/customer/inspections-customer.component';
import { ModelesComponent } from './inspections/modeles/modeles.component';
import { InspectionsGlossaryComponent } from './inspections/glossary/inspections-glossary.component';
import { InspectionsPeriodComponent } from './inspections/period/inspections-period.component';
import { InspectionsPeriodRulesComponent } from './inspections/period/inspections-period-rules/inspections-period-rules.component';
import { InspectionsSetManagerComponent } from './inspections/set/manager/inspections-set-manager.component';
import { InspectionsBaseLotComponent } from './inspections/base-lot/inspections-base-lot/inspections-base-lot.component';
import { InspectionsHistoryComponent } from './inspections/history/inspections-history.component';
import { InspectionsBetaComponent } from './inspections/inspections-beta/inspections-beta.component';
import { BarTypesIndexComponent } from './inspections/bar-types/bar-types-index/bar-types-index.component';
import { InspectionsGridLotComponent } from './inspections/inspections-grid-lot/inspections-grid-lot.component';
import { OverviewComponent } from './overview/overview.component';
import { OverviewItemsListComponent } from './overview/overview-pages/overview-items-list/overview-items-list.component';

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
    ],
  },
  { path: 'redirect', component: RedirectComponent },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AppWithSidebarComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'overview',
        component: OverviewComponent,
      },
      {
        path: 'overview/list/tile/:id',
        component: OverviewItemsListComponent,
      },
      {
        path: 'customers/search',
        component: CustomersSearchComponent,
      },
      {
        path: 'customer-relationship/programmed-standards-responses',
        component: ProgrammedStandardsResponsesListComponent,
      },
      {
        path: 'customer-relationship/mails-send',
        component: MailsSendListComponent,
      },
      {
        path: 'customer-relationship/sms-send',
        component: SmsSendListComponent,
      },
      {
        path: 'affairs/create',
        component: AffairCreateComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'affairs/create-dq',
        component: AffairCreateDqComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'affairs/index',
        component: AffairsTabsComponent,
      },
      {
        path: 'affairs/:id/show',
        component: AffairShowComponent,
      },
      {
        path: 'affairs/mylink/index',
        component: AffairsMylinkIndexComponent,
      },
      {
        path: 'affairs/exports',
        component: AffairsExportsComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'affairs/:id/document-customer/import',
        component: AffairDocumentCustomerImportComponent,
      },
      {
        path: 'affairs/:id/document-delivered/import',
        component: AffairDocumentDeliveredImportComponent,
      },
      {
        path: 'affairs/:id/document-delivered/regenerate',
        component: RegenerateDocumentDeliveredComponent,
      },
      {
        path: 'affairs/:ref/analysis-constatator',
        component: AffairShowAnalysisRpComponent,
      },
      {
        path: 'affairs/analysis-constatator',
        component: AffairsListAnalysisRpComponent,
      },
      {
        path: 'affairs/create-fake',
        component: AffairCreateFakeComponent,
      },
      {
        path: 'affairs-xml-inspections/affair/:mlRef',
        component: AffairsXmlInspectionByAffairComponent,
      },
      {
        path: 'affairs-xml-inspections/list',
        component: AffairsXmlInspectionsListComponent,
      },
      /*  {
        path: 'customers/consult/:id',
        component: CustomersConsultGraphComponent,
      }, */
      {
        path: 'customers/create',
        component: CustomersCreateComponent,
      },
      {
        path: 'customers/:id/show',
        component: CustomersShowComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'customers/:id/show/search/:contactSearch',
        component: CustomersShowComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'incoming-call',
        component: IncomingCallSheetComponent,
      },
      {
        path: 'interventions/index',
        component: InterventionsTabsComponent,
      },
      {
        path: 'interventions/preparation',
        component: InterventionsPreparationComponent,
      },
      {
        path: 'events/index',
        component: EventsIndexComponent,
      },
      {
        path: 'interventions/create/affair/:id',
        component: InterventionCreateComponent,
      },
      {
        path: 'interventions/:id/edit',
        component: InterventionEditComponent,
      },
      {
        path: 'interventions/waiting-validation',
        component: InterventionInskyWaitingValidationListComponent,
      },
      {
        path: 'interventions/abm-document-needed',
        component: InterventionInskyAbmDocumentNeededListComponent,
      },
      {
        path: 'interventions/not-valid',
        component: InterventionInskyNotValidListComponent,
      },
      {
        path: 'invoices/index',
        component: InvoicesTabsComponent,
      },
      {
        path: 'invoices/manual/create',
        component: InvoiceManualCreateComponent,
      },
      {
        path: 'invoices/export/generate',
        component: InvoicesExportGenerateComponent,
      },
      {
        path: 'invoices/:id/show',
        component: InvoiceShowComponent,
      },
      {
        path: 'quotations/index',
        component: QuotationsIndexComponent,
      },
      {
        path: 'quotations/manual/create',
        component: QuotationManualCreateComponent,
      },
      {
        path: 'quotations/:id/show',
        component: QuotationShowComponent,
      },
      {
        path: 'payment/create',
        component: PaymentCreateComponent,
      },
      {
        path: 'payments/index',
        component: PaymentsIndexComponent,
      },
      {
        path: 'accounting/exports/index',
        component: AccountingExportsIndexComponent,
      },
      {
        path: 'expenses-accounts/index',
        component: ExpensesAccountsListComponent,
      },
      {
        path: 'etransactions/index',
        component: EtransactionsIndexComponent,
      },
      {
        path: 'stats/volumes',
        component: StatisticsVolumesComponent,
      },
      {
        path: 'stats/revenues',
        component: StatisticsRevenuesComponent,
      },
      {
        path: 'inspections/create',
        component: InspectionsSetCreateComponent,
      },
      {
        path: 'inspections/customer/:id',
        component: InspectionsCustomerComponent,
      },
      {
        path: 'inspections/modeles',
        component: ModelesComponent,
      },
      {
        path: 'inspections/glossary',
        component: InspectionsGlossaryComponent,
      },
      {
        path: 'inspections/period',
        component: InspectionsPeriodComponent,
      },
      {
        path: 'inspections/period/:id',
        component: InspectionsPeriodRulesComponent,
      },
      {
        path: 'inspections/set',
        component: InspectionsSetManagerComponent,
      },
      {
        path: 'inspections/base-lot',
        component: InspectionsBaseLotComponent,
      },
      {
        path: 'inspections/grid-lot',
        component: InspectionsGridLotComponent,
      },
      {
        path: 'inspections/history',
        component: InspectionsHistoryComponent,
      },
      {
        path: 'inspections/beta',
        component: InspectionsBetaComponent,
      },
      {
        path: 'inspections/bar-types',
        component: BarTypesIndexComponent,
      },
      {
        path: 'purchase-orders/create',
        component: PurchasesOrdersCreateComponent,
      },
      {
        path: 'purchase-orders/index',
        component: PurchasesOrdersIndexComponent,
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
      },
    ],
  },
  {
    path: '',
    component: AppWithoutSidebarComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'calendar',
        component: CalendarComponent,
      },
      {
        path: 'document/preview',
        component: DocumentViewerComponent,
      },
    ],
  },
  {
    path: '',
    component: AppWithSidebarComponent,
    canActivate: [AdminGuardService],
    children: [
      {
        path: 'admin/users/index',
        component: UsersIndexComponent,
      },
      {
        path: 'admin/user/create',
        component: UserCreateComponent,
      },
      {
        path: 'admin/users/:id',
        component: UserEditComponent,
      },
      {
        path: 'admin/roles/index',
        component: RolesIndexComponent,
      },
      {
        path: 'admin/permissions/index',
        component: PermissionsIndexComponent,
      },
      {
        path: 'admin/permission/create',
        component: PermissionCreateEditComponent,
      },
      {
        path: 'admin/permission/:id/edit',
        component: PermissionCreateEditComponent,
      },
      {
        path: 'admin/role/create',
        component: RoleCreateEditComponent,
      },
      {
        path: 'admin/role/:id/edit',
        component: RoleCreateEditComponent,
      },
      {
        path: 'admin/diagnosis-equipments/index',
        component: DiagnosisEquipmentsIndexComponent,
      },
      {
        path: 'admin/technical-sheets/index',
        component: TechnicalSheetsIndexComponent,
      },
      {
        path: 'settings/affairs',
        component: SettingsAffairsComponent,
        data: { appKey: environment.appKey },
      },
      {
        path: 'settings/products',
        component: SettingsProductsComponent,
      },
      {
        path: 'settings/invoicing',
        component: SettingsInvoicingComponent,
      },
      {
        path: 'settings/accounting',
        component: SettingsAccountingComponent,
      },
      {
        path: 'settings/interventions',
        component: SettingsInterventionsComponent,
      },
      {
        path: 'settings/connections',
        component: SettingsConnectionsComponent,
      },
      {
        path: 'settings/customer-relationship',
        component: SettingsCustomerRelationshipComponent,
      },
      {
        path: 'settings/dashboard',
        component: SettingsDashboardComponent,
      },
    ],
  },
];
