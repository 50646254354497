import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AffairContact, Contact } from '@libs/models/src';
import { AffairService, UnsubscribeOnDestroy } from '@libs/services/src';
import { SearchService } from '@libs/services/src/lib/search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-incoming-call-sheet',
  templateUrl: './incoming-call-sheet.component.html',
  styleUrls: ['./incoming-call-sheet.component.scss'],
})
export class IncomingCallSheetComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public phoneNumber: string;
  public contact: Contact | AffairContact;
  public isFound = null;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private titleService: Title,
    private spinnerService: NgxSpinnerService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Fiche contact${tabSuffix}`);
    this.route.queryParams
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((params) => {
        this.phoneNumber = params.phoneNumber;
        if (this.phoneNumber) {
          this.spinnerService.show('load-sheet');
          this.searchService.searchByPhone(this.phoneNumber)
            .pipe(
              finalize(() => {
                this.spinnerService.hide('load-sheet');
              }),
              catchError((error) => {
                this.isFound = false;
                return throwError(error);
              }),
            )
            .subscribe(
              (response) => {
                this.isFound = true;
                this.contact = response;
              },
            );
        }
      });
  }

  onRedirectAffairListClicked(): void {
    this.router.navigateByUrl('affairs/index');
  }

  onRedirectAffairCreateClicked(): void {
    this.router.navigateByUrl('affairs/create');
  }

}
