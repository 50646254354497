import { Component, OnInit, Input, ViewChild, AfterViewInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AffairProductService, AffairService, NotifService, SubmitButtonService, UnsubscribeOnDestroy } from '@eros-front/services';
import { Affair, AffairGoal, Product, ProductOffer, SelectModel, SubmitButton } from '@eros-front/models';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { ApplicationDomainVerifications } from '@libs/customers/src/_models/application-domain.model';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';

@Component({
  selector: 'affair-product-modal-add',
  templateUrl: './affair-product-modal-add.component.html',
  styleUrls: ['./affair-product-modal-add.component.scss'],
})
export class AffairProductModalAddComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  @Input() affair: Affair;
  @Input() products: Product[];
  @Input() productsOffers: ProductOffer[];
  @Input() techniciansItems: SelectModel[];
  @Input() affairsGoals: AffairGoal[];
  @Input() affairsGoalsSelect: SelectModel[];
  @Input() appKey: string = 'abm';
  @Input() applicationDomainVerifications: ApplicationDomainVerifications = null;
  @Input() productsStatuses: any[];
  public selectedProductOffer: number = null;

  public readonly P3_NRJ_ID = 96;
  public form: FormGroup;
  public affairProducts: FormArray;
  public affairFields: FormArray;
  public submitButton: SubmitButton;
  public selectedProducts: Product[] = [];
  public selectedProductsIds: number[] = [];

  constructor(
    private modal: NzModalRef,
    private formBuilder: FormBuilder,
    private affairService: AffairService,
    private affairProductService: AffairProductService,
    private notifService: NotifService,
    private submitButtonService: SubmitButtonService,
    private affairGoalService: AffairGoalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormValues();
    if (this.affairsGoals) {
      this.affairsGoalsSelect = this.affairGoalService.getSelectByMarketType(this.affairsGoals, this.affair.marketTypeId);
    }
    this.determinateSubmitButton();
  }

  onAffairProductAdd(item: Product, selectedOfferProductId?: number): void {
    const found = this.selectedProducts.find((x) => { return x.id === item.id; });
    if (!found) {
      this.selectedProducts.push(item);
    }
    this.affairProducts = this.form.get('affairProducts') as FormArray;
    this.affairProducts.push(this.affairProductService.getAddForm(this.affair, item, this.techniciansItems, selectedOfferProductId));
    this.updateAffairsGoalsValidity();
    // We also add affair fields
    this.affairFields = this.form.get('affairFields') as FormArray;
    item.fields.forEach((field) => {
      if (!this.affairFields.value.some((e) => { return e.id === field.id; })
        && !this.affair.fields.some((e) => { return e.id === field.id; })) {
        this.affairFields.push(this.affairService.createAffairFieldForm(field, this.affair));
      }
    });
  }

  onAffairProductRemove(item: any): void {
    for (let i = 0; i < this.affairProducts.controls.length; i++) {
      const control = this.affairProducts.controls[i];
      if (control.value.productId === item.value.id) {
        this.affairProducts.removeAt(i);
        break;
      }
    }
    // We keep a field if at least one product need it
    const fieldsIndexesToDelete = [];
    let offsetDelete = 0;
    for (let i = 0; i < this.affairFields.controls.length; i++) {
      let atLeastOneNeeded = false;
      const control = this.affairFields.controls[i];
      this.selectedProducts.forEach((product) => {
        product.fields.forEach((f) => {
          if (f.id === control.value.id) {
            atLeastOneNeeded = true;

          }
        });
      });
      if (!atLeastOneNeeded) {
        fieldsIndexesToDelete.push(i);
      }
    }
    fieldsIndexesToDelete.forEach((i) => {
      this.affairFields.removeAt(i - offsetDelete);
      offsetDelete += 1;
    });
    this.updateAffairsGoalsValidity();
  }

  submitForm(): void {
    if (this.isValidForm()) {
      this.affairProductService.storeMultiple(this.form)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          },
          (error) => {
            this.notifService.showErrorNotif(error);
          },
        );
    }
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  isDisplayAffairsGoalsSelect(): boolean {
    if (this.form) {
      return this.appKey === 'nrj'
      && this.form.value.affairProducts.find((x) => { return x.productId === this.P3_NRJ_ID; })
      && this.applicationDomainVerifications
      && this.applicationDomainVerifications.applicationDomain != null;
    }
    return false;
  }

  onSelectProductOfferChange(): void {
    const affairProductsArray = this.form.get('affairProducts') as FormArray;
    // Clear the form array
    affairProductsArray.clear();
    if (this.selectedProductOffer === null) {
      this.selectedProductsIds = [];
      return;
    }
    this.selectedProductsIds = this.productsOffers.find((x) => { return x.id === this.selectedProductOffer; }).products.map((x) => { return x.id; });
    this.selectedProducts = this.selectedProductsIds.map((productId) => {
      return this.products.find((product) => { return product.id === productId; });
    }).filter((product) => { return product !== undefined; });
    this.selectedProducts.forEach((product) => {
      this.onAffairProductAdd(product, this.selectedProductOffer);
    });

  }

  private updateAffairsGoalsValidity(): void {
    if (this.isDisplayAffairsGoalsSelect() === true) {
      this.form.controls.affairsGoalsIds.setValidators(Validators.required);
      this.form.controls.affairsGoalsIds.updateValueAndValidity();
    } else {
      this.form.controls.affairsGoalsIds.setValidators(null);
      this.form.controls.affairsGoalsIds.updateValueAndValidity();
    }
  }

  private initFormValues(): void {
    if (this.affair) {
      let selectedGoalsIds = null;
      if (this.affair.affairsGoals) {
        selectedGoalsIds = this.affair.affairsGoals.map((x) => { return x.id; });
      } else if (this.applicationDomainVerifications != null && this.applicationDomainVerifications.affairGoalId != null) {
        selectedGoalsIds = [this.applicationDomainVerifications.affairGoalId];
      }
      this.affairProducts = new FormArray([]);
      this.affairFields = new FormArray([]);
      this.form = this.formBuilder.group({
        affairId: [this.affair.id, Validators.required],
        affairsGoalsIds: [selectedGoalsIds],
        affairProducts: this.formBuilder.array([]),
        affairFields: this.formBuilder.array([]),
      });
    }
  }

  private determinateSubmitButton(): void {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un ou plusieurs code(s) produit(s)',
      text: 'Ajouter',
      icon: faPlus,
    });
    this.affairProductService.setSubmitButton(this.submitButton);
  }

}
