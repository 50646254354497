import { catchError } from 'rxjs/operators';
import { ErosResponse, SelectModel, User } from '@eros-front/models';
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';

@Injectable()
export class UserService extends ModelWithDatatableAndCrudObservable {

  private route = '/users';
  private redirectUrl = '/admin/users/index';
  public usersSelect$ = new BehaviorSubject<any>(undefined);

  constructor(
    http: HttpClient,
    notifService: NotifService,
    submitButtonService: SubmitButtonService,
    swalService: SwalService,
    router: Router,
    apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
  ) {
    super(
      http,
      notifService,
      submitButtonService,
      swalService,
      apiService,
      router,
    );
  }

  public initDataTable(selector: string, columns: DataTableColumn[]): void {
    return super.initializeDataTable({
      url: `${this.route}/list`,
      selector: `#${selector}`,
      dataTableColumns: columns,
    });
  }

  public get(id: number): Observable<User> {
    return super.get(id, this.route);
  }

  public getAll(): Observable<User[]> {
    return super.getAll(this.route);
  }

  public store(form: FormGroup): Observable<ErosResponse> {
    return super.store(form.value, this.route);
  }

  public update(id: number, form: FormGroup): Observable<ErosResponse> {
    return super.update(id, form.value, this.route, this.redirectUrl);
  }

  public delete(id: number): Observable<ErosResponse> {
    return super.delete(id, this.route);
  }

  public deactivate(id: number): void {
    this.apiService.get(`${this.route}/deactivate/${id}`)
      .pipe(
        catchError((error) => {
          this.notifService.showErrorNotif(error);
          return throwError(error);
        }),
      )
      .subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
        },
      );
  }

  public logAs(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/log-as/${id}`)
      .pipe(
        catchError((error) => {
          this.notifService.showErrorNotif(error);
          return throwError(error);
        }),
      );
  }

  public getCreateForm(): FormGroup {
    const lastname = '';
    const firstname = '';
    const email = '';
    const color = '#000000';
    const phone = '';
    const aircallId = '';
    const initials = '';
    const password = '';
    const postalCode = '';
    const locality = '';
    const selectedMlAgencies = [];
    return this.formBuilder.group({
      initials: [initials],
      lastname: [lastname, Validators.required],
      firstname: [firstname, Validators.required],
      email: [email, Validators.required],
      color: color,
      phone: phone,
      // eslint-disable-next-line camelcase
      aircallId: aircallId,
      password: [password, Validators.required],
      roles: new FormArray([]),
      selectedMlAgencies: selectedMlAgencies,
      postalCode: postalCode,
      locality: locality,
    });
  }

  public getEditForm(user: User): FormGroup {
    return this.formBuilder.group({
      initials: [user.initials],
      lastname: [user.lastname, Validators.required],
      firstname: [user.firstname, Validators.required],
      email: [user.email, Validators.required],
      sendAsEmail: [user.sendAsEmail],
      sendAsEmailSecondary: [user.sendAsEmailSecondary],
      personalNumber: [user.personalNumber],
      postalCode: [user.postalCode],
      locality: [user.locality],
      color: user.color,
      phone: user.phone,
      // eslint-disable-next-line camelcase
      aircallId: user.aircallId,
      roles: new FormArray([]),
      selectedMlAgencies: [],
      marketsTypes: [user.marketsTypes.map((x) => { return x.id; })],
    });
  }

  getAllTechnicians(): Observable<User[]> {
    return this.apiService.get('/users/technician');
  }

  getAllTechniciansWithFilters(filters: any): Observable<User[]> {
    return this.apiService.post('/users/technician/filters', filters);
  }

  getAllAssistants(): Observable<User[]> {
    return this.apiService.get('/users/assistant');
  }

  getTechniciansSelect(): Observable<SelectModel[]> {
    return this.apiService.get('/users/technician/select');
  }

  getUsersSelect(): Observable<SelectModel[]> {
    return this.apiService.get('/users/select');
  }

  updateMarketsTypes(marketsTypesIds: number[]): Observable<any> {
    return this.apiService.put(`${this.route}/update-markets-types`, {
      marketsTypesIds: marketsTypesIds,
    });
  }

  public valueToFormSelectMultiple(values: any[]): SelectModel[] {
    if (values != null) {
      return values.map((x) => { return { label: [x.lastname, x.firstname].filter(Boolean).join(' '), value: x.id }; });
    }
    return null;
  }

  public formatToSelectMultiple(users: User[]): SelectModel[] {
    const usersSelect = [];
    users.forEach((user: User) => {
      usersSelect.push({
        label: user.fullName,
        value: user.id,
      });

    });
    return usersSelect;
  }

}
