import Swal from 'sweetalert2';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AffairContactService, ContactService, NotifService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { Subscription } from 'rxjs';
import { Affair, AffairContact, Contact } from '@libs/models/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AircallService } from '@libs/services/src/lib/aircall.service';
import { AffairShowContactsModalComponent } from './affair-show-contacts-modal/affair-show-contacts-modal.component';

@Component({
  selector: 'app-affair-show-contacts',
  templateUrl: './affair-show-contacts.component.html',
  styleUrls: ['./affair-show-contacts.component.scss'],
})
export class AffairShowContactsComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  @Input() affair: Affair;
  @Input() affairContacts: AffairContact[] = [];
  @Input() appKey: string;
  public research: string = '';
  public contacts: Contact[] = [];
  public agencyContacts: Contact[] = [];
  public form: FormGroup;

  constructor(
    private affairContactService: AffairContactService,
    private contactService: ContactService,
    private notifService: NotifService,
    private swalService: SwalService,
    private modalService: NzModalService,
    private spinnerService: NgxSpinnerService,
    private aircallService: AircallService,
  ) {
    super();
  }

  ngOnInit(): void {
    // this.initContacts();
    this.initAgencyContacts();
  }

  onSearchChange(value: string): void {
    this.research = value;
    this.initAgencyContacts();
  }

  onClearSearchClicked(): void {
    this.research = '';
    this.initAgencyContacts();
  }

  onQuickAddClicked(contactId: number): void {
    this.affairContactService.quickStore(this.affair.id, contactId)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (response) => {
          this.notifService.showSuccessNotif(response);
          this.refreshAffairsContacts();
        },
      );
  }

  onTextCopied(text: string): void {
    navigator.clipboard.writeText(text);
    this.notifService.showSuccessNotif(`${text} copié dans le presse-papier avec succès !`);
  }

  onAddEditContactClicked(contact?: AffairContact | Contact): void {
    this.form = this.affairContactService.getForm(this.affair.id, contact);
    const modalInstance = this.modalService.create({
      nzContent: AffairShowContactsModalComponent,
      nzComponentParams: {
        affair: this.affair,
        contact: contact,
        form: this.form,
        contacts: this.agencyContacts,
      },
      nzOnOk: () => { return this.refreshAffairsContacts(); },
    });
  }

  onEditContactClicked(id: number): void {
    this.affairContactService.get(id);
  }

  onDeleteContactClicked(id: number): void {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'Le contact sera retiré de l\'affaire de manière irréversible.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.affairContactService.delete(id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (success) => {
              this.notifService.showSuccessNotif(success);
              this.refreshAffairsContacts();
            },
          );
      }
    });
  }

  private initAgencyContacts() {
    this.contactService.getAllByAffair(this.affair.id, this.research)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (contacts) => {
          this.agencyContacts = contacts;
        },
      );
  }

  private initContacts() {
    this.contactService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (contacts) => {
          this.agencyContacts = contacts;
        },
      );
  }

  private refreshAffairsContacts() {
    this.spinnerService.show('load-affairs-contacts');
    this.affairContactService.getAllByAffair(this.affair.id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((data) => {
        this.spinnerService.hide('load-affairs-contacts');
        this.affairContacts = data;
        if (data.length === 0 && this.affair.isAffairContactsNeeded) {
          this.notifService.showWarningNotif("Attention, une ou plusieurs conditions d'envoie nécessitent un contact affaire.");
        }
      });
  }

  callContact(phoneNumber: string) {
    if (this.aircallService.telephonyWindow !== null && !this.aircallService.telephonyWindow.closed) {
      this.aircallService.makeCall(phoneNumber).subscribe();
      this.aircallService.telephonyWindow.focus();
    } else {
      this.aircallService.getUserAvailabitlty().subscribe(
        (response) => {
          if (response.availability === 'offline') {
            this.aircallService.openTelephonyWindow(phoneNumber);
          } else {
            this.aircallService.makeCall(phoneNumber).subscribe();
          }
        },
      );
    }
  }

}
