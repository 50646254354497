import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AffairTagsModalComponent } from '@libs/components/src/lib/affair-tags-modal/affair-tags-modal.component';
import { Affair, Agency, Intervention, Tag } from '@libs/models/src';
import { AffairService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affair-show-headband',
  templateUrl: './affair-show-headband.component.html',
  styleUrls: ['./affair-show-headband.component.scss'],
})
export class AffairShowHeadbandComponent implements OnInit {

  @Input() affair: Affair;
  @Input() appKey: string;
  @Input() consteoUrl: string;
  @Input() isRenovationMarket: boolean;
  @Input() tags: Tag[] = [];
  public consteoWindow: Window = null;

  constructor(
    private router: Router,
    private affairService: AffairService,
    private modalService: NzModalService,
  ) { }

  ngOnInit(): void {
  }

  redirectShowAgency(agency: Agency): void {
    window.open(`customers/${agency.ref}/show`);
  }

  redirectShowIntervention(intervention: Intervention): void {
    this.router.navigateByUrl(`interventions/${intervention.id}/edit`);
  }

  getTextAffairToCopy(): string {
    let str = `${this.affair.mlRef}`;
    if (this.affair.finalCustomerLastnameSecondary) {
      str += ` - ${this.affair.finalCustomerLastnameSecondary}/${this.affair.finalCustomerLastnameSecondary} ${this.affair.finalCustomerFirstname}/${this.affair.finalCustomerLastname}`;
    } else {
      str += ` - ${this.affair.finalCustomerLastname} ${this.affair.finalCustomerFirstname}/${this.affair.finalCustomerLastname}`;
    }
    return str;

  }

  openConsteoWindow(event: Event): void {
    event.preventDefault();

    if (this.consteoWindow === null || this.consteoWindow.closed) {
      this.consteoWindow = window.open(`${this.consteoUrl}/folders/${this.affair.consteoId}`, 'consteo', 'popup,width=1344,height=756,screenX=100,top=200');
      const timer = setInterval(() => {
        if (this.consteoWindow.closed) {
          clearInterval(timer);
          this.affairService.get(this.affair.id);
        }
      }, 1000);
    } else {
      this.consteoWindow.focus();
    }
  }

  public openAffairTagsModal(): void {
    this.modalService.create({
      nzContent: AffairTagsModalComponent,
      nzComponentParams: {
        affair: this.affair,
        tags: this.tags,
      },
      nzOnOk: () => {
        this.affairService.get(this.affair.id);
      },
    });
  }

}
