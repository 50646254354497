import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AffairContactService, AffairService, BuildingPartService, InterventionService, NotifService, PayerService, SelectService, SwalService, UnsubscribeOnDestroy } from '@eros-front/services';
import { Subscription, forkJoin, throwError } from 'rxjs';
import { Affair, AffairContact, AffairGoal, AffairStatus, BuildingObject, Part, Payer, SelectModel, Tag } from '@libs/models/src';
import { AffairMarketType } from '@libs/enum/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { tabSuffix } from '_config/tab-suffix';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { ApplicationDomainVerifications } from '@libs/customers/src/_models/application-domain.model';

type TAB_TYPE = 'INFORMATIONS' | 'DOCUMENTS_CUSTOMERS' | 'DOCUMENTS_DELIVERED' | 'BUILDINGS' | 'CONTACTS' | 'PROGRAMMATION'
  | 'INVOICING' | 'QUOTATIONS' | 'HISTORY' | 'YNERGIE' | 'MAILS';

@Component({
  selector: 'app-affair-show',
  templateUrl: './affair-show.component.html',
  styleUrls: ['./affair-show.component.scss'],
})
export class AffairShowComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public affair: Affair;
  private affair$: Subscription;
  public agenciesSelect: SelectModel[] = [];
  public mlAgenciesSelect: SelectModel[] = [];
  public payersByAgencySelect: Payer[] = [];
  public affairContacts: AffairContact[] = [];
  public buildingObject: BuildingObject = null;
  public marketTypeEnum = AffairMarketType;
  public affairSelectsForShow$: Subscription;
  public products: SelectModel[];
  public technicians: SelectModel[];
  public productsStatuses: SelectModel[];
  public marketsTypes: MarketType[];
  public affairsStatuses: AffairStatus[];
  public assistants: SelectModel[];
  public affairsGoals: AffairGoal[];
  public worksCompanies: SelectModel[];
  public studiesOffices: SelectModel[];
  public affairProductsToProgram: any = [];
  public tags: Tag[] = [];
  public appKey = environment.appKey;
  public consteoUrl = environment.consteoUrl;
  public affairMarketTypeEnum = AffairMarketType;
  public affairsSelectsInit = false;
  public activeTab: TAB_TYPE = 'INFORMATIONS';
  public applicationDomainVerifications: ApplicationDomainVerifications = null;
  public firstLoad = true;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private affairService: AffairService,
    private affairContactService: AffairContactService,
    private partService: BuildingPartService,
    private spinner: NgxSpinnerService,
    private interventionService: InterventionService,
    private swalService: SwalService,
    private payerService: PayerService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initSubscriptions();
    this.initValues();
    this.getValues();
  }

  ngOnDestroy(): void {
    this.affair$?.unsubscribe();
    this.affairService.reset();
  }

  isRenovationMarket(): boolean {
    return this.affair.marketTypeId === this.affairMarketTypeEnum.RENOVATION;
  }

  private initWhenAffair() {
    this.titleService.setTitle(`Gestion affaire : ${this.affair.mlRef}${tabSuffix}`);
    const observables = [
      this.payerService.getPayersByAgency(this.affair.agencyId, this.affair.marketTypeId),
      this.affairContactService.getAllByAffair(this.affair.id),
      this.interventionService.getAffairsProductsToProgram(null, this.affair.id),
      this.affairService.getApplicationDomainVerifications(this.affair.id),
      this.partService.getBuildingsByAffair(this.affair.id),
    ];

    forkJoin(observables)
      .pipe(
        takeUntil(this.isDestroyed$),
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      )
      .subscribe((data) => {
        this.payersByAgencySelect = data[0];
        this.affairContacts = data[1];
        this.affairProductsToProgram = data[2];
        this.applicationDomainVerifications = data[3];
        this.buildingObject = data[4];
        if (this.firstLoad && this.affairContacts.length === 0 && this.affair.isAffairContactsNeeded) {
          this.notifService.showWarningNotif("Attention, une ou plusieurs conditions d'envoi nécessitent un contact affaire.");
          this.firstLoad = false;
        }
      });
  }

  private initValues(): void {
    this.affair = null;
    this.agenciesSelect = [];
    this.mlAgenciesSelect = [];
    this.payersByAgencySelect = [];
  }

  private getValues(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (!Number.isNaN(id)) {
        this.spinner.show('load-affair');
        this.affairService.get(id);
        this.affairService.getSelectsForShow(id)
          .pipe(
            takeUntil(this.isDestroyed$),
            catchError((error) => {
              this.swalService.showSwalError(error);
              return throwError(error);
            }),
          )
          .subscribe((data) => {
            this.agenciesSelect = data.agencies;
            this.mlAgenciesSelect = data.mlAgencies;
            this.products = data.products;
            this.technicians = data.technicians;
            this.productsStatuses = data.productsStatuses;
            this.marketsTypes = data.marketsTypes;
            this.affairsStatuses = data.affairsStatuses;
            this.assistants = data.assistants;
            this.affairsGoals = data.affairsGoals;
            this.worksCompanies = data.worksCompanies;
            this.studiesOffices = data.studiesOffices;
            this.tags = data.tags;
            this.affairsSelectsInit = true;
          });
      }
    });
  }

  private initSubscriptions(): void {
    this.affair$ = this.affairService.affair$.subscribe(
      (affair) => {
        if (affair) {
          this.affair = affair;
          this.spinner.hide('load-affair');
          this.initWhenAffair();
        }
      },
    );
  }

}
