import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Affair, AffairSendSetting, Contact, SelectModel, SendSetting, StandardResponse, SubmitButton, User } from '@libs/models/src';
import { AffairStandardResponseService, MailService, NotifService, SendSettingService, SubmitButtonService, UnsubscribeOnDestroy, UserService } from '@libs/services/src';
import { Subscription } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthentificationService } from '@libs/auth/src';
import { finalize, takeUntil } from 'rxjs/operators';
import { AffairMarketType } from '@libs/enum/src';

export interface StandardResponseFiles {
  documentsCustomers: any;
  documentsDelivered: any;
  invoices: any;
  quotations: any;
  attachments: any;
}
interface UploadFile extends File {
  uid: string
}

@Component({
  selector: 'affair-modal-standard-response',
  templateUrl: './affair-modal-standard-response.component.html',
  styleUrls: ['./affair-modal-standard-response.component.scss'],
})
export class AffairModalStandardResponseComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges, OnDestroy {

  @Input() affair: Affair;
  @Input() affairSendSettings: AffairSendSetting[];
  @Input() affairContacts: any[];
  private files$: Subscription;
  private textReplaced$: Subscription;
  private recipients$: Subscription;
  public sendSettings: SendSetting[] = [];
  public standResps: StandardResponse[] = [];
  public recipients: Contact[] = [];
  public recipientsCopy: Contact[] = [];
  public files: StandardResponseFiles = {
    documentsCustomers: [],
    documentsDelivered: [],
    invoices: [],
    quotations: [],
    attachments: [],
  };

  public marketTypeEnum = AffairMarketType;

  public modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
    ],
  };

  public form: FormGroup;
  public selectedDocuments: string[] = [];
  public protectedDocuments: string[] = [];
  public products = [];
  public contacts = [];
  public interventions: SelectModel[] = [];
  public submitButton: SubmitButton;
  public userInfos: any = null;
  public user: User = null;
  public selectSendAsEmail: string[] = [];
  public loadingName = 'documents';
  public fileList: UploadFile[] = [];
  public showAttachment = false;

  constructor(
    private modal: NzModalRef,
    private affairResponseStandardService: AffairStandardResponseService,
    private sendSettingService: SendSettingService,
    private submitButtonService: SubmitButtonService,
    private mailService: MailService,
    private notifService: NotifService,
    private spinner: NgxSpinnerService,
    private authService: AuthentificationService,
    private userService: UserService,
  ) {
    super();
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.files = this.initFilesObject();
    this.fileList = [];
    this.selectedDocuments = [];
    this.showAttachment = false;
    this.userInfos = this.authService.getUserInfos();

    this.spinner.show(this.loadingName);
    this.userService.get(this.userInfos.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinner.hide(this.loadingName); }),
      )
      .subscribe({
        next: (user) => {
          this.user = user;
          if (this.affair) {
            this.form = this.affairResponseStandardService.initForm(this.affair);
            if (this.affair) {
              this.initProductsList();
              this.initContactsList();
              if (this.affair.interventions && this.affair.interventions.length > 0) {
                this.initInterventionsList();
              }
            }
            if (this.user.sendAsEmailSecondary != null) {
              this.selectSendAsEmail = [this.user.sendAsEmail, this.user.sendAsEmailSecondary];
              this.form.patchValue({
                sendAsEmail: this.user.sendAsEmail,
              });
            }
            this.setSubmitButton();
          }
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.affair && changes.affair.previousValue !== changes.affair.currentValue) {
      this.initProductsList();
      this.initContactsList();
      if (this.affair.interventions && this.affair.interventions.length > 0) {
        this.initInterventionsList();
      }
    }

  }

  ngOnDestroy(): void {
    if (this.files$) {
      this.files$.unsubscribe();
    }
    if (this.textReplaced$) {
      this.textReplaced$.unsubscribe();
    }
    this.files = this.initFilesObject();
    this.recipients$?.unsubscribe();
    this.fileList = [];
    this.selectedDocuments = [];
    this.showAttachment = false;
  }

  onSendSettingChange(value: AffairSendSetting): void {
    this.standResps = this.sendSettingService.getStandardsResponses(value.sendSetting, this.affair.marketTypeId);
    // Recipients recovery
    const recs = value.recipients.length > 0
      ? value.recipients.map((x) => { return x.email; }) : null;
    // RecipientsCopy recovery
    const recsCC = value.recipientsCopy.length > 0
      ? value.recipientsCopy.map((x) => { return x.email; }) : null;
    // Patch value of form
    this.affairResponseStandardService.getRecipients(this.affair.id, this.form);
  }

  onStandardResponseChange(value: StandardResponse): void {
    this.selectedDocuments = [];
    this.fileList = [];
    this.showAttachment = false;
    this.form.patchValue({
      subject: value.subject,
      content: value.body,
    });
    if (this.isValidForm()) {
      this.spinner.show(this.loadingName);
      this.affairResponseStandardService.getFiles(this.affair.id, this.form);
      this.affairResponseStandardService.replaceTextTags(this.affair.id, this.form);
    }
  }

  onSelectionChange(): void {
    if (this.isValidForm()) {
      this.spinner.show(this.loadingName);
      this.affairResponseStandardService.getFiles(this.affair.id, this.form);
      this.affairResponseStandardService.replaceTextTags(this.affair.id, this.form);
    }
  }

  onThumbnailSelected(document: any): void {
    if (this.selectedDocuments.includes(document.path)) {
      this.selectedDocuments = this.selectedDocuments.filter((e) => { return e !== document.path; });
    } else {
      this.selectedDocuments.push(document.path);
    }
    if (this.form) {
      this.form.patchValue({
        attachments: this.selectedDocuments,
      });
    }
  }

  isDocumentInSelectedDocuments(path: string): boolean {
    return this.selectedDocuments.includes(path);
  }

  isDocumentInFileList(uid: string): boolean {
    let isInFileList = false;
    this.fileList.forEach((file) => {
      if (file.uid === uid) {
        isInFileList = true;
      }
    });
    return isInFileList;
  }

  getButtonType(value: string): string {
    return this.isDocumentInSelectedDocuments(value) ? 'primary' : 'default';
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.fileList.length > 0) {
        this.form.patchValue({
          files: this.fileList,
        });
      }
      this.mailService.send(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.files = this.initFilesObject();
          this.fileList = [];
          this.selectedDocuments = [];
          this.modal.close();
        },
      );
    }
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  /**
   *
   * PRIVATE FUNCTIONS
   */
  private initSubscriptions(): void {
    this.files$ = this.affairResponseStandardService.files$.subscribe(
      (files) => {
        if (files) {
          this.selectedDocuments = [];
          this.fileList = [];
          this.showAttachment = false;
          this.files = files;
          this.files.attachments.forEach((attachment) => {
            this.onThumbnailSelected(attachment);
            this.protectedDocuments.push(attachment.path);
          });
          this.files.documentsCustomers.forEach((documentCustomers) => {
            if (documentCustomers.isSelected) {
              this.onThumbnailSelected(documentCustomers);
            }
          });
          this.files.documentsDelivered.forEach((documentDelivered) => {
            if (documentDelivered.isSelected) {
              this.onThumbnailSelected(documentDelivered);
            }
          });
          this.files.invoices.forEach((invoice) => {
            if (invoice.isSelected) {
              this.onThumbnailSelected(invoice);
            }
          });

          this.spinner.hide(this.loadingName);
          this.showAttachment = true;
        }
      },
    );
    this.textReplaced$ = this.affairResponseStandardService.textReplaced$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({
              subject: this.replaceSubjectStringTemplate(data.subject),
              content: data.content,
            });
          }
        }
      },
    );
    this.recipients$ = this.affairResponseStandardService.recipients$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({
              recipients: data.to,
              recipientsCopy: data.copy,
            });
          }
        }
      },
    );
  }

  private initFilesObject(): StandardResponseFiles {
    return {
      documentsCustomers: [],
      documentsDelivered: [],
      invoices: [],
      quotations: [],
      attachments: [],
    };
  }

  private replaceSubjectStringTemplate(s: string): string {
    s = s.replace('{mlRef}', this.affair.mlRef);
    return s;
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un mail',
      text: 'Envoyer',
      icon: faPaperPlane,
    });
    this.mailService.setSubmitButton(this.submitButton);
  }

  private initProductsList(): void {
    this.products = [];
    if (this.affair.affairProducts.length > 0) {
      this.affair.affairProducts.forEach((affairProduct) => {
        this.products.push({
          value: affairProduct.id,
          label: affairProduct.product.name,
        });
      });
    }
  }

  private initInterventionsList(): void {
    this.interventions = [];
    if (this.affair.interventions.length > 0) {
      this.affair.interventions.forEach((intervention) => {
        this.interventions.push({
          value: intervention.id,
          label: intervention.name,
        });
      });
    }
  }

  private initContactsList(): void {
    this.contacts = [];
    if (this.affair.agency) {
      this.affair.agency.contacts.forEach((contact) => {
        this.contacts.push({
          email: contact.email,
        });
      });
    }
  }

  beforeUpload = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  onUnselectFromSelectedDocuments(event: any): void {
    const path = event.currentTarget.getAttribute('path');
    if (this.isFileProtected(path)) {
      return;
    }
    this.selectedDocuments = this.selectedDocuments.filter((e) => { return e !== path; });
    if (this.form) {
      this.form.patchValue({
        attachments: this.selectedDocuments,
      });
    }
  }

  onUnselectFromFileList(event: any): void {
    const uid = event.currentTarget.getAttribute('uid');
    this.fileList = this.fileList.filter((file) => { return file.uid !== uid; });
  }

  isFileProtected(path: string): boolean {
    return this.protectedDocuments.includes(path);
  }

}
