import { Component, Input, OnInit } from '@angular/core';
import { AffairModalStandardResponseComponent } from '@libs/components/src/lib/affair-modal-standard-response/affair-modal-standard-response.component';
import { Intervention,
  InterventionNonconformity,
  AffairDocument } from '@libs/models/src';
import { NotifService,
  SwalService,
  UnsubscribeOnDestroy } from '@libs/services/src';
import { InterventionInskyService } from '@libs/services/src/lib/intervention-insky.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { InterventionEditInskySolutionComponent } from './intervention-edit-insky-solution/intervention-edit-insky-solution.component';
import { InterventionInskyRefusalReasonModalComponent } from '../intervention-insky-list/intervention-insky-refusal-reason-modal/intervention-insky-refusal-reason-modal.component';
import { InskyService } from '../../_services/insky.service';

@Component({
  selector: 'app-intervention-edit-insky',
  templateUrl: './intervention-edit-insky.component.html',
  styleUrls: ['./intervention-edit-insky.component.scss'],
})
export class InterventionEditInskyComponent
  extends UnsubscribeOnDestroy
  implements OnInit {

  @Input() intervention: Intervention;
  public interventionsNonconformities: InterventionNonconformity[] = [];
  public readonly NOT_VALID = 1;
  public readonly WAITING_VALIDATION = 2;
  public readonly VALID = 3;
  public loadingName = 'load-intervention';
  public refusalReasons: any[] = [];
  public reinterventionStatus = null;
  public nrjDocumentsCustomers: AffairDocument[];

  constructor(
    private interventionInskyService: InterventionInskyService,
    private inskyService: InskyService,
    private swalService: SwalService,
    private spinnerService: NgxSpinnerService,
    private notifService: NotifService,
    private modalService: NzModalService,
    private router: Router,
    public affairDocumentService: AffairDocumentCustomerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList();
    this.inskyService.getRefusalReasons().subscribe({
      next: (refusalReasons) => {
        this.refusalReasons = refusalReasons;
      },
    });
  }

  confirm(interventionNonconformity: InterventionNonconformity): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'La non-conformité sera validée',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.spinnerService.show(this.loadingName);
        that.interventionInskyService
          .confirm(interventionNonconformity.id)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
              this.spinnerService.hide(this.loadingName);
            }),
          )
          .subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.initList();
          });
      }
    });
  }

  unconfirm(interventionNonconformity: InterventionNonconformity): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'La non-conformité sera invalidée',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.modalService.create({
          nzContent: InterventionInskyRefusalReasonModalComponent,
          nzComponentParams: {
            interventionNonconformity: interventionNonconformity,
            loadingName: this.loadingName,
            refusalReasons: this.refusalReasons,
          },
          nzOnOk: () => {
            this.initList();
          },
        });
      }
    });
  }

  initList(): void {
    this.spinnerService.show(this.loadingName);
    this.interventionInskyService
      .getNonconformities(this.intervention.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.spinnerService.hide(this.loadingName);
        }),
      )
      .subscribe({
        next: (response) => {
          this.reinterventionStatus = response.reinterventionStatus;
          this.interventionsNonconformities = response.interventionsNonconformities;
        },
      });
    if (this.intervention.affair) {
      this.affairDocumentService
        .getAll(this.intervention.affair.id, null)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((documents) => {
          if (documents) {
            this.nrjDocumentsCustomers = documents.nrj;
          }
        });
    }
  }

  onSelectSolutionClicked(
    interventionNonconformity: InterventionNonconformity,
  ): void {
    this.modalService.create({
      nzContent: InterventionEditInskySolutionComponent,
      nzWidth: '80%',
      nzComponentParams: {
        interventionNonconformity: interventionNonconformity,
        intervention: this.intervention,
      },
      nzOnOk: () => {
        this.initList();
      },
    });
  }

  onModalStandardResponseShow(): void {
    const modalInstance = this.modalService.create({
      nzTitle: "Envoi d'une réponse standardisée",
      nzWidth: '80%',
      nzContent: AffairModalStandardResponseComponent,
      nzComponentParams: {
        affair: this.intervention.affair,
        affairSendSettings:
          this.intervention.affair.affairOfficeFile.affairsSendSettings,
        affairContacts: this.intervention.affairsContacts,
      },
      nzStyle: { top: '2vh' },
    });
  }

  goToAffairClicked(): void {
    this.router.navigateByUrl(`affairs/${this.intervention.affairId}/show`);
  }

}
