import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { OnChangeType } from 'ng-zorro-antd/core/types';
import { ArrayTool } from '_tools/array';

@Component({
  selector: 'nz-table-search-input',
  templateUrl: './nz-table-search-input.component.html',
  styleUrls: ['./nz-table-search-input.component.scss'],
})
export class NzTableSearchInputComponent implements OnInit, OnChanges {

  @Input() isBackEnd: boolean = false;
  @Input() placeholder: string | null;
  @Input() initialData: any[];
  @Input() searchableFields: string[];
  @Input() totalNbElem: number;
  @Input() inputSearchValue: string | null = null;
  @Input() displayNbFiltered: boolean = true;
  @Output() dataFiltered = new EventEmitter<any[]>();
  @Output() searchValueChanged = new EventEmitter<string>();
  @Input() refresh: boolean = false;
  @Output() refreshChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public filteredData: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refresh && changes.refresh.previousValue !== changes.refresh.currentValue) {
      this.onInputSearchChanged();
      this.refreshChange.emit(this.refresh);
    }
  }

  showNbFilteredText(): boolean {
    return this.totalNbElem > 0 && this.totalNbElem !== this.filteredData.length;
  }

  onInputSearchChanged(): void {
    if (this.inputSearchValue != null) {
      if (!this.isBackEnd) {
        // Front-end search
        const result = this.initialData.filter((e: any) => {
          return this.dataIncludesSearch(e);
        });
        this.filteredData = [...result];
        this.dataFiltered.emit(this.filteredData);
      } else {
        // Back-end search
        this.searchValueChanged.emit(this.formatSearch());
      }
    }
  }

  onInputClearIconClicked(): void {
    this.inputSearchValue = null;
    if (!this.isBackEnd) {
      // Front-end search
      this.filteredData = [...this.initialData];
      this.dataFiltered.emit(this.filteredData);
    } else {
      // Back-end search
      this.searchValueChanged.emit(this.formatSearch());
    }
  }

  private dataIncludesSearch(obj: any): boolean {
    if (obj != null && this.searchableFields.length > 0) {
      for (let i = 0; i < this.searchableFields.length; i++) {
        const data = ArrayTool.findVal(obj, this.searchableFields[i]);
        if (data != null && data.toString().trim().toLowerCase().includes(this.inputSearchValue.trim().toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  }

  private formatSearch(): string {
    if (this.inputSearchValue != null) {
      return this.inputSearchValue.trim().toLowerCase();
    }
    return '';
  }

}
