import { Component, Input, OnInit } from '@angular/core';
import { Affair, AffairDocument, DashboardTile } from '@libs/models/src';
import { FileService, FileUtilsService } from '@libs/services/src';

@Component({
  selector: 'app-overview-affair-documents-delivered-list',
  templateUrl: './overview-affair-documents-delivered-list.component.html',
  styleUrls: ['./overview-affair-documents-delivered-list.component.scss'],
})
export class OverviewAffairDocumentsDeliveredListComponent implements OnInit {

  @Input() affair: Affair;
  @Input() dashboardTile: DashboardTile;
  public isHoverId = null;

  constructor(
    private fileUtilsService: FileUtilsService,
    private fileService: FileService,

  ) { }

  ngOnInit(): void {
  }

  onMouseEnter(item: any): void {
    this.isHoverId = item.id;
  }

  onMouseLeave(): void {
    this.isHoverId = null;
  }

  onShow(event: any, document: AffairDocument): void {
    event.stopPropagation();
    if (document.mimeType === 'application/pdf') {
      this.fileUtilsService.openPreviewDocument(document.path, document.mimeType);
    } else {
      this.fileService.getBlob(document.path).subscribe(
        (file) => {
          const blob = new Blob([file], { type: document.mimeType });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
      );
    }
  }

}
