// Angular Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Basic Bootstrap Modules
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// Modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { ServicesModule } from '@libs/services/src';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// Nz modules
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzProgressModule } from 'ng-zorro-antd/progress';

// Pages Modules
import { pgCardModule } from '@pages/components/card/card.module';
import { QuillModule } from 'ngx-quill';

// Components
import { NgxSpinnerModule } from 'ngx-spinner';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { NgSelectMultipleComponent } from './ng-select-multiple/ng-select-multiple.component';
import { TableDatatableComponent } from './table-datatable/table-datatable.component';
import { CytoscapeComponent } from './cytoscape/cytoscape.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TableDatatableToggleVisComponent } from './table-datatable-toggle-vis/table-datatable-toggle-vis.component';
import { InputClearIconComponent } from './input-clear-icon/input-clear-icon.component';
import { MarketsTypesSelectionComponent } from './markets-types-selection/markets-types-selection.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { InputEmailComponent } from './input-email/input-email.component';
import { AffairModalStandardResponseComponent } from './affair-modal-standard-response/affair-modal-standard-response.component';
import { DocumentThumbnailComponent } from './document-thumbnail/document-thumbnail.component';
import { DocumentThumbnailSimpleComponent } from './document-thumbnail-simple/document-thumbnail-simple.component';
import { InvoiceThumbnailComponent } from './invoice-thumbnail/invoice-thumbnail.component';
import { CustomSpinnerComponent } from './custom-spinner/custom-spinner.component';

import { ModalMarketTypeComponent } from './modal-market-type/modal-market-type.component';
import { MarketsTypesCheckboxesComponent } from './markets-types-checkboxes/markets-types-checkboxes.component';
import { GlobalSearchOverlayComponent } from './global-search-overlay/global-search-overlay.component';
import { PayerInformationsFormComponent } from './forms/payer-informations-form/payer-informations-form.component';
import { ProductsArrayFormComponent } from './forms/products-array-form/products-array-form.component';
import { MlSocietiesWorkWithComponent } from './ml-societies-work-with/ml-societies-work-with.component';
import { InternalFormNotesComponent } from './forms/internal-form-notes/internal-form-notes.component';
import { GeneralAdressFormComponent } from './forms/general-adress-form/general-adress-form.component';
import { QuickStatsWidgetComponent } from './quick-stats-widget/quick-stats-widget.component';
import { QuotationThumbnailComponent } from './quotation-thumbnail/quotation-thumbnail.component';
import { InputTimeSlotComponent } from './input-time-slot/input-time-slot.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { ImageThumbnailComponent } from './image-thumbnail/image-thumbnail.component';
import { ErosNzTableCheckboxesComponent } from './eros-nz-table-checkboxes/eros-nz-table-checkboxes.component';
import { TableButtonsComponent } from './table-buttons/table-buttons.component';
import { NzTableSearchInputComponent } from './nz-table-search-input/nz-table-search-input.component';
import { ContactsCallModalComponent } from './contacts-call-modal/contacts-call-modal.component';
import { ContactsCallContactTemplateComponent } from './contacts-call-modal/contacts-call-contact-template/contacts-call-contact-template.component';
import { DocumentModalStandardResponseComponent } from './document-modal-standard-response/document-modal-standard-response.component';
import { HouseTagComponent } from './house-tag/house-tag.component';
import { TableColumnsAffairComponent } from './table-columns/table-columns-affair/table-columns-affair.component';
import { TableColumnsWorkSiteComponent } from './table-columns/table-columns-work-site/table-columns-work-site.component';
import { TableColumnsAffairProductComponent } from './table-columns/table-columns-affair-product/table-columns-affair-product.component';
import { TableColumnsExpendAffairMonitoringComponent } from './table-columns/table-columns-expend-affair-monitoring/table-columns-expend-affair-monitoring.component';
import { NotebookProcessComponent } from './notebook-process/notebook-process.component';
import { AffairProductsDashboardModalComponent } from './affair-products-dashbord-modal/affairs-products-dashboard-modal';
import { InputNoSpaceComponent } from './input-no-space/input-no-space.component';
import { AffairsDashboardModalComponent } from './affairs-dashboard-modal/affairs-dashboard-modal.component';
import { ModalTimerComponent } from './modal-timer/modal-timer.component';
import { AffairsMonitoringDashboardModalComponent } from './affairs-monitoring-dashboard-modal/affairs-monitoring-dashboard-modal.component';
import { StatsWidgetItemComponent } from './stats-widget-item/stats-widget-item.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';
import { NotificationItemComponent } from './notifications-panel/notification-item/notification-item.component';
import { DepositInvoiceThumbnailComponent } from './deposit-invoice-thumbnail/deposit-invoice-thumbnail.component';
import { AffairTagsModalComponent } from './affair-tags-modal/affair-tags-modal.component';
import { ModalStandardResponseComponent } from './modal-standard-response/modal-standard-response.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FontAwesomeModule,
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    ServicesModule,
    NgxSpinnerModule,
    NzModalModule,
    NzTableModule,
    NzPopconfirmModule,
    pgCardModule,
    GooglePlaceModule,
    NzToolTipModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    NzSkeletonModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzTagModule,
    NzBadgeModule,
    NzTabsModule,
    NzProgressModule,
    QuillModule,
    NzUploadModule,
    NzDropDownModule,
    NzDividerModule,
    NzSwitchModule,
  ],
  declarations: [
    TableDatatableComponent,
    TableDatatableToggleVisComponent,
    NgSelectMultipleComponent,
    SubmitButtonComponent,
    CytoscapeComponent,
    TimelineComponent,
    InputClearIconComponent,
    MarketsTypesSelectionComponent,
    InputPhoneComponent,
    InputEmailComponent,
    AffairModalStandardResponseComponent,
    ModalStandardResponseComponent,
    DocumentThumbnailComponent,
    DocumentThumbnailSimpleComponent,
    InvoiceThumbnailComponent,
    CustomSpinnerComponent,
    ModalMarketTypeComponent,
    MarketsTypesCheckboxesComponent,
    GlobalSearchOverlayComponent,
    PayerInformationsFormComponent,
    ProductsArrayFormComponent,
    MlSocietiesWorkWithComponent,
    InternalFormNotesComponent,
    GeneralAdressFormComponent,
    QuickStatsWidgetComponent,
    QuotationThumbnailComponent,
    InputTimeSlotComponent,
    DocumentViewerComponent,
    ImageThumbnailComponent,
    ErosNzTableCheckboxesComponent,
    TableButtonsComponent,
    NzTableSearchInputComponent,
    ContactsCallModalComponent,
    ContactsCallContactTemplateComponent,
    DocumentModalStandardResponseComponent,
    HouseTagComponent,
    InputNoSpaceComponent,
    // Table Columns
    TableColumnsAffairComponent,
    TableColumnsWorkSiteComponent,
    TableColumnsAffairProductComponent,
    TableColumnsExpendAffairMonitoringComponent,
    NotebookProcessComponent,
    AffairProductsDashboardModalComponent,
    AffairsDashboardModalComponent,
    ModalTimerComponent,
    AffairsMonitoringDashboardModalComponent,
    StatsWidgetItemComponent,
    CopyToClipboardComponent,
    NotificationsPanelComponent,
    NotificationItemComponent,
    DepositInvoiceThumbnailComponent,
    AffairTagsModalComponent,
  ],
  exports: [
    TableDatatableComponent,
    TableDatatableToggleVisComponent,
    NgSelectMultipleComponent,
    SubmitButtonComponent,
    CytoscapeComponent,
    TimelineComponent,
    InputClearIconComponent,
    MarketsTypesSelectionComponent,
    InputPhoneComponent,
    InputEmailComponent,
    AffairModalStandardResponseComponent,
    ModalStandardResponseComponent,
    DocumentThumbnailComponent,
    DocumentThumbnailSimpleComponent,
    InvoiceThumbnailComponent,
    CustomSpinnerComponent,
    ModalMarketTypeComponent,
    MarketsTypesCheckboxesComponent,
    GlobalSearchOverlayComponent,
    PayerInformationsFormComponent,
    ProductsArrayFormComponent,
    MlSocietiesWorkWithComponent,
    InternalFormNotesComponent,
    GeneralAdressFormComponent,
    QuickStatsWidgetComponent,
    QuotationThumbnailComponent,
    InputTimeSlotComponent,
    DocumentViewerComponent,
    ImageThumbnailComponent,
    ErosNzTableCheckboxesComponent,
    TableButtonsComponent,
    NzTableSearchInputComponent,
    ContactsCallModalComponent,
    ContactsCallContactTemplateComponent,
    DocumentModalStandardResponseComponent,
    HouseTagComponent,
    TableColumnsAffairComponent,
    TableColumnsWorkSiteComponent,
    TableColumnsAffairProductComponent,
    TableColumnsExpendAffairMonitoringComponent,
    NotebookProcessComponent,
    AffairProductsDashboardModalComponent,
    InputNoSpaceComponent,
    AffairsDashboardModalComponent,
    ModalTimerComponent,
    AffairsMonitoringDashboardModalComponent,
    StatsWidgetItemComponent,
    CopyToClipboardComponent,
    NotificationsPanelComponent,
    NotificationItemComponent,
    DepositInvoiceThumbnailComponent,
    AffairTagsModalComponent,
  ],
})
export class ComponentsModule {}
