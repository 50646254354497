import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { AffairDocumentImportMultipleParam, AffairDocumentImportParam } from '@libs/services/src/lib/classes/affair-document-common';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Subscription } from 'rxjs';
import { delay, finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affair-document-import',
  templateUrl: './affair-document-import.component.html',
  styleUrls: ['./affair-document-import.component.scss'],
})
export class AffairDocumentImportComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  @Input() affair: any;
  @Input() dropzones: any[];
  @Input() affairDocumentService: AffairDocumentCustomerService | AffairDocumentDeliveredService;
  public files: File[][] = [];
  private uploadProcess$: Subscription;
  public backButtonIsDisabled = false;
  public affairDocumentParam: AffairDocumentImportParam;
  public affairDocumentMultipleParam: AffairDocumentImportMultipleParam;

  constructor(
    private router: Router,
    private notifService: NotifService,
    private spinnerService: NgxSpinnerService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.uploadProcess$) {
      this.uploadProcess$.unsubscribe();
    }
  }

  goToAffairClicked(): void {
    this.router.navigateByUrl(`affairs/${this.affair.id}/show`);
  }

  onFileImport(param): void {
    const { event } = param;
    const { documentId } = param;
    if (this.files[documentId] == null) {
      this.files[documentId] = [];
    }
    if (event.addedFiles.length === 1) {
      this.affairDocumentParam = {
        affairId: this.affair.id,
        affairProductId: param.affairProductId,
        documentId: param.documentId,
        incrementVersion: false,
        buildingId: param.buildingId,
        file: null,
      };
    } else {
      this.affairDocumentMultipleParam = {
        affairId: this.affair.id,
        affairProductId: param.affairProductId,
        documentId: param.documentId,
        incrementVersion: false,
        buildingId: param.buildingId,
        files: null,
      };
    }

    if (param.isImported) {
      Swal.fire({
        title: 'Est-ce une nouvelle version du document ?',
        text: 'Le numéro de version sera incrémenté',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (event.addedFiles.length === 1) {
            this.affairDocumentParam.incrementVersion = true;
          } else {
            this.affairDocumentMultipleParam.incrementVersion = true;
          }
        }
        if (event.addedFiles.length === 1) {
          this.importFile(this.affairDocumentParam, event);
        } else {
          this.importFile(this.affairDocumentMultipleParam, event);
        }
      });
    } else if (event.addedFiles.length === 1) {
      this.importFile(this.affairDocumentParam, event);
    } else {
      this.importFile(this.affairDocumentMultipleParam, event);
    }
  }

  private importFile(affairDocumentParam: AffairDocumentImportParam | AffairDocumentImportMultipleParam, event: any) {
    this.backButtonIsDisabled = true;
    this.notifService.showNotif({
      type: 'warning',
      text: 'Import en cours...',
    });
    if (event.addedFiles.length === 1) {
      this.affairDocumentParam.file = event.addedFiles[0];
      this.affairDocumentService
        .import(this.affairDocumentParam)
        .pipe(
          takeUntil(this.isDestroyed$),
          finalize(() => {
            this.backButtonIsDisabled = false;
          }),
        )
        .subscribe(
          (response) => {
            this.files[affairDocumentParam.documentId].push(event.addedFiles[0]);
            this.notifService.showNotif({
              type: 'success',
              text: response.message,
            });
            this.refreshDropzones();
          },
        );
    } else {
      this.affairDocumentMultipleParam.files = event.addedFiles;
      this.affairDocumentService.importMultiple(this.affairDocumentMultipleParam)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (response) => {
            this.notifService.showNotif({
              type: 'success',
              text: response.message,
            });
            this.refreshDropzones();
            this.backButtonIsDisabled = false;
          },
          (error) => {
            this.notifService.showErrorNotif("Erreur lors de l'import. Vérifiez le format des fichiers.");
            this.backButtonIsDisabled = false;
          },
        );

    }
  }

  private refreshDropzones() {
    this.affairDocumentService.getDropzones(this.affair.id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (dropzones) => {
          this.dropzones = dropzones;
        },
      );
  }

}
