/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Spreadsheet } from 'apps/eros-nrj/src/assets/plugins/spreadsheet/spreadsheet';
import { HttpClient } from '@angular/common/http';
import { NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { AuthentificationService } from '@libs/auth/src';
import { combineLatest, Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { locale } from '../../../_tools/dhx-spreadsheet-locale';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'eros-front-inspections-base-lot',
  templateUrl: './inspections-base-lot.component.html',
  styleUrls: ['./inspections-base-lot.component.css'],
})
export class InspectionsBaseLotComponent extends UnsubscribeOnDestroy implements OnInit {

  @ViewChild('widget', { static: true }) container: ElementRef;
  private _spreadSheet: Spreadsheet;
  private _isLoading: boolean = true;
  private _initRawData;
  public searchFilter: string;
  public formattedData: any[];
  public nbFreezeRow: number = 1;
  public isActiveSearch: boolean = false;
  private _nbFreezeCol: number = 0;
  public isVisibleModalTimer: boolean = false;
  public percentage: number = 0;
  public initTime: number = -1;
  public remainingMinutes: number = 0;
  public remainingSeconds: number = 0;
  private remainingTime: number = 0;
  private _nbRows: number;
  private _deltaNbRows: number = 50;
  public spinnerName = 'loading-base-lot';

  /**
   * Constructor of InspectionsBaseLotComponent
   * @param _http HttpClient
   * @param _notifService NotifService
   * @param authService
   */
  constructor(
    private _http: HttpClient,
    private _notifService: NotifService,
    public authService: AuthentificationService,
    private spinnerService: NgxSpinnerService,
  ) { super(); }

  /**
   * Executed on initialisation of component
   */
  ngOnInit(): void {
    // set the value of nbFreezeCol if defined in localstorage
    const nbFreezeColLocalStorage = localStorage.getItem('nbFreezeCol');
    if (nbFreezeColLocalStorage !== null) {
      this._nbFreezeCol = Number(nbFreezeColLocalStorage);
    }

    // set te value of nb rows
    this._http.get<number>(`${environment.serviceEUrl}/base-lot/nbRows`)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (data) => {
          this._nbRows = data;
          this.setUpdateCellHoock();
          this.getBaseLot(null);
        },
        error: (err) => {
          this._nbRows = 1000;
          this.setUpdateCellHoock();
          this.getBaseLot(null);
        },
      });
  }

  /**
   * Get the data of Base Lot
   */
  public getBaseLot(filter: string | null): void {
    if (filter === null) {
      this._http.get<any>(`${environment.serviceEUrl}/base-lot`)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: (data) => {
            this._initRawData = data;
            this.formattedData = [];
            this.fillSpreadSheet();
            this._spreadSheet.parse(this.formattedData);
            this.lockCells();
            this._isLoading = false;
            this._spreadSheet.setStyle('A1:ZZ1', { width: '300' });
          },
        });
    } else {
      const payload = {
        filter: filter,
      };
      this._http.post<any>(`${environment.serviceEUrl}/base-lot/filter`, payload)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: (data) => {
            this._initRawData = data;
            this.formattedData = [];
            this.fillSpreadSheet();
            this._spreadSheet.parse(this.formattedData);
            this.lockCells();
            this._isLoading = false;
            this._spreadSheet.setStyle('A1:ZZ1', { width: '300' });
          },
        });
    }
  }

  /**
   * Fill the spreadsheet with all the data in _initRawData
   */
  public fillSpreadSheet(): void {
    for (let i = 0; i < this._initRawData.length; i++) {
      this.formattedData.push({ cell: this._initRawData[i].cell, value: this._initRawData[i].value, format: 'text' });
    }
  }

  /**
   * Lock the cells that has to be
   */
  public lockCells(): void {
    for (let i = 0; i < this._initRawData.length; i++) {
      if (this._initRawData[i].locked) {
        this._spreadSheet.lock(this._initRawData[i].cell);
      }
    }
  }

  /**
   * Return the value of _nbFreezeCol
   */
  public get nbFreezeCol(): number {
    return this._nbFreezeCol;
  }

  /**
   * Set the value of _nbFreezeCol
   * @param val value to set
   */
  public set nbFreezeCol(val: number) {
    this._nbFreezeCol = val;

    // set the value to local storage
    localStorage.setItem('nbFreezeCol', JSON.stringify(this._nbFreezeCol));
  }

  /**
   * Function to filter the spreadsheet depending on the value of the filter
   */
  public filter(): void {
    this.formattedData = [];
    const filteredData = [];
    if (this.searchFilter.trim().length > 0) {
      this.isActiveSearch = true;
      // it's a particular case of spreadsheet filling, that's why it's not in a function
      // We add header in the filtered results

      this.getBaseLot(this.searchFilter);
    } else {
      this.isActiveSearch = false;
      this.getBaseLot(null);
    }
    this._spreadSheet.clear();
    this._spreadSheet.parse(this.formattedData);
    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i].locked) {
        this._spreadSheet.lock(filteredData[i].cell);
      }
    }
    this._spreadSheet.setStyle('A1:BZ1', { width: '300' });
  }

  /**
   * Set event handler after the value of a cell is updated to push modifications on database
   */
  public setUpdateCellHoock(): void {
    // Set french locale
    // @ts-ignore
    // dhx.i18n.setLocale('spreadsheet', locale.fr);
    // @ts-ignore
    this._spreadSheet = new dhx.Spreadsheet(this.container.nativeElement, {
      topSplit: this.nbFreezeRow, // the number of rows to "freeze"
      leftSplit: 0, // the number of columns to "freeze"
      colsCount: 200,
      rowsCount: this._nbRows + this._deltaNbRows,
    });

    this._spreadSheet.events.on('afterValueChange', (cell, value) => {
      const updatedCell = this._initRawData.filter((row) => {
        return row.cell === cell;
      });
      const { letter } = updatedCell[0];
      const payload = {
        letter: letter,
        value: value,
      };

      this._http.patch<any>(`${environment.serviceEUrl}/set/beneficiary/${updatedCell[0].id}`, payload).subscribe({
        next: (_) => {
          this._notifService.showSuccessNotif('Le contenu de la cellule a bien été modifié');
        },
        error: (err) => {
          this._notifService.showErrorNotif(err.error.message);
        },
      });
    });
  }

  /**
   * Return the value of _isLoading variable
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Execute the commands in the following order
   * KizeoPull
   * BaseLot Refresh + PowerBI Refresh
   */
  public updateAll(): void {
    let refreshDurationPowerBI;
    let refreshDurationBaseLot;
    this.remainingTime = 0;
    this.percentage = 0;

    /* this.observablePowerBIRefreshDuration().subscribe({
      next: (data) => {
        refreshDurationPowerBI = data;

        this.observableBaseLotRefreshDuration().subscribe({
          next: (lotData) => {
            refreshDurationBaseLot = lotData;
            // land the timer of refresh duration
            this.showModalTimer(refreshDurationBaseLot + refreshDurationPowerBI);
            // show the modal for timer
            this.isVisibleModalTimer = true;

            // pull the data from kizeo
            this.observableKizeoPull().subscribe({
              next: (_) => {
                this._notifService.showSuccessNotif('Données Kizéo récupérées.');

                // when kizeo pull is completed, we refresh the base lot and powerbi
                combineLatest(this.observableBaseLotRefresh(), this.observablePowerBIRefresh()).subscribe(
                  ([val1, val2]) => {
                    this.isVisibleModalTimer = false;
                    this.remainingTime = 0;
                    this.percentage = 0;
                    this.getBaseLot(null);
                  },
                );
              },
            });
          },
        });
      },
    }); */
    this.observablePowerBIRefreshDuration().subscribe({
      next: (data) => {
        refreshDurationPowerBI = data;
        this.showModalTimer(refreshDurationPowerBI);
        // show the modal for timer
        this.isVisibleModalTimer = true;
        // pull the data from kizeo
        this.observableKizeoPull().subscribe({
          next: (_) => {
            this._notifService.showSuccessNotif('Données Kizéo récupérées.');
            // when kizeo pull is completed, we refresh the base lot and powerbi
            this.observablePowerBIRefresh().subscribe(
              (res) => {
                this.isVisibleModalTimer = false;
                this.remainingTime = 0;
                this.percentage = 0;
                this.getBaseLot(null);
              },
            );
          },
        });
      },
    });

  }

  public refreshKizeo(): void {
    this.spinnerService.show(this.spinnerName);
    this.observableKizeoPull()
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinnerService.hide(this.spinnerName); }),
      )
      .subscribe({
        next: (_) => {
          this._notifService.showSuccessNotif('Données Kizéo récupérées.');
        },
      });
  }

  public refreshBaseLot(): void {
    this.spinnerService.show(this.spinnerName);
    this.observableBaseLotRefresh()
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinnerService.hide(this.spinnerName); }),
      )
      .subscribe({
        next: (_) => {
          this._notifService.showSuccessNotif('Base lot rafraîchit.');
        },
      });
  }

  /**
   * Return an observable of KizeoPull
   */
  public observableKizeoPull(): Observable<any> {
    return this._http.get<any>(`${environment.serviceEUrl}/kizeo/pull`);
  }

  /**
   * Return an observable of refreshing base lot
   */
  public observableBaseLotRefresh(): Observable<any> {
    return this._http.get<any>(`${environment.serviceEUrl}/base-lot/refresh`);
  }

  /**
   * Return an observable of refreshing PowerBI
   */
  public observablePowerBIRefresh(): Observable<any> {
    return this._http.get<any>(`${environment.serviceEUrl}/powerbi/refresh`);
  }

  /**
   * Return an observable of refresh duration of base lot
   */
  public observableBaseLotRefreshDuration(): Observable<any> {
    return this._http.get<number>(`${environment.serviceEUrl}/base-lot/refresh-duration`);
  }

  /**
   * Return an observable of refresh duration of powerbi
   */
  public observablePowerBIRefreshDuration(): Observable<any> {
    return this._http.get<number>(`${environment.serviceEUrl}/powerbi/refresh-duration`);
  }

  /**
   * Sets the timer for global refresh
   */
  public showModalTimer(time): void {
    const that = this;

    this.remainingTime = Math.round(time);
    this.initTime = Math.round(time);
    this.calculateMinutesSeconds();

    const timer = setInterval(() => {
      // desc 1 second
      that.remainingTime -= 1;
      const diffTime = that.initTime - that.remainingTime;
      this.calculateMinutesSeconds();
      that.percentage = Math.round((diffTime / that.initTime) * 100);
      if (that.remainingTime === 0) {
        // end of timer
        clearTimeout(timer);
      }
    }, 1000);
  }

  /**
   * make the remaining time in minuts:seconds format
   */
  private calculateMinutesSeconds(): void {
    this.remainingMinutes = Math.floor(this.remainingTime / 60);
    this.remainingSeconds = this.remainingTime - this.remainingMinutes * 60;
  }

  /**
   * Executed on destroy of component
   */
  ngOnDestroy(): void {
    this._spreadSheet && this._spreadSheet.destructor();
  }

}
