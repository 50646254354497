import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';
import { BarTypeService } from '../../services/bar-type.service';
import { BarType } from '../../types/bar-type.type';
import { BarTypeModalComponent } from '../bar-type-modal/bar-type-modal.component';

@Component({
  selector: 'app-bar-types-index',
  templateUrl: './bar-types-index.component.html',
  styleUrls: ['./bar-types-index.component.scss'],
})
export class BarTypesIndexComponent implements OnInit, OnDestroy {

  public barTypes: BarType[];
  public barTypes$: Subscription;

  constructor(
    private barTypeService: BarTypeService,
    private modalService: NzModalService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(`Fiches bar${tabSuffix}`);
    this.initSubscriptions();
    this.barTypeService.getAll();
  }

  ngOnDestroy(): void {
    this.barTypes$?.unsubscribe();
  }

  onBarTypeModalClicked(barType?: BarType): void {
    const modalInstance = this.modalService.create({
      nzContent: BarTypeModalComponent,
      nzComponentParams: {
        barType: barType,
      },
    });
    modalInstance.afterClose.subscribe(
      () => {
        this.barTypeService.getAll();
      },
    );
  }

  private initSubscriptions() {
    this.barTypes$ = this.barTypeService.barTypes$.subscribe(
      (data) => {
        this.barTypes = data;
      },
    );
  }

}
