/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalTimerComponent } from '@libs/components/src/lib/modal-timer/modal-timer.component';
import { NotifService, UnsubscribeOnDestroy, YzierService } from '@libs/services/src';
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { Grid as GridDHX, DataCollection } from 'dhx-suite';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, zip } from 'rxjs';
import { finalize, retry, takeUntil } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';
import { BeneficiaryEditModalComponent } from './beneficiary-edit-modal/beneficiary-edit-modal.component';

@Component({
  selector: 'app-inspections-grid-lot',
  templateUrl: './inspections-grid-lot.component.html',
  styleUrls: ['./inspections-grid-lot.component.scss'],
})
export class InspectionsGridLotComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  @ViewChild('grid', { static: true })
    container: ElementRef;

  grid: GridDHX;
  wait: Promise<void>;
  data: any = new DataCollection();
  public spinnerName = 'loading-base-lot';
  public spinnerRefreshName = 'refresh-base-lot';
  public searchFilter = '';
  public baseUrl = environment.serviceEUrl;
  public modalInstance: NzModalRef;

  constructor(
    private yzierService: YzierService,
    private notifService: NotifService,
    private spinnerService: NgxSpinnerService,
    private modalService: NzModalService,
    private titleService: Title,
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Base lot ${tabSuffix}`);
    this.buildGridLot();
  }

  ngOnDestroy(): void {
    if (this.grid) {
      this.grid.destructor();
    }
  }

  public buildGridLot(): void {
    if (this.grid) {
      this.searchFilter = null;
      this.grid.destructor();
    }
    this.spinnerService.show(this.spinnerName);
    zip(
      this.yzierService.getGridColumns(this.baseUrl),
      this.yzierService.getGridData(this.baseUrl),
    )
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinnerService.hide(this.spinnerName); }),
      )
      .subscribe(([columns, data]) => {
        const that = this;
        this.grid = new GridDHX(this.container.nativeElement, {
          columns: columns,
          data: this.data,
          selection: 'row',
          eventHandlers: {
            onclick: {
              btn_edit_kizeo: function (event, cellData) {
                that.onShowModalEdit(cellData.row);
              },
            },
          },
          // adjust: true,
          // autoWidth: true,
        });
        this.data.parse(data);
        this.initEvents();
      });
  }

  public initEvents(): void {

    this.grid.events.on('afterEditEnd', (value, row, col: any) => {
      const { id } = col;
      const payload = {
        id: id,
        rowId: row._id,
        value: value,
      };
      // eslint-disable-next-line no-underscore-dangle
      this.yzierService.updateBeneficiary(this.baseUrl, row.beneficiary_id, payload)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (res) => {
            this.notifService.showSuccessNotif('Ligne bénéficiaire mise à jour avec succès');
          },
        );
    });
  }

  public applyFilter(): void {
    this.grid.data.filter((item) => {
      const values: string[] = Object.values(item);
      return this.isFoundMatches(values);
    });
  }

  public refreshLot(): void {
    this.yzierService.gridLotRefreshDuration(this.baseUrl).subscribe(
      ({
        next: (time) => {
          this.showModalTimer(time);
          this.yzierService.kizeoPull(this.baseUrl).subscribe(
            {
              next: () => {
                this.notifService.showSuccessNotif('Les données kizeo ont été récupérées');
                this.yzierService.gridLotRefresh(this.baseUrl).subscribe(
                  ({
                    next: () => {
                      this.modalInstance.close();
                      this.notifService.showSuccessNotif('Base lot actualisée');
                      this.buildGridLot();
                    },
                    error: () => {
                      this.notifService.showErrorNotif('Erreur actualisation base lot');
                      this.modalInstance.close();
                    },
                  }),
                );
              },
              error: () => {
                this.modalInstance.close();
                this.notifService.showErrorNotif('Erreur récupération données kizeo');
              },
            },
          );
        },
      }),
    );
  }

  public refreshPowerBi(): void {
    this.yzierService.powerBiRefreshDuration(this.baseUrl).subscribe({
      next: (time) => {
        this.showModalTimer(time);
        this.yzierService.powerBiRefresh(this.baseUrl).subscribe(
          {
            next: () => {
              this.modalInstance.close();
              this.notifService.showSuccessNotif('Données pour Power Bi actualisées');
              this.buildGridLot();
            },
            error: () => {
              this.modalInstance.close();
            },
          },
        );

      },
    });

  }

  public kizeoPull(): void {
    this.spinnerService.show(this.spinnerRefreshName);
    this.yzierService.kizeoPull(this.baseUrl).subscribe(
      ({
        next: () => {
          this.spinnerService.hide(this.spinnerRefreshName);
          this.notifService.showSuccessNotif('Données kizeo récupérées');
          this.buildGridLot();
        },
        error: () => {
          this.spinnerService.hide(this.spinnerRefreshName);
        },
      }),
    );
  }

  public updateAll(): void {
    let refreshDurationPowerBI = 0;
    let refreshDurationBaseLot = 0;
    this.yzierService.powerBiRefreshDuration(this.baseUrl)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (timePowerBi) => {
          refreshDurationPowerBI = timePowerBi;

          this.yzierService.gridLotRefreshDuration(this.baseUrl)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe({
              next: (timeGrid) => {
                refreshDurationBaseLot = timeGrid;
                // land the timer of refresh duration
                this.showModalTimer(refreshDurationBaseLot + refreshDurationPowerBI);
                // pull the data from kizeo
                this.yzierService.kizeoPull(this.baseUrl).subscribe({
                  next: (_) => {
                    this.notifService.showSuccessNotif('Données Kizéo récupérées.');

                    // when kizeo pull is completed, we refresh the base lot and powerbi
                    zip(this.yzierService.gridLotRefresh(this.baseUrl), this.yzierService.powerBiRefresh(this.baseUrl)).subscribe(
                      ([val1, val2]) => {
                        this.modalInstance.close();
                        this.notifService.showSuccessNotif('Données lot et powerbi actualisées');
                        this.buildGridLot();
                      },
                    );
                  },
                });
              },
              error: () => {
                this.modalInstance.close();
                this.notifService.showErrorNotif('Erreur lors de l\'actualisation.');
              },
            });
        },
      });

  }

  public showModalTimer(time: number): void {
    this.modalInstance = this.modalService.create({
      nzOkText: null,
      nzCancelText: null,
      nzClosable: false,
      nzContent: ModalTimerComponent,
      nzComponentParams: {
        time: time,
      },
    });

  }

  public clearFilter(): void {
    this.searchFilter = null;
    this.grid.data.filter();
  }

  public isFoundMatches(strArray: string[]): boolean {
    const found = strArray.find((x) => {
      if (x != null) {
        return x.toString().toLocaleLowerCase().includes(this.searchFilter.toLowerCase());
      }
      return false;
    });
    if (found) {
      return true;
    }
    return false;
  }

  public onShowModalEdit(beneficiary: any) {
    const modalInstance = this.modalService.create({
      nzContent: BeneficiaryEditModalComponent,
      nzComponentParams: {
        beneficiary: beneficiary,
      },
    });
    modalInstance.afterClose.subscribe(
      () => {
        this.buildGridLot();
      },
    );
  }

}
