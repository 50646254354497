import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Injectable()
export class FormValidatorsService {

  constructor() { }

  minSelectedCheckboxes(min = 1): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
        .map((control) => { return control.value; })
        .filter((value) => { return value.checked === true || value.checked === 1; })
        .length;
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };

    };

    return validator;
  }

  atLeastOne = (validator: ValidatorFn, controls: string[] = null) => {
    return (
      group: FormGroup,
    ): ValidationErrors | null => {
      if (!controls) {
        controls = Object.keys(group.controls);
      }

      const hasAtLeastOne = group && group.controls && controls
        .some((k) => { return !validator(group.controls[k]); });

      return hasAtLeastOne ? null : {
        atLeastOne: true,
      };
    };
  };

  postalCode(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const zipCodeRegexp = /^(?:0[1-9]|[1-8]\d|9[0-8]|2[abAB])\d{3}$/;
      const regexpResult = zipCodeRegexp.test(control.value);
      return regexpResult ? null : { postalCode: { value: control.value } };
    }
    return null;
  }

}
