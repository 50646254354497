import { Component, Input, OnInit } from '@angular/core';
import { AffairContact, Contact } from '@libs/models/src';
import { IncomingCallSheetMailParameters, MailService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-incoming-call-sheet-emails',
  templateUrl: './incoming-call-sheet-emails.component.html',
  styleUrls: ['./incoming-call-sheet-emails.component.scss'],
})
export class IncomingCallSheetEmailsComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() contact: AffairContact | Contact;
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public total: number = 1;
  public loading: boolean;
  public incomingCallSheetParams: IncomingCallSheetMailParameters = {
    email: null,
    size: 10,
    search: '',
    page: 1,
  };

  public emailsData = [];
  public inputSearchValue: string = '';

  constructor(
    private mailService: MailService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initMailsList();
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.initMailsList();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.initMailsList();
  }

  private initMailsList(): void {
    this.incomingCallSheetParams = {
      email: this.contact.email,
      size: this.pageSize,
      page: this.pageIndex,
      search: this.inputSearchValue,
    };
    this.loading = true;
    this.mailService.getForIncomingCallList(this.incomingCallSheetParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        takeUntil(this.isDestroyed$),
      ).subscribe(
        (response) => {
          this.emailsData = response.data;
          this.total = response.meta.total;
        },
      );
  }

}
