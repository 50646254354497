import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StatisticModeEnum } from '@libs/enum/src';
import { ChartService, StatisticInterventionRevenueService, UnsubscribeOnDestroy } from '@libs/services/src';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-statistics-revenues-technicians',
  templateUrl: './statistics-revenues-technicians.component.html',
  styleUrls: ['./statistics-revenues-technicians.component.scss'],
})
export class StatisticsRevenuesTechniciansComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public options: any;
  public statisticsData: any;
  @Input() displayMode: number;

  constructor(
    private statisticInterventionRevenueService: StatisticInterventionRevenueService,
    private chartService: ChartService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.statisticInterventionRevenueService.statsInterventionsRevenueTechnicians$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          if (data) {
            this.initBarChart(data);
            this.statisticsData = data;
          }
        },
      );
  }

  initBarChart(data: any): void {
    if (data) {
      if (this.displayMode === StatisticModeEnum.YMINUSONE) {
        this.options = this.chartService.initYMinusOneVolumeOptions(data);
      } else {
        this.options = this.chartService.initRevenueOptions(data);
      }
    }
  }

}
