import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { BarType } from '../types/bar-type.type';

@Injectable()
export class BarTypeService {

  public barTypes$ = new BehaviorSubject<BarType[]>(undefined);

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
  ) {}

  getAll(): void {
    this.http.get<BarType[]>(`${environment.serviceEUrl}/bar-types`).subscribe({
      next: (barTypes: BarType[]) => {
        this.barTypes$.next(barTypes);
      },
    });
  }

  create(form: FormGroup): Observable<any> {
    return this.http.post<BarType[]>(`${environment.serviceEUrl}/bar-types`, form.value);
  }

  update(barTypeName: string, form: FormGroup): Observable<any> {
    return this.http.put<BarType[]>(`${environment.serviceEUrl}/bar-types/${barTypeName}`, form.value);
  }

  getForm(barType?: BarType): FormGroup {
    const name = barType ? barType.name : null;
    const linkedTo = barType ? barType.linked_to : null;
    return this.formBuilder.group({
      name: [name, Validators.required],
      linkedTo: [linkedTo],
    });
  }

}
