import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';

export interface IncomingCallRecordsSheetParameters {
  phoneNumber: string;
  size: number;
  page: number;
  search: string;
}
@Injectable({
  providedIn: 'root',
})
export class AircallService extends ModelWithDatatableAndCrud {

  public telephonyWindow: Window = null;

  constructor(
    http: HttpClient,
    notifService: NotifService,
    submitButtonService: SubmitButtonService,
    swalService: SwalService,
    router: Router,
    apiService: ApiService,
  ) {
    super(
      http,
      notifService,
      submitButtonService,
      swalService,
      apiService,
      router,
    );
  }

  public makeCall(number: string): Observable<unknown> {
    return this.apiService.post('/aircall/make-call', { number }).pipe(
      catchError((error) => {
        this.swalService.showSwalError('test');
        return throwError(error);
      }),
    );
  }

  public getRecordsForIncomingCallSheetList(params: IncomingCallRecordsSheetParameters): Observable<any> {
    return this.apiService.post('/aircall-records/calls/list', params)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  // TODO : Changer l'url une fois que Logan m'a fourni cette route (rul/params/reponse?)
  public getUserAvailabitlty(): Observable<{availability: string}> {
    return this.apiService.get<{availability: string}>('/aircall/check-availability')
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  public openTelephonyWindow(numberToCall: string = null): void {
    if (this.telephonyWindow === null || this.telephonyWindow.closed) {
      this.telephonyWindow = window.open(`/telephony${numberToCall ? `?toCall=${numberToCall}` : ''}`, 'telephony', 'popup');
    } else {
      this.telephonyWindow.focus();
      if (numberToCall) {
        this.makeCall(numberToCall).subscribe();
      }
    }
  }

}
