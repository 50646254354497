/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { ApiService } from '@libs/api/src';
import { Subscription } from 'rxjs';
import { NzAutocompleteOptionComponent } from 'ng-zorro-antd/auto-complete';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { tabSuffix } from '_config/tab-suffix';
import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { PeriodBatch } from '../../types/periodbatch.type';
import { Delegator } from '../../types/delegator.type';
import { Set } from '../../types/set.type';
import { BarType } from '../../types/bar-type.type';
import { BarTypeService } from '../../services/bar-type.service';
import { Model } from '../../types/model.type';

@Component({
  selector: 'eros-front-create',
  templateUrl: './inspections-set-create.component.html',
  styleUrls: ['./inspections-set-create.component.scss'],
})
export class InspectionsSetCreateComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public form: FormGroup;
  private _delegators: Delegator[];
  private _filesMoulinette: File[] = [];
  private _files: File[] = [];
  private _fullSets: any[] = [];
  private _sets: string[];
  private _columnsIndex: any[];
  private _glossary: any[];
  private _isVisibleImport: boolean = false;
  private _isVisibleIndex: boolean = false;
  private _setUid: string;
  public isDuplucate: boolean;
  public newIndex: string;
  public selectedIndex: string;
  private _isVisibleMerge: boolean;
  private _currentColumn: any;
  private _listOfColumns: any[];
  private _listOfDisplayColumns: any[];
  public searchValue = '';
  public visibleSearch = false;
  public listOfCellsToMerge: string[] = [] as string[];
  public isDisabledOk: boolean = true;
  public periodBatchs: PeriodBatch[] = [] as PeriodBatch[];
  public unknowIndex: string = '';
  public kizeotags: any[];
  public name: string;
  public keywords: string;
  public locked: boolean;
  public barTypes: BarType[] = [];
  public isSetComplement: boolean = false;
  private _barTypes$: Subscription;
  public models: Model[];
  public isLoadingModels: boolean = true;

  /**
   * Style for merge cells table
   */
  public gridStyle = {
    width: '25%',
    textAlign: 'center',
  };

  /**
   * Constructor of Create Set component
   * @param _http HttpClient
   * @param _notifService NotificationService
   * @param _apiService ApiService
   * @param _barTypeService BarTypeService
   */
  public constructor(
    private _http: HttpClient,
    private _notifService: NotifService,
    private _apiService: ApiService,
    private _barTypeService: BarTypeService,
    private spinnerService: NgxSpinnerService,
    private titleService: Title,
  ) {
    super();
    this.form = this._buildForm();
    this._sets = [] as string[];
    this._barTypes$ = this._barTypeService.barTypes$
      .subscribe({
        next: (barTypes: BarType[]) => {
          this.barTypes = barTypes;
        },
      });
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Import d'un lot ${tabSuffix}`);
    this._http.get<Set[]>(`${environment.serviceEUrl}/set`).subscribe({
      next: (data) => {
        this._fullSets = data;
        for (let i = 0; i < data.length; i++) {
          this._sets.push(data[i].set_number);
        }
      },
    });

    this._apiService.get<any>('/agencies/customers-types/authorized-representative').subscribe({
      next: (data) => {
        this._delegators = data;
        this._delegators = [...this._delegators];
      },
    });

    this._http.get<PeriodBatch[]>(`${environment.serviceEUrl}/period-batch`).subscribe({
      next: (data) => {
        this.periodBatchs = data;
      },
    });

    this._barTypeService.getAll();

    this._http.get<Model[]>(`${environment.serviceEUrl}/models`).subscribe({
      next: (data) => {
        this.models = data;
        this.isLoadingModels = false;
      },
    });
  }

  ngOnDestroy(): void {
    this._barTypes$.unsubscribe();
  }

  /**
   * Set modal import row danger background
   */
  public isRowDanger(id: string): boolean {
    return id === this.unknowIndex;
  }

  /**
   * Set modal import row warning background
   */
  public isRowWarning(id: string): boolean {
    return id !== this.unknowIndex && this.columnIndex.filter((col) => { return col.id_index === id; }).length > 1;
  }

  /**
   * Return the list of files for moulinette
   */
  public get filesMoulinette(): File[] {
    return this._filesMoulinette;
  }

  /**
   * Add the files to upload to the list of files for moulinette
   * @param event
   */
  public onSelectMoulinette(event): void {
    this._filesMoulinette.push(...event.addedFiles);
    if (this._filesMoulinette.length > 1) {
      this._filesMoulinette.splice(0, 1);
    }
  }

  /**
   * Remove a file from the list of uploaded files for moulinette
   * @param event
   */
  public onRemoveMoulinette(event): void {
    this._filesMoulinette.splice(this._filesMoulinette.indexOf(event), 1);
  }

  /**
   * Apply moulinette
   */
  public submitFormMoulinette(): void {
    const payload = new FormData();
    payload.append('file', this._filesMoulinette[0]);
    this._http.post<any>(`${environment.serviceEUrl}/tools/moulinette`, payload).subscribe({
      next: (_) => {
        window.open(`${environment.serviceEUrl}/tools/moulinette`, '_blank');
        this._filesMoulinette = [];
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });
  }

  /**
   * Reset the search input
   */
  public reset(): void {
    this.searchValue = '';
    this.search();
  }

  /**
   * Filter the spreadsheet depending the value in the input
   */
  public search(): void {
    this.visibleSearch = false;
    this._listOfDisplayColumns = this._listOfColumns.filter((item: any) => { return item.set_column_name.indexOf(this.searchValue) !== -1; });
  }

  /**
   * display modal, creates the set and display the index of the glossary
   */
  public showModalImport(): void {
    const delegataireId = this.form.get('selectedDelegator').value;
    const setName = this.form.get('selectedSetName').value;
    // gets the index for the set
    this._http.get<any>(`${environment.serviceEUrl}/set/header/${delegataireId}/${setName}`).subscribe({
      next: (data) => {
        this._columnsIndex = data;
        this._http.get<any>(`${environment.serviceEUrl}/glossary`).subscribe({
          next: (glossary) => {
            this._glossary = glossary;
            this.isDisabledOk = false;
            this.unknowIndex = this.getUnknowIndex();
            this.form.reset();
            this._sets = [];
            this._fullSets = [];
            this._files = [];
            this.ngOnInit();
          },
        });
      },
    });
  }

  /**
   * Update the index of the header
   * @param idHeader id of the header
   * @param event
   */
  public updateIndex(columnLetter: string, event): void {
    const payload = {
      id_index: event,
    };
    this._http.patch<any>(`${environment.serviceEUrl}/set/header/${columnLetter}`, payload).subscribe({
      next: (_) => {
        this._notifService.showSuccessNotif("L'index a bien été modifié");
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });
  }

  /**
   * Return the glossary
   */
  public get glossary(): any[] {
    return this._glossary;
  }

  /**
   * Return the index of the columns
   */
  public get columnIndex(): any[] {
    return this._columnsIndex;
  }

  /**
   * Return true of the index are still loading, otherwise false
   */
  public get isLoadingColumnIndex(): boolean {
    return this._glossary === undefined;
  }

  /**
   * When the user clicks ok on the modal, display a notification and close the modal
   */
  public handleOkImportModal(): void {
    this._glossary = undefined;
    this._isVisibleImport = false;
    this.unknowIndex = this.getUnknowIndex();
    this.spinnerService.show('load-set-create');
    this._http.get<any>(`${environment.serviceEUrl}/set/${this._setUid}/sanitize`)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinnerService.hide('load-set-create'); }),
      )
      .subscribe(() => {
        this._notifService.showSuccessNotif('Le lot a été créé avec succès !');
      });

  }

  /**
   * Return true if the list of delegators is getting loaded, otherwise false
   */
  public get isLoadingDelegators(): boolean {
    return this._delegators === undefined;
  }

  /**
   * Close the modal
   */
  public handleCancelMergeModal(): void {
    this._isVisibleMerge = false;
  }

  /**
   * Return true if the modal is visible, otherwise false
   */
  public get isVisibleImport(): boolean {
    return this._isVisibleImport;
  }

  /**
   * Show the modal to create an index
   */
  public showModalIndex(): void {
    this.name = null;
    this.keywords = null;
    this.kizeotags = [];
    this.barTypes.forEach((barType) => {
      const item: any = {};
      item[barType.name] = '';
      this.kizeotags.push(item);
    });
    this.locked = false;
    this._isVisibleIndex = true;
  }

  /**
   * close the modal when the user clicks on Ok and create the index
   */
  public handleOkIndex(): void {
    this.createIndex();
    this._isVisibleIndex = false;
  }

  /**
   * Show the modal
   */
  public showModalMerge(currentColumn: any, listOfColumns): void {
    this._currentColumn = currentColumn;
    this._listOfColumns = listOfColumns;
    this._listOfDisplayColumns = listOfColumns;
    this._isVisibleMerge = true;
  }

  /**
   * Return the value of _listOfDisplayColumns
   */
  public get getListOfColumnsForMerge(): any[] {
    return this._listOfDisplayColumns;
  }

  /**
   * Return the value of _isVisibleMerge
   */
  public get isVisibleMerge(): boolean {
    return this._isVisibleMerge;
  }

  /**
   * Set the value of _isVisibleMerge
   * @param val
   */
  public set isVisibleMerge(val: boolean) {
    this._isVisibleMerge = val;
  }

  /**
   * Create a new index in the glossary
   */
  public createIndex(): void {
    // set keywords to lowercase
    this.keywords = this.keywords.toLowerCase();
    this.keywords.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    this._http.post<any>(`${environment.serviceEUrl}/glossary`, {
      name: this.name,
      key_words: this.keywords,
      kizeo_tags: this.kizeotags,
      locked: this.locked,
    }).subscribe({
      next: (_) => {
        this._http.get<any>(`${environment.serviceEUrl}/glossary`).subscribe({
          next: (data) => {
            this._glossary = data;
            this.unknowIndex = this.getUnknowIndex();
            this._notifService.showSuccessNotif('L\'index a bien été ajouté');
          },
        });
      },
    });
  }

  /**
   * close the modal when the user clicks on Cancel
   */
  public handleCancelIndex(): void {
    this._isVisibleIndex = false;
  }

  /**
   * Return the value of _isVisibleIndex variable
   */
  public get isVisibleIndex(): boolean {
    return this._isVisibleIndex;
  }

  /**
   * Submit the form and makes a post request to the api to create the set
   */
  public submitForm(): void {
    this._isVisibleImport = true;
    const payload = new FormData();
    payload.append('id_delegataire', this.form.get('selectedDelegator').value);
    payload.append('set_number', this.form.get('selectedSetName').value);
    payload.append('return_date', this.form.get('returnDate').value);
    payload.append('id_model', this.form.get('selectedModel').value);
    for (let i = 0; i < this._files.length; i++) {
      payload.append('file[]', this._files[i]);
    }
    this.spinnerService.show('load-set-create');
    this._http.post<any>(`${environment.serviceEUrl}/set`, payload)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinnerService.hide('load-set-create'); }),
      )
      .subscribe({
        next: (data) => {
          this._setUid = data.set_uid;
          this.isDuplucate = data.is_duplicate;
          this.showModalImport();
        },
        error: (err) => {
          this._isVisibleImport = false;
          this.form.reset();
          this._sets = [];
          this._files = [];
          this._notifService.showErrorNotif(err.error.message);
        },
      });
  }

  /**
   * Add the files to upload to the list of files
   * @param event
   */
  public onSelect(event): void {
    this._files.push(...event.addedFiles);
    if (this._files.length > 1) {
      this._files.splice(0, 1);
    }
  }

  /**
   * Remove a file from the list of uploaded files
   * @param event
   */
  public onRemove(event): void {
    this._files.splice(this._files.indexOf(event), 1);
  }

  /**
   * Return true if the button has to be disabled, otherwise false
   */
  public canUploadSet(): boolean {
    const selectedDelegator = this.form.get('selectedDelegator').value === null;
    const selectedSetName = this.form.get('selectedSetName').value === null;
    const selectedModel = this.form.get('selectedModel').value === null;
    const files = this._files.length === 0;
    if (!this.isSetComplement) {
      return selectedDelegator || selectedSetName || files || selectedModel;
    }
    return selectedSetName || files;

  }

  /**
   * Return the list of files
   */
  public get files(): File[] {
    return this._files;
  }

  /**
   * Return the list of delegators
   */
  public get delegators(): Delegator[] {
    return this._delegators;
  }

  /**
   * Return true if it's a set complement, otherwise false
   */
  public get isComplement(): boolean {
    return this.form.get('isSetComplement').value;
  }

  /**
   * Merge the content of the columns in parameter in the column in the url
   */
  public mergeCells(): void {
    const payload = {
      letters: this.listOfCellsToMerge,
    };
    this._http.patch<any>(`${environment.serviceEUrl}/set/beneficiary/${this._setUid}/${this._currentColumn.set_column_letter}`, payload)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (_) => {
          this._notifService.showSuccessNotif(`Le contenu de la cellule ${this._currentColumn.set_column_name} a bien été fusionné dans la cellule sélectionnée.`);
        },
        error: (err) => {
          this._notifService.showErrorNotif(err.error.message);
        },
      });
    this.isVisibleMerge = false;
  }

  /**
   * Return the value of _currentColumn.set_column_name
   */
  public get cellToGiveContent(): string {
    return this._currentColumn.set_column_name;
  }

  /**
   * Builds the FormGroup and returns its valÒue
   * All fields are required
   */
  private _buildForm(): FormGroup {
    return new FormGroup({
      selectedDelegator: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      selectedSetName: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      isSetComplement: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      setColumnName: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      returnDate: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      selectedIndexId: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      selectedModel: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
    });
  }

  /**
   * Get unknow index to display error if needed
   */
  private getUnknowIndex(): string {
    if (Array.isArray(this._glossary)) {
      for (let i = 0; i < this._glossary.length; i++) {
        if (this._glossary[i].name_id === 'index-inconnu') {
          return this._glossary[i]._id;
        }
      }
    }
    return '';
  }

  /**
   * Add the letter to the list of not already inserted, otherwise remove
   * @param letter
   */
  public updateMergeCellsList(letter: string): void {
    if (this.listOfCellsToMerge.includes(letter)) {
      this.listOfCellsToMerge.splice(this.listOfCellsToMerge.indexOf(letter), 1);
    } else {
      this.listOfCellsToMerge.push(letter);
    }
  }

  /**
   * Return all sets
   */
  public get sets(): string[] {
    return this.isComplement ? this._sets : [];
  }

  /**
   * Get the value associated to a key in _kizeotags on input change
   */
  public getKizeoTagValue(barTypeName: string): string {
    this.kizeotags.forEach((tag) => {
      if (Object.keys(tag)[0] === barTypeName) {
        return tag[barTypeName];
      }
    });
    return '';
  }

  /**
   * Edit the key-value array _kizeotags on input change
   */
  public onKizeoTagChange(barTypeName: string, event: any): void {
    for (const tag of this.kizeotags) {
      if (Object.keys(tag)[0] === barTypeName) {
        tag[barTypeName] = event.target.value;
        break;
      }
    }
  }

  onSetComplementChanged(): void {
    this.form.patchValue({
      selectedDelegator: null,
      selectedSetName: null,
      returnDate: null,
    }, { emitEvent: false });
    this.isSetComplement = (this.form != null && this.form.value.isSetComplement);
  }

  onSetNameAutocomplete(value: NzAutocompleteOptionComponent): void {
    const item = this._fullSets.find((s) => { return s.set_number === value.nzValue; });
    if (item != null) {
      this.form.patchValue({
        selectedDelegator: item.id_delegataire,
        returnDate: item.return_date,
      }, { emitEvent: false });
    } else {
      this._notifService.showErrorNotif('Erreur ! Aucun lot ne correspond à la sélection.');
    }
  }

}
