import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Agency, Contact, Group } from '@libs/models/src';
import { AgencyService, ContactService, CustomerService, NotifService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { TelephonyService } from '@libs/services/src/lib/telephony.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AircallService } from '@libs/services/src/lib/aircall.service';
import { CustomersShowContactsModalComponent } from './customers-show-contacts-modal/customers-show-contacts-modal.component';

@Component({
  selector: 'app-customers-show-contacts',
  templateUrl: './customers-show-contacts.component.html',
  styleUrls: ['./customers-show-contacts.component.scss'],
})
export class CustomersShowContactsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() appKey: string;
  @Input() customer: any;
  @Input() professions: any[];
  @Input() group: Group;
  @Input() mlSocieties: any[];
  @Input() agencies: Agency[];
  @ViewChild('searchField') searchField: ElementRef;
  @Input() value = '';
  @Input() isAgency = false;
  private destroy$ = new Subject<void>();
  public contactsFiltered: any;
  public delayTime = 500;
  public form: FormGroup;
  public groups: Group[] = [];
  public defaultAgencyId = null;

  constructor(
    private contactService: ContactService,
    private customerService: CustomerService,
    private modalService: NzModalService,
    private notifService: NotifService,
    private spinner: NgxSpinnerService,
    private telephonyService: TelephonyService,
    private agencyService: AgencyService,
    private aircallService: AircallService,
    private swalService: SwalService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.customer) {
      this.contactsFiltered = this.customer.contacts;
      this.groups.push(this.group);

      if (this.value.length > 0) {
        this.filterContacts(this.value.toLocaleLowerCase());
      }
      this.agencyService.getForSelectModel()
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (agencies) => {
            this.agencies = agencies;
          },
        );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer && changes.customer.previousValue !== changes.customer.currentValue) {
      this.contactsFiltered = this.customer.contacts;
      if (this.value.length > 0) {
        this.filterContacts(this.value.toLocaleLowerCase());
      }
    }
  }

  onShowModalAddEditModal(contact?: Contact): void {
    if (contact == null && this.isAgency) {
      this.defaultAgencyId = this.customer.id;
    }
    this.form = this.contactService.getForm(contact, this.defaultAgencyId);
    const modalInstance = this.modalService.create({
      nzContent: CustomersShowContactsModalComponent,
      nzComponentParams: {
        contact: contact,
        customer: this.customer,
        form: this.form,
        group: this.group,
        professions: this.professions,
        groups: this.groups,
        mlSocieties: this.mlSocieties,
        agencies: this.agencies,
      },
      nzStyle: { top: '2vh' },
      nzWidth: '98%',
    });
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.customerService.refreshEntityByCustomerRef(this.customer.ref);
    });
  }

  onUserPortalCreateClicked(contact: Contact): void {
    Swal.fire({
      title: 'Souhaitez-vous générer des accès à l\'espace ABM ?',
      text: 'Un email contenant les intructions de connexion sera envoyé automatiquement',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.contactService.createPortalAccess(contact.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (success) => {
              this.notifService.showSuccessNotif(success);
              this.customerService.refreshEntityByCustomerRef(this.customer.ref);
            },
          );
      }
    });
  }

  onDeleteContactClicked(contact: Contact): void {
    Swal.fire({
      title: 'Êtes vous sûr ?',
      text: 'Le contact sera supprimé',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show('delete-contact');
        this.contactService.delete(contact.id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (success) => {
              this.notifService.showSuccessNotif(success);
              this.customerService.refreshEntityByCustomerRef(this.customer.ref);
              this.spinner.hide('delete-contact');
            },
            (error) => {
              this.spinner.hide('delete-contact');
            },
          );
      }
    });
  }

  searchKeyPress(event): void {

    fromEvent(this.searchField.nativeElement, 'input')
      .pipe(
        debounce(() => { return timer(this.delayTime); }),
        distinctUntilChanged(
          null,
          (e: Event) => { return (e.target as HTMLInputElement).value; },
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((e) => {
        this.filterContacts(this.value);
      });
  }

  onRedirectCallClicked(phoneNumber: string): void {
    window.open(`incoming-call?phoneNumber=${phoneNumber}`);
  }

  onCallClicked(phoneNumber: string): void {
    if (this.appKey === 'nrj') {
      this.telephonyService.call(phoneNumber).subscribe(
        (success) => {
          this.notifService.showSuccessNotif('Appel effectué avec succès');
        },
      );
    } else if (this.appKey === 'abm') {
      if (this.aircallService.telephonyWindow !== null && !this.aircallService.telephonyWindow.closed) {
        this.aircallService.makeCall(phoneNumber).subscribe();
        this.aircallService.telephonyWindow.focus();
      } else {
        this.aircallService.getUserAvailabitlty().subscribe(
          (response) => {
            if (response.availability === 'offline') {
              this.aircallService.openTelephonyWindow(phoneNumber);
            } else {
              this.aircallService.makeCall(phoneNumber).subscribe();
            }
          },
        );
      }
    }

  }

  clearSearch(): void {
    this.value = '';
    this.contactsFiltered = this.customer.contacts;
  }

  private filterContacts(value) {
    this.contactsFiltered = this.customer.contacts.filter((c) => { return c.fullName.toLowerCase().indexOf(value) !== -1; });
  }

}
