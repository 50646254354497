import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { Affair, Agency, Intervention, Product } from '@libs/models/src';
import { AffairService, AgencyService, CommonService, InterventionService, NotifService, ProductService, SwalService, UserService } from '@libs/services/src';
import { TelephonyService } from '@libs/services/src/lib/telephony.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateUtilsService } from '@libs/services/src/lib/utilities/date-utils.service';
import { CallReason, CallReasons, ReasonType } from '../../_const/call-reason';

@Component({
  selector: 'app-inspections-modal-call',
  templateUrl: './inspections-modal-call.component.html',
  styleUrls: ['./inspections-modal-call.component.scss'],
})
export class InspectionsModalCallComponent implements OnInit, OnDestroy {

  @Input() beneficiaryId: string;
  @Input() customer: any;
  public form: FormGroup;
  public isLoading: boolean;
  public affairProducts: FormArray;
  public callReasons: CallReason[];
  public agencies: any[];
  public products: Product[];
  public technicians: any[];
  public reason: ReasonType;
  public comment: string;
  public isCreateAffair: boolean = false;
  public isContactAppointment: boolean = false;
  public defaultDate = null;
  public showTime = {
    nzMinuteStep: 15,
    nzFormat: 'HH:mm',
  };

  public contactForm: FormGroup;
  public intervention: Intervention = null;
  public affair: Affair = null;
  public currentDate = new Date();
  public currentDateString = '';
  public currentTimeString = '';

  private selectedAgency: Agency;
  private agency$: Subscription;
  private agencies$: Subscription;
  private technicians$: Subscription;
  private isDestroyed$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private modal: NzModalRef,
    private commonService: CommonService,
    private notifService: NotifService,
    private swalService: SwalService,
    private telephonyService: TelephonyService,
    private agencyService: AgencyService,
    private userService: UserService,
    private productService: ProductService,
    private interventionService: InterventionService,
    private dateUtilsService: DateUtilsService,
    private affairService: AffairService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.agencyService.getForSelect();
    this.userService.getTechniciansSelect();
    this.callReasons = CallReasons;
    const defaultString = this.commonService.getDefaultFormStringValue();
    const defaultNull = this.commonService.getDefaultFormNullValue();
    const defaultRadio = this.commonService.getDefaultFormRadioValue();
    const isPP = this.customer['type-personne'] === 'PP';
    this.form = this.formBuilder.group({
      beneficiaryId: [this.beneficiaryId, Validators.required],
      agencyId: [defaultNull, Validators.required],
      workSiteStreetNumber: defaultString,
      workSiteAddress: [this.customer['adresse-beneficiaire'], Validators.required],
      workSiteAdditionalAddress: defaultString,
      workSitePostalCode: [this.customer['code-postal'].toString(), Validators.required],
      workSiteLocality: [this.customer.ville, Validators.required],
      finalCustomerLastname: [isPP ? this.customer['nom-du-beneficiaire'] : this.customer['raison-sociale-beneficiaire-operation'], Validators.required],
      finalCustomerFirstname: isPP ? this.customer['prenom-du-bénéficiaire'] : '',
      customerRef: [this.customer['ref-client'], Validators.required],
      techniciansIds: [defaultNull, Validators.required],
      description: this.customer['saisie-eros'],
      unitNumber: [this.customer['numero-lot'], Validators.required],
      unitDeadline: [this.customer['date-butoir']],
      doNotShift: false,
      doNotShiftReason: defaultString,
      timeSlot: defaultNull,
      startTimeSlot: defaultNull,
      endTimeSlot: defaultNull,
      isTimeSlot: [defaultRadio, Validators.required],
      startDate: [defaultNull, Validators.required],
      duration: [defaultNull, Validators.required],
      isConfirmed: this.reason === ReasonType.AppointmentConfirmed,
      products: this.formBuilder.array([]),
    });
    this.affairProducts = this.form.get('products') as FormArray;
    this.addEmptyProduct();

    this.currentDateString = this.currentDate.toLocaleDateString('fr');
    this.currentTimeString = this.currentDate.toLocaleTimeString('fr');
    this.contactForm = this.formBuilder.group({
      contactDate: this.currentDateString,
      contactTime: this.currentTimeString,
      contactWay: ['', Validators.required],
      workExistence: ['', Validators.required],
      qualityMissing: [''],
      qualityComment: [''],
      agencyId: [defaultNull, Validators.required],
      workSiteStreetNumber: defaultString,
      workSiteAddress: [this.customer['adresse-beneficiaire'], Validators.required],
      workSiteAdditionalAddress: defaultString,
      workSitePostalCode: [this.customer['code-postal'].toString(), Validators.required],
      workSiteLocality: [this.customer.ville, Validators.required],
      finalCustomerLastname: [isPP ? this.customer['nom-du-beneficiaire'] : this.customer['raison-sociale-beneficiaire-operation'], Validators.required],
      finalCustomerFirstname: isPP ? this.customer['prenom-du-bénéficiaire'] : '',
      customerRef: [this.customer['ref-client'], Validators.required],
      unitNumber: [this.customer['numero-lot'], Validators.required],
    });

  }

  ngOnDestroy(): void {
    this.agency$?.unsubscribe();
    this.agencies$?.unsubscribe();
    this.technicians$?.unsubscribe();
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  isValidForm(): boolean {
    if (this.isCreateAffair) {
      return this.form != null && this.form.valid;
    } if (this.isContactAppointment) {
      return this.contactForm != null && this.contactForm.valid;
    }
    return this.reason != null;
  }

  clickToCall(phoneNumber: string): void {
    if (phoneNumber != null && phoneNumber.length > 0) {
      this.isLoading = true;
      this.telephonyService.call(phoneNumber)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe();
    }
  }

  onReasonChange(reason: CallReason): void {
    this.reason = reason.value;
    this.isContactAppointment = reason.value === ReasonType.AppointmentContact;
    this.isCreateAffair = (reason.value === ReasonType.AppointmentConfirmed || reason.value === ReasonType.ConfirmationWaiting);
    this.form.patchValue({
      isConfirmed: this.reason === ReasonType.AppointmentConfirmed,
    });
  }

  onAgencyChange(selected: any): void {
    this.agencyService.get(selected.value)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (agency: Agency) => {
          if (agency) {
            this.selectedAgency = agency;
          }
        },
      );
  }

  onAddProduct(): void {
    this.addEmptyProduct();
  }

  onProductChange(product: Product, index: number): void {
    const productFound = this.selectedAgency?.products.find((p: any) => { return p.productId === product.id && p.marketTypeId === AffairMarketType.INSPECTION; });
    if (productFound != null) {
      this.affairProducts.at(index).patchValue({
        price: productFound.price,
      });
      this.form.patchValue({
        duration: this.dateUtilsService.getDuration(product.duration),
      });
    }
  }

  onRemoveProduct(index: number): void {
    if (this.affairProducts.length > 1) {
      this.affairProducts.removeAt(index);
    }
  }

  onDoNotShiftChanged(): void {
    if (!this.isDoNotShiftChecked()) {
      this.form.patchValue({
        doNotShiftReason: '',
      });
    }
  }

  isDoNotShiftChecked(): boolean {
    return this.form.value.doNotShift;
  }

  isTimeSlotChecked(): boolean {
    if (this.form != null) {
      return this.form.value.isTimeSlot === '1';
    }
    return false;
  }

  onClose(): void {
    this.modal.destroy();
  }

  onSave(): void {
    if (this.isValidForm()) {
      this.isLoading = true;
      if (this.isCreateAffair) {
        this.interventionService.createForInspection(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (response) => {
              this.notifService.showSuccessNotif(response);
              this.isLoading = false;
              if (response.data) {
                this.intervention = response.data;
              }
              this.modal.triggerOk();
              this.modal.destroy();
            },
            (error) => {
              this.swalService.showSwalError(error);
              this.isLoading = false;
            },
          );
      } else if (this.isContactAppointment) {
        this.affairService.createForInspection(this.contactForm)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (response) => {
              this.notifService.showSuccessNotif(response);
              this.isLoading = false;
              if (response.data) {
                this.affair = response.data;
              }
              this.modal.triggerOk();
              this.modal.destroy();
            },
          );
      } else {
        this.isLoading = false;
        this.modal.triggerOk();
        this.modal.destroy();
      }
    }
  }

  private initSubscriptions(): void {
    this.agencies$ = this.agencyService.getForSelect()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((agencies: any[]) => {
        this.agencies = agencies;

      });
    this.userService.getAllTechnicians()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((technicians: any[]) => {
        this.technicians = this.userService.formatToSelectMultiple(technicians);
      });
    this.productService.getByMarketType(AffairMarketType.INSPECTION)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (products: Product[]) => {
          this.products = products;
        },
        (error) => {
          this.swalService.showSwalError(error);
        },
      );
  }

  private addEmptyProduct(): void {
    this.affairProducts.push(this.formBuilder.group({
      productId: [this.commonService.getDefaultFormNullValue(), Validators.required],
      quantity: [1, Validators.required],
      price: [0, Validators.required],
    }));
  }

}
