import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthentificationService } from '@libs/auth/src';
import { InterventionService, MarketTypeService, SwalService, UnsubscribeOnDestroy, UserService } from '@libs/services/src';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, throwError, zip } from 'rxjs';
import { EchoService } from 'angular-laravel-echo';
import { catchError, debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import Echo from 'laravel-echo';
import * as io from 'socket.io-client';
// Configs
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';
import { SelectModel } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { Router } from '@angular/router';
import { isThisHour } from 'date-fns';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends UnsubscribeOnDestroy implements OnInit {

  public userInfos: any;
  public form: FormGroup;
  public users: SelectModel[];
  public days = [
    {
      label: '7 jours',
      value: 7,
    },
    {
      label: '15 jours',
      value: 15,
    },
    {
      label: '30 jours',
      value: 30,
    },
  ];

  public defaultDayNumber = {
    label: '7 jours',
    value: 7,
  };

  echo: Echo;
  public categories = [];
  public selectedMarketsTypes = [];
  public readonly storageKey = 'dashboard-form';

  public marketsTypes: SelectModel[] = [];
  public isAssistant: boolean = false;
  public isTechnician: boolean = false;
  public refresh: boolean = false;

  constructor(
    private titleService: Title,
    private authService: AuthentificationService,
    private userService: UserService,
    private dashboardService: DashboardService,
    private localStorageService: CustomLocalStorageService,
    private storageService: FormLocalStorageService,
    private marketTypeService: MarketTypeService,
    private router: Router,
  ) {
    super();
    this.titleService.setTitle(`Accueil${tabSuffix}`);
  }

  ngOnInit(): void {
    this.userInfos = this.authService.getUserInfos();
    this.isAssistant = this.authService.isAssistants();
    this.isTechnician = this.authService.isTechnician();
    this.selectedMarketsTypes = this.localStorageService.getSelectedMarketsTypes();
    this.userService.getUsersSelect()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.users = data;
        },
      );
    this.marketTypeService.getForSelect()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((data) => {
        this.marketsTypes = data;
      });
    this.initForm();
  }

  private initForm(): void {
    const selectedMarketsTypes = this.selectedMarketsTypes.map((x) => { return x.id; });
    const storageFormValue = this.storageService.retrieve(this.storageKey);

    if (storageFormValue != null) {
      storageFormValue.userId = this.userInfos.id;
      this.form = this.dashboardService.getForm(storageFormValue, selectedMarketsTypes, this.marketsTypes);
      this.formValueChanges();
    } else {
      const filtersValues = {
        userId: this.userInfos.id,
        dayNumber: this.defaultDayNumber,
      };
      this.form = this.dashboardService.getForm(filtersValues, selectedMarketsTypes, this.marketsTypes);
      this.formValueChanges();
    }
  }

  private formValueChanges(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((form) => {
        this.storageService.store(this.storageKey, this.form);
        this.refresh = !this.refresh;
      });
  }

}
