import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardTileKeyEnum } from '@libs/enum/src';
import { DashboardCategory, DashboardStatistic, DashboardTile } from '@libs/models/src';
import { DashboardCategoryService, DashboardStatisticService, UnsubscribeOnDestroy } from '@libs/services/src';
import { interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-overview-assistant',
  templateUrl: './overview-assistant.component.html',
  styleUrls: ['./overview-assistant.component.scss'],
})
export class OverviewAssistantComponent extends UnsubscribeOnDestroy implements OnInit {

  public dashboardCategories: DashboardCategory[] = [];
  public dashboardStatistics: DashboardStatistic[] = [];
  public dashboardTileEnum = DashboardTileKeyEnum;

  constructor(
    private dashboardCategoryService: DashboardCategoryService,
    private dashboardStatisticService: DashboardStatisticService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dashboardCategoryService.getAll().subscribe((dashboardCategories) => {
      this.dashboardCategories = dashboardCategories;
    });
    timer(0, 60000) // 60000 millisecondes = 1 minute
      .pipe(
        switchMap(() => { return this.dashboardStatisticService.getForUser(); }),
      )
      .subscribe({
        next: (dashboardStatistics) => {
          this.dashboardStatistics = dashboardStatistics;
        },
        error: (error) => {
          console.error('Une erreur s\'est produite : ', error);
        },
      });
  }

  getStatisticValue(dashboardTileId: number): number {
    const dashboardStatistic = this.dashboardStatistics.find((x) => { return x.dashboardTileId === dashboardTileId; });
    if (dashboardStatistic) {
      return dashboardStatistic.value;
    }
    return 0;
  }

  getStatisticTotal(dashboardTileId: number): number {
    const dashboardStatistic = this.dashboardStatistics.find((x) => { return x.dashboardTileId === dashboardTileId; });
    if (dashboardStatistic) {
      return dashboardStatistic.total;
    }
    return 0;
  }

  onRedirectOverviewList(dashboardTile: DashboardTile): void {
    if (dashboardTile.key === this.dashboardTileEnum.NONCONFORMITY_WAITING_VALIDATION_KEY) {
      window.open('interventions/waiting-validation', '_blank');
    } else if (dashboardTile.key === this.dashboardTileEnum.NONCONFORMITY_WAITING_ACTION_CLIENT_KEY) {
      window.open('interventions/not-valid', '_blank');
    } else {
      window.open(`/overview/list/tile/${dashboardTile.id}`, '_blank');
    }
  }

  getStatisticTotalTextByEntity(entity: string): string {
    switch (entity) {
      case 'affair': return 'affaires';
      case 'intervention': return 'interventions';
      case 'nonconformity': return 'non-conformités';
      case 'event': return 'évènements';
      case 'affair-monitoring': return 'suivis d\'affaire';
      default: return '';
    }
  }

}
