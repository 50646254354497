import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel, User } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { CustomLocalStorageService, ToggleService, UnsubscribeOnDestroy } from '@libs/services/src';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss'],
})
export class DashboardSettingsComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() users: User[];
  @Input() days: SelectModel[];
  public selectedmarkettypes: string[] = [];
  @Input() marketsTypes: SelectModel[];

  public isOpen: boolean = false;

  constructor(
    private toggleService: ToggleService,
    private localStorageService: CustomLocalStorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.toggleService.quickViewToggle
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((data) => {
        this.toggle();
      });
  }

  toggle(): void {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

}
