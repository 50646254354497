// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';
import { PayersModule } from '@eros-front/payers';

// Services
import { CustomerService,
  NotifService,
  GroupService,
  SwalService,
  BrandService,
  AgencyService,
  ContactService,
  ThingService,
  AffairService,
  ProductStatusService,
  DatatableService,
  ToggleService,
  ProfessionService,
  SubmitButtonService,
  SelectService,
  FormFormatterService } from '@eros-front/services';
import { MessageService } from '@pages/components/message/message.service';
import { ApiService } from '@libs/api/src';

// Modules
import { MessageModule } from '@pages/components/message/message.module';

// Components
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LocalStorageService } from 'ngx-webstorage';
import { pgSliderModule } from '@pages/components/slider/slider.module';
import { CustomersSearchComponent } from './customers-search/customers-search.component';

import { CustomersConsultGraphInternalNotesModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-internal-notes-module/customers-consult-graph-internal-notes-module.component';
import { CustomersConsultGraphManagersModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-managers-module/customers-consult-graph-managers-module.component';
import { CustomersConsultGraphModalGroupComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-group/customers-consult-graph-modal-group.component';
import { CustomersConsultGraphModalBrandComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-brand/customers-consult-graph-modal-brand.component';
import { CustomersConsultGraphModalSyncIndicatorsTypesComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-indicators-types/customers-consult-graph-modal-sync-indicators-types.component';
import { CustomersConsultGraphModalSyncManagersComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-managers/customers-consult-graph-modal-sync-managers.component';
import { CustomersConsultGraphModalSyncPricesComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-prices/customers-consult-graph-modal-sync-prices.component';
import { CustomersConsultGraphModalSyncRivalsComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-rivals/customers-consult-graph-modal-sync-rivals.component';
import { CustomersConsultGraphModalSyncInternalNotesComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-internal-notes/customers-consult-graph-modal-sync-internal-notes.component';
import { CustomersConsultGraphPricesModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-prices-module/customers-consult-graph-prices-module.component';
import { CustomersConsultGraphRivalsModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-rivals-module/customers-consult-graph-rivals-module.component';
import { CustomersConsultGraphSettingsComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-settings/customers-consult-graph-settings.component';
import { CustomersConsultGraphTypesIndicatorsModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-types-indicators-module/customers-consult-graph-types-indicators-module.component';
import { CustomersConsultGraphModulePriceInputComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-prices-module/customers-consult-graph-prices-module/customers-consult-graph-module-price-input.component';
import { CustomersConsultGraphModalAgencyComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-agency/customers-consult-graph-modal-agency.component';
import { CustomersShowComponent } from './customers-show/customers-show.component';
import { CustomersShowInformationsComponent } from './customers-show/customers-show-informations/customers-show-informations.component';
import { CustomersShowPricesComponent } from './customers-show/customers-show-prices/customers-show-prices.component';
import { CustomersShowFinancialHealthComponent } from './customers-show/customers-show-financial-health/customers-show-financial-health.component';
import { CustomersShowSynchronizationComponent } from './customers-show/customers-show-synchronization/customers-show-synchronization.component';
import { CustomersShowModalSyncPricesComponent } from './customers-show/customers-show-synchronization/customers-show-modal-sync-prices/customers-show-modal-sync-prices.component';
import { CustomersShowModalSyncInternalNoteComponent } from './customers-show/customers-show-synchronization/customers-show-modal-sync-internal-note/customers-show-modal-sync-internal-note.component';
import { CustomersShowModalSyncIndicatorsTypesComponent } from './customers-show/customers-show-synchronization/customers-show-modal-sync-indicators-types/customers-show-modal-sync-indicators-types.component';
import { CustomersShowInvoicingComponent } from './customers-show/customers-show-invoicing/customers-show-invoicing.component';
import { CustomersShowOfficesFilesComponent } from './customers-show/customers-show-offices-files/customers-show-offices-files.component';
import { CustomersShowOfficesFilesModalComponent } from './customers-show/customers-show-offices-files/customers-show-offices-files-modal/customers-show-offices-files-modal.component';
import { CustomersShowOfficesFilesTemplateComponent } from './customers-show/customers-show-offices-files/customers-show-offices-files-template/customers-show-offices-files-template.component';
import { CustomersShowContactsComponent } from './customers-show/customers-show-contacts/customers-show-contacts.component';
import { CustomersShowContactsModalComponent } from './customers-show/customers-show-contacts/customers-show-contacts-modal/customers-show-contacts-modal.component';
import { CustomersShowGraphComponent } from './customers-show/customers-show-graph/customers-show-graph.component';
import { CustomersCreateComponent } from './customers-create/customers-create.component';
import { CustomersCreateBrandModalComponent } from './customers-modals/customers-create-brand-modal/customers-create-brand-modal.component';
import { CustomersCreateAgencyModalComponent } from './customers-modals/customers-create-agency-modal/customers-create-agency-modal.component';
import { CustomerFormInformationsComponent } from './customer-form/customer-form-informations/customer-form-informations.component';
import { CustomersShowHeadbandComponent } from './customers-show/customers-show-headband/customers-show-headband.component';
import { CustomersShowImportLogoComponent } from './customers-show/customers-show-import-logo/customers-show-import-logo.component';
import { CustomersShowTechnicalComponent } from './customers-show/customers-show-technical/customers-show-technical.component';
import { CustomerInvoicingPreferenceModalComponent } from './customers-show/customers-show-invoicing/customer-invoicing-preference-modal/customer-invoicing-preference-modal.component';
import { CustomerInvoicingTemplateComponent } from './customers-show/customers-show-invoicing/customer-invoicing-template/customer-invoicing-template.component';
import { CustomerPayersListComponent } from './customers-show/customers-show-invoicing/customer-payers-list/customer-payers-list.component';
import { ApplicationDomainService } from '../_services/application-domain.service';
import { CustomersShowDomainsApplicationsComponent } from './customers-show/customers-show-domains-applications/customers-show-domains-applications.component';
import { ApplicationDomainTemplateComponent } from './customers-show/customers-show-domains-applications/application-domain-template/application-domain-template.component';
import { CustomersShowClientsFilesComponent } from './customers-show/customers-show-clients-files/customers-show-clients-files.component';
import { ClientFileImportModalComponent } from './customers-show/customers-show-clients-files/client-file-import-modal/client-file-import-modal.component';
import { CustomersShowAreasComponent } from './customers-show/customers-show-areas/customers-show-areas.component';
import { AgencyAreaModalComponent } from './customers-show/customers-show-areas/agency-area-modal/agency-area-modal.component';

@NgModule({
  imports: [
    SharedModule,
    MessageModule,
    PayersModule,
    ScrollingModule,
    pgSliderModule,
  ],
  declarations: [
    CustomersSearchComponent,

    CustomersConsultGraphInternalNotesModuleComponent,
    CustomersConsultGraphManagersModuleComponent,
    CustomersConsultGraphModalGroupComponent,
    CustomersConsultGraphModalBrandComponent,
    CustomersConsultGraphModalAgencyComponent,
    CustomersConsultGraphModalSyncIndicatorsTypesComponent,
    CustomersConsultGraphModalSyncManagersComponent,
    CustomersConsultGraphModalSyncPricesComponent,
    CustomersConsultGraphModalSyncRivalsComponent,
    CustomersConsultGraphModalSyncInternalNotesComponent,
    CustomersConsultGraphPricesModuleComponent,
    CustomersConsultGraphRivalsModuleComponent,
    CustomersConsultGraphSettingsComponent,
    CustomersConsultGraphTypesIndicatorsModuleComponent,
    CustomersConsultGraphModulePriceInputComponent,
    CustomersShowComponent,
    CustomersShowInformationsComponent,
    CustomersShowPricesComponent,
    CustomersShowFinancialHealthComponent,
    CustomersShowSynchronizationComponent,
    CustomersShowModalSyncPricesComponent,
    CustomersShowModalSyncInternalNoteComponent,
    CustomersShowModalSyncIndicatorsTypesComponent,
    CustomersShowInvoicingComponent,
    CustomersShowOfficesFilesComponent,
    CustomersShowOfficesFilesModalComponent,
    CustomersShowOfficesFilesTemplateComponent,
    CustomersShowContactsComponent,
    CustomersShowContactsModalComponent,
    CustomersShowHeadbandComponent,
    CustomersShowGraphComponent,
    CustomersCreateComponent,
    CustomersCreateBrandModalComponent,
    CustomersCreateAgencyModalComponent,
    CustomerFormInformationsComponent,
    CustomersShowImportLogoComponent,
    CustomersShowTechnicalComponent,
    CustomerInvoicingPreferenceModalComponent,
    CustomerInvoicingTemplateComponent,
    CustomerPayersListComponent,
    CustomersShowDomainsApplicationsComponent,
    ApplicationDomainTemplateComponent,
    CustomersShowClientsFilesComponent,
    ClientFileImportModalComponent,
    CustomersShowAreasComponent,
    AgencyAreaModalComponent,
  ],
  providers: [
    SubmitButtonService,
    CustomerService,
    ApiService,
    NotifService,
    MessageService,
    GroupService,
    BrandService,
    SwalService,
    SelectService,
    FormFormatterService,
    AgencyService,
    ContactService,
    ThingService,
    AffairService,
    ProductStatusService,
    DatatableService,
    ToggleService,
    ProfessionService,
    LocalStorageService,
    ApplicationDomainService,
  ],
})
export class CustomersModule { }
