// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';

// Modules
import { ColorPickerModule } from 'ngx-color-picker';

// Components
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { SettingsInterventionsConformitiesModalComponent } from './settings-interventions/settings-interventions-conformities/settings-interventions-conformities-modal/settings-interventions-conformities-modal.component';
import { SettingsInterventionsConformitiesIndexComponent } from './settings-interventions/settings-interventions-conformities/settings-interventions-conformities-index/settings-interventions-conformities-index.component';
import { SettingsInterventionsInterventionsStatusesModalComponent } from './settings-interventions/settings-interventions-interventions-statuses/settings-interventions-interventions-statuses-modal/settings-interventions-interventions-statuses-modal.component';
import { SettingsInterventionsSettingsIndexComponent } from './settings-interventions/settings-interventions-settings/settings-interventions-settings-index/settings-interventions-settings-index.component';
import { SettingsInterventionsSettingsModalComponent } from './settings-interventions/settings-interventions-settings/settings-interventions-settings-modal/settings-interventions-settings-modal.component';
import { SettingsInterventionsInterventionsStatusesIndexComponent } from './settings-interventions/settings-interventions-interventions-statuses/settings-interventions-interventions-statuses-index/settings-interventions-interventions-statuses-index.component';
import { SettingsInvoicingSettingsModalComponent } from './settings-invoicing/settings-invoicing-settings/settings-invoicing-settings-modal/settings-invoicing-settings-modal.component';
import { SettingsInvoicingSettingsIndexComponent } from './settings-invoicing/settings-invoicing-settings/settings-invoicing-settings-index/settings-invoicing-settings-index.component';
import { SettingsInvoicingUnpaidStatusesModalComponent } from './settings-invoicing/settings-invoicing-unpaid-statuses/settings-invoicing-unpaid-statuses-modal/settings-invoicing-unpaid-statuses-modal.component';
import { SettingsInvoicingUnpaidStatusesIndexComponent } from './settings-invoicing/settings-invoicing-unpaid-statuses/settings-invoicing-unpaid-statuses-index/settings-invoicing-unpaid-statuses-index.component';
import { SettingsInvoicingRegionsModalComponent } from './settings-invoicing/settings-invoicing-regions/settings-invoicing-regions-modal/settings-invoicing-regions-modal.component';
import { SettingsInvoicingRegionsIndexComponent } from './settings-invoicing/settings-invoicing-regions/settings-invoicing-regions-index/settings-invoicing-regions-index.component';
import { SettingsInvoicingInvoicesStatusesIndexComponent } from './settings-invoicing/settings-invoicing-invoices-statuses/settings-invoicing-invoices-statuses-index/settings-invoicing-invoices-statuses-index.component';
import { SettingsInvoicingMlAgenciesModalComponent } from './settings-invoicing/settings-invoicing-ml-agencies/settings-invoicing-ml-agencies-modal/settings-invoicing-ml-agencies-modal.component';
import { SettingsInvoicingMlAgenciesIndexComponent } from './settings-invoicing/settings-invoicing-ml-agencies/settings-invoicing-ml-agencies-index/settings-invoicing-ml-agencies-index.component';
import { SettingsProductsSettingsModalComponent } from './settings-products/settings-products-settings/settings-products-settings-modal/settings-products-settings-modal.component';
import { SettingsProductsSettingsIndexComponent } from './settings-products/settings-products-settings/settings-products-settings-index/settings-products-settings-index.component';
import { SettingsProductsPerformancesModalComponent } from './settings-products/settings-products-performances/settings-products-performances-modal/settings-products-performances-modal.component';
import { SettingsProductsPerformancesIndexComponent } from './settings-products/settings-products-performances/settings-products-performances-index/settings-products-performances-index.component';
import { SettingsProductsDocumentsDeliveredIndexComponent } from './settings-products/settings-products-documents-delivered/settings-products-documents-delivered-index/settings-products-documents-delivered-index.component';
import { SettingsProductsDocumentsCustomersModalComponent } from './settings-products/settings-products-documents-customers/settings-products-documents-customers-modal/settings-products-documents-customers-modal.component';
import { SettingsProductsDocumentsCustomersIndexComponent } from './settings-products/settings-products-documents-customers/settings-products-documents-customers-index/settings-products-documents-customers-index.component';
import { SettingsProductsProductsStatusesModalComponent } from './settings-products/settings-products-products-statuses/settings-products-products-statuses-modal/settings-products-products-statuses-modal.component';
import { SettingsProductsProductsStatusesIndexComponent } from './settings-products/settings-products-products-statuses/settings-products-products-statuses-index/settings-products-products-statuses-index.component';
import { SettingsProductsFieldsIndexComponent } from './settings-products/settings-products-fields/settings-products-fields-index/settings-products-fields-index.component';
import { SettingsProductsProductsModalComponent } from './settings-products/settings-products-products/settings-products-products-modal/settings-products-products-modal.component';
import { SettingsProductsProductsIndexComponent } from './settings-products/settings-products-products/settings-products-products-index/settings-products-products-index.component';
import { SettingsProductsComponent } from './settings-products/settings-products.component';
import { SettingsProductsFieldsModalComponent } from './settings-products/settings-products-fields/settings-products-fields-modal/settings-products-fields-modal.component';
import { SettingsProductsDocumentsDeliveredModalComponent } from './settings-products/settings-products-documents-delivered/settings-products-documents-delivered-modal/settings-products-documents-delivered-modal.component';
import { SettingsInvoicingComponent } from './settings-invoicing/settings-invoicing.component';
import { SettingsInvoicingInvoicesStatusesModalComponent } from './settings-invoicing/settings-invoicing-invoices-statuses/settings-invoicing-invoices-statuses-modal/settings-invoicing-invoices-statuses-modal.component';
import { SettingsInterventionsComponent } from './settings-interventions/settings-interventions.component';
import { SettingsInterventionsIndicatorsTypesIndexComponent } from './settings-interventions/settings-interventions-indicators-types/settings-interventions-indicators-types-index/settings-interventions-indicators-types-index.component';
import { SettingsInterventionsIndicatorsTypesModalComponent } from './settings-interventions/settings-interventions-indicators-types/settings-interventions-indicators-types-modal/settings-interventions-indicators-types-modal.component';
import { SettingsProductsProductsModalInformationsComponent } from './settings-products/settings-products-products/settings-products-products-modal/settings-products-products-modal-informations/settings-products-products-modal-informations.component';
import { SettingsProductsProductsModalDocumentsCustomersComponent } from './settings-products/settings-products-products/settings-products-products-modal/settings-products-products-modal-documents-customers/settings-products-products-modal-documents-customers.component';
import { SettingsProductsProductsModalDocumentsDeliveredComponent } from './settings-products/settings-products-products/settings-products-products-modal/settings-products-products-modal-documents-delivered/settings-products-products-modal-documents-delivered.component';
import { SettingsProductsProductsModalFieldsComponent } from './settings-products/settings-products-products/settings-products-products-modal/settings-products-products-modal-fields/settings-products-products-modal-fields.component';
import { SettingsCustomerRelationshipComponent } from './settings-customer-relationship/settings-customer-relationship.component';
import { StandardsResponsesIndexComponent } from './settings-customer-relationship/standards-responses/standards-responses-index/standards-responses-index.component';
import { StandardsResponseModalComponent } from './settings-customer-relationship/standards-responses/standards-response-modal/standards-response-modal.component';
import { SendSettingsIndexComponent } from './settings-customer-relationship/send-settings/send-settings-index/send-settings-index.component';
import { SendSettingsModalComponent } from './settings-customer-relationship/send-settings/send-settings-modal/send-settings-modal.component';
import { SettingsAffairsComponent } from './settings-affairs/settings-affairs.component';
import { AffairsMonitoringTypesIndexComponent } from './settings-affairs/affairs-monitoring-types/affairs-monitoring-types-index/affairs-monitoring-types-index.component';
import { AffairsMonitoringTypesModalComponent } from './settings-affairs/affairs-monitoring-types/affairs-monitoring-types-modal/affairs-monitoring-types-modal.component';
import { AffairsGoalsIndexComponent } from './settings-affairs/affairs-goals/affairs-goals-index/affairs-goals-index.component';
import { AffairsGoalsModalComponent } from './settings-affairs/affairs-goals/affairs-goals-modal/affairs-goals-modal.component';
import { StudiesOfficesIndexComponent } from './settings-affairs/studies-offices/studies-offices-index/studies-offices-index.component';
import { StudiesOfficesModalComponent } from './settings-affairs/studies-offices/studies-offices-modal/studies-offices-modal.component';
import { SendSettingsTypesIndexComponent } from './settings-customer-relationship/send-settings-types/send-settings-types-index/send-settings-types-index.component';
import { SendSettingsTypesModalComponent } from './settings-customer-relationship/send-settings-types/send-settings-types-modal/send-settings-types-modal.component';
import { OfficesFilesIndicatorsIndexComponent } from './settings-customer-relationship/offices-files-indicators/offices-files-indicators-index/offices-files-indicators-index.component';
import { OfficesFilesIndicatorsModalComponent } from './settings-customer-relationship/offices-files-indicators/offices-files-indicators-modal/offices-files-indicators-modal.component';
import { CustomerRelationshipSettingsIndexComponent } from './settings-customer-relationship/customer-relationship-settings/customer-relationship-settings-index/customer-relationship-settings-index.component';
import { CustomerRelationshipSettingsModalComponent } from './settings-customer-relationship/customer-relationship-settings/customer-relationship-settings-modal/customer-relationship-settings-modal.component';
import { SettingsProductsProductsModalConformitiesComponent } from './settings-products/settings-products-products/settings-products-products-modal/settings-products-products-modal-conformities/settings-products-products-modal-conformities.component';
import { AffairsStatusesIndexComponent } from './settings-affairs/affairs-statuses/affairs-statuses-index/affairs-statuses-index.component';
import { AffairsStatusesModalComponent } from './settings-affairs/affairs-statuses/affairs-statuses-modal/affairs-statuses-modal.component';
import { AffairsStatusesSettingsModalComponent } from './settings-affairs/affairs-statuses/affairs-statuses-settings-modal/affairs-statuses-settings-modal.component';
import { MonitoringTypesIndexComponent } from './settings-products/monitoring-types/monitoring-types-index/monitoring-types-index.component';
import { MonitoringTypesModalComponent } from './settings-products/monitoring-types/monitoring-types-modal/monitoring-types-modal.component';
import { QuotationsStatusesIndexComponent } from './settings-invoicing/quotation-statuses/quotations-statuses-index/quotations-statuses-index.component';
import { QuotationsStatusesModalComponent } from './settings-invoicing/quotation-statuses/quotations-statuses-modal/quotations-statuses-modal.component';
import { TextTagsIndexComponent } from './settings-customer-relationship/text-tags/text-tags-index/text-tags-index.component';
import { TextTagsModalComponent } from './settings-customer-relationship/text-tags/text-tags-modal/text-tags-modal.component';
import { ProductsUrgenciesLevelsModalComponent } from './settings-products/products-urgencies-levels/products-urgencies-levels-modal/products-urgencies-levels-modal.component';
import { ProductsUrgenciesLevelsIndexComponent } from './settings-products/products-urgencies-levels/products-urgencies-levels-index/products-urgencies-levels-index.component';
import { ShiftOriginsIndexComponent } from './settings-interventions/shift-origins/shift-origins-index/shift-origins-index.component';
import { ShiftOriginsModalComponent } from './settings-interventions/shift-origins/shift-origins-modal/shift-origins-modal.component';
import { EventsTypesIndexComponent } from './settings-interventions/events-types/events-types-index/events-types-index.component';
import { EventsTypesModalComponent } from './settings-interventions/events-types/events-types-modal/events-types-modal.component';
import { SettingsAccountingComponent } from './settings-accounting/settings-accounting.component';
import { ExpensesAccountsStatusesIndexComponent } from './settings-accounting/expenses-accounts-statuses/expenses-accounts-statuses-index/expenses-accounts-statuses-index.component';
import { ExpensesAccountsStatusesModalComponent } from './settings-accounting/expenses-accounts-statuses/expenses-accounts-statuses-modal/expenses-accounts-statuses-modal.component';
import { ExpensesTypesModalComponent } from './settings-accounting/expenses-types/expenses-types-modal/expenses-types-modal.component';
import { ExpensesWordingIndexComponent } from './settings-accounting/expenses-wording/expenses-wording-index/expenses-wording-index.component';
import { ExpensesWordingModalComponent } from './settings-accounting/expenses-wording/expenses-wording-modal/expenses-wording-modal.component';
import { ExpensesTypesIndexComponent } from './settings-accounting/expenses-types/expenses-types-index/expenses-types-index.component';
import { SettingsAccountingSettingsIndexComponent } from './settings-accounting/settings-accounting-settings/settings-accounting-settings-index/settings-accounting-settings-index.component';
import { SettingsAccountingSettingsModalComponent } from './settings-accounting/settings-accounting-settings/settings-accounting-settings-modal/settings-accounting-settings-modal.component';
import { SettingsConnectionsComponent } from './settings-connections/settings-connections.component';
import { StardarResponseOrderModalComponent } from './settings-customer-relationship/standards-responses/stardards-responses-order-modal/stardards-responses-order-modal.component';
import { DocumentsCustomersCategoriesIndexComponent } from './settings-products/documents-customers-categories/documents-customers-categories-index/documents-customers-categories-index.component';
import { DocumentsCustomersCategoriesModalComponent } from './settings-products/documents-customers-categories/documents-customers-categories-modal/documents-customers-categories-modal.component';
import { SettingsProductsPerformancesOrderModalComponent } from './settings-products/settings-products-performances/settings-products-performances-order-modal/settings-products-performances-order-modal.component';
import { ProductsOrderModalComponent } from './settings-products/settings-products-products/products-order-modal/products-order-modal.component';
import { DashboardCategoriesListComponent } from './settings-dashboard/dashboard-categories/dashboard-categories-list/dashboard-categories-list.component';
import { DashboardCategoryModalComponent } from './settings-dashboard/dashboard-categories/dashboard-category-modal/dashboard-category-modal.component';
import { SettingsDashboardComponent } from './settings-dashboard/settings-dashboard/settings-dashboard.component';
import { DashboardTilesListComponent } from './settings-dashboard/dashboard-tiles/dashboard-tiles-list/dashboard-tiles-list.component';
import { DashboardTileModalComponent } from './settings-dashboard/dashboard-tiles/dashboard-tile-modal/dashboard-tile-modal.component';
import { DashboardCategoryDashboardTilesOrderModalComponent } from './settings-dashboard/dashboard-categories/dashboard-category-dashboard-tiles-order-modal/dashboard-category-dashboard-tiles-order-modal.component';
import { ProductsOffersIndexComponent } from './settings-products/products-offers/products-offers-index/products-offers-index.component';
import { ProductsOffersModalComponent } from './settings-products/products-offers/products-offers-modal/products-offers-modal.component';
import { TagsIndexComponent } from './settings-affairs/tags/tags-index/tags-index.component';
import { TagModalComponent } from './settings-affairs/tags/tag-modal/tag-modal.component';

// Configs
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

@NgModule({
  imports: [
    SharedModule,
    ColorPickerModule,
    DragDropModule,
  ],
  declarations: [
    SettingsProductsComponent,
    SettingsInvoicingComponent,
    SettingsInterventionsComponent,
    // Settings products
    SettingsProductsProductsIndexComponent,
    SettingsProductsProductsModalComponent,
    SettingsProductsFieldsIndexComponent,
    SettingsProductsFieldsModalComponent,
    SettingsProductsProductsStatusesIndexComponent,
    SettingsProductsProductsStatusesModalComponent,
    SettingsProductsDocumentsCustomersIndexComponent,
    SettingsProductsDocumentsCustomersModalComponent,
    SettingsProductsDocumentsDeliveredIndexComponent,
    SettingsProductsDocumentsDeliveredModalComponent,
    SettingsProductsPerformancesIndexComponent,
    SettingsProductsPerformancesModalComponent,
    SettingsProductsSettingsIndexComponent,
    SettingsProductsSettingsModalComponent,
    SettingsProductsProductsModalInformationsComponent,
    SettingsProductsProductsModalDocumentsCustomersComponent,
    SettingsProductsProductsModalDocumentsDeliveredComponent,
    SettingsProductsProductsModalFieldsComponent,
    SettingsProductsProductsModalConformitiesComponent,
    MonitoringTypesIndexComponent,
    MonitoringTypesModalComponent,
    ProductsUrgenciesLevelsModalComponent,
    ProductsUrgenciesLevelsIndexComponent,
    DocumentsCustomersCategoriesIndexComponent,
    DocumentsCustomersCategoriesModalComponent,
    SettingsProductsPerformancesOrderModalComponent,
    ProductsOrderModalComponent,
    ProductsOffersIndexComponent,
    ProductsOffersModalComponent,
    // Settings invoicings
    SettingsInvoicingMlAgenciesIndexComponent,
    SettingsInvoicingMlAgenciesModalComponent,
    SettingsInvoicingRegionsIndexComponent,
    SettingsInvoicingRegionsModalComponent,
    SettingsInvoicingInvoicesStatusesIndexComponent,
    SettingsInvoicingInvoicesStatusesModalComponent,
    SettingsInvoicingUnpaidStatusesIndexComponent,
    SettingsInvoicingUnpaidStatusesModalComponent,
    SettingsInvoicingSettingsIndexComponent,
    SettingsInvoicingSettingsModalComponent,
    QuotationsStatusesIndexComponent,
    QuotationsStatusesModalComponent,
    // Settings interventions
    SettingsInterventionsInterventionsStatusesIndexComponent,
    SettingsInterventionsInterventionsStatusesModalComponent,
    SettingsInterventionsConformitiesIndexComponent,
    SettingsInterventionsConformitiesModalComponent,
    SettingsInterventionsIndicatorsTypesIndexComponent,
    SettingsInterventionsIndicatorsTypesModalComponent,
    SettingsInterventionsSettingsIndexComponent,
    SettingsInterventionsSettingsModalComponent,
    ShiftOriginsIndexComponent,
    ShiftOriginsModalComponent,
    EventsTypesIndexComponent,
    EventsTypesModalComponent,
    // Settings shuttle sheets
    // Settings customer relationship
    SettingsCustomerRelationshipComponent,
    StandardsResponsesIndexComponent,
    StandardsResponseModalComponent,
    SendSettingsIndexComponent,
    SendSettingsModalComponent,
    SendSettingsTypesIndexComponent,
    SendSettingsTypesModalComponent,
    OfficesFilesIndicatorsIndexComponent,
    OfficesFilesIndicatorsModalComponent,
    CustomerRelationshipSettingsIndexComponent,
    CustomerRelationshipSettingsModalComponent,
    TextTagsIndexComponent,
    TextTagsModalComponent,
    // Settings affairs
    SettingsAffairsComponent,
    AffairsMonitoringTypesIndexComponent,
    AffairsMonitoringTypesModalComponent,
    AffairsGoalsIndexComponent,
    AffairsGoalsModalComponent,
    StudiesOfficesIndexComponent,
    StudiesOfficesModalComponent,
    AffairsStatusesIndexComponent,
    AffairsStatusesModalComponent,
    AffairsStatusesSettingsModalComponent,
    TagsIndexComponent,
    TagModalComponent,
    // Settings accounting
    SettingsAccountingComponent,
    ExpensesAccountsStatusesIndexComponent,
    ExpensesAccountsStatusesModalComponent,
    ExpensesTypesIndexComponent,
    ExpensesTypesModalComponent,
    ExpensesWordingIndexComponent,
    ExpensesWordingModalComponent,
    SettingsAccountingSettingsIndexComponent,
    SettingsAccountingSettingsModalComponent,
    // Settings connexions
    SettingsConnectionsComponent,
    StardarResponseOrderModalComponent,
    // Settings dashboard
    SettingsDashboardComponent,
    DashboardCategoriesListComponent,
    DashboardCategoryModalComponent,
    DashboardTilesListComponent,
    DashboardTileModalComponent,
    DashboardCategoryDashboardTilesOrderModalComponent,
  ],
  providers: [
    {
      provide: CDK_DRAG_CONFIG,
      useValue: DragConfig,
    },
  ],
})
export class SettingsModule { }
