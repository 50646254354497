import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { NotifService, SubmitButtonService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BarTypeService } from '../../services/bar-type.service';
import { BarType } from '../../types/bar-type.type';

@Component({
  selector: 'app-bar-type-modal',
  templateUrl: './bar-type-modal.component.html',
  styleUrls: ['./bar-type-modal.component.scss'],
})
export class BarTypeModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() barType: BarType = null;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private barTypeService: BarTypeService,
    private modal: NzModalRef,
    private notifService: NotifService,
    private submitButtonService: SubmitButtonService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.barTypeService.getForm(this.barType);
    this.determinateSubmitButton();
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  public onSubmit(): void {
    if (this.isValidForm()) {
      if (this.barType) {
        this.submitButtonService.setDisabled(this.submitButton);
        this.barTypeService.update(this.barType.name, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe({
            next: () => {
              this.notifService.showSuccessNotif('Fiche BAR mise à jour avec succèss');
              this.modal.close();
            },
          });
      } else {
        this.barTypeService.create(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe({
            next: () => {
              this.notifService.showSuccessNotif('Fiche BAR ajoutée avec succèss');
              this.modal.close();
            },
          });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'ma fiche BAR',
      text: 'Enregistrer',
      icon: faSave,
    });
  }

}
