import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DateService {

  /**
   * Constructor of DateService
   */
  constructor() { }

  /**
   * Return the date in parameter in the format 'dd/MM/yyyy'
   * @param dateToFormat
   */
  public getDate(dateToFormat: Date): string {
    return formatDate(dateToFormat, 'dd/MM/yyyy', 'fr-FR');
  }

  /**
   * Return the date in parameter in the format 'yyyy-MM-dd'
   * @param dateToFormat
   */
  public getDateForKizeo(dateToFormat: Date): string {
    return formatDate(dateToFormat, 'yyyy-MM-dd', 'fr-FR');
  }

  /**
   * Return the date in parameter in the format dd/MM/yyyy HH:mm
   * @param dateToFormat
   */
  public getDateAndHour(dateToFormat: Date): string {
    return formatDate(dateToFormat, 'dd/MM/yyyy HH:mm', 'fr-FR');
  }

}
