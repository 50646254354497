import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlideInOutAnimation } from '@animations/slide-in-out';
import { Affair, AffairMonitoring, DashboardTile } from '@libs/models/src';
import { AffairMonitoringService, NotifService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-overview-affair-monitoring-list',
  templateUrl: './overview-affair-monitoring-list.component.html',
  styleUrls: ['./overview-affair-monitoring-list.component.scss'],
  animations: [SlideInOutAnimation],
})
export class OverviewAffairMonitoringListComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() affair: Affair;
  @Input() dashboardTile: DashboardTile;
  @Input() loadingName: string;
  @Output() actionDone = new EventEmitter<any>();

  public isDivVisible = true;
  public isHoverId = null;

  constructor(
    private affairMonitoringService: AffairMonitoringService,
    private swalService: SwalService,
    private spinnerService: NgxSpinnerService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onMouseEnter(item: any): void {
    this.isHoverId = item.id;
  }

  onMouseLeave(): void {
    this.isHoverId = null;
  }

  public toggleDiv(): void {
    this.isDivVisible = !this.isDivVisible;
  }

  public markAsClosed(affairMonitoring: AffairMonitoring): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'Le suivi affaire sera en clôturé.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.spinnerService.show(this.loadingName);
        this.affairMonitoringService.markAsClosed(affairMonitoring.id)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
              this.spinnerService.hide(this.loadingName);
            }),
          )
          .subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.actionDone.emit();
          });
      }
    });

  }

}
