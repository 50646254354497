import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToggleService, UnsubscribeOnDestroy } from '@libs/services/src';
import { StatisticInterventionService } from '@libs/services/src/lib/statistic-intervention.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-statistic-settings',
  templateUrl: './statistic-settings.component.html',
  styleUrls: ['./statistic-settings.component.scss'],
})
export class StatisticSettingsComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  public isOpen = false;

  constructor(
    private toggleService: ToggleService,
    private statisticService: StatisticInterventionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.toggleService.quickViewToggle
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((data) => {
        this.toggle();
      });
  }

  toggle(): void {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  clearForm(): void {
    // this.form.reset();
    const resetForm = this.statisticService.initForm();
    this.form.reset(resetForm.value);
  }

}
