import { Component, Input, OnInit } from '@angular/core';
import { DashboardTile } from '@libs/models/src';

@Component({
  selector: 'app-overview-item-card',
  templateUrl: './overview-item-card.component.html',
  styleUrls: ['./overview-item-card.component.scss'],
})
export class OverviewItemCardComponent implements OnInit {

  @Input() item: any;
  @Input() dashboardTile: DashboardTile;

  constructor() { }

  ngOnInit(): void {
  }

}
