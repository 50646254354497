export enum ReasonType {
  FirstCall = 'appel 1',
  SecondCall = 'appel 2',
  ThirdCall = 'appel 3',
  AppointmentContact = 'rdv contact',
  NumKO = 'num ko',
  Uncontactable = 'injoignable',
  ConfirmationWaiting = 'attente conf',
  AppointmentConfirmed = 'rdv confirme',
  AppointmentRefused = 'rdv refuse',
}

export interface CallReason {
  label: string;
  value: ReasonType;
}

export const CallReasons: CallReason[] = [
  {
    label: 'Appel 1',
    value: ReasonType.FirstCall,
  },
  {
    label: 'Appel 2',
    value: ReasonType.SecondCall,
  },
  {
    label: 'Appel 3',
    value: ReasonType.ThirdCall,
  },
  {
    label: 'RDV Contact',
    value: ReasonType.AppointmentContact,
  },
  {
    label: 'N° KO',
    value: ReasonType.NumKO,
  },
  {
    label: 'Injoignable',
    value: ReasonType.Uncontactable,
  },
  {
    label: 'Attente conf.',
    value: ReasonType.ConfirmationWaiting,
  },
  {
    label: 'RDV confirmé',
    value: ReasonType.AppointmentConfirmed,
  },
  {
    label: 'RDV refusé',
    value: ReasonType.AppointmentRefused,
  },
];
