import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Region, SelectModel } from '@libs/models/src';
import { RegionService } from '@libs/services/src';
import { CHARTS_NAMES } from 'apps/eros-nrj/src/config/charts-names';
import { endOfMonth, addMonths } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistic-filters',
  templateUrl: './statistic-filters.component.html',
  styleUrls: ['./statistic-filters.component.scss'],
})
export class StatisticFiltersComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() technicians: SelectModel[];
  @Input() regions: Region[];
  @Input() formattedRegions: SelectModel[];
  @Input() mlAgencies: SelectModel[];
  @Input() products: SelectModel[];
  @Input() marketsTypes: SelectModel[];
  @Input() selectedChart: string;
  public defaultRange: any;
  public chartsNames = CHARTS_NAMES;
  public agencyMode = false;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public regionsSelectTechnicians$: Subscription;
  public dateRangeMode = 'month';

  constructor(
    private regionService: RegionService,
  ) {
    this.regionsSelectTechnicians$ = this.regionService.regionsSelectTechnicians$.subscribe(
      (data) => {
        if (data) {
          this.form.patchValue({ techniciansIds: data });
        }
      },
    );
  }

  ngOnInit(): void {
    this.defaultRange = { 'Ce mois-ci': [new Date(), endOfMonth(new Date())], 'Le mois suivant': [addMonths(new Date(), 1), addMonths(new Date(), 1)] };

    if (this.form) {
      this.form.get('objectsIds').valueChanges.subscribe((val) => {
        const selectedRegionsIds = val.map((x: any) => { return x.value; });
        const regionsItems = this.regions.filter((x) => { return selectedRegionsIds.includes(x.id); });
        const mlAgenciesIds = [];
        regionsItems.forEach((region) => {
          return region.mlAgencies.forEach((mlAgency) => {
            mlAgenciesIds.push({ label: mlAgency.name, value: mlAgency.id });
          });
        });
        this.form.patchValue({ mlAgenciesIds: mlAgenciesIds });
        this.regionService.getForSelectTechnicians(selectedRegionsIds);
      });

      this.form.get('dateDisplayMode').valueChanges.subscribe((val) => {
        this.dateRangeMode = this.getDisplayDateMode(val);
      });

      this.dateRangeMode = this.getDisplayDateMode(this.form.value.dateDisplayMode);
    }

  }

  activateAgencyMode($event: any): void {
    this.agencyMode = !this.agencyMode;
    this.form.patchValue({ isRegion: !this.agencyMode });
  }

  getDisplayDateMode(dateDisplayMode: number): string {
    switch (dateDisplayMode) {
      case 0: return 'day';
      case 3: return 'year';
      default: return 'month';
    }
  }

}
