import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {

  constructor(
     private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(`Tableau de bord${tabSuffix}`);

  }

}
