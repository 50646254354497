import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardTileKeyEnum } from '@libs/enum/src';
import { Affair, DashboardTile } from '@libs/models/src';
import { NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-overview-affair-documents-customers-list',
  templateUrl: './overview-affair-documents-customers-list.component.html',
  styleUrls: ['./overview-affair-documents-customers-list.component.scss'],
})
export class OverviewAffairDocumentsCustomersListComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() affair: Affair;
  @Input() loadingName: string;
  @Input() dashboardTile: DashboardTile;
  public dashboardTileEnum = DashboardTileKeyEnum;
  files: File[] = [];
  public affairDocumentParam: any = [];
  public readonly XML_RSEE_ID = 49;
  public readonly PURCHASE_ORDER_SIGNED_ID = 19;
  @Output() actionDone = new EventEmitter<any>();

  constructor(
    private spinnerService: NgxSpinnerService,
    private affairDocumentCustomerService: AffairDocumentCustomerService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onUploadDocument(event, documentId: number): void {
    this.spinnerService.show(this.loadingName);
    this.files.push(...event.addedFiles);
    this.affairDocumentParam = {
      affairId: this.affair.id,
      affairProductId: this.affair.affairProducts[0].id,
      documentId: documentId,
      incrementVersion: false,
      buildingId: null,
      file: event.addedFiles[0],
    };
    this.spinnerService.show(this.loadingName);
    this.affairDocumentCustomerService.import(this.affairDocumentParam)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.spinnerService.hide(this.loadingName);
        }),
      )
      .subscribe({
        next: (response) => {
          this.notifService.showSuccessNotif(response);
          this.files = [];
          this.actionDone.emit();
        },
        error: (error) => {
        },
      });
  }

}
