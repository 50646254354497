/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit } from '@angular/core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { NotifService, SubmitButtonService, UnsubscribeOnDestroy, YzierService } from '@libs/services/src';
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { isThisHour } from 'date-fns';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-beneficiary-edit-modal',
  templateUrl: './beneficiary-edit-modal.component.html',
  styleUrls: ['./beneficiary-edit-modal.component.scss'],
})
export class BeneficiaryEditModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() beneficiary = null;
  public kizeoId: string = '';
  public formId: string = '';
  public submitButton: SubmitButton;
  public baseUrl = environment.serviceEUrl;

  constructor(
    private modal: NzModalRef,
    private submitButtonService: SubmitButtonService,
    private yzierService: YzierService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    if (this.beneficiary) {
      this.kizeoId = this.beneficiary.data_id;
      this.formId = this.beneficiary.form_id;
    }
  }

  public onSubmit(): void {
    const payload = {
      dataId: this.kizeoId,
      formId: this.formId,
    };
    this.yzierService.updateData(this.baseUrl, this.beneficiary.beneficiary_id, payload)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (success) => {
          this.notifService.showSuccessNotif('Identifiant mis à jour avec succès');
          this.modal.close();
        },
      );

  }

  private determinateSubmitButton(): void {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'le bénéficiaire',
      text: 'Mettre à jour',
      icon: faSave,
    });

  }

}
