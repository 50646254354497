import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AffairContact, Contact } from '@libs/models/src';
import { IncomingCallSheetMailParameters, MailService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-telephony-aircall-tabs-emails',
  templateUrl: './telephony-aircall-tabs-emails.component.html',
  styleUrls: ['./telephony-aircall-tabs-emails.component.scss'],
})
export class TelephonyAircallTabsEmailsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() contact: AffairContact | Contact;
  @Output() onTotalEmails = new EventEmitter<number>();
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public total: number = 0;
  public loading: boolean;
  public loadingName = 'load-aircall-other';
  public incomingCallSheetParams: IncomingCallSheetMailParameters = {
    email: null,
    size: 10,
    search: '',
    page: 1,
  };

  public emailsData = [];
  public inputSearchValue: string = '';

  constructor(private mailService: MailService, private spinnerService: NgxSpinnerService) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.initMailsList();
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.initMailsList();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.initMailsList();
  }

  private initMailsList(): void {
    if (this.contact) {
      if (!this.contact.email) {
        this.emailsData = [];
      } else {
        this.incomingCallSheetParams = {
          email: this.contact.email,
          size: this.pageSize,
          page: this.pageIndex,
          search: this.inputSearchValue,
        };
        this.spinnerService.show(this.loadingName);
        this.loading = true;
        this.mailService.getForIncomingCallList(this.incomingCallSheetParams)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
              this.spinnerService.hide(this.loadingName);
              this.loading = false;
            }),
          ).subscribe(
            (response) => {
              this.emailsData = response.data;
              this.total = response.meta.total;
              this.onTotalEmails.emit(this.total);
            },
          );
      }
    } else {
      this.emailsData = [];
      this.total = 0;
      this.onTotalEmails.emit(this.total);
    }

  }

}
