import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { SubmitButton } from '@libs/models/src';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwalService } from './utilities/swal.service';
import { SubmitButtonService } from './utilities/submit-button.service';

export interface IncomingCallSheetMailParameters {
    email: string;
    size: number;
    page: number;
    search: string;
}

@Injectable()
export class MailService {

  public submitButton: SubmitButton;
  private route = '/mails';

  constructor(
        private apiService: ApiService,
        private submitButtonService: SubmitButtonService,
        private swalService: SwalService,
        private formBuilder: FormBuilder,
  ) { }

  public send(form: FormGroup): Observable<any> {
    this.submitButtonService.setDisabled(this.submitButton);
    return this.apiService.importFile(this.route, this.buildFormData(form))
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.submitButtonService.setEnabled(this.submitButton);
        }),
      );
  }

  public getForList(form: FormGroup): Observable<any> {

    return this.apiService.post(`${this.route}/list`, form.value)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  public getByAffair(affairId: number): Observable<any> {

    return this.apiService.get(`${this.route}/affair/${affairId}`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  public getForIncomingCallList(params: IncomingCallSheetMailParameters): Observable<any> {
    return this.apiService.post(`${this.route}/calls/list`, params);
  }

  public initForm(): FormGroup {
    return this.formBuilder.group({
      standardResponseId: [null, Validators.required],
      recipients: [null],
      recipientsCopy: [null],
      recipientsBlindCopy: [null],
      subject: [null],
      content: [null],
      attachments: [null],
    });
  }

  public initFiltersForm(): FormGroup {
    return this.formBuilder.group({
      dateMin: [null],
      dateMax: [null],
      page: 1,
      size: 10,
      search: '',
    });

  }

  setSubmitButton(button: SubmitButton): void {
    this.submitButton = button;
  }

  buildFormData(form: FormGroup): FormData {
    const formData = new FormData();
    Object.keys(form.value).forEach((key) => {
      if (form.value[key]) {
        if (key === 'files') {
          form.value.files.forEach((file: File) => {
            formData.append(`${key}[]`, file);
          });
        } else if (Array.isArray(form.value[key]) && form.value[key].length > 0) {
          formData.append(key, JSON.stringify(form.value[key]));
        } else {
          formData.append(key, form.value[key]);
        }
      }
    });
    return formData;
  }

}
