/* eslint-disable no-underscore-dangle */
import { Router } from '@angular/router';
import { pagesToggleService } from '@pages/services/toggler.service';
import { RootLayout } from '@pages/layouts/root/root.component';
import { Component, OnInit } from '@angular/core';
import { User } from '@libs/models/src';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { Subscription } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalMarketTypeComponent } from '@libs/components/src/lib/modal-market-type/modal-market-type.component';
import { MarketTypeService } from '@libs/services/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { AuthentificationService } from '../../../../../../libs/auth/src/lib/authentification.service';

@Component({
  selector: 'eros-front-app-with-sidebar',
  templateUrl: './app-with-sidebar.component.html',
  styleUrls: ['./app-with-sidebar.component.scss'],
})
export class AppWithSidebarComponent extends RootLayout implements OnInit {

  public menuLinks = [];
  public userInfos: User;
  public selectedMarketsTypes = [];
  public marketsTypes$: Subscription;
  public marketsTypes: MarketType[];
  public roles: any[];
  public userHasLines = false;
  public canDesactivate = false;

  constructor(
    _toggler: pagesToggleService,
    _router: Router,
    public localStorageService: CustomLocalStorageService,
    private authService: AuthentificationService,
    private marketTypeService: MarketTypeService,
    private modalService: NzModalService,
  ) {
    super(_toggler, _router, localStorageService);

  }

  ngOnInit(): void {
    this.userInfos = this.authService.getUserInfos();
    this.initValues();
    this._menuPin = this.localStorageService.isMenuPin();
    this.selectedMarketsTypes = this.localStorageService.getSelectedMarketsTypes();
    this.toggleMenuPin(null);
    this.roles = this.authService.getRoles();
    this.initMenuLinks();
  }

  logout(): void {
    this.authService.logout();
  }

  onSelectMarketTypeClicked(): void {
    const modalInstance = this.modalService.create({
      nzContent: ModalMarketTypeComponent,
      nzComponentParams: {
        marketsTypes: this.marketsTypes,
      },
    });
    modalInstance.afterClose.subscribe(() => {
    });

  }

  isAdministrator(): boolean {
    if (this.roles) {
      return this.roles.includes('administrator');
    }
    return false;
  }

  isManager(): boolean {
    if (this.roles) {
      return this.roles.includes('ra');
    }
    return false;
  }

  isProductionMode(): boolean {
    return environment.production;
  }

  private initValues() {
    this.marketsTypes$ = this.marketTypeService.getAll().subscribe(
      (data) => {
        this.marketsTypes = data;
      },
    );
  }

  private initMenuLinks() {
    this.menuLinks = [
      {
        label: 'Accueil',
        routerLink: '/dashboard',
        iconType: 'fa',
        iconName: 'th-large',
        isShow: true,
      },
      {
        label: 'Accueil V2',
        routerLink: '/overview',
        iconType: 'fa',
        iconName: 'th-large',
        isShow: true,
      },
      {
        label: 'Admin',
        iconType: 'fa',
        iconName: 'lock',
        toggle: 'close',
        isShow: this.isAdministrator(),
        submenu: [
          {
            label: 'Gestion des utilisateurs',
            routerLink: '/admin/users/index',
            iconType: 'letter',
            iconName: 'gu',
          },
          {
            label: 'Gestion des rôles',
            routerLink: '/admin/roles/index',
            iconType: 'letter',
            iconName: 'gr',
          },
          {
            label: 'Gestion des permissions',
            routerLink: '/admin/permissions/index',
            iconType: 'letter',
            iconName: 'gp',
          },
          {
            label: 'Gestion des équipements',
            routerLink: '/admin/diagnosis-equipments/index',
            iconType: 'letter',
            iconName: 'ge',
          },
          {
            label: 'Gestion des fiches techniciens',
            routerLink: '/admin/technical-sheets/index',
            iconType: 'letter',
            iconName: 'gf',
          },
        ],
      },
      {
        label: 'Calendrier',
        externalLink: '/calendar',
        target: '_blank',
        iconType: 'fas',
        iconName: 'fas fa-calendar',
        isShow: true,
      },
      {
        label: 'Relation client',
        iconType: 'fas',
        iconName: 'fa fa-envelope',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Mails envoyés',
            routerLink: '/customer-relationship/mails-send',
            iconType: 'letter',
            iconName: 'ms',
          },
          {
            label: 'Réponses programmés',
            routerLink: '/customer-relationship/programmed-standards-responses',
            iconType: 'letter',
            iconName: 'ps',
          },
          {
            label: 'Sms envoyés',
            routerLink: '/customer-relationship/sms-send',
            iconType: 'letter',
            iconName: 'sm',
          },
        ],
      },
      {
        label: 'Programmation',
        iconType: 'fas',
        iconName: 'fa fa-calendar-day',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Liste des évènements',
            routerLink: '/events/index',
            iconType: 'letter',
            iconName: 'ev',
          },
          {
            label: 'Préparation',
            routerLink: '/interventions/preparation',
            iconType: 'letter',
            iconName: 'pr',
          },
          {
            label: 'Inspections XML',
            routerLink: '/affairs-xml-inspections/list',
            iconType: 'letter',
            iconName: 'xl',
          },
        ],
      },
      {
        label: 'Affaires',
        iconType: 'fa',
        iconName: 'suitcase',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Créer une affaire',
            routerLink: '/affairs/create',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Liste',
            routerLink: '/affairs/index',
            iconType: 'letter',
            iconName: 'lt',
          },
          {
            label: 'Liste des interventions',
            routerLink: '/interventions/index',
            iconType: 'letter',
            iconName: 'in',
          },
          {
            label: 'Affaire groupe',
            routerLink: '/affairs/mylink/index',
            iconType: 'letter',
            iconName: 'ag',
          },
          {
            label: 'Exports',
            routerLink: '/affairs/exports',
            iconType: 'letter',
            iconName: 'ex',
          },
        ],
      },
      {
        label: 'Base client',
        iconType: 'fa',
        iconName: 'user-alt',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Créer',
            routerLink: '/customers/create',
            iconType: 'letter',
            iconName: 'cr',
          },
          {
            label: 'Rechercher',
            routerLink: '/customers/search',
            iconType: 'letter',
            iconName: 'rc',
          },
        ],
      },
      {
        label: 'Facturation',
        iconType: 'fa',
        iconName: 'credit-card',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Factures',
            routerLink: '/invoices/index',
            iconType: 'letter',
            iconName: 'fc',
          },
          {
            label: 'Devis',
            routerLink: '/quotations/index',
            iconType: 'letter',
            iconName: 'dv',
          },
          {
            label: 'Bons de commandes',
            routerLink: '/purchase-orders/index',
            iconType: 'letter',
            iconName: 'lb',
          },
        ],
      },
      {
        label: 'Comptabilité',
        iconType: 'fa',
        iconName: 'university',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Paiements',
            routerLink: '/payments/index',
            iconType: 'letter',
            iconName: 'pm',
          },
          {
            label: 'Exports',
            routerLink: '/accounting/exports/index',
            iconType: 'letter',
            iconName: 'ex',
          },
          {
            label: 'Notes de frais',
            routerLink: '/expenses-accounts/index',
            iconType: 'letter',
            iconName: 'nf',
          },
        ],
      },
      {
        label: 'Inspections',
        iconType: 'fa',
        iconName: 'house',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Importer un lot',
            routerLink: '/inspections/create',
            iconType: 'letter',
            iconName: 'il',
            isHidden: !this.isAdministrator(),
          },
          {
            label: 'Lots',
            routerLink: '/inspections/set',
            iconType: 'letter',
            iconName: 'lo',
            isHidden: !this.isAdministrator(),
          },
          {
            label: 'Base Lot',
            routerLink: '/inspections/grid-lot',
            iconType: 'letter',
            iconName: 'bl',
          },
          {
            label: 'Modèles',
            routerLink: '/inspections/modeles',
            iconType: 'letter',
            iconName: 'md',
            isHidden: !this.isAdministrator(),
          },
          {
            label: 'Glossaire',
            routerLink: '/inspections/glossary',
            iconType: 'letter',
            iconName: 'gl',
            isHidden: !this.isAdministrator(),
          },
          {
            label: 'Fiches BAR',
            routerLink: '/inspections/bar-types',
            iconType: 'letter',
            iconName: 'pe',
            isHidden: !this.isAdministrator(),
          },
          {
            label: 'Historique de liaisons',
            routerLink: '/inspections/history',
            iconType: 'letter',
            iconName: 'hi',
            isHidden: !this.isAdministrator(),
          },
        ],
      },
      /* {
        label: 'Constatator',
        routerLink: '/affairs/analysis-constatator',
        iconType: 'fas',
        iconName: 'far fa-file-search',
        isShow: true,
      }, */
      {
        label: 'Statistiques',
        iconType: 'fa',
        iconName: 'chart-line',
        toggle: 'close',
        isShow: this.isManager() || this.isAdministrator(),
        submenu: [
          {
            label: 'Volumes',
            routerLink: '/stats/volumes',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Chiffre d\'affaires',
            routerLink: '/stats/revenues',
            iconType: 'letter',
            iconName: 'ca',
          },
        ],
      },
      {
        label: 'Paramètres',
        iconType: 'fa',
        iconName: 'cog',
        toggle: 'close',
        isShow: this.isAdministrator(),
        submenu: [
          {
            label: 'Tableau de bord',
            routerLink: '/settings/dashboard',
            iconType: 'letter',
            iconName: 'da',
          },
          {
            label: 'Affaire',
            routerLink: '/settings/affairs',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Production',
            routerLink: '/settings/products',
            iconType: 'letter',
            iconName: 'pt',
          },
          {
            label: 'Facturation',
            routerLink: '/settings/invoicing',
            iconType: 'letter',
            iconName: 'ft',
          },
          {
            label: 'Comptabilité',
            routerLink: '/settings/accounting',
            iconType: 'letter',
            iconName: 'cp',
          },
          {
            label: 'Interventions',
            routerLink: '/settings/interventions',
            iconType: 'letter',
            iconName: 'cp',
          },
          {
            label: 'Relation client',
            routerLink: '/settings/customer-relationship',
            iconType: 'letter',
            iconName: 'rc',
          },
          {
            label: 'Connexions',
            routerLink: '/settings/connections',
            iconType: 'letter',
            iconName: 'co',
          },
        ],
      },
    ];

  }

}
