/* eslint-disable consistent-return */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationDomainVerifications } from '@libs/customers/src/_models/application-domain.model';
import { Affair } from '@libs/models/src';
import { AffairService, UnsubscribeOnDestroy } from '@libs/services/src';
import { ta } from 'date-fns/locale';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-affair-show-verifications',
  templateUrl: './affair-show-verifications.component.html',
  styleUrls: ['./affair-show-verifications.component.scss'],
})
export class AffairShowVerificationsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() affair: Affair;
  @Input() applicationDomainVerifications: ApplicationDomainVerifications = null;
  public verifications = null;
  public displayVerifications = false;
  public readonly P3O = 11;

  constructor(
    private affairService: AffairService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initVerifications();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.affair) {
      this.initVerifications();
    }
  }

  initVerifications(): void {
    this.affairService.getVerifications(this.affair.id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (verifications) => {
          this.verifications = verifications;
          this.isDisplayVerifications();
        },
      });
  }

  isDisplayVerifications(): void {
    if (this.verifications != null) {
      if (this.verifications.footprint === true || this.verifications.rsenv === true || this.verifications.affairXmlInspection !== null) {
        this.displayVerifications = true;
      }
    }
    if (this.applicationDomainVerifications && this.applicationDomainVerifications.applicationDomain != null) {
      this.displayVerifications = true;
    }
  }

  onRedirectAffairXmlInspectionClicked(): void {
    window.open(`affairs-xml-inspections/affair/${this.affair.mlRef}`, '_blank');
  }

}
