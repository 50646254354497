import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErosResponse, Setting } from '@libs/models/src';
import { NotifService } from './utilities/notif.service';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { FormFormatterService } from './utilities/form-formatter.service';

export interface SettingsDatatableParameters {
    settingType: string;
}

@Injectable()
export class SettingService extends ModelWithDatatableAndCrudObservable {

  private route = '/settings';
  // eslint-disable-next-line no-undef
  public dtInstance: DataTables.Api;
  private columns: DataTableColumn[];
  public modalAddEdit: ModalDirective;

  constructor(
    http: HttpClient,
    notifService: NotifService,
    submitButtonService: SubmitButtonService,
    swalService: SwalService,
    apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private formFormatterService: FormFormatterService,
  ) {
    super(
      http,
      notifService,
      submitButtonService,
      swalService,
      apiService,
    );
  }

  public initDataTable(selector: string, columns: DataTableColumn[], settingType: string): void {
    return super.initializeDataTable({
      url: `${this.route}/list`,
      selector: `#${selector}`,
      dataTableColumns: columns,
    }, {
      settingType: settingType,
    });
  }

  public get(id: number): Observable<Setting> {
    return super.get(id, this.route);
  }

  public getAll(): Observable<Setting[]> {
    return super.getAll(this.route);
  }

  public store(form: FormGroup): Observable<ErosResponse> {
    return super.store(form.value, this.route);
  }

  public update(id: number, form: FormGroup): Observable<ErosResponse> {
    return super.update(id, form.value, this.route);
  }

  public delete(id: number): Observable<ErosResponse> {
    return super.delete(id, this.route);
  }

  public getForm(setting: Setting, settingTypeKey: string): FormGroup {
    const key = setting
      ? this.commonService.valueToFormString(setting.key) : this.commonService.getDefaultFormStringValue();
    const description = setting
      ? this.commonService.valueToFormString(setting.description) : this.commonService.getDefaultFormStringValue();
    const fieldType = setting
      ? this.commonService.valueToFormString(setting.fieldType) : 'text';
    const entityClass = setting
      ? this.commonService.valueToFormString(setting.entityClass) : this.commonService.getDefaultFormStringValue();
    const value = setting
      ? this.commonService.valueToFormString(setting.value) : this.commonService.getDefaultFormStringValue();
    const values = setting
      ? setting.values : this.commonService.getDefaultFormNullValue();
    return this.formBuilder.group({
      key: [key, Validators.required],
      description: [description],
      settingType: [settingTypeKey, Validators.required],
      fieldType: [fieldType, Validators.required],
      entityClass: [entityClass],
      value: [value],
      values: [values],
    });
  }

  public getValue(key: string): Observable<Setting> {
    return this.apiService.get(`/settings/value/${key}`);
  }

}
