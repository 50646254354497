import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import France from 'echarts-countries-js/echarts-countries-js';
import * as echarts from 'echarts';
import { HttpClient } from '@angular/common/http';
import { N } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';
import { StatisticInterventionVolume, UnsubscribeOnDestroy } from '@libs/services/src';
import { MapChartService } from '@libs/services/src/lib/utilities/map-chart.service';
import { StatisticModeEnum } from '@libs/enum/src';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-statistics-volumes-map',
  templateUrl: './statistics-volumes-map.component.html',
  styleUrls: ['./statistics-volumes-map.component.scss'],
})
export class StatisticsVolumesMapComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  @ViewChild('chart') chart: ElementRef;
  @Input() displayMode: number;
  public options: any;
  public statisticsData: any;
  private statsInterventionsDepartments$: Subscription;

  constructor(
    private http: HttpClient,
    private statisticInterventionVolume: StatisticInterventionVolume,
    private mapChartService: MapChartService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.http.get('assets/data/france.json')
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((geoJson) => {
        echarts.registerMap('FR', geoJson);
        this.statsInterventionsDepartments$ = this.statisticInterventionVolume.statsInterventionsDepartments$
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (data) => {
              if (data) {
                this.initMap(geoJson, data);
              }
            },
          );
      });
  }

  private initMap(geoJson, data) {
    if (this.displayMode === StatisticModeEnum.YMINUSONE) {
      this.statisticsData = this.mapChartService.generateDataYMinusOneMode(geoJson, data.volumes, data.volumesYMinusOne);
    } else {
      this.statisticsData = this.mapChartService.generateDataSimpleMode(geoJson, data);
    }
    this.options = this.mapChartService.getOptions(this.statisticsData, geoJson);
  }

}
