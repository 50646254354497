// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';
import { PayersModule } from '@eros-front/payers';

// Components
import { InvoicesModule } from '@eros-front/invoices';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AffairShowInvoicesComponent } from './affair-show/affair-show-invoices/affair-show-invoices.component';
import { AffairShowInvoicesInvoicingPreferencesComponent } from './affair-show/affair-show-invoices/affair-show-invoices-invoicing-preferences/affair-show-invoices-invoicing-preferences.component';
import { AffairShowInvoicesGenerateComponent } from './affair-show/affair-show-invoices/affair-show-invoices-generate/affair-show-invoices-generate.component';
import { AffairShowDepositsGenerateComponent } from './affair-show/affair-show-invoices/affair-show-deposits-generate/affair-show-deposits-generate.component';
import { AffairsTabsComponent } from './affairs-tabs/affairs-tabs.component';
import { AffairsDatatableFiltersComponent } from './affairs-datatable-filters/affairs-datatable-filters.component';
import { AffairCreateComponent } from './affair-create/affair-create.component';
import { AffairsMylinkIndexComponent } from './affairs-mylink-index/affairs-mylink-index.component';
import { AffairShowDocumentsCustomersComponent } from './affair-show/affair-show-documents-customers/affair-show-documents-customers.component';
import { AffairShowDocumentFiltersComponent } from './affair-show/affair-show-modules/affair-show-document-filters/affair-show-document-filters.component';
import { AffairShowModalRenameDocumentComponent } from './affair-show/affair-show-modules/affair-show-modal-rename-document/affair-show-modal-rename-document.component';
import { AffairShowDocumentsComponent } from './affair-show/affair-show-modules/affair-show-documents/affair-show-documents.component';
import { AffairDocumentCustomerImportComponent } from './affair-document-customer-import/affair-document-customer-import.component';
import { AffairDocumentDeliveredImportComponent } from './affair-document-delivered-import/affair-document-delivered-import.component';
import { AffairDocumentImportComponent } from './affair-document-import/affair-document-import.component';
import { AffairDocumentDropzonesComponent } from './affair-document-dropzones/affair-document-dropzones.component';
import { AffairShowContactsComponent } from './affair-show/affair-show-contacts/affair-show-contacts.component';
import { AffairShowContactsModalComponent } from './affair-show/affair-show-contacts/affair-show-contacts-modal/affair-show-contacts-modal.component';
import { AffairFormWorkSiteInformationsComponent } from './affair-form/affair-form-work-site-informations/affair-form-work-site-informations.component';
import { AffairFormFinalCustomerInformationsComponent } from './affair-form/affair-form-final-customer-informations/affair-form-final-customer-informations.component';
import { AffairFormAffairInformationsComponent } from './affair-form/affair-form-affair-informations/affair-form-affair-informations.component';
import { AffairShowDocumentsDeliveredComponent } from './affair-show/affair-show-documents-delivered/affair-show-documents-delivered.component';
import { AffairShowOfficeFileComponent } from './affair-show/affair-show-office-file/affair-show-office-file.component';
import { AffairShowOfficeFileModalComponent } from './affair-show/affair-show-office-file/affair-show-office-file-modal/affair-show-office-file-modal.component';
import { AffairCreateMylinkResultsComponent } from './affair-create/affair-create-mylink-results/affair-create-mylink-results.component';
import { AffairShowHeadbandComponent } from './affair-show/affair-show-headband/affair-show-headband.component';
import { AffairShowInvoicesThumbnailsComponent } from './affair-show/affair-show-invoices/affair-show-invoices-thumbnails/affair-show-invoices-thumbnails.component';
import { AffairShowDepositsThumbnailsComponent } from './affair-show/affair-show-invoices/affair-show-deposits-thumbnails/affair-show-deposits-thumbnails.component';
import { AffairFormNotesComponent } from './affair-form/affair-form-notes/affair-form-notes.component';
import { AffairFormAffairProductsComponent } from './affair-form/affair-form-affair-products/affair-form-affair-products.component';
import { AffairProductModalAddComponent } from './affair-product-modal/affair-product-modal-add/affair-product-modal-add.component';
import { AffairProductModalEditComponent } from './affair-product-modal/affair-product-modal-edit/affair-product-modal-edit.component';
import { AffairProductArrayComponent } from './affair-product-modal/affair-product-array/affair-product-array.component';
import { AffairProductsToInvoicingTabsComponent } from './affair-products/affair-products-to-invoicing/affair-products-to-invoicing-tabs/affair-products-to-invoicing-tabs.component';
import { AffairProductsToInvoicingIndexComponent } from './affair-products/affair-products-to-invoicing/affair-products-to-invoicing-index/affair-products-to-invoicing-index.component';
import { AffairProductsToInvoicingFiltersComponent } from './affair-products/affair-products-to-invoicing/affair-products-to-invoicing-filters/affair-products-to-invoicing-filters.component';
import { AffairsProductsToInvoicingModalComponent } from './affair-products/affair-products-to-invoicing/affairs-products-to-invoicing-modal/affairs-products-to-invoicing-modal.component';
import { AffairsProductsToInvoicingSelectTempoModalComponent } from './affair-products/affair-products-to-invoicing/affairs-products-to-invoicing-select-tempo-modal/affairs-products-to-invoicing-select-tempo-modal.component';
import { AffairsProductsUncompletedListComponent } from './affair-products/affairs-products-uncompleted-list/affairs-products-uncompleted-list.component';
import { AffairsProductsCompletedListComponent } from './affair-products/affairs-products-completed-list/affairs-products-completed-list.component';
import { AffairShowMailsComponent } from './affair-show/affair-show-mails/affair-show-mails.component';
import { AffairsProductsFinishedListComponent } from './affair-products/affairs-products-finished-list/affairs-products-finished-list.component';
import { AffairsExportComponent } from './affairs-export/affairs-export.component';
import { AffairShowQuotationsComponent } from './affair-show/affair-show-quotations/affair-show-quotations.component';
import { AffairShowQuotationsGenerateComponent } from './affair-show/affair-show-quotations/affair-show-quotations-generate/affair-show-quotations-generate.component';
import { AffairShowPreviewAmountComponent } from './affair-show/affair-show-modules/affair-show-preview-amount/affair-show-preview-amount.component';
import { AffairShowQuotationsThumbnailsComponent } from './affair-show/affair-show-quotations/affair-show-quotations-thumbnails/affair-show-quotations-thumbnails.component';
import { AffairProductModifyModalComponent } from './affair-product-modal/affair-product-modify-modal/affair-product-modify-modal.component';
import { AffairDocumentCustomerAttachedComponent } from './affair-document-customer-attached/affair-document-customer-attached.component';
import { AffairFormWorkSiteAddressComponent } from './affair-form/affair-form-work-site-address/affair-form-work-site-address.component';
import { AffairFormFinalCustomerAddressComponent } from './affair-form/affair-form-final-customer-address/affair-form-final-customer-address.component';
import { AffairFormFieldsComponent } from './affair-form/affair-form-fields/affair-form-fields.component';
import { AffairShowModalIdentifyDocumentComponent } from './affair-show/affair-show-modules/affair-show-modal-identify-document/affair-show-modal-identify-document.component';
import { AffairShowMonitoringComponent } from './affair-show/affair-show-monitoring/affair-show-monitoring.component';
import { AffairShowMonitoringFormComponent } from './affair-show/affair-show-monitoring/affair-show-monitoring-form/affair-show-monitoring-form.component';
import { AffairsArchivesIndexComponent } from './affairs-archives-index/affairs-archives-index.component';
import { AffairShowHistoryComponent } from './affair-show/affair-show-history/affair-show-history.component';
import { AffairsExportsComponent } from './affairs-exports/affairs-exports.component';
import { AffairsExportsDocumentsDeliveredComponent } from './affairs-exports/affairs-exports-documents-delivered/affairs-exports-documents-delivered.component';
import { AffairsExportsFiltersComponent } from './affairs-exports/affairs-exports-filters/affairs-exports-filters.component';
import { AffairsYnergieListComponent } from './affairs-ynergie/affairs-ynergie-list/affairs-ynergie-list.component';
import { AffairYnergieCreateModalComponent } from './affairs-ynergie/affair-ynergie-create-modal/affair-ynergie-create-modal.component';
import { AffairShowYnergieComponent } from './affairs-ynergie/affair-show-ynergie/affair-show-ynergie.component';
import { AffairShowYnergieScenarioModalComponent } from './affairs-ynergie/affair-show-ynergie/affair-show-ynergie-scenario-modal/affair-show-ynergie-scenario-modal.component';
import { AffairShowYnergieScenarioComponent } from './affairs-ynergie/affair-show-ynergie/affair-show-ynergie-scenario/affair-show-ynergie-scenario.component';
import { AffairsListComponent } from './affairs-list/affairs-list.component';
import { AffairsIndexComponent } from './affairs-index/affairs-index.component';
import { AffairsYnergieStatsComponent } from './affairs-ynergie/affairs-ynergie-list/affairs-ynergie-stats/affairs-ynergie-stats.component';
import { AffairCreateDqComponent } from './affair-create-dq/affair-create-dq.component';
import { AffairShowModalLinkDocumentComponent } from './affair-show/affair-show-modules/affair-show-modal-link-document/affair-show-modal-link-document.component';
import { AffairsIndexExportChoiceComponent } from './affairs-index/affairs-index-export-choice/affairs-index-export-choice.component';
import { AffairShowVerificationsComponent } from './affair-show/affair-show-verifications/affair-show-verifications.component';
import { AffairResidenceModalComponent } from '../_components/affair-residence-modal/affair-residence-modal.component';
import { AffairsDashboardWithProblemsListComponent } from './affairs-dashboard/affairs-dashboard-with-problems-list/affairs-dashboard-with-problems-list.component';
import { AffairsDashboardWithRecallDateListComponent } from './affairs-dashboard/affairs-dashboard-with-recall-date-list/affairs-dashboard-with-recall-date-list.component';
import { AffairsDashboardWithoutPurchaseOrdersListComponent } from './affairs-dashboard/affairs-dashboard-without-purchase-orders-list/affairs-dashboard-without-purchase-orders-list.component';
import { AffairDocumentsChoiceComponent } from './affair-show/affair-show-modules/affair-show-documents/affair-documents-choice/affair-documents-choice.component';
import { AffairCreateFakeComponent } from '../_components/affair-create-fake/affair-create-fake.component';
import { AffairsMrIndexComponent } from './affairs-mr-index/affairs-mr-index.component';
import { AffairShowConsteoComponent } from './affair-show/affair-show-consteo/affair-show-consteo.component';
import { AffairShowConsteoHistoryComponent } from './affair-show/affair-show-consteo/affair-show-consteo-history/affair-show-consteo-history.component';
import { AffairShowConsteoHistoryItemComponent } from './affair-show/affair-show-consteo/affair-show-consteo-history/affair-show-consteo-history-item/affair-show-consteo-history-item.component';
import { AffairShowConsteoAppointmentComponent } from './affair-show/affair-show-consteo/affair-show-consteo-appointment/affair-show-consteo-appointment.component';
import { AffairShowConsteoAppointmentItemComponent } from './affair-show/affair-show-consteo/affair-show-consteo-appointment/affair-show-consteo-appointment-item/affair-show-consteo-appointment-item.component';
import { AffairShowConsteoInputComponent } from './affair-show/affair-show-consteo/affair-show-consteo-input/affair-show-consteo-input.component';
import { AffairShowConsteoInputItemComponent } from './affair-show/affair-show-consteo/affair-show-consteo-input/affair-show-consteo-input-item/affair-show-consteo-input-item.component';
import { AffairsXmlInspectionByAffairComponent } from '../_components/affairs-xml-inspections/affairs-xml-inspection-by-affair/affairs-xml-inspection-by-affair.component';
import { AffairXmlInspectionCarpentriesComponent } from '../_components/affairs-xml-inspections/affairs-xml-inspection-by-affair/affair-xml-inspection-carpentries/affair-xml-inspection-carpentries.component';
import { AffairXmlInspectionGenerationComponent } from '../_components/affairs-xml-inspections/affairs-xml-inspection-by-affair/affair-xml-inspection-generation/affair-xml-inspection-generation.component';
import { AffairXmlInspectionVentilationComponent } from '../_components/affairs-xml-inspections/affairs-xml-inspection-by-affair/affair-xml-inspection-ventilation/affair-xml-inspection-ventilation.component';
import { AffairsXmlInspectionsListComponent } from '../_components/affairs-xml-inspections/affairs-xml-inspections-list/affairs-xml-inspections-list.component';
import { AffairsXmlInspectionsFiltersComponent } from '../_components/affairs-xml-inspections/affairs-xml-inspections-list/affairs-xml-inspections-filters/affairs-xml-inspections-filters.component';

@NgModule({
  imports: [
    SharedModule,
    InvoicesModule,
    PayersModule,
    NgxExtendedPdfViewerModule,
  ],
  declarations: [
    AffairShowInvoicesComponent,
    AffairShowInvoicesInvoicingPreferencesComponent,
    AffairShowInvoicesGenerateComponent,
    AffairShowDepositsGenerateComponent,
    AffairsTabsComponent,
    AffairsDatatableFiltersComponent,
    AffairCreateComponent,
    AffairsMylinkIndexComponent,
    AffairShowDocumentsCustomersComponent,
    AffairShowDocumentsDeliveredComponent,
    AffairShowDocumentFiltersComponent,
    AffairShowModalRenameDocumentComponent,
    AffairShowDocumentsComponent,
    AffairShowContactsComponent,
    AffairShowContactsModalComponent,
    AffairDocumentCustomerImportComponent,
    AffairDocumentDeliveredImportComponent,
    AffairDocumentImportComponent,
    AffairDocumentDropzonesComponent,
    AffairFormWorkSiteInformationsComponent,
    AffairFormFinalCustomerInformationsComponent,
    AffairFormAffairInformationsComponent,
    AffairShowOfficeFileComponent,
    AffairShowOfficeFileModalComponent,
    AffairCreateMylinkResultsComponent,
    AffairShowHeadbandComponent,
    AffairShowInvoicesThumbnailsComponent,
    AffairShowDepositsThumbnailsComponent,
    AffairFormNotesComponent,
    AffairFormAffairProductsComponent,
    AffairProductModalAddComponent,
    AffairProductModalEditComponent,
    AffairProductArrayComponent,
    AffairProductsToInvoicingTabsComponent,
    AffairProductsToInvoicingIndexComponent,
    AffairProductsToInvoicingFiltersComponent,
    AffairsProductsToInvoicingModalComponent,
    AffairsProductsToInvoicingSelectTempoModalComponent,
    AffairsProductsUncompletedListComponent,
    AffairsProductsCompletedListComponent,
    AffairShowMailsComponent,
    AffairsProductsFinishedListComponent,
    AffairsExportComponent,
    AffairShowQuotationsComponent,
    AffairShowQuotationsGenerateComponent,
    AffairShowPreviewAmountComponent,
    AffairShowQuotationsThumbnailsComponent,
    AffairProductModifyModalComponent,
    AffairDocumentCustomerAttachedComponent,
    AffairFormWorkSiteAddressComponent,
    AffairFormFinalCustomerAddressComponent,
    AffairFormFieldsComponent,
    AffairShowModalIdentifyDocumentComponent,
    AffairShowMonitoringComponent,
    AffairShowMonitoringFormComponent,
    AffairsArchivesIndexComponent,
    AffairShowHistoryComponent,
    AffairShowConsteoComponent,
    AffairShowConsteoHistoryComponent,
    AffairShowConsteoAppointmentComponent,
    AffairShowConsteoHistoryItemComponent,
    AffairShowConsteoInputComponent,
    AffairShowConsteoInputItemComponent,
    AffairShowConsteoAppointmentItemComponent,
    AffairsExportsComponent,
    AffairsExportsDocumentsDeliveredComponent,
    AffairsExportsFiltersComponent,
    AffairsYnergieListComponent,
    AffairYnergieCreateModalComponent,
    AffairShowYnergieComponent,
    AffairShowYnergieScenarioModalComponent,
    AffairShowYnergieScenarioComponent,
    AffairsIndexComponent,
    AffairsListComponent,
    AffairsYnergieStatsComponent,
    AffairCreateDqComponent,
    AffairShowModalLinkDocumentComponent,
    AffairsIndexExportChoiceComponent,
    AffairShowVerificationsComponent,
    AffairResidenceModalComponent,
    // Affair monitoring
    AffairsDashboardWithProblemsListComponent,
    AffairsDashboardWithRecallDateListComponent,
    AffairsDashboardWithoutPurchaseOrdersListComponent,
    AffairDocumentsChoiceComponent,
    AffairCreateFakeComponent,
    AffairsMrIndexComponent,
    AffairsXmlInspectionByAffairComponent,
    AffairXmlInspectionCarpentriesComponent,
    AffairXmlInspectionGenerationComponent,
    AffairXmlInspectionVentilationComponent,
    AffairsXmlInspectionsListComponent,
    AffairsXmlInspectionsFiltersComponent,
  ],
  exports: [
    AffairShowInvoicesInvoicingPreferencesComponent,
    AffairShowInvoicesGenerateComponent,
    AffairShowDepositsGenerateComponent,
    AffairShowDocumentsCustomersComponent,
    AffairShowDocumentsDeliveredComponent,
    AffairDocumentCustomerImportComponent,
    AffairDocumentDeliveredImportComponent,
    AffairDocumentImportComponent,
    AffairDocumentDropzonesComponent,
    AffairsMylinkIndexComponent,
    AffairShowContactsComponent,
    AffairShowContactsModalComponent,
    AffairShowInvoicesComponent,
    AffairFormWorkSiteInformationsComponent,
    AffairFormFinalCustomerInformationsComponent,
    AffairFormAffairInformationsComponent,
    AffairShowOfficeFileComponent,
    AffairShowOfficeFileModalComponent,
    AffairCreateMylinkResultsComponent,
    AffairShowHeadbandComponent,
    AffairFormNotesComponent,
    AffairFormAffairProductsComponent,
    AffairProductModalAddComponent,
    AffairProductModalEditComponent,
    AffairProductsToInvoicingTabsComponent,
    AffairsProductsToInvoicingModalComponent,
    AffairsProductsUncompletedListComponent,
    AffairsProductsCompletedListComponent,
    AffairsProductsFinishedListComponent,
    AffairShowMailsComponent,
    AffairShowQuotationsComponent,
    AffairDocumentCustomerAttachedComponent,
    AffairFormWorkSiteAddressComponent,
    AffairFormFinalCustomerAddressComponent,
    AffairFormFieldsComponent,
    AffairShowMonitoringComponent,
    AffairShowMonitoringFormComponent,
    AffairShowHistoryComponent,
    AffairShowConsteoComponent,
    AffairsExportsComponent,
    AffairsExportsDocumentsDeliveredComponent,
    AffairsYnergieListComponent,
    AffairShowYnergieComponent,
    AffairCreateDqComponent,
    AffairShowVerificationsComponent,
    AffairsDashboardWithProblemsListComponent,
    AffairsDashboardWithRecallDateListComponent,
    AffairsDashboardWithoutPurchaseOrdersListComponent,
    AffairsXmlInspectionByAffairComponent,
    AffairsXmlInspectionsListComponent,
  ],
})
export class AffairsModule { }
