import { Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output } from '@angular/core';
import { AffairDocument, InterventionNonconformity } from '@libs/models/src';

@Component({
  selector: 'tr[app-intervention-edit-insky-row]',
  templateUrl: './intervention-edit-insky-row.component.html',
  styleUrls: ['./intervention-edit-insky-row.component.scss'],
})
export class InterventionEditInskyRowComponent implements OnInit {

  @Input() data: any;
  @Input() documents: AffairDocument[];
  @Output() confirm = new EventEmitter<any>();
  @Output() selectSolutionClicked = new EventEmitter<any>();
  @Output() unconfirm = new EventEmitter<any>();
  public lastInvalidDocuments: AffairDocument[];

  public readonly NOT_VALID = 1;
  public readonly WAITING_VALIDATION = 2;
  public readonly VALID = 3;
  constructor() {}

  ngOnInit(): void {
    this.lastInvalidDocuments = this.getlastInvalidDocuments();
  }

  onConfirm(): void {
    this.confirm.emit(this.data);
  }

  onSelectSolutionClicked(): void {
    this.selectSolutionClicked.emit(this.data);
  }

  onUnconfirm(): void {
    this.unconfirm.emit(this.data);
  }

  getlastInvalidDocuments(): AffairDocument[] {
    const lastInvalidDocuments = [];
    const statusId = this.data.nonconformityStatus?.id;
    if (statusId === 3) {
      return lastInvalidDocuments;
    }
    const documentsIds = this.getPossibleDocumentsIds(this.data);
    const findDocuments = this.documents.filter((document) => { return documentsIds.includes(document.documentId); });
    console.log(findDocuments);
    if (findDocuments.length > 0) {
      switch (statusId) {
        case 1:
          lastInvalidDocuments.push(findDocuments[0]);
          break;
        case 2:
          lastInvalidDocuments.push(findDocuments[1]);
          break;
        default:
          break;
      }
    }
    return lastInvalidDocuments;
  }

  getPossibleDocumentsIds(data: InterventionNonconformity): number[] {
    const documentsIds: number[] = [];
    data.nonconformity.solutions.forEach((solution) => {
      solution.documentsCustomers.forEach((document) => {
        documentsIds.push(document.documentId);
      });
    });
    return documentsIds;
  }

}
