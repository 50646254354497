import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Contact } from '@libs/models/src';
import { UnsubscribeOnDestroy } from '@libs/services/src';
import { finalize, takeUntil } from 'rxjs/operators';
import AircallPhone from 'aircall-everywhere';
import { SearchService } from '@libs/services/src/lib/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { faBriefcase, faCalendarAlt, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MlSocietyService } from '@libs/services/src/lib/ml-society.service';
import { FormControl } from '@angular/forms';
import { ModalStandardResponseComponent } from '@libs/components/src/lib/modal-standard-response/modal-standard-response.component';
import { AircallService } from '@libs/services/src/lib/aircall.service';
import { TelephonyAircallContactsModalComponent } from './telephony-aircall-contact/telephony-aircall-contact-modal/telephony-aircall-contact-modal.component';

@Component({
  selector: 'app-telephony-aircall',
  templateUrl: './telephony-aircall.component.html',
  styleUrls: ['./telephony-aircall.component.scss'],
})
export class TelephonyAircallComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {

  public phone: AircallPhone;
  public phoneNumber: string;
  public contact: Contact = null;
  public mlSocieties;
  public faCalendarAlt = faCalendarAlt;
  public faUserPlus = faUser;
  public faBriefCase = faBriefcase;
  public faEnvelope = faEnvelope;
  public searchContact = new FormControl('');

  constructor(
    private titleService: Title,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private modalService: NzModalService,
    private mlSocietyService: MlSocietyService,
    private aircallService: AircallService,
  ) {
    super();
    this.titleService.setTitle('Téléphonie');
  }

  ngOnInit(): void {
    this.loadAircall('#aircall-phone');
    const paramsPhoneNumber = this.route.snapshot.queryParams.phoneNumber;
    if (paramsPhoneNumber) {
      this.phoneNumber = paramsPhoneNumber;
      this.getContactInformations(`+33${paramsPhoneNumber}`);
    }
    this.mlSocietyService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.mlSocieties = data;
        },
      );
  }

  ngAfterViewInit(): void {
    Notification.requestPermission();
  }

  showNotification(message: string): void {
    const notification = new Notification('AirCall', { lang: 'fr', body: message, icon: '_assets/logos/logo-aircall.svg' });
    notification.onclick = () => {
      window.focus();
    };
  }

  getContactInformations(phoneNumber: string): void {
    this.spinnerService.show('loadingTelephonyContact');
    this.searchService.searchByPhone(phoneNumber)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinnerService.hide('loadingTelephonyContact'); }),
      )
      .subscribe(
        (contact) => {
          this.contact = contact;
        },
        (error) => {
          this.contact = null;
        },
      );
  }

  public loadAircall(selector: string): void {
    this.phone = new AircallPhone({
      domToLoadPhone: selector,
      size: 'small',
      onLogin: () => {
        const { toCall } = this.route.snapshot.queryParams;
        if (toCall) {
          setTimeout(() => { this.aircallService.makeCall(toCall).subscribe(); }, 5000);
          this.router.navigate([], { relativeTo: this.route, queryParams: null });
        }
      },
    });
    this.phone.on('outgoing_call', (callInfos) => {
      this.phoneNumber = callInfos.to;
      window.focus();
      this.showNotification(`Appel en cours vers ${callInfos.to}`);
      this.getContactInformations(callInfos.to);
    });
    this.phone.on('incoming_call', (callInfos) => {
      this.phoneNumber = callInfos.from;
      window.focus();
      this.showNotification(`Appel entrant du ${callInfos.from}`);
      this.getContactInformations(callInfos.from);

    });
    this.phone.on('external_dial', (callInfos) => {
      this.phoneNumber = callInfos.phone_number;
      window.focus();
      this.showNotification(`Appel présaisi ${callInfos.from}`);
      this.getContactInformations(callInfos.phone_number);
    });
  }

  onShowModalAddEditModal(contact?: Contact): void {
    const modalInstance = this.modalService.create({
      nzContent: TelephonyAircallContactsModalComponent,
      nzComponentParams: {
        contact: contact,
        mlSocieties: this.mlSocieties,
      },
      nzStyle: { top: '2vh' },
      nzWidth: '98%',
      nzOnOk: () => { this.contact = null; this.searchContact.reset(); },
    });

  }

  onModalStandardResponseShow(): void {
    const selectedContactsEmails = [];
    if (this.contact && this.contact.email) {
      selectedContactsEmails.push(this.contact.email);
    }
    const modalInstance = this.modalService.create({
      nzContent: ModalStandardResponseComponent,
      nzComponentParams: {
        selectedContactsEmails: selectedContactsEmails,
      },
      nzStyle: { top: '2vh' },
    });
  }

}
