/* eslint-disable no-undef */
import { Calendar } from '@fullcalendar/core';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg } from '@fullcalendar/common';
import { AffairProduct, Contact, Event, Intervention, Region, SelectModel, User } from '@libs/models/src';
import { ContactSelectParameters, EventService, EventTypeService, InterventionService, NotifService, RegionService, SettingService, SwalService, UnsubscribeOnDestroy, UserService } from '@libs/services/src';
import { Subject, Subscription, throwError, zip } from 'rxjs';
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { DateUtilsService } from '@libs/services/src/lib/utilities/date-utils.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import Swal from 'sweetalert2';
import { catchError, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { InterventionStatusEnum } from '@libs/enum/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthentificationService } from '@libs/auth/src';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';
import tippy from 'tippy.js';
import { te } from 'date-fns/locale';
import { InterventionModalCreateComponent } from '../interventions/intervention-modal-create/intervention-modal-create.component';
import { InterventionEditShiftModalComponent } from '../interventions/intervention-edit/intervention-edit-informations/intervention-edit-shift-modal/intervention-edit-shift-modal.component';
import { InterventionEditDeprogramModalComponent } from '../interventions/intervention-edit/intervention-edit-informations/intervention-edit-deprogram-modal/intervention-edit-deprogram-modal.component';
import { EventModalAddEditComponent } from '../events/event-modal-add-edit/event-modal-add-edit.component';
import { EventAffairModalAddEditComponent } from '../events/event-affair-modal-add-edit/event-affair-modal-add-edit.component';
import { InterventionModalPreviewComponent } from '../interventions/intervention-modal-preview/intervention-modal-preview.component';
import { CalendarSelectMapsDayModalComponent } from './calendar-select-maps-day-modal/calendar-select-maps-day-modal.component';

interface EventAdd {
  startDate: Date;
  duration: Date;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild('external') external: ElementRef;
  @ViewChild('calendarContextMenu') public contextMenu: ContextMenuComponent;
  public form: FormGroup;
  public interventionForm: FormGroup;
  private calendarApi: Calendar;
  private interventionsCalendarSelects$: Subscription;
  private affairsProductsToProgram$: Subscription;
  public events: any[] = [];
  public eventsData: any;
  public interventionsData: any;
  public users: User[];
  public usersSelect: SelectModel[];
  public daysInWeek = 6;
  public research = '';
  public event: Event = null;
  public intervention: Intervention = null;
  public interventionDa: Intervention = null;
  public affairProduct: AffairProduct = null;
  public eventsTypes: SelectModel[];
  public refresh: Subject<any> = new Subject();
  public eventAdd: EventAdd = null;
  public viewDate: Date;
  public conformities: SelectModel[] = [];
  public worksSupervisors: SelectModel[] = [];
  public indicatorsInterventionsTypes: SelectModel[] = [];
  public affairProductsToProgram: AffairProduct[] = [];
  public affairProductsToProgramAll: AffairProduct[] = [];
  public usersSelected: any[];
  public calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek',
    },
    initialView: 'timeGridWeek',
    editable: true,
    selectable: true,
    locale: frLocale,
    weekNumbers: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventResize: this.handleEventResize.bind(this),
    eventDrop: this.handleEventDrop.bind(this),
    eventDidMount: this.handleEventContent.bind(this),
    eventReceive: this.handleEventReceive.bind(this),
    slotDuration: '00:15:00',
    slotMinTime: '07:00:00',
    slotMaxTime: '20:00:00',
    plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
  };

  public isQuickAddOpen = false;
  public activeDayIsOpen: boolean = false;
  public readonly RESOURCE_EVENT = 'event';
  public readonly RESOURCE_INTERVENTION = 'intervention';
  private readonly storageKey = 'calendar-form';
  public shiftOrigins = [];
  public interventionAdd: any;
  public settings;
  public interventionStatusEnum = InterventionStatusEnum;
  public selectContacts$: Subscription;
  public contacts: Contact[];
  public selectWorksSupervisors$: Subscription;
  public worksSupervisorsSelect: SelectModel[];
  public selectContactsParams: ContactSelectParameters;
  public zip$: Subscription;
  public technicians: SelectModel[];
  public buildings;
  public housings;
  public isSelectsInit = false;
  public affairProductsToProgram$: Subscription;
  public affairProducts;
  public documentsCustomersAttached$: Subscription;
  public documentsCustomersAttached: any;
  public shiftForm: FormGroup;
  public deprogramForm: FormGroup;
  public eventForm: FormGroup;
  public echo: any;
  public regions: Region[];
  public selectedRegionsIds = [];
  public calendarElement: HTMLElement;

  constructor(
    private titleService: Title,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private userService: UserService,
    private dateUtilsService: DateUtilsService,
    private formBuilder: FormBuilder,
    private interventionService: InterventionService,
    private storageService: FormLocalStorageService,
    private contextMenuService: ContextMenuService,
    private swalService: SwalService,
    private settingService: SettingService,
    private notifService: NotifService,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private authService: AuthentificationService,
    private regionService: RegionService,
  ) {
    super();
    const { name } = Calendar;
    this.titleService.setTitle(`Calendrier${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.interventionService.getSelectsForCalendar();
    this.interventionService.getAffairsProductsToProgram();
    this.settingService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (settings) => {
          this.settings = settings;
        },
      );
  }

  ngOnDestroy(): void {
    this.interventionsCalendarSelects$.unsubscribe();
    this.affairsProductsToProgram$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.fullcalendar.getApi();
    this.calendarElement = this.fullcalendar.getApi().el;
    const event = new Draggable(this.external.nativeElement, {
      itemSelector: '.fc-event',
      eventData: function (eventEl) {
        const data: any = JSON.parse(eventEl.getAttribute('data-event'));
        const duration = data.product.duration != null ? data.product.duration : '01:00:00';
        return {
          title: eventEl.innerText,
          duration: duration,
          data: data,
        };
      },
    });
    $('.fc-prev-button').on('click', (x) => {
      this.form.value.dateFrom = this.getCurrentDateFrom();
      this.form.value.dateTo = this.getCurrentDateTo();
      this.initCalendarEvents();
    });
    $('.fc-next-button').on('click', (x) => {
      this.form.value.dateFrom = this.getCurrentDateFrom();
      this.form.value.dateTo = this.getCurrentDateTo();
      this.initCalendarEvents();
    });
  }

  onChanges(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((form) => {
        this.form.value.dateFrom = this.getCurrentDateFrom();
        this.form.value.dateTo = this.getCurrentDateTo();
        this.storageService.store(this.storageKey, this.form);
        this.eventService.getForCalendar(this.form, this.users);
        this.interventionService.getForCalendar(this.form, this.users);
      });
  }

  initForm(formValue: any, users: any): void {
    const usersIds = formValue
      ? formValue.users.map((v, i) => { return (v ? users[i].id : null); }).filter((v) => { return v !== null; }) : [];
    this.form = this.formBuilder.group({
      dateFrom: this.getCurrentDateFrom(),
      dateTo: this.getCurrentDateTo(),
      users: new FormArray([]),
    });
    this.addCheckboxes(usersIds);
  }

  get usersFormArray() {
    return this.form.controls.users as FormArray;
  }

  getCurrentDateFrom(): Date {
    return this.calendarApi.view.currentStart;
  }

  getCurrentDateTo(): Date {
    return this.calendarApi.view.currentEnd;
  }

  getExternalEvent(event): any {
    return JSON.stringify(event);
  }

  getEventResource(event): any {
    return event.extendedProps.resource;
  }

  toggleQuickAdd(): void {
    if (this.isQuickAddOpen) {
      this.isQuickAddOpen = false;
    } else {
      this.isQuickAddOpen = true;
    }
  }

  onSearchChange(value: string): void {
    this.research = value;
    this.interventionService.getAffairsProductsToProgram(this.research);
  }

  onClearSearchClicked(): void {
    this.research = '';
  }

  onDateChange(result: Date): void {
    this.calendarApi.gotoDate(result);
    this.form.value.dateFrom = this.getCurrentDateFrom();
    this.form.value.dateTo = this.getCurrentDateTo();
    this.initCalendarEvents();
  }

  handleDateSelect(selectInfo: DateSelectArg): void {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
    const duration = this.dateUtilsService.calcTimeDifference(selectInfo.start, selectInfo.end);

    this.eventAdd = {
      startDate: selectInfo.start,
      duration: duration,
    };
    this.usersSelected = this.form.value.users.map((v, i) => { return (v ? this.users[i] : null); }).filter((v) => { return v !== null; });
    this.eventForm = this.eventService.getForm(null, this.eventAdd, this.usersSelect, this.usersSelected);
    const modalInstance = this.modalService.create({
      nzContent: EventModalAddEditComponent,
      nzComponentParams: {
        form: this.eventForm,
        users: this.usersSelect,
        usersSelected: this.usersSelected,
        eventsTypes: this.eventsTypes,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.initCalendarEvents();
    });
  }

  handleEventClick(clickInfo: EventClickArg): void {

    const resource = this.getEventResource(clickInfo.event);
    if (resource === this.RESOURCE_EVENT) {
      this.event = clickInfo.event.extendedProps.data;
      /**
       * lieu de création de la modal d'evenement
       */
      if (this.event.affairId) {
        this.eventForm = this.eventService.getFormForAffair(this.event.affair, this.event, this.usersSelect);
        const modalInstance = this.modalService.create({
          nzContent: EventAffairModalAddEditComponent,
          nzComponentParams: {
            form: this.eventForm,
            affair: this.event.affair,
            buildings: this.event.affair.buildings,
            interventions: this.event.affair.interventions,
            event: this.event,
            users: this.usersSelect,
            eventsTypes: this.eventsTypes,
            editFromCalendar: true,
          },
        });
        modalInstance.afterClose.subscribe(() => {
          this.initCalendarEvents();
        });

      } else {
        this.eventForm = this.eventService.getForm(this.event, null, this.usersSelect, this.usersSelected);
        const modalInstance = this.modalService.create({
          nzContent: EventModalAddEditComponent,
          nzComponentParams: {
            form: this.eventForm,
            users: this.usersSelect,
            event: this.event,
            usersSelected: this.usersSelected,
            eventsTypes: this.eventsTypes,
          },
        });
        modalInstance.afterClose.subscribe(() => {
          this.initCalendarEvents();
        });
      }
    } else {
      this.intervention = clickInfo.event.extendedProps.data;
      this.getEditForCalendar(this.intervention.id);
    }
  }

  onOpenModalIntervention(): void {
    // open modal intervention
    const modalInstance = this.modalService.create({
      nzContent: InterventionModalPreviewComponent,
      nzComponentParams: {
        intervention: this.intervention,
        users: this.usersSelect,

      },
      nzWidth: '90%',
    });

    /* modalInstance.afterClose.subscribe(() => {
      this.onModalCloseIntervention();
    }); */
  }

  handleEventResize(resizeInfo): void {
    this.updateDateResource(resizeInfo);
  }

  handleEventDrop(eventDropInfo): void {
    this.updateDateResource(eventDropInfo);
  }

  handleEventContent(event): void {
    const that = this;
    event.el.addEventListener('contextmenu', (mouseEvent: MouseEvent) => {
      that.onContextMenu(mouseEvent, event);
    });
    const element = event.el as HTMLElement;
    const tooltipInstance = tippy(element, {
      trigger: 'manual',
      allowHTML: true,
      placement: 'auto',
      theme: 'light',
    });
    element.addEventListener('mouseenter', () => {
      const tooltipContent = this.getTooltipContent(event.event);
      tooltipInstance.setContent(tooltipContent);
      tooltipInstance.show();
    });
    element.addEventListener('mouseleave', () => {
      tooltipInstance.hide();
    });
    element.addEventListener('click', () => {
      tooltipInstance.hide();
    });

    this.calendarApi.on('eventDragStop', (info) => {
      tooltipInstance.hide();
    });
    this.calendarApi.on('eventResizeStop', (info) => {
      tooltipInstance.hide();
    });
  }

  private getTooltipContent(event: any): string {
    let template = `<p class='p-2 bold'>${event.title}</p>`;
    const data = this.getExtendedPropsData(event);
    if (data?.description) {
      template += `<p class='p-2'>${data.description}</p>`;
    }
    if (data?.interventions?.length > 0) {
      template += '<ul>';
      data.interventions.forEach((intervention) => {
        template += `<li>${intervention.name} - ${intervention.startDateFormatted}</li>`;
      });
      template += '</ul>';
    }
    return template;
  }

  onContextMenu($event: MouseEvent, item: any): void {
    this.contextMenuService.show.next({
      event: $event,
      item: item.event,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  isResourceEvent = (item: any): boolean => {
    return this.getEventResource(item) === this.RESOURCE_EVENT;
  };

  isResourceIntervention = (item: any): boolean => {
    return this.getEventResource(item) === this.RESOURCE_INTERVENTION;
  };

  isWaitingConfirmation = (item: any): boolean => {
    const intervention = this.getExtendedPropsData(item);
    return intervention && intervention.interventionStatus
      ? intervention.interventionStatus.id === this.getSettingValue(this.interventionStatusEnum.INTERVENTION_STATUS_WAITING_CONFIRMATION) : false;
  };

  isConfirmed = (item: any): boolean => {
    const intervention = this.getExtendedPropsData(item);
    return intervention && intervention.interventionStatus
      ? intervention.interventionStatus.id === this.getSettingValue(this.interventionStatusEnum.INTERVENTION_STATUS_CONFIRMED) : false;
  };

  handleEventReceive(data): void {
    const duration = this.dateUtilsService.calcTimeDifference(data.event.start, data.event.end);
    this.interventionAdd = {
      startDate: data.event.start,
      duration: duration,
      usersSelected: this.form.value.users.map((v, i) => { return (v ? this.users[i] : null); }).filter((v) => { return v !== null; }),
    };

    this.affairProduct = this.getExtendedPropsData(data.event);
    /**
     * lieu de création de la modal
     */
    const modalInstance = this.modalService.create({
      nzContent: InterventionModalCreateComponent,
      nzComponentParams: {
        interventionAdd: this.interventionAdd,
        affairProduct: this.affairProduct,
        users: this.usersSelect,
        conformities: this.conformities,
        indicatorsInterventionsTypes: this.indicatorsInterventionsTypes,
      },
      nzStyle: { top: 0 },
      nzWidth: '99%',
    });
    modalInstance.afterClose.subscribe(() => {
      this.onModalCloseIntervention();
      this.interventionService.getAffairsProductsToProgram(this.research);
    });
    data.event.remove();
  }

  onModalCloseEvent(): void {
    this.event = null;
    this.initCalendarEvents();
  }

  onModalCloseIntervention(): void {
    this.intervention = null;
    this.interventionForm = null;
    this.initCalendarEvents();
  }

  onModalDeprogramClose(): void {
    this.intervention = null;
    this.initCalendarEvents();
  }

  onConfirmIntervention(event): void {
    this.intervention = this.getExtendedPropsData(event);
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut confirmé.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.confirm(that.intervention.id).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.initCalendarEvents();
          },
        );
      }
    });
  }

  onShiftClick(event): void {
    const usersSelect = this.userService.formatToSelectMultiple(this.users);
    this.intervention = this.getExtendedPropsData(event);
    this.shiftForm = this.interventionService.initShiftForm(this.intervention, usersSelect);
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditShiftModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.shiftForm,
        users: usersSelect,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.initCalendarEvents();
    });
  }

  onDeprogramClick(event): void {
    this.intervention = this.getExtendedPropsData(event);
    this.deprogramForm = this.interventionService.initDeprogramForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditDeprogramModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.deprogramForm,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.initCalendarEvents();
    });
  }

  onDeleteEvent(event): void {
    const eventData = this.getExtendedPropsData(event);
    const that = this;
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'L\'événement sera supprimé',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.eventService.delete(eventData.id).pipe(
          catchError((error) => {
            this.swalService.showSwalError(error);
            return throwError(error);
          }),
        )
          .subscribe(
            (success) => {
              this.notifService.showSuccessNotif(success);
              this.initCalendarEvents();
            },
          );
      }
    });
  }

  getAffairProducts(affairProductsToProgram, interventionAffairProducts): any {
    let affairProducts = [];
    affairProducts = interventionAffairProducts;
    affairProductsToProgram.forEach((affairProduct) => {
      const found = interventionAffairProducts.find((x) => { return x.id === affairProduct.id; });
      if (!found) {
        affairProducts.push(affairProduct);
      }
    });
    return affairProducts;
  }

  private addCheckboxes(usersIds): void {
    this.users.forEach((user) => {
      let checked = false;
      if (usersIds.length > 0) {
        checked = usersIds.map((x) => { return x; }).includes(user.id);
      }
      this.usersFormArray.push(new FormControl(checked));
    });
  }

  private updateDateResource(eventInfo): void {
    const { resource } = eventInfo.event.extendedProps;
    if (resource === this.RESOURCE_EVENT) {
      this.event = eventInfo.event.extendedProps.data;
      this.eventService.updateDate(this.event.id, eventInfo.event.start, eventInfo.event.end);
    } else {
      this.intervention = eventInfo.event.extendedProps.data;
      if (this.intervention.isReasonNeeded) {
        const usersSelect = this.userService.formatToSelectMultiple(this.users);
        this.shiftForm = this.interventionService.initShiftForm(this.intervention, usersSelect, eventInfo.event.start, eventInfo.event.end);
        const modalInstance = this.modalService.create({
          nzContent: InterventionEditShiftModalComponent,
          nzComponentParams: {
            intervention: this.intervention,
            shiftOrigins: this.shiftOrigins,
            form: this.shiftForm,
            users: usersSelect,
          },
        });
        modalInstance.afterClose.subscribe(() => {
          this.initCalendarEvents();
        });
      } else {
        this.interventionService.updateDate(this.intervention.id, eventInfo.event.start, eventInfo.event.end);
      }
    }
  }

  private initCalendarEvents(dontShowSpinner?: boolean): void {
    if (!dontShowSpinner) {
      this.spinner.show('load-events');
    }
    this.interventionService.getForCalendar(this.form, this.users);
    this.eventService.getForCalendar(this.form, this.users);
  }

  private getExtendedPropsData(event: any): any {
    return event.extendedProps.data;
  }

  getSettingValue(key): void {
    return this.settings.find((x) => { return x.key === key; }).value;
  }

  public getEditForCalendar(id: number): void {
    this.interventionService.getEditForCalendar(id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.intervention = data.intervention;
          this.contacts = data.contacts;
          this.worksSupervisors = data.worksSupervisors;
          this.housings = data.housings;
          this.buildings = data.buildings;
          this.documentsCustomersAttached = data.documentsCustomersAttached;
          // Init form
          this.interventionForm = this.interventionService.getEditForm(this.intervention, this.usersSelect, this.contacts);
          this.interventionService.initHousingsCheckboxes(this.interventionForm, this.housings, this.intervention.housings);
          this.interventionService.initAffairProductsCheckboxes(this.interventionForm, this.affairProductsToProgram, this.intervention.affairProducts, this.intervention.affair.affairProducts);
          // Filter & traitements
          if (this.buildings.length > 0 && this.intervention.buildings.length > 0) {
            this.interventionService.filterHousings(this.intervention.buildings, this.housings);
          }
          this.isSelectsInit = true;
          this.worksSupervisorsSelect = data.worksSupervisors;
          this.affairProducts = this.getAffairProducts(this.affairProductsToProgram, this.intervention.affairProducts);
          this.onOpenModalIntervention();
        },
      );
  }

  private initSubscriptions() {
    this.initUsers();
    this.userService.getUsersSelect()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.usersSelect = data;
        },
      );
    this.regionService.getAllForCalendar().subscribe({
      next: (regions) => {
        this.regions = regions;
      },
    });
    this.eventTypeService.getForSelect()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.eventsTypes = data;
        },
      );
    zip(
      this.eventService.events$,
      this.interventionService.interventionsCalendar$,
    )
      .subscribe(([events, interventions]) => {
        this.events = [];
        if (events) {
          this.eventsData = events;
          for (let i = 0; i < this.eventsData.length; i++) {
            this.events.push({
              title: this.eventsData[i].name,
              start: new Date(this.eventsData[i].start),
              end: new Date(this.eventsData[i].end),
              data: this.eventsData[i].data,
              description: this.eventsData[i].description,
              backgroundColor: this.eventsData[i].backgroundColor,
              textColor: this.getTextColor(this.eventsData[i].backgroundColor),
              resource: 'event',
            });
          }
        }
        if (interventions) {
          this.interventionsData = interventions;
          for (let i = 0; i < this.interventionsData.length; i++) {
            this.events.push({
              title: `${this.interventionsData[i].name} - ${this.interventionsData[i].locality}`,
              start: new Date(this.interventionsData[i].start),
              end: new Date(this.interventionsData[i].end),
              data: this.interventionsData[i].data,
              description: this.interventionsData[i].description,
              backgroundColor: this.interventionsData[i].backgroundColor,
              textColor: this.interventionsData[i].backgroundColor ? this.getTextColor(this.interventionsData[i].backgroundColor) : '#FFFFFF',
              resource: 'intervention',
            });
          }
        }
        this.calendarOptions.events = this.events;
        this.spinner.hide('load-events');
      });
    this.interventionsCalendarSelects$ = this.interventionService.interventionsCalendarSelects$.subscribe(
      (data) => {
        if (data) {
          this.conformities = data.conformities;
          this.indicatorsInterventionsTypes = data.indicatorsInterventionsTypes;
          this.shiftOrigins = data.shiftOrigins;
        }
      },
    );
    this.settingService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          if (data) {
            this.settings = data;
          }
        },
      );
  }

  public onUnselectClicked(): void {
    // eslint-disable-next-line array-callback-return
    this.selectedRegionsIds = [];
    this.initUsers();
    this.usersFormArray.controls.map((control) => {
      control.patchValue(false);
    });
  }

  public groupEventsByDay(events) {
    const groupedEvents = {};

    events.forEach((event) => {
      const start = new Date(event.start); // Convertit la date en objet Date

      // Formate la date au format ISO8601 pour assurer un tri correct
      const isoDate = start.toISOString().split('T')[0];

      if (!groupedEvents[isoDate]) {
        groupedEvents[isoDate] = [];
      }

      groupedEvents[isoDate].push(event);
    });

    // Trier les clés (dates) au format ISO8601
    const sortedDates = Object.keys(groupedEvents)
      .sort();

    // Créer un nouvel objet avec les clés triées
    const sortedGroupedEvents = {};
    sortedDates.forEach((isoDate) => {
      const date = new Date(isoDate);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
      sortedGroupedEvents[formattedDate] = groupedEvents[isoDate];
    });

    return sortedGroupedEvents;
  }

  onOpenMapsDirection(user: User): void {
    // eslint-disable-next-line array-callback-return
    const userEvents = this.events.filter((event) => {
      if (event.data.usersTechnicians) {
        return event.data.usersTechnicians.filter((x) => { return x.id === user.id; });
      }
    });
    const groupedEvents = this.groupEventsByDay(userEvents);

    this.modalService.create({
      nzContent: CalendarSelectMapsDayModalComponent,
      nzComponentParams: {
        groupedEvents: groupedEvents,
        user: user,
      },
    });
  }

  public getTextColor(hex): string {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }

  onSelectRegionClicked(region: Region): void {
    const index = this.selectedRegionsIds.indexOf(region.id);

    if (index === -1) {
    // L'élément n'est pas déjà sélectionné, l'ajouter
      this.selectedRegionsIds.push(region.id);
    } else {
    // L'élément est déjà sélectionné, le retirer
      this.selectedRegionsIds.splice(index, 1);
    }
    this.initUsers();
  }

  isRegionSelected(region: Region): boolean {
    return this.selectedRegionsIds.includes(region.id);
  }

  private initUsers() {
    this.userService.getAllTechniciansWithFilters({
      regionsIds: this.selectedRegionsIds,
    })
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.users = data;
          const sessionFormValue = this.storageService.retrieve(this.storageKey);
          this.initForm(sessionFormValue, this.users);
          this.onChanges();
          this.initCalendarEvents();
        },
      );

  }

}
