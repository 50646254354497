import { Component, Input, OnInit } from '@angular/core';
import { AffairService, IncomingCallSheetParameters } from '@libs/services/src';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-incoming-call-sheet-affairs',
  templateUrl: './incoming-call-sheet-affairs.component.html',
  styleUrls: ['./incoming-call-sheet-affairs.component.scss'],
})
export class IncomingCallSheetAffairsComponent implements OnInit {

  @Input() contact: any;
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public total: number = 1;
  public loading: boolean;
  private expandSet = new Set<string>();
  public inputSearchValue = '';
  public incomingCallSheetParams: IncomingCallSheetParameters = {
    contactId: null,
    contactType: null,
    contactEmail: null,
    size: 10,
    search: '',
    page: 1,
  };

  public affairsList = [];

  constructor(
    private affairService: AffairService,
  ) { }

  ngOnInit(): void {
    this.initAffairsList();
  }

  expandSetHas(id: string): boolean {
    return this.expandSet.has(id);
  }

  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.initAffairsList();
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.initAffairsList();
  }

  onRedirectAffairClicked(affairId: number): void {
    window.open(`affairs/${affairId}/show`);
  }

  onRedirectInterventionClicked(interventionId: number): void {
    window.open(`interventions/${interventionId}/edit`);
  }

  private initAffairsList(): void {
    this.incomingCallSheetParams = {
      contactId: this.contact.id,
      contactType: this.contact.type,
      contactEmail: this.contact.email,
      size: this.pageSize,
      page: this.pageIndex,
      search: this.inputSearchValue,
    };
    this.loading = true;
    this.affairService.getForIncomingCallSheetList(this.incomingCallSheetParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      ).subscribe(
        (response) => {
          this.affairsList = response.data;
          this.total = response.meta.total;
        },
      );
  }

}
