import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DashboardTileKeyEnum } from '@libs/enum/src';
import { DashboardTile, Tag } from '@libs/models/src';
import { DashboardTileService, EventService, InterventionService, TagService, UnsubscribeOnDestroy } from '@libs/services/src';
import { AffairDashboardService } from '@libs/services/src/lib/affairs/affair-dashboard.service';
import { tabSuffix } from '_config/tab-suffix';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-overview-items-list',
  templateUrl: './overview-items-list.component.html',
  styleUrls: ['./overview-items-list.component.scss'],
})
export class OverviewItemsListComponent extends UnsubscribeOnDestroy implements OnInit {

  public dashboardTileId: number;
  public dashboardTile: DashboardTile;
  public form: FormGroup;
  public displayAll = false;
  public data = [];
  public dataFiltered = [];
  public totalData = [];
  public affairId: number = null;
  public selectedItem = null;
  public loadingName = 'overview-intervention';
  public inputSearchValue = '';
  public searchableFields: string[];
  public tags: Tag[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private interventionService: InterventionService,
    private dashboardTileService: DashboardTileService,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private eventService: EventService,
    private affairDashboardService: AffairDashboardService,
    private titleService: Title,
    private tagService: TagService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.searchableFields = ['affairMlRef', 'name'];
    this.activatedRoute.params.subscribe((params) => {
      this.dashboardTileId = params.id;
      this.dashboardTileService.get(this.dashboardTileId).subscribe((dashboardTile: DashboardTile) => {
        this.dashboardTile = dashboardTile;
        this.initForm();
        this.titleService.setTitle(`${this.dashboardTile.name}${tabSuffix}`);
        this.initDatas();
        this.tagService.getAll().subscribe((tags) => {
          this.tags = tags;
        });
      });
    });
  }

  onSelectInterventionClicked(item: any): void {
    this.selectedItem = item;
    if (this.dashboardTile.entity === 'affair') {
      this.affairId = item.id;
    } else {
      this.affairId = item.affairId;
    }
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      search: [''],
      userId: null,
      selectedMarketsTypes: [this.dashboardTile.marketsTypes.map((x) => { return x.id; })],
    });
  }

  onFilteredData(data: any): void {
    this.dataFiltered = data;
  }

  onDisplayAllChanged(): void {
    this.displayAll = !this.displayAll;
  }

  initDatas(affairId?: number): void {
    this.spinnerService.show(this.loadingName);
    this.affairId = null;
    this.selectedItem = null;
    const observable = this.getRequestByDashboardTileKey(this.dashboardTile.key);
    if (observable) {
      observable
        .pipe(
          takeUntil(this.isDestroyed$),
          finalize(() => {
            this.spinnerService.hide(this.loadingName);
          }),
        )
        .subscribe((data) => {
          this.data = data.data;
          this.totalData = data.totalData;
          this.dataFiltered = this.data;
          if (this.data.length > 0) {
            if (this.dashboardTile.entity === 'affair') {
              if (affairId) {
                for (let i = 0; i < this.data.length; i++) {
                  if (this.data[i].id !== affairId) {
                    this.selectedItem = this.data[i];
                    this.affairId = this.data[i].id;
                    break;
                  }
                }
              } else {
                this.selectedItem = this.data[0];
                this.affairId = this.data[0].id;
              }
            } else if (affairId) {
              for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].affairId !== affairId) {
                  this.selectedItem = this.data[i];
                  this.affairId = this.data[i].affairId;
                  break;
                }
              }
            } else {
              this.selectedItem = this.data[0];
              this.affairId = this.data[0].affairId;
            }
          }
        });
    }
  }

  getRequestByDashboardTileKey(key: string): Observable<any> {
    let observable = null;
    switch (key) {
      case DashboardTileKeyEnum.WAITING_XML_KEY:
        observable = this.interventionService.initXMLNeededList(this.form);
        break;
      case DashboardTileKeyEnum.WAITING_CONFIRMATION_KEY:
        observable = this.interventionService.initUnconfirmedList(this.form);
        break;
      case DashboardTileKeyEnum.WAITING_PROGRAMMATION_KEY:
        observable = this.interventionService.initWaitingProgrammingList(this.form);
        break;
      case DashboardTileKeyEnum.WAITING_PAYMENT_KEY:
        observable = this.interventionService.initWaitingPaymentList(this.form);
        break;
      case DashboardTileKeyEnum.INVOICE_TODO_MI_KEY:
        observable = this.interventionService.initNotBilledList(this.form);
        break;
      case DashboardTileKeyEnum.INVOICE_TODO_CO_KEY:
        observable = this.interventionService.initNotBilledList(this.form);
        break;
      case DashboardTileKeyEnum.INVOICE_TODO_INSP_KEY:
        observable = this.interventionService.initNotBilledList(this.form);
        break;
      case DashboardTileKeyEnum.SEND_TODO_KEY:
        observable = this.interventionService.initNotSentList(this.form);
        break;
      case DashboardTileKeyEnum.SEND_TODO_CO_KEY:
        observable = this.interventionService.initNotSentList(this.form);
        break;
      case DashboardTileKeyEnum.WAITING_MANUAL_SEND_KEY:
        observable = this.interventionService.initWaitingDocumentSendManualList(this.form);
        break;
      case DashboardTileKeyEnum.EVENT_TO_CLOSE:
        observable = this.eventService.notClosedList(this.form);
        break;
      case DashboardTileKeyEnum.EVENT_WAITING_INFORMATION:
        observable = this.eventService.waitingInformationList(this.form);
        break;
      case DashboardTileKeyEnum.AFFAIR_WITHOUT_PURCHASE_ORDER_KEY:
        observable = this.affairDashboardService.withoutPurchaseOrdersList(this.form);
        break;
      case DashboardTileKeyEnum.AFFAIR_WITH_RECALL_DATE_KEY:
        observable = this.affairDashboardService.withRecallDateList(this.form);
        break;
      case DashboardTileKeyEnum.AFFAIR_WITH_PROBLEMS_KEY:
        observable = this.affairDashboardService.withProblemsList(this.form);
        break;
      default:
        break;
    }
    return observable;
  }

}
