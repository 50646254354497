import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Period } from '../../types/period.type';

@Component({
  selector: 'eros-front-inspections-period-edit-modal',
  templateUrl: './inspections-period-edit-modal.component.html',
  styleUrls: ['./inspections-period-edit-modal.component.scss'],
})
export class InspectionsPeriodEditModalComponent implements OnInit {

  @Input() period: Period;
  public name: string;
  public dates: Date[];
  public satisfactionRate: number;

  constructor(private modal: NzModalRef) { }

  ngOnInit(): void {
    const clonedPeriod: Period = JSON.parse(JSON.stringify(this.period));
    this.name = clonedPeriod.name;
    this.dates = [clonedPeriod.from, clonedPeriod.to];
    this.satisfactionRate = clonedPeriod.sat_rate;
  }

  canSave(): boolean {
    return this.name != null && this.dates != null && this.dates.length === 2 && this.satisfactionRate != null;
  }

  onClose(): void {
    this.modal.destroy();
  }

  onSave(): void {
    if (this.canSave()) {
      this.modal.triggerOk();
    }
  }

}
