import { ConformityService, ContactService, IndicatorInterventionTypeService, NotifService, SwalService, TagService, UnsubscribeOnDestroy } from '@eros-front/services';
import { FormGroup, FormBuilder, Validators, FormArray, ValidatorFn, FormControl } from '@angular/forms';
import { Subscription, throwError, zip } from 'rxjs';
import { InterventionService } from 'libs/services/src/lib/intervention.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Intervention, Tag } from '@libs/models/src';
import { tabSuffix } from '_config/tab-suffix';
import { Title } from '@angular/platform-browser';
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-intervention-edit',
  templateUrl: './intervention-edit.component.html',
  styleUrls: ['./intervention-edit.component.scss'],
})
export class InterventionEditComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public intervention: Intervention = null;
  public interventionId: number;
  public appKey = environment.appKey;
  public tags: Tag[] = [];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private interventionService: InterventionService,
    private tagService: TagService,
  ) {
    super();
  }

  onInterventionUpdated(intervention: Intervention): void {
    this.intervention = intervention;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (!Number.isNaN(id)) {
        this.interventionId = id;
        this.initIntervention();
        this.tagService.getAll().subscribe((tags) => {
          this.tags = tags;
        });
      }
    });
  }

  onRefresh(): void {
    this.initIntervention();
  }

  private initIntervention() {
    this.interventionService.get(this.interventionId)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.intervention = data;
          this.titleService.setTitle(`Gestion intervention : ${data.name}${tabSuffix}`);
        },
      );
  }

}
