import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@libs/shared/src';
import { AffairShowInterventionsComponent } from '../_components/affair-show-interventions/affair-show-interventions.component';
import { AffairShowProgramComponent } from '../_components/affair-show-program/affair-show-program.component';
import { AffairShowEventsComponent } from '../_components/affair-show-events/affair-show-events.component';
import { EventModalAddEditComponent } from '../_components/events/event-modal-add-edit/event-modal-add-edit.component';
import { EventsIndexComponent } from '../_components/events/events-index/events-index.component';
import { EventAffairModalAddEditComponent } from '../_components/events/event-affair-modal-add-edit/event-affair-modal-add-edit.component';
import { EventsDatatableFiltersComponent } from '../_components/events/events-datatable-filters/events-datatable-filters.component';
import { InterventionCreateComponent } from '../_components/interventions/intervention-create/intervention-create.component';
import { InterventionEditComponent } from '../_components/interventions/intervention-edit/intervention-edit.component';
import { InterventionEditHeadbandComponent } from '../_components/interventions/intervention-edit/intervention-edit-headband/intervention-edit-headband.component';
import { InterventionEditHistoricalComponent } from '../_components/interventions/intervention-edit/intervention-edit-historical/intervention-edit-historical.component';
import { InterventionEditInformationsComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-informations.component';
import { InterventionUpdateStatusModalComponent } from '../_components/interventions/intervention-edit/intervention-update-status-modal/intervention-update-status-modal.component';
import { InterventionHousingsComponent } from '../_components/interventions/intervention-housings/intervention-housings.component';
import { InterventionIndicatorsTypesComponent } from '../_components/interventions/intervention-indicators-types/intervention-indicators-types.component';
import { InterventionInformationsComponent } from '../_components/interventions/intervention-informations/intervention-informations.component';
import { InterventionModalCreateComponent } from '../_components/interventions/intervention-modal-create/intervention-modal-create.component';
import { InterventionModalEditComponent } from '../_components/interventions/intervention-modal-edit/intervention-modal-edit.component';
import { InterventionModalPreviewComponent } from '../_components/interventions/intervention-modal-preview/intervention-modal-preview.component';
import { InterventionProductsComponent } from '../_components/interventions/intervention-products/intervention-products.component';
import { InterventionTimeChoiceComponent } from '../_components/interventions/intervention-time-choice/intervention-time-choice.component';
import { InterventionsDashbordModalComponent } from '../_components/interventions/interventions-dashbord-modal/interventions-dashbord-modal.component';
import { InterventionsDatatableFiltersComponent } from '../_components/interventions/interventions-datatable-filters/interventions-datatable-filters.component';
import { InterventionsIndexComponent } from '../_components/interventions/interventions-index/interventions-index.component';
import { InterventionsPreparationComponent } from '../_components/interventions/interventions-preparation/interventions-preparation.component';
import { InterventionsTabsComponent } from '../_components/interventions/interventions-tabs/interventions-tabs.component';
import { InterventionsVolumeConfirmationComponent } from '../_components/interventions/interventions-volume-confirmation/interventions-volume-confirmation.component';
import { InterventionEditCancelModalComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-cancel-modal/intervention-edit-cancel-modal.component';
import { InterventionEditDeprogramModalComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-deprogram-modal/intervention-edit-deprogram-modal.component';
import { InterventionEditPermeabilityComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-permeability/intervention-edit-permeability.component';
import { InterventionEditProgramModalComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-program-modal/intervention-edit-program-modal.component';
import { InterventionEditShiftModalComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-shift-modal/intervention-edit-shift-modal.component';
import { InterventionsPreparationFiltersComponent } from '../_components/interventions/interventions-preparation/interventions-preparation-filters/interventions-preparation-filters.component';
import { CalendarComponent } from '../_components/calendar/calendar.component';
import { CalendarSelectMapsDayModalComponent } from '../_components/calendar/calendar-select-maps-day-modal/calendar-select-maps-day-modal.component';
import { InterventionsListComponent } from '../_components/interventions/interventions-list/interventions-list.component';
import { AffairProductsToProgramListComponent } from '../_components/interventions/interventions-dashboard/affair-products-to-program-list/affair-products-to-program-list.component';
import { InterventionsCompletedListComponent } from '../_components/interventions/interventions-dashboard/interventions-completed-list/interventions-completed-list.component';
import { InterventionsNotBilledListComponent } from '../_components/interventions/interventions-dashboard/interventions-not-billed-list/interventions-not-billed-list.component';
import { InterventionsUncompletedListComponent } from '../_components/interventions/interventions-dashboard/interventions-uncompleted-list/interventions-uncompleted-list.component';
import { InterventionsUnconfirmedListComponent } from '../_components/interventions/interventions-dashboard/interventions-unconfirmed-list/interventions-unconfirmed-list.component';
import { InterventionsWaitingDocumentCustomerListComponent } from '../_components/interventions/interventions-dashboard/interventions-waiting-document-customer-list/interventions-waiting-document-customer-list.component';
import { InterventionsWaitingDocumentDeliveredListComponent } from '../_components/interventions/interventions-dashboard/interventions-waiting-document-delivered-list/interventions-waiting-document-delivered-list.component';
import { InterventionsWaitingPaymentListComponent } from '../_components/interventions/interventions-dashboard/interventions-waiting-payment-list/interventions-waiting-payment-list.component';
import { InterventionsWaitingSendDocumentManualComponent } from '../_components/interventions/interventions-dashboard/interventions-waiting-send-document-manual/interventions-waiting-send-document-manual.component';
import { InterventionsXmlNeededListComponent } from '../_components/interventions/interventions-dashboard/interventions-xml-needed-list/interventions-xml-needed-list.component';
import { InterventionsWaitingProgrammingListComponent } from '../_components/interventions/interventions-dashboard/interventions-waiting-programming-list/interventions-waiting-programming-list.component';
import { EventsCompletedListComponent } from '../_components/events/events-dashboard/events-completed-list/events-completed-list.component';
import { EventsDashboardModalComponent } from '../_components/events/events-dashboard/events-dashboard-modal/events-dashboard-modal.component';
import { EventsNotClosedListComponent } from '../_components/events/events-dashboard/events-not-closed-list/events-not-closed-list.component';
import { EventsNotCompletedListComponent } from '../_components/events/events-dashboard/events-not-completed-list/events-not-completed-list.component';
import { EventsWaitingInformationListComponent } from '../_components/events/events-dashboard/events-waiting-information-list/events-waiting-information-list.component';
import { InterventionEditProductsComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-products/intervention-edit-products.component';
import { InterventionEditStandardsResponsesErrorsComponent } from '../_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-standards-responses-errors/intervention-edit-standards-responses-errors.component';
import { InskyModule } from '@libs/insky/src';
import { AffairsModule } from '@libs/affairs/src';
import { InterventionShowDocumentsCustomersComponent } from '../_components/interventions/intervention-show/intervention-show-documents-customers/intervention-show-documents-customers.component';
import { InterventionShowDocumentsDeliveredComponent } from '../_components/interventions/intervention-show/intervention-show-documents-delivered/intervention-show-documents-delivered.component';
import { InterventionShowDocumentsComponent } from '../_components/interventions/intervention-show/intervention-show-modules/intervention-show-documents/intervention-show-documents.component';
import { InterventionShowDocumentFiltersComponent } from '../_components/interventions/intervention-show/intervention-show-modules/intervention-show-document-filters/intervention-show-document-filters.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    InskyModule,
    AffairsModule
  ],
  declarations: [
    //Affair
    AffairShowInterventionsComponent,
    AffairShowProgramComponent,
    AffairShowEventsComponent,
    //Events
    EventAffairModalAddEditComponent,
    EventModalAddEditComponent,
    EventsIndexComponent,
    EventsDatatableFiltersComponent,
    //Interventions
    InterventionCreateComponent,
    InterventionEditComponent,
    InterventionEditHeadbandComponent,
    InterventionEditHistoricalComponent,
    InterventionEditInformationsComponent,
    InterventionEditCancelModalComponent,
    InterventionEditDeprogramModalComponent,
    InterventionEditProductsComponent,
    InterventionEditPermeabilityComponent,
    InterventionEditProgramModalComponent,
    InterventionEditShiftModalComponent,
    InterventionEditStandardsResponsesErrorsComponent,
    InterventionUpdateStatusModalComponent,
    InterventionHousingsComponent,
    InterventionIndicatorsTypesComponent,
    InterventionInformationsComponent,
    InterventionModalCreateComponent,
    InterventionModalEditComponent,
    InterventionModalPreviewComponent,
    InterventionProductsComponent,
    InterventionTimeChoiceComponent,
    InterventionsDashbordModalComponent,
    InterventionsDatatableFiltersComponent,
    InterventionsIndexComponent,
    InterventionsPreparationComponent,
    InterventionsPreparationFiltersComponent,
    InterventionsTabsComponent,
    InterventionsVolumeConfirmationComponent,
    InterventionsListComponent,
    InterventionShowDocumentsCustomersComponent,
    InterventionShowDocumentsDeliveredComponent,
    InterventionShowDocumentsComponent,
    InterventionShowDocumentFiltersComponent,
    //Dashboard
    AffairProductsToProgramListComponent,
    InterventionsCompletedListComponent,
    InterventionsNotBilledListComponent,
    InterventionsUncompletedListComponent,
    InterventionsUnconfirmedListComponent,
    InterventionsWaitingDocumentCustomerListComponent,
    InterventionsWaitingDocumentDeliveredListComponent,
    InterventionsWaitingPaymentListComponent,
    InterventionsWaitingSendDocumentManualComponent,
    InterventionsXmlNeededListComponent,
    InterventionsWaitingProgrammingListComponent,
    EventsCompletedListComponent,
    EventsDashboardModalComponent,
    EventsNotClosedListComponent,
    EventsNotCompletedListComponent,
    EventsWaitingInformationListComponent,
    //Calendar
    CalendarComponent,
    CalendarSelectMapsDayModalComponent,
  ],
  exports: [
    AffairShowInterventionsComponent,
    AffairShowProgramComponent,
    //Events
    EventAffairModalAddEditComponent,
    //Dashboard
    AffairProductsToProgramListComponent,
    InterventionsCompletedListComponent,
    InterventionsNotBilledListComponent,
    InterventionsUncompletedListComponent,
    InterventionsUnconfirmedListComponent,
    InterventionsWaitingDocumentCustomerListComponent,
    InterventionsWaitingDocumentDeliveredListComponent,
    InterventionsWaitingPaymentListComponent,
    InterventionsWaitingSendDocumentManualComponent,
    InterventionsXmlNeededListComponent,
    InterventionsWaitingProgrammingListComponent,
    EventsCompletedListComponent,
    EventsDashboardModalComponent,
    EventsNotClosedListComponent,
    EventsNotCompletedListComponent,
    EventsWaitingInformationListComponent,
    //Calendar
    CalendarComponent
  ]
})
export class InterventionsModule {
}
