import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Modules
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgSelectModule } from '@ng-select/ng-select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ComponentsModule } from '../../../components/src/lib/components.module';
// Services
import { PermissionService } from '../../../services/src/lib/permission.service';
import { RoleService } from '../../../services/src/lib/role.service';
import { pgCardModule } from '../../../../@pages/components/card/card.module';
import { UserService } from '../../../services/src/lib/user.service';
import { UsersIndexComponent } from './users-index/users-index.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { RolesIndexComponent } from './roles-index/roles-index.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { PermissionsIndexComponent } from './permissions-index/permissions-index.component';
import { PermissionCreateEditComponent } from './permission-create-edit/permission-create-edit.component';
import { RoleCreateEditComponent } from './role-create-edit/role-create-edit.component';
import { DiagnosisEquipmentService } from '../_services/diagnosis-equipment.service';
import { DiagnosisEquipmentsIndexComponent } from '../_components/diagnosis-equipments/diagnosis-equipments-index/diagnosis-equipments-index.component';
import { DiagnosisEquipmentModalComponent } from '../_components/diagnosis-equipments/diagnosis-equipment-modal/diagnosis-equipment-modal.component';
import { TechnicalSheetModalComponent } from '../_components/technical-sheets/technical-sheet-modal/technical-sheet-modal.component';
import { TechnicalSheetsIndexComponent } from '../_components/technical-sheets/technical-sheets-index/technical-sheets-index.component';
import { TechnicalSheetService } from '../_services/technical-sheet.service';

@NgModule({
  imports: [
    CommonModule,
    pgCardModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    ColorPickerModule,
    NzTableModule,
    NzDatePickerModule,
    NzModalModule,
    NzUploadModule,
    NzButtonModule,
    NgSelectModule,
    NzToolTipModule,
  ],
  declarations: [
    UsersIndexComponent,
    UserCreateComponent,
    RolesIndexComponent,
    UserEditComponent,
    PermissionsIndexComponent,
    PermissionCreateEditComponent,
    RoleCreateEditComponent,
    DiagnosisEquipmentsIndexComponent,
    DiagnosisEquipmentModalComponent,
    TechnicalSheetModalComponent,
    TechnicalSheetsIndexComponent,
  ],
  providers: [
    UserService,
    RoleService,
    PermissionService,
    DiagnosisEquipmentService,
    TechnicalSheetService,
  ],
})
export class UsersModule {}
