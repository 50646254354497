import { catchError, finalize } from 'rxjs/operators';
import { Affair, AffairProduct, Building, Contact, ErosResponse, Intervention, InterventionListResponse, SelectModel, SubmitButton, User } from '@eros-front/models';
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import moment from 'moment';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { DateUtilsService } from './utilities/date-utils.service';
import { FormValidatorsService } from './utilities/form-validators.service';
import { AffairContactService } from './affair-contact.service';

export interface InterventionDatatableParameters {
    selectedAffair?: number;
    selectedMarketsTypes: string;
    selectedAgencies: string;
    selectedAssistants: string;
    selectedTechnicians: string;
    selectedConformities: string;
    selectedInterventionsStatuses: string;
    selectedProducts: string;
    selectedProductsStatuses: string;
    selectedDocumentsCustomersMissing: string;
    selectedAffairsGoals: string;
    selectedAffairsStatuses: string;
    selectedAffairsMonitoringTypes: string;
    selectedTags: string;
    affairMonitoringTypeLevel: number;
    isAfterIntervention: boolean;
    dateInterventionMin: string;
    dateInterventionMax: string;
    affairCreatedAtMin: string;
    affairCreatedAtMax: string;
    agenciesMarketsTypes: string;
    agenciesCustomersTypes: string;
    agenciesIndicatorsCustomers: string;
    createdAtMin: string;
    createdAtMax: string;
    isNotBilled: number;
    isWaitingCheck: number;
    isDeliveredHouse: number;
    isOffice: number;
    withDocumentDeliveredStandardsResponsesErrors: number;
    withDocumentCustomerStandardsResponsesErrors: number;
    plansAttached: number;
    page: number;
    size: number;
    search: string;
    addressSearch: string;
    sortField: string,
    sortOrder: string,
    individualSearch: any;
}

export interface InterventionNameParameters {
    affair: Affair;
    affairProductsSelected?: any;
    buildings?: Building[];
    isDeliveredHouse?: boolean;
    isTimeSlot?: boolean;
}

@Injectable()
export class InterventionService {

  private route = '/interventions';
  private redirectUrl = '/admin/interventions/index';
  private editUrl = '/interventions/$id/edit';
  private affairShowUrl = '/affairs/$id/show';
  public interventions$ = new BehaviorSubject<any>(undefined);
  public interventionsSelects$ = new BehaviorSubject<any>(undefined);
  public interventionsEditSelects$ = new BehaviorSubject<any>(undefined);
  public interventionsCalendarSelects$ = new BehaviorSubject<any>(undefined);
  public interventionsCreateSelects$ = new BehaviorSubject<any>(undefined);
  public interventionsCalendar$ = new BehaviorSubject<any>(undefined);
  public affairsProductsToProgram$ = new BehaviorSubject<any>(undefined);
  public generatedName$ = new BehaviorSubject<any>(undefined);
  public generatedDescription$ = new BehaviorSubject<any>(undefined);
  public submitButton: SubmitButton;
  public submitButtonShift: SubmitButton;
  public modalProgram: ModalDirective;
  public submitButtonProgram: SubmitButton;
  public modalDeprogram: ModalDirective;
  public submitButtonDeprogram: SubmitButton;
  public submitButtonCancel: SubmitButton;
  public modalEdit: ModalDirective;
  public modalAdd: ModalDirective;
  public refreshIntervention$ = new BehaviorSubject<any>(undefined);
  public documentsCustomersAttached$ = new BehaviorSubject<any>(undefined);
  public statsCompleted$ = new BehaviorSubject<any>(undefined);

  constructor(
    private http: HttpClient,
    private notifService: NotifService,
    private submitButtonService: SubmitButtonService,
    private swalService: SwalService,
    private router: Router,
    private apiService: ApiService,
        private formFormatterService: FormFormatterService,
        private dateUtilsService: DateUtilsService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private formValidatorsService: FormValidatorsService,
        private affairContactService: AffairContactService,
  ) {
  }

  public initList(form: FormGroup): Observable<InterventionListResponse> {
    return this.apiService.post(`${this.route}/list`, this.formToDatatableParameters(form));
  }

  public get(id: number): Observable<Intervention> {
    return this.apiService.get(`${this.route}/${id}`);
  }

  public getAll(): Observable<Intervention[]> {
    return this.apiService.get(`${this.route}`);
  }

  public storeForAffair(form: FormGroup): void {
    this.affairShowUrl = this.affairShowUrl.replace('$id', form.value.affairId);
    this.apiService.post(`${this.route}`, this.formatCreateForm(form));
  }

  public storeForModal(form: FormGroup): Observable<any> {
    this.submitButtonService.setDisabled(this.submitButton);
    return this.apiService.post(this.route, this.formatCreateForm(form))
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.submitButtonService.setEnabled(this.submitButton);
        }),
      );

  }

  public update(id: number, form: FormGroup): Observable<ErosResponse> {
    return this.apiService.put(`${this.route}/${id}`, this.formatUpdateForm(form));
  }

  public updateStatus(id: number, interventionStatusId: number): Observable<ErosResponse> {
    return this.apiService.put(`${this.route}/${id}/update-status`, {
      interventionStatusId: interventionStatusId,
    });
  }

  public updateForModal(id: number, form: FormGroup): Observable<any> {
    this.submitButtonService.setDisabled(this.submitButton);
    return this.apiService.put(`${this.route}/${id}`, this.formatUpdateForm(form))
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.submitButtonService.setEnabled(this.submitButton);
        }),
      );
  }

  public updateDate(id: number, startDate: Date, endDate: Date): void {
    const duration = this.dateUtilsService.calcTimeDifference(startDate, endDate);
    this.apiService.put(`${this.route}/${id}/update-date`, {
      startDate: startDate.toString(),
      duration: duration.toTimeString(),
    })
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      )
      .subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
        },
      );
  }

  public delete(id: number): Observable<any> {
    return this.apiService.delete(`${this.route}/${id}`);
  }

  getSelects(): Observable<any> {
    return this.apiService.get('/interventions/list/selects');
  }

  getSelectsForCreate(affairId: number): Observable<any> {
    return this.apiService.get(`/interventions/create-for-affair/${affairId}/selects`);
  }

  getSelectsForEdit(interventionId: number): Observable<any> {
    return this.apiService.get(`/interventions/${interventionId}/edit/selects`);
  }

  getSelectsForCalendar(): void {
    this.apiService.get('/interventions/calendar/selects')
      .subscribe(
        (selects) => {
          this.interventionsCalendarSelects$.next(selects);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération',
          });
        },
      );

  }

  getEditForCalendar(id: number): Observable<any> {
    return this.apiService.get(`/interventions/${id}/get-for-calendar`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  getForAffair(form: any): Observable<any> {
    return this.apiService.post(`${this.route}/get-for-affair`, this.formatAffairListForm(form))
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  getAudits(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/audits`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  confirm(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/confirm`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  shift(id: number, form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/${id}/shift`, this.formatProgramForm(form));
  }

  program(id: number, form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/${id}/program`, this.formatProgramForm(form));
  }

  deprogram(id: number, form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/${id}/deprogram`, form.value);
  }

  cancel(id: number, form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/${id}/cancel`, form.value);
  }

  markAsPaid(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/mark-as-paid`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  markAsFree(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/mark-as-free`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  markAsWaitingConfirmation(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/mark-as-waiting-confirmation`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  markAsBilled(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/mark-as-billed`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  markAsReportsSent(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/mark-as-reports-sent`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  markAsReportsNotSent(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/mark-as-reports-not-sent`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  createForInsky(id: number): Observable<ErosResponse> {
    return this.apiService.get(`${this.route}/insky/${id}/create-for-insky`);
  }

  getDocumentCustomersAttached(id: number): void {
    this.apiService.get(`${this.route}/${id}/documents-customers/attached`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      )
      .subscribe(
        (object) => {
          this.documentsCustomersAttached$.next(object);
        },
      );
  }

  setSubmitButton(button: SubmitButton): void {
    this.submitButton = button;
  }

  setSubmitButtonShift(button: SubmitButton): void {
    this.submitButtonShift = button;
  }

  setSubmitButtonProgram(button: SubmitButton): void {
    this.submitButtonProgram = button;
  }

  setSubmitButtonDeprogram(button: SubmitButton): void {
    this.submitButtonDeprogram = button;
  }

  setSubmitButtonCancel(button: SubmitButton): void {
    this.submitButtonCancel = button;
  }

  setModalAdd(modal: ModalDirective): void {
    this.modalAdd = modal;
  }

  showModalAdd(): void {
    this.modalAdd.show();
  }

  setModalEdit(modal: ModalDirective): void {
    this.modalEdit = modal;
  }

  showModalEdit(): void {
    this.modalEdit.show();
  }

  getAddForm(affair: Affair, technicians: SelectModel[], interventionAdd?: any): FormGroup {
    const name = this.commonService.getDefaultFormStringValue();
    const generatedDescription = this.commonService.getDefaultFormStringValue();

    let usersIds = [];
    if (interventionAdd) {
      usersIds = technicians.filter((x) => { return interventionAdd.usersSelected.map((u: User) => { return u.id; }).includes(x.value); });
    } else {
      usersIds = technicians.filter((x) => { return affair.userTechnicianId === x.value; });
    }
    const startDate = interventionAdd
      ? this.commonService.valueToFormDate(interventionAdd.startDate) : this.commonService.getDefaultFormNullValue();
    const duration = interventionAdd
      ? this.commonService.valueToFormDate(interventionAdd.duration) : this.commonService.getDefaultFormNullValue();
    return this.formBuilder.group({
      affairId: affair.id,
      name: [name, Validators.required],
      generatedDescription: [generatedDescription, Validators.required],
      description: [this.commonService.getDefaultFormStringValue()],
      conformityId: null,
      worksSupervisorId: null,
      deliveredDate: null,
      startDate: startDate,
      duration: duration,
      userAssistantId: [null, Validators.required],
      techniciansIds: [usersIds, Validators.required],
      affairProducts: new FormArray([], this.formValidatorsService.minSelectedCheckboxes(1)),
      contacts: [],
      affairsContacts: [],
      isConfirmed: false,
      isDeliveredHouse: false,
      isWaitingCheck: false,
      recoverCheck: false,
      isOffice: false,
      purchaseOrder: null,
      unitNumber: null,
      commitmentDate: null,
      isTimeSlot: [this.commonService.getDefaultFormRadioValue(), Validators.required],
      timeSlot: [this.commonService.getDefaultFormNullValue()],
      startTimeSlot: null,
      endTimeSlot: null,
      doNotShift: [false],
      doNotShiftReason: [this.commonService.getDefaultFormStringValue()],
      isPrepared: [false],
      buildingsIds: null,
      housings: new FormArray([]),
    });
  }

  getEditForm(intervention: Intervention, technicians: SelectModel[], contacts?: Contact[]): FormGroup {
    const name = intervention
      ? this.commonService.valueToFormString(intervention.name) : this.commonService.getDefaultFormStringValue();
    const generatedDescription = intervention
      ? this.commonService.valueToFormString(intervention.generatedDescription) : this.commonService.getDefaultFormStringValue();
    const description = intervention
      ? this.commonService.valueToFormString(intervention.description) : this.commonService.getDefaultFormStringValue();
    const conformityId = intervention
      ? this.commonService.valueToFormSelect(intervention.conformityId) : this.commonService.getDefaultFormNullValue();
    const userAssistantId = intervention
      ? this.commonService.valueToFormSelect(intervention.userAssistantId) : this.commonService.getDefaultFormNullValue();
    const techniciansIds = intervention
      ? technicians.filter((x) => { return intervention.usersTechnicians.map((y) => { return y.id; }).includes(x.value); }) : [];
    const worksSupervisorId = intervention
      ? this.commonService.valueToFormSelect(intervention.worksSupervisorId) : this.commonService.getDefaultFormNullValue();
    const contactsIds = intervention && contacts
      ? contacts.filter((x) => { return intervention.contacts.map((y) => { return y.id; }).includes(x.id); }) : [];
    const affairsContacts = intervention
      ? intervention.affairsContacts : [];
    const isDeliveredHouse = intervention
      ? intervention.isDeliveredHouse : this.commonService.getDefaultFormCheckboxValue();
    const isWaitingCheck = intervention
      ? intervention.isWaitingCheck : this.commonService.getDefaultFormCheckboxValue();
    const isOffice = intervention
      ? intervention.isOffice : this.commonService.getDefaultFormCheckboxValue();
    const purchaseOrder = intervention
      ? intervention.purchaseOrder : this.commonService.getDefaultFormStringValue();
    const buildingsIds = intervention
      ? this.commonService.valueToFormSelectMultiple(intervention.buildings) : [];
    const commitmentDate = intervention
      ? this.commonService.valueToFormDate(intervention.commitmentDate) : this.commonService.getDefaultFormDateValue();
    const unitDeadline = intervention
      ? this.commonService.valueToFormDate(intervention.unitDeadline) : this.commonService.getDefaultFormDateValue();
    const timeSlot = intervention
      ? intervention.timeSlot : this.commonService.getDefaultFormStringValue();
    const doNotShift = intervention
      ? intervention.doNotShift : false;
    const doNotShiftReason = intervention
      ? intervention.doNotShiftReason : this.commonService.getDefaultFormStringValue();
    const isPrepared = intervention
      ? intervention.isPrepared : false;
    const recoverCheck = intervention
      ? intervention.recoverCheck : false;
    let affairStatusId = this.commonService.getDefaultFormNullValue();

    const permeabilityGoal = intervention
      ? this.commonService.valueToSlider(intervention.permeabilityGoal) : this.commonService.getDefaultFormNumberValue();
    const permeabilityResult = intervention
      ? this.commonService.valueToSlider(intervention.permeabilityResult) : this.commonService.getDefaultFormNumberValue();

    if (intervention && intervention.affairStatusId) {
      affairStatusId = this.commonService.valueToFormSelect(intervention.affairStatusId);
    } else if (intervention && intervention.affair.affairStatus) {
      affairStatusId = this.commonService.valueToFormSelect(intervention.affair.affairStatusId);
    }
    const startTimeSlot = intervention
      ? this.commonService.valueToFormTime(intervention.startTimeSlot) : null;
    const endTimeSlot = intervention
      ? this.commonService.valueToFormTime(intervention.endTimeSlot) : null;

    return this.formBuilder.group({
      id: [intervention.id],
      name: [name, [Validators.required]],
      generatedDescription: [generatedDescription],
      description: [description],
      conformityId: conformityId,
      affairStatusId: [affairStatusId],
      worksSupervisorId: worksSupervisorId,
      affairProducts: new FormArray([], this.formValidatorsService.minSelectedCheckboxes(1)),
      userAssistantId: [userAssistantId, Validators.required],
      techniciansIds: [techniciansIds, Validators.required],
      contacts: [contactsIds],
      affairsContacts: [affairsContacts],
      isDeliveredHouse: isDeliveredHouse,
      isWaitingCheck: isWaitingCheck,
      isOffice: isOffice,
      purchaseOrder: purchaseOrder,
      commitmentDate: [commitmentDate],
      unitDeadline: [unitDeadline],
      timeSlot: [timeSlot],
      buildingsIds: [buildingsIds],
      doNotShift: [doNotShift],
      doNotShiftReason: [doNotShiftReason],
      recoverCheck: [recoverCheck],
      isPrepared: [isPrepared],
      housings: new FormArray([]),
      permeabilityGoal: [permeabilityGoal],
      permeabilityResult: [permeabilityResult],
      startTimeSlot: [startTimeSlot],
      endTimeSlot: [endTimeSlot],
    });
  }

  getPreparationForm(userId: number): FormGroup {
    const userSelectId = userId
      ? this.commonService.valueToFormSelect(userId) : this.commonService.getDefaultFormNullValue();
    const dateInterventionMin = this.dateUtilsService.getNextWeekStart();
    const dateInterventionMax = this.dateUtilsService.getNextWeekEnd();

    return this.formBuilder.group({
      userId: [userSelectId, [Validators.required]],
      dateInterventionMin: [dateInterventionMin, [Validators.required]],
      dateInterventionMax: [dateInterventionMax, [Validators.required]],
      isPrepared: false,
    });

  }

  initProgramForm(): FormGroup {
    return this.formBuilder.group({
      startDate: [null, Validators.required],
      duration: [null, Validators.required],
      isConfirmed: false,
      isTimeSlot: [this.commonService.getDefaultFormRadioValue()],
      timeSlot: [null],
      startTimeSlot: [null],
      endTimeSlot: [null],
    });
  }

  initShiftForm(intervention: Intervention, technicians: any, dropStart?: any, dropEnd?: any): FormGroup {

    let startDate = this.commonService.getDefaultFormDateValue();
    if (dropStart) {
      startDate = this.commonService.valueToFormDate(dropStart);
    } else if (intervention) {
      startDate = this.commonService.valueToFormDate(intervention.startDate);
    }
    let duration = this.commonService.getDefaultFormNullValue();
    if (dropEnd) {
      const durationCalc = this.dateUtilsService.calcTimeDifference(dropStart, dropEnd);
      duration = durationCalc;
    } else if (intervention) {
      duration = this.getDurationDate(intervention);
    }
    const isTimeSlot = intervention
      ? this.commonService.valueToRadioFormString(intervention.isTimeSlot) : this.commonService.getDefaultFormRadioValue();
    const timeSlot = intervention && intervention.isTimeSlot
      ? this.commonService.valueToFormString(intervention.timeSlot) : null;
    const techniciansIds = intervention
      ? technicians.filter((x) => { return intervention.usersTechnicians.map((y) => { return y.id; }).includes(x.value); }) : [];
    const startTimeSlot = intervention
      ? this.commonService.valueToFormTime(intervention.startTimeSlot) : null;
    const endTimeSlot = intervention
      ? this.commonService.valueToFormTime(intervention.endTimeSlot) : null;

    return this.formBuilder.group({
      techniciansIds: [techniciansIds, Validators.required],
      startDate: [startDate, Validators.required],
      duration: [duration, Validators.required],
      shiftOriginId: [this.commonService.getDefaultShiftOriginValue(), Validators.required],
      reason: [null],
      isTimeSlot: [isTimeSlot],
      timeSlot: [timeSlot],
      startTimeSlot: [startTimeSlot],
      endTimeSlot: [endTimeSlot],
    });
  }

  initDeprogramForm(): FormGroup {
    return this.formBuilder.group({
      origin: [null, Validators.required],
      reason: [null],
    });
  }

  initCancelForm(): FormGroup {
    return this.formBuilder.group({
      origin: [null, Validators.required],
      reason: [null],
    });
  }

  getForCalendar(form: FormGroup, users: any): void {
    const usersIds = JSON.stringify(form.value.users.map((v, i) => { return (v ? users[i].id : null); }).filter((v) => { return v !== null; }));
    this.apiService.post(`${this.route}/get-for-calendar`, {
      dateFrom: form.value.dateFrom,
      dateTo: form.value.dateTo,
      users: usersIds,
    })
      .subscribe(
        (events: any) => {
          this.interventionsCalendar$.next(events);
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        },
      );
  }

  getAffairsProductsToProgram(research?: string, affairId?: number): Observable<any> {
    return this.apiService.post(`${this.route}/affairs-products/get-to-program`, {
      search: research,
      affairId: affairId,
    });
  }

  getFormControls(form: FormGroup, controlName: string): AbstractControl[] {
    return (form.get(controlName) as FormArray).controls;
  }

  initInterventionsTypesSwitches(form: FormGroup, indicatorsInterventionsTypes: any, intervention?: Intervention, affair?: Affair): void {
    indicatorsInterventionsTypes.forEach((indicator) => {
      let checked = false;
      if (intervention) {
        const found = intervention.indicatorsInterventionsTypes.find((x) => { return x.id === indicator.id; });
        if (found) {
          checked = found.pivot.value;
        }
      } else if (affair) {
        const found = affair.documentsCustomersIds.some((x) => { return indicator.documentsCustomersIds.includes(x); });
        if (found) {
          checked = true;
        }
      }
      const indicatorsInterventionsTypesFormArray = form.get('indicatorsInterventionsTypes') as FormArray;
      indicatorsInterventionsTypesFormArray.push(new FormGroup({
        checked: new FormControl(checked),
        indicatorInterventionTypeId: new FormControl(indicator.id),
      }));
    });
  }

  initAffairProductsCheckboxes(form: FormGroup, affairProductsToProgram: AffairProduct[], affairProductsSelected?: AffairProduct[], affairProducts?: AffairProduct[]): void {
    const affairProductsFormArray = form.get('affairProducts') as FormArray;
    if (affairProductsSelected) {
      affairProductsSelected.forEach((affairProduct) => {
        let { quantity } = affairProduct;
        if (affairProducts) {
          const productFromAffair = affairProducts.find((x) => { return x.id === affairProduct.id; });
          quantity = productFromAffair.quantity;
        }
        affairProductsFormArray.push(new FormGroup({
          checked: new FormControl(true),
          affairProductId: new FormControl(affairProduct.id),
          productId: new FormControl(affairProduct.productId),
          productStatusId: new FormControl(affairProduct.productStatusId),
          quantity: new FormControl(affairProduct.quantity, [Validators.max(quantity), Validators.min(1)]),
          price: new FormControl(affairProduct.price),
        }));

      });
    }
    affairProductsToProgram.forEach((affairProduct) => {
      const found = affairProductsSelected ? affairProductsSelected.find((x) => { return x.id === affairProduct.id; }) : false;
      if (!found) {
        affairProductsFormArray.push(new FormGroup({
          checked: new FormControl(false),
          affairProductId: new FormControl(affairProduct.id),
          productId: new FormControl(affairProduct.productId),
          productStatusId: new FormControl(affairProduct.productStatusId),
          quantity: new FormControl(affairProduct.quantity, [Validators.max(affairProduct.quantity), Validators.min(1)]),
          price: new FormControl(affairProduct.price),
        }));
      }
    });
  }

  initHousingsCheckboxes(form: FormGroup, housings: any, housingsSelected?: any): void {
    const housingsFormArray = form.get('housings') as FormArray;
    while (housingsFormArray.length !== 0) {
      housingsFormArray.removeAt(0);
    }
    housings.forEach((housing) => {
      let checked = false;
      let housingSelected = housing;
      if (housingsSelected) {
        checked = housingsSelected.map((x) => { return x.id; }).includes(housing.id);
        if (checked) {
          housingSelected = housingsSelected.find((x) => { return x.id === housing.id; });
        }
      }

      housingsFormArray.push(new FormGroup({
        checked: new FormControl(checked),
        name: new FormControl(housing.name),
        housingId: new FormControl(housingSelected.id),
        result: new FormControl(housingSelected.result),
      }));
    });
  }

  updateDuration(form: FormGroup, affair: Affair, affairProductsSelected?: any): void {
    const currentDateObject = new Date();
    let dateObject = new Date();
    if (affairProductsSelected) {
      for (let i = 0; i < affairProductsSelected.length; i++) {
        if (affairProductsSelected[i].product.duration != null) {
          dateObject = moment(dateObject).add(affairProductsSelected[i].product.duration, 'm').toDate();
        }
      }
    }
    const duration = this.dateUtilsService.calcTimeDifference(currentDateObject, dateObject);
    form.controls.duration.patchValue(duration);
  }

  updateTechnicians(form: FormGroup, technicians: SelectModel[], techniciansIds: number[]): void {
    const selectedTechnicians = technicians.filter((x) => { return techniciansIds.includes(x.value); });
    form.controls.techniciansIds.patchValue(selectedTechnicians);
  }

  filterHousings(buildings: any, housings: any[], valueChange?: boolean) {
    const buildingsSelected = buildings.map((v) => { return (valueChange ? v.value : v.id); });
    return housings.filter((x) => { return buildingsSelected.includes(x.buildingId); });
  }

  initUnconfirmedList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/unconfirmed`, this.formatFiltersStatusForm(form));
  }

  initWaitingProgrammingList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/waiting-programming`, this.formatFiltersStatusForm(form));
  }

  initWaitingProgrammingRenovationList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/waiting-programming-renovation`, this.formatFiltersStatusForm(form));
  }

  initWaitingPaymentList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/waiting-payment`, this.formatFiltersStatusForm(form));
  }

  initWaitingDocumentCustomerList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/waiting-document-customer-list`, this.formatFiltersStatusForm(form));
  }

  initWaitingDocumentDeliveredList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/waiting-document-delivered-list`, this.formatFiltersStatusForm(form));
  }

  initWaitingDocumentSendManualList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/waiting-send-document-manual`, this.formatFiltersStatusForm(form));
  }

  initUncompletedList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/uncompleted`, this.formatFiltersStatusForm(form));
  }

  initXMLNeededList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/xml-needed`, this.formatFiltersStatusForm(form));
  }

  initNotBilledList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/not-billed`, this.formatFiltersStatusForm(form));
  }

  initCompletedList(form?: FormGroup): void {
    this.apiService.post(`${this.route}/list/completed`, this.formatFiltersStatusForm(form))
      .subscribe({
        next: (stats) => {
          this.statsCompleted$.next(stats);
        },
      });
  }

  initNotSentList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/completed`, this.formatFiltersStatusForm(form));
  }

  initAffairProductsList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/affair-products`, this.formatFiltersStatusForm(form));
  }

  initPreparationList(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list/preparation`, this.formatFiltersPreparationForm(form));
  }

  dashboardVolume(form?: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/dashboard/volume`, this.formatFiltersStatusForm(form));
  }

  prepare(interventionsIds: any): Observable<HttpResponse<any>> {
    return this.apiService.postBlob(`${this.route}/prepare`, {
      interventionsIds: interventionsIds,
    });
  }

  getGeneratedName(form: any): void {
    this.apiService.post(`${this.route}/get-generated-name`, this.formatGeneratedNameForm(form))
      .subscribe(
        (data: any) => {
          this.generatedName$.next(data);
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        },
      );
  }

  getGeneratedDescription(form: any): void {
    this.apiService.post(`${this.route}/get-generated-description`, this.formatGeneratedDescriptionForm(form))
      .subscribe(
        (data: any) => {
          this.generatedDescription$.next(data);
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        },
      );
  }

  private formToDatatableParameters(form: FormGroup): InterventionDatatableParameters {
    if (form) {
      return {
        selectedAffair: form.value.selectedAffair,
        selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
        agenciesMarketsTypes: this.formFormatterService.formatSelectMultiple(form.value.agenciesMarketsTypes),
        agenciesCustomersTypes: this.formFormatterService.formatSelectMultiple(form.value.agenciesCustomersTypes),
        agenciesIndicatorsCustomers: this.formFormatterService.formatSelectMultiple(form.value.agenciesIndicatorsCustomers),
        selectedMarketsTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedMarketsTypes),
        selectedAssistants: this.formFormatterService.formatSelectMultiple(form.value.selectedAssistants),
        selectedTechnicians: this.formFormatterService.formatSelectMultiple(form.value.selectedTechnicians),
        selectedConformities: this.formFormatterService.formatSelectMultiple(form.value.selectedConformities),
        selectedInterventionsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedInterventionsStatuses),
        selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
        selectedProductsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedProductsStatuses),
        selectedDocumentsCustomersMissing: this.formFormatterService.formatSelectMultiple(form.value.selectedDocumentsCustomersMissing),
        selectedAffairsGoals: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsGoals),
        selectedAffairsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsStatuses),
        selectedAffairsMonitoringTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsMonitoringTypes),
        selectedTags: this.formFormatterService.formatSelectMultiple(form.value.selectedTags),
        affairMonitoringTypeLevel: form.value.affairMonitoringTypeLevel,
        isAfterIntervention: form.value.isAfterIntervention,
        dateInterventionMin: form.value.dateInterventionMin ? new Date(form.value.dateInterventionMin).toJSON() : null,
        dateInterventionMax: form.value.dateInterventionMax ? new Date(form.value.dateInterventionMax).toJSON() : null,
        affairCreatedAtMin: form.value.affairCreatedAtMin ? new Date(form.value.affairCreatedAtMin).toJSON() : null,
        affairCreatedAtMax: form.value.affairCreatedAtMax ? new Date(form.value.affairCreatedAtMax).toJSON() : null,
        createdAtMin: form.value.createdAtMin ? new Date(form.value.createdAtMin).toJSON() : null,
        createdAtMax: form.value.createdAtMax ? new Date(form.value.createdAtMax).toJSON() : null,
        isNotBilled: this.formFormatterService.formatBoolean(form.value.isNotBilled),
        isWaitingCheck: this.formFormatterService.formatBoolean(form.value.isWaitingCheck),
        isDeliveredHouse: this.formFormatterService.formatBoolean(form.value.isDeliveredHouse),
        isOffice: this.formFormatterService.formatBoolean(form.value.isOffice),
        withDocumentDeliveredStandardsResponsesErrors: this.formFormatterService.formatBoolean(form.value.withDocumentDeliveredStandardsResponsesErrors),
        withDocumentCustomerStandardsResponsesErrors: this.formFormatterService.formatBoolean(form.value.withDocumentCustomerStandardsResponsesErrors),
        plansAttached: this.formFormatterService.formatBoolean(form.value.plansAttached),
        page: form.value.page,
        size: form.value.size,
        search: form.value.search,
        addressSearch: form.value.addressSearch,
        sortField: form.value.sortField,
        sortOrder: form.value.sortOrder,
        individualSearch: form.value.individualSearch,
      };
    }
    return null;
  }

  private formatCreateForm(form: FormGroup): any {
    const values = this.formFormatterService.createFormCopy(form);
    values.techniciansIds = this.formFormatterService.formatSelectMultipleToIntArray(values.techniciansIds);
    values.affairProducts = this.formFormatterService.filterCheckedValues(values.affairProducts);
    values.housings = this.formFormatterService.filterCheckedValues(values.housings);
    values.startDate = values.startDate ? new Date(values.startDate).toString() : null;
    values.duration = values.duration ? new Date(values.duration).toTimeString() : null;
    values.contacts = this.formFormatterService.formatSelectMultipleToIntArray(values.contacts, 'id');
    values.affairsContacts = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsContacts, 'id');
    values.buildingsIds = this.formFormatterService.formatSelectMultipleToIntArray(values.buildingsIds);
    values.startTimeSlot = values.startTimeSlot ? new Date(values.startTimeSlot).toTimeString() : null;
    values.endTimeSlot = values.endTimeSlot ? new Date(values.endTimeSlot).toTimeString() : null;
    return values;
  }

  private formatProgramForm(form: FormGroup): any {
    const values = this.formFormatterService.createFormCopy(form);
    values.techniciansIds = this.formFormatterService.formatSelectMultipleToIntArray(values.techniciansIds);
    values.startDate = values.startDate ? new Date(values.startDate).toString() : null;
    values.duration = values.duration ? new Date(values.duration).toTimeString() : null;
    values.startTimeSlot = values.startTimeSlot ? new Date(values.startTimeSlot).toTimeString() : null;
    values.endTimeSlot = values.endTimeSlot ? new Date(values.endTimeSlot).toTimeString() : null;
    if (values.unitDeadline) {
      values.unitDeadline = new Date(values.unitDeadline).toDateString();
    }
    return values;
  }

  private formatUpdateForm(form: FormGroup): any {
    const values = this.formFormatterService.createFormCopy(form);
    values.techniciansIds = this.formFormatterService.formatSelectMultipleToIntArray(values.techniciansIds);
    values.affairProducts = this.formFormatterService.filterCheckedValues(values.affairProducts);
    values.housings = this.formFormatterService.filterCheckedValues(values.housings);
    values.contacts = this.formFormatterService.formatSelectMultipleToIntArray(values.contacts, 'id');
    values.affairsContacts = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsContacts, 'id');
    values.buildingsIds = this.formFormatterService.formatSelectMultipleToIntArray(values.buildingsIds);
    values.startTimeSlot = values.startTimeSlot ? new Date(values.startTimeSlot).toTimeString() : null;
    values.endTimeSlot = values.endTimeSlot ? new Date(values.endTimeSlot).toTimeString() : null;
    values.unitDeadline = values.unitDeadline ? new Date(values.unitDeadline).toDateString() : null;
    return values;
  }

  private formatFiltersStatusForm(form: any) {
    const values: any = this.formFormatterService.createFormCopy(form);
    if (typeof values.selectedMarketsTypes !== 'undefined') {
      values.userId = this.formFormatterService.formatSelectSingle(values.userId);
      values.dayNumber = this.formFormatterService.formatSelectSingle(values.dayNumber);
      // values.selectedMarketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.selectedMarketsTypes);
    }
    return values;
  }

  private formatFiltersPreparationForm(form) {
    const values = this.formFormatterService.createFormCopy(form);
    values.userId = this.formFormatterService.formatSelectSingle(values.userId);
    values.dateInterventionMin = new Date(form.value.dateInterventionMin).toJSON();
    values.dateInterventionMax = new Date(form.value.dateInterventionMax).toJSON();
    return values;
  }

  private formatGeneratedNameForm(form: any) {
    const values = this.formFormatterService.createFormCopy(form);
    values.affairProducts = this.formFormatterService.filterCheckedValues(values.affairProducts);
    values.housings = this.formFormatterService.filterCheckedValues(values.housings);
    return values;
  }

  private formatGeneratedDescriptionForm(form: any) {
    const values = this.formFormatterService.createFormCopy(form);
    values.worksSupervisorId = this.formFormatterService.formatSelectSingle(values.worksSupervisorId);
    values.contacts = this.formFormatterService.formatSelectMultipleToIntArray(values.contacts, 'id');
    values.affairsContacts = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsContacts, 'id');
    values.housings = this.formFormatterService.filterCheckedValues(values.housings);
    values.startTimeSlot = values.startTimeSlot ? new Date(values.startTimeSlot).toTimeString() : null;
    values.endTimeSlot = values.endTimeSlot ? new Date(values.endTimeSlot).toTimeString() : null;
    return values;
  }

  private formatAffairListForm(form: any) {
    const values = this.formFormatterService.createFormCopy(form);
    values.selectedConformities = this.formFormatterService.formatSelectMultiple(form.value.selectedConformities);
    values.selectedInterventionsStatuses = this.formFormatterService.formatSelectMultiple(form.value.selectedInterventionsStatuses);
    return values;
  }

  private getDurationDate(intervention: Intervention): Date {
    const dateTimeString = `${new Date().toDateString()} ${intervention.duration}`;
    return new Date(dateTimeString);
  }

  public getFiltersForm(formValue?: any, marketTypeId?: number): FormGroup {
    let selectedMarketsTypes = this.commonService.getDefaultFormNullValue();
    if (marketTypeId) {
      selectedMarketsTypes = [{ label: marketTypeId, value: marketTypeId }];
    } else if (formValue?.selectedMarketsTypes) {
      selectedMarketsTypes = this.commonService.valueToFormSelect(formValue.selectedMarketsTypes);
    }
    const selectedAgencies = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
    const agenciesMarketsTypes = formValue
      ? this.commonService.valueToFormSelect(formValue.agenciesMarketsTypes) : this.commonService.getDefaultFormNullValue();
    const agenciesCustomersTypes = formValue
      ? this.commonService.valueToFormSelect(formValue.agenciesCustomersTypes) : this.commonService.getDefaultFormNullValue();
    const agenciesIndicatorsCustomers = formValue
      ? this.commonService.valueToFormSelect(formValue.agenciesIndicatorsCustomers) : this.commonService.getDefaultFormNullValue();
    const selectedAssistants = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAssistants) : this.commonService.getDefaultFormNullValue();
    const selectedTechnicians = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedTechnicians) : this.commonService.getDefaultFormNullValue();
    const selectedConformities = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedConformities) : this.commonService.getDefaultFormNullValue();
    const selectedInterventionsStatuses = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedInterventionsStatuses) : this.commonService.getDefaultFormNullValue();
    const selectedProducts = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedProducts) : this.commonService.getDefaultFormNullValue();
    const selectedProductsStatuses = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedProductsStatuses) : this.commonService.getDefaultFormNullValue();
    const selectedDocumentsCustomersMissing = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedDocumentsCustomersMissing) : this.commonService.getDefaultFormNullValue();
    const selectedTags = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedTags) : this.commonService.getDefaultFormNullValue();
    const dateInterventionMin = formValue
      ? this.commonService.valueToFormDate(formValue.dateInterventionMin) : this.commonService.getDefaultFormNullValue();
    const dateInterventionMax = formValue
      ? this.commonService.valueToFormDate(formValue.dateInterventionMax) : this.commonService.getDefaultFormNullValue();
    const deliveredDateMin = formValue
      ? this.commonService.valueToFormDate(formValue.deliveredDateMin) : this.commonService.getDefaultFormNullValue();
    const deliveredDateMax = formValue
      ? this.commonService.valueToFormDate(formValue.deliveredDateMax) : this.commonService.getDefaultFormNullValue();
    const affairCreatedAtMin = formValue
      ? this.commonService.valueToFormDate(formValue.affairCreatedAtMin) : this.commonService.getDefaultFormNullValue();
    const affairCreatedAtMax = formValue
      ? this.commonService.valueToFormDate(formValue.affairCreatedAtMax) : this.commonService.getDefaultFormNullValue();
    const createdAtMin = formValue
      ? this.commonService.valueToFormDate(formValue.createdAtMin) : this.commonService.getDefaultFormNullValue();
    const createdAtMax = formValue
      ? this.commonService.valueToFormDate(formValue.createdAtMax) : this.commonService.getDefaultFormNullValue();
    const selectedAffairsGoals = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAffairsGoals) : this.commonService.getDefaultFormNullValue();
    const selectedAffairsStatuses = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAffairsStatuses) : this.commonService.getDefaultFormNullValue();
    const selectedAffairsMonitoringTypes = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAffairsMonitoringTypes) : this.commonService.getDefaultFormNullValue();
    const affairMonitoringTypeLevel = formValue
      ? formValue.affairMonitoringTypeLevel : 1;
    const isAfterIntervention = formValue
      ? formValue.isAfterIntervention : null;
    const isNotBilled = formValue
      ? formValue.isNotBilled : this.commonService.getDefaultFormCheckboxValue();
    const isWaitingCheck = formValue
      ? formValue.isWaitingCheck : this.commonService.getDefaultFormCheckboxValue();
    const isDeliveredHouse = formValue
      ? formValue.isDeliveredHouse : this.commonService.getDefaultFormCheckboxValue();
    const isOffice = formValue
      ? formValue.isOffice : this.commonService.getDefaultFormCheckboxValue();
    const withDocumentDeliveredStandardsResponsesErrors = formValue
      ? formValue.withDocumentDeliveredStandardsResponsesErrors : this.commonService.getDefaultFormCheckboxValue();
    const withDocumentCustomerStandardsResponsesErrors = formValue
      ? formValue.withDocumentCustomerStandardsResponsesErrors : this.commonService.getDefaultFormCheckboxValue();
    const plansAttached = formValue
      ? formValue.plansAttached : this.commonService.getDefaultFormCheckboxValue();
    const page = formValue
      ? formValue.page : 1;
    const size = formValue
      ? formValue.page : 10;
    const search = formValue
      ? formValue.search : this.commonService.getDefaultFormStringValue();
    const addressSearch = formValue
      ? formValue.addressSearch : this.commonService.getDefaultFormStringValue();
    const sortField = formValue
      ? formValue.sortField : this.commonService.getDefaultFormNullValue();
    const sortOrder = formValue
      ? formValue.sortOrder : this.commonService.getDefaultFormNullValue();

    return this.formBuilder.group({
      selectedAgencies: [selectedAgencies],
      agenciesMarketsTypes: [agenciesMarketsTypes],
      agenciesCustomersTypes: [agenciesCustomersTypes],
      agenciesIndicatorsCustomers: [agenciesIndicatorsCustomers],
      selectedMarketsTypes: [selectedMarketsTypes],
      selectedAssistants: [selectedAssistants],
      selectedTechnicians: [selectedTechnicians],
      selectedConformities: [selectedConformities],
      selectedInterventionsStatuses: [selectedInterventionsStatuses],
      selectedProducts: [selectedProducts],
      selectedProductsStatuses: [selectedProductsStatuses],
      dateInterventionMin: [dateInterventionMin],
      dateInterventionMax: [dateInterventionMax],
      deliveredDateMin: [deliveredDateMin],
      deliveredDateMax: [deliveredDateMax],
      affairCreatedAtMin: [affairCreatedAtMin],
      affairCreatedAtMax: [affairCreatedAtMax],
      createdAtMin: [createdAtMin],
      createdAtMax: [createdAtMax],
      selectedDocumentsCustomersMissing: [selectedDocumentsCustomersMissing],
      selectedAffairsGoals: [selectedAffairsGoals],
      selectedAffairsStatuses: [selectedAffairsStatuses],
      selectedAffairsMonitoringTypes: [selectedAffairsMonitoringTypes],
      selectedTags: [selectedTags],
      affairMonitoringTypeLevel: [affairMonitoringTypeLevel],
      isAfterIntervention: [isAfterIntervention],
      indicators: [],
      isNotBilled: [isNotBilled],
      isWaitingCheck: [isWaitingCheck],
      isDeliveredHouse: [isDeliveredHouse],
      isOffice: [isOffice],
      withDocumentDeliveredStandardsResponsesErrors: [withDocumentDeliveredStandardsResponsesErrors],
      withDocumentCustomerStandardsResponsesErrors: [withDocumentCustomerStandardsResponsesErrors],
      plansAttached: [plansAttached],
      size: size,
      page: page,
      search: search,
      addressSearch: addressSearch,
      sortField: sortField,
      sortOrder: sortOrder,
      individualSearch: new FormArray([]),
    });
  }

  pushToYsier(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/push-yzier`);
  }

  createForInspection(form: FormGroup): Observable<ErosResponse> {
    return this.apiService.post(`${this.route}/inspections/create`, this.formatProgramForm(form));
  }

  public generateListExport(form: FormGroup): Observable<HttpResponse<Blob>> {
    return this.apiService.postBlob(`${this.route}/list/export`, this.formToDatatableParameters(form));
  }

}
