/* eslint-disable camelcase */
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { AffairMarketType, AffairPayerPreference } from '@eros-front/enum';
import { catchError, finalize } from 'rxjs/operators';
import { Affair, AffairOfficeFile, ErosResponse, ProgrammedStandardResponse } from '@libs/models/src';
import { NotifService } from './utilities/notif.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { FormValidatorsService } from './utilities/form-validators.service';

export interface AffairDatatableParameters {
    selectedMlAgencies: string;
    selectedAgencies: string;
}

export interface IncomingCallSheetParameters {
    contactId?: number;
    contactType: string;
    contactEmail?: string;
    size: number;
    page: number;
    search: string;
}

@Injectable()
export class AffairService extends ModelWithDatatableAndCrud {

  private route = '/affairs';
  public affair$ = new BehaviorSubject<any>(undefined);
  public affairForAdd$ = new BehaviorSubject<any>(undefined);
  public affairProductEdit$ = new BehaviorSubject<any>(undefined);
  public affairSelects$ = new BehaviorSubject<any>(undefined);
  public affairSelectsForShow$ = new BehaviorSubject<any>(undefined);
  public affairProgrammedStandardResponses$ = new BehaviorSubject<any>(undefined);
  public affairPayerPreferenceEnum = AffairPayerPreference;
  public affairDatatableParams: AffairDatatableParameters;
  public marketTypeEnum = AffairMarketType;
  private readonly PERMIT_SIGNATORY_ID = 2;

  constructor(
    http: HttpClient,
    notifService: NotifService,
    submitButtonService: SubmitButtonService,
    swalService: SwalService,
    router: Router,
    apiService: ApiService,
    private formBuilder: FormBuilder,
    private formFormatterService: FormFormatterService,
    private commonService: CommonService,
    private validatorService: FormValidatorsService,
  ) {
    super(
      http,
      notifService,
      submitButtonService,
      swalService,
      apiService,
      router,
    );
  }

  public initList(form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/list`, this.formatListForm(form));
  }

  public get(id: number): void {
    super.get(id, this.affair$, this.route);
  }

  public getForAdd(id: number): Observable<Affair> {
    return this.apiService.get(`${this.route}/${id}/get-for-add`);
  }

  public getByRef(mlRef: string): Observable<Affair> {
    return this.apiService.get(`${this.route}/${mlRef}/get-by-ref`);
  }

  public getForOverview(id: number): Observable<Affair> {
    return this.apiService.get(`${this.route}/${id}/get-for-overview`);
  }

  public create(form: FormGroup): void {
    this.submitButtonService.setDisabled(this.submitButton);
    this.apiService.post(this.route, this.formatForm(form))
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.submitButtonService.setEnabled(this.submitButton);
        }),
      )
      .subscribe(
        (response: any) => {
          this.router.navigateByUrl(`/affairs/${response.data.id}/show`);
          this.notifService.showSuccessNotif(response);
        },
      );
  }

  createForInspection(form: FormGroup): Observable<ErosResponse> {
    return this.apiService.post(`${this.route}/inspection/create`, this.formatCreateForInspectionForm(form));
  }

  public update(id: number, form: FormGroup): Observable<ErosResponse> {
    this.submitButtonService.setDisabled(this.submitButton);
    return this.apiService.put(`${this.route}/${id}`, this.formatForm(form));
  }

  public linkForDq(id: number, form: FormGroup): Observable<ErosResponse> {
    return this.apiService.put(`${this.route}/${id}/link-for-dq`, this.formatForm(form));
  }

  public archive(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/archive`);
  }

  public unarchive(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/archive/0`);
  }

  public validate(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}/validate`);
  }

  public getInformationsForm(affair?: Affair): FormGroup {
    const agencyId = affair
      ? this.commonService.valueToFormSelect(affair.agencyId) : this.commonService.getDefaultFormNullValue();
    const workCompanyId = affair
      ? this.commonService.valueToFormSelect(affair.workCompanyId) : this.commonService.getDefaultFormNullValue();
    const mlAgencyId = affair
      ? this.commonService.valueToFormSelect(affair.mlAgencyId) : this.commonService.getDefaultFormNullValue();
    const userAssistantId = affair
      ? this.commonService.valueToFormSelect(affair.userAssistantId) : this.commonService.getDefaultFormNullValue();
    const userTechnicianId = affair
      ? this.commonService.valueToFormSelect(affair.userTechnicianId) : this.commonService.getDefaultFormNullValue();
    const studyOfficeId = affair
      ? this.commonService.valueToFormSelect(affair.studyOfficeId) : this.commonService.getDefaultFormNullValue();
    const marketTypeId = affair && affair.marketTypeId
      ? this.commonService.valueToFormNumber(affair.marketType.id) : this.marketTypeEnum.STANDALONE_HOUSE;
    const affairStatusId = affair && affair.affairStatusId
      ? this.commonService.valueToFormSelect(affair.affairStatusId) : this.commonService.getDefaultFormNullValue();
    const workSiteStreetNumber = affair
      ? this.commonService.valueToFormString(affair.workSiteStreetNumber) : this.commonService.getDefaultFormStringValue();
    const workSiteAddress = affair
      ? this.commonService.valueToFormString(affair.workSiteAddress) : this.commonService.getDefaultFormStringValue();
    const workSiteAdditionalAddress = affair
      ? this.commonService.valueToFormString(affair.workSiteAdditionalAddress) : this.commonService.getDefaultFormStringValue();
    const workSitePostalCode = affair
      ? this.commonService.valueToFormString(affair.workSitePostalCode) : this.commonService.getDefaultFormStringValue();
    const workSiteLocality = affair
      ? this.commonService.valueToFormString(affair.workSiteLocality) : this.commonService.getDefaultFormStringValue();
    const finalCustomerLastname = affair
      ? this.commonService.valueToFormString(affair.finalCustomerLastname) : this.commonService.getDefaultFormStringValue();
    const finalCustomerFirstname = affair
      ? this.commonService.valueToFormString(affair.finalCustomerFirstname) : this.commonService.getDefaultFormStringValue();
    const finalCustomerLastnameSecondary = affair
      ? this.commonService.valueToFormString(affair.finalCustomerLastnameSecondary) : this.commonService.getDefaultFormStringValue();
    const finalCustomerFirstnameSecondary = affair
      ? this.commonService.valueToFormString(affair.finalCustomerFirstnameSecondary) : this.commonService.getDefaultFormStringValue();
    const finalCustomerEmail = affair
      ? this.commonService.valueToFormString(affair.finalCustomerEmail) : this.commonService.getDefaultFormStringValue();
    const finalCustomerPhone = affair
      ? this.commonService.valueToFormString(affair.finalCustomerPhone) : this.commonService.getDefaultFormStringValue();
    const finalCustomerEmailSecondary = affair
      ? this.commonService.valueToFormString(affair.finalCustomerEmailSecondary) : this.commonService.getDefaultFormStringValue();
    const finalCustomerPhoneSecondary = affair
      ? this.commonService.valueToFormString(affair.finalCustomerPhoneSecondary) : this.commonService.getDefaultFormStringValue();
    const folderNumber = affair
      ? this.commonService.valueToFormString(affair.folderNumber) : this.commonService.getDefaultFormStringValue();
    const anahFolderNumber = affair
      ? this.commonService.valueToFormString(affair.anahFolderNumber) : this.commonService.getDefaultFormStringValue();
    const customerRef = affair
      ? this.commonService.valueToFormString(affair.customerRef) : this.commonService.getDefaultFormStringValue();
    const invoiceComment = affair
      ? this.commonService.valueToFormString(affair.invoiceComment) : this.commonService.getDefaultFormStringValue();
    const finalCustomerStreetNumber = affair
      ? this.commonService.valueToFormString(affair.finalCustomerStreetNumber) : this.commonService.getDefaultFormStringValue();
    const finalCustomerAddress = affair
      ? this.commonService.valueToFormString(affair.finalCustomerAddress) : this.commonService.getDefaultFormStringValue();
    const finalCustomerAdditionalAddress = affair
      ? this.commonService.valueToFormString(affair.finalCustomerAdditionalAddress) : this.commonService.getDefaultFormStringValue();
    const finalCustomerPostalCode = affair
      ? this.commonService.valueToFormString(affair.finalCustomerPostalCode) : this.commonService.getDefaultFormStringValue();
    const finalCustomerLocality = affair
      ? this.commonService.valueToFormString(affair.finalCustomerLocality) : this.commonService.getDefaultFormStringValue();
    const deliveryDate = affair
      ? this.commonService.valueToFormDate(affair.deliveryDate) : this.commonService.getDefaultFormNullValue();
    const constructionYear = affair
      ? this.commonService.valueToFormDate(affair.constructionYear) : this.commonService.getDefaultFormNullValue();
    const costEstimateRef = affair
      ? this.commonService.valueToFormString(affair.costEstimateRef) : this.commonService.getDefaultFormStringValue();
    const administrativeInternalNote = affair
      ? this.commonService.valueToFormString(affair.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
    const technicalInternalNote = affair
      ? this.commonService.valueToFormString(affair.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
    const renovationComment = affair
      ? this.commonService.valueToFormString(affair.renovationComment) : this.commonService.getDefaultFormStringValue();
    const brandPurchaseOrder = affair
      ? this.commonService.valueToFormString(affair.brandPurchaseOrder) : this.commonService.getDefaultFormStringValue();
    const unitNumber = affair
      ? this.commonService.valueToFormString(affair.unitNumber) : this.commonService.getDefaultFormStringValue();
    const fields = [];
    if (affair) {
      affair.fields.forEach((field) => {
        fields.push(this.createAffairFieldForm(field, affair));
      });
    }
    const affairsGoalsIds = affair
      ? this.commonService.valueToFormSelectMultiple(affair.affairsGoals) : [];
    const performances = affair
      ? this.commonService.valueToFormSelectMultiple(affair.performances) : [];

    return this.formBuilder.group({
      marketTypeId: [marketTypeId, Validators.required],
      affairStatusId: [affairStatusId],
      agencyId: [agencyId, Validators.required],
      workCompanyId: [workCompanyId],
      mlAgencyId: [mlAgencyId],
      userAssistantId: [userAssistantId],
      userTechnicianId: [userTechnicianId],
      studyOfficeId: [studyOfficeId],
      workSiteStreetNumber: workSiteStreetNumber,
      workSiteAddress: [workSiteAddress, Validators.required],
      workSiteAdditionalAddress: workSiteAdditionalAddress,
      workSitePostalCode: [workSitePostalCode, [Validators.required, this.validatorService.postalCode]],
      workSiteLocality: [workSiteLocality, Validators.required],
      finalCustomerLastname: [finalCustomerLastname, {
        validators: Validators.required,
        updateOn: 'blur',
      }],
      finalCustomerFirstname: finalCustomerFirstname,
      finalCustomerLastnameSecondary: finalCustomerLastnameSecondary,
      finalCustomerFirstnameSecondary: finalCustomerFirstnameSecondary,
      finalCustomerEmail: finalCustomerEmail,
      finalCustomerPhone: finalCustomerPhone,
      finalCustomerEmailSecondary: finalCustomerEmailSecondary,
      finalCustomerPhoneSecondary: finalCustomerPhoneSecondary,
      invoiceComment: invoiceComment,
      finalCustomerStreetNumber: finalCustomerStreetNumber,
      finalCustomerAddress: finalCustomerAddress,
      finalCustomerAdditionalAddress: finalCustomerAdditionalAddress,
      finalCustomerPostalCode: [finalCustomerPostalCode, this.validatorService.postalCode],
      finalCustomerLocality: finalCustomerLocality,
      folderNumber: folderNumber,
      anahFolderNumber: anahFolderNumber,
      customerRef: customerRef,
      deliveryDate: deliveryDate,
      constructionYear: constructionYear,
      costEstimateRef: costEstimateRef,
      administrativeInternalNote: administrativeInternalNote,
      renovationComment: renovationComment,
      technicalInternalNote: technicalInternalNote,
      brandPurchaseOrder: brandPurchaseOrder,
      unitNumber: unitNumber,
      affairFields: this.formBuilder.array(fields),
      affairsGoalsIds: [affairsGoalsIds],
      performances: [performances],
    });
  }

  public getCreateForDqForm(params: any): any {
    // eslint-disable-next-line camelcase
    const { line, excel_id, sheet_name, product, goal } = params;
    const workSiteStreetNumber = params?.workSiteStreetNumber ? this.commonService.valueToFormString(params.workSiteStreetNumber) : this.commonService.getDefaultFormStringValue();
    const workSiteAddress = params?.workSiteAddress ? this.commonService.valueToFormString(params.workSiteAddress) : this.commonService.getDefaultFormStringValue();
    const workSitePostalCode = params?.workSitePostalCode ? this.commonService.valueToFormString(params.workSitePostalCode) : this.commonService.getDefaultFormStringValue();
    const workSiteLocality = params?.workSiteLocality ? this.commonService.valueToFormString(params.workSiteLocality) : this.commonService.getDefaultFormStringValue();
    const finalCustomerLastname = params?.finalCustomerLastname ? this.commonService.valueToFormString(params.finalCustomerLastname) : this.commonService.getDefaultFormStringValue();
    const finalCustomerFirstname = params?.finalCustomerFirstname ? this.commonService.valueToFormString(params.finalCustomerFirstname) : this.commonService.getDefaultFormStringValue();
    const deliveryDate = params?.deliveryDate ? this.commonService.valueToFormDate(params.deliveryDate) : this.commonService.getDefaultFormStringValue();

    return this.formBuilder.group({
      marketTypeId: [AffairMarketType.STANDALONE_HOUSE, Validators.required],
      mlAgencyId: [null],
      userAssistantId: [null],
      userTechnicianId: [null],
      agencyId: [null, Validators.required],
      studyOfficeId: [null],
      workSiteStreetNumber: workSiteStreetNumber,
      workSiteAddress: [workSiteAddress, Validators.required],
      workSiteAdditionalAddress: null,
      workSitePostalCode: [workSitePostalCode, Validators.required],
      workSiteLocality: [workSiteLocality, Validators.required],
      finalCustomerLastname: [finalCustomerLastname, {
        validators: Validators.required,
        updateOn: 'blur',
      }],
      finalCustomerFirstname: finalCustomerFirstname,
      finalCustomerLastnameSecondary: '',
      finalCustomerFirstnameSecondary: '',
      finalCustomerEmail: '',
      finalCustomerPhone: '',
      finalCustomerEmailSecondary: '',
      finalCustomerPhoneSecondary: '',
      finalCustomerStreetNumber: '',
      finalCustomerAddress: '',
      finalCustomerAdditionalAddress: '',
      finalCustomerPostalCode: '',
      finalCustomerLocality: '',
      folderNumber: '',
      customerRef: '',
      invoiceComment: '',
      constructionYear: '',
      costEstimateRef: '',
      deliveryDate: deliveryDate,
      affairFields: [],
      line: line,
      excel_id: excel_id,
      sheet_name: sheet_name,
      product: product,
      goal: goal,
    });
  }

  public getInvoicesPreferencesForm(affair: any): FormGroup {
    let mlAgencyId = null;
    let payerPreference = '';
    let payerName = '';
    let payerAccountingCode = '';
    let payerAddress = '';
    let payerPostalCode = '';
    let payerLocality = '';
    let affairInvoicing = '';
    let billingPreference = '';
    if (affair) {
      const pref = affair.affairInvoicingPreferences;
      mlAgencyId = affair.mlAgencyId;
      if (pref != null) {
        payerPreference = pref.payerPreference.toString();
        payerName = pref.payerName;
        payerAccountingCode = pref.payerAccountingCode;
        payerAddress = pref.payerAddress;
        payerPostalCode = pref.payerPostalCode;
        payerLocality = pref.payerLocality;
        affairInvoicing = pref.affairInvoicing.toString();
        billingPreference = pref.billingPreference;
      }
    }
    return this.formBuilder.group({
      mlAgencyId: [mlAgencyId, Validators.required],
      payerPreference: [payerPreference, Validators.required],
      payerName: [payerName, Validators.required],
      payerAccountingCode: [payerAccountingCode, Validators.required],
      payerAddress: payerAddress,
      payerPostalCode: [payerPostalCode, Validators.required],
      payerLocality: [payerLocality, Validators.required],
      affairInvoicing: affairInvoicing,
    });
  }

  public getFiltersForm(formValue?: any, marketTypeId?: number): FormGroup {
    let selectedMarketsTypes = this.commonService.getDefaultFormNullValue();
    if (marketTypeId) {
      if (marketTypeId === AffairMarketType.COLLECTIVE) {
        selectedMarketsTypes = [{ label: marketTypeId, value: marketTypeId }, { label: AffairMarketType.TERTIARY.toString(), value: AffairMarketType.TERTIARY }];
      } else {
        selectedMarketsTypes = [{ label: marketTypeId, value: marketTypeId }];
      }
    } else if (formValue?.selectedMarketsTypes) {
      selectedMarketsTypes = this.commonService.valueToFormSelect(formValue.selectedMarketsTypes);
    }
    const selectedAgencies = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
    const agenciesMarketsTypes = formValue
      ? this.commonService.valueToFormSelect(formValue.agenciesMarketsTypes) : this.commonService.getDefaultFormNullValue();
    const agenciesCustomersTypes = formValue
      ? this.commonService.valueToFormSelect(formValue.agenciesCustomersTypes) : this.commonService.getDefaultFormNullValue();
    const agenciesIndicatorsCustomers = formValue
      ? this.commonService.valueToFormSelect(formValue.agenciesIndicatorsCustomers) : this.commonService.getDefaultFormNullValue();
    const selectedAssistants = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAssistants) : this.commonService.getDefaultFormNullValue();
    const selectedTechnicians = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedTechnicians) : this.commonService.getDefaultFormNullValue();
    const selectedMlAgencies = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedMlAgencies) : this.commonService.getDefaultFormNullValue();
    const selectedAffairsGoals = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAffairsGoals) : this.commonService.getDefaultFormNullValue();
    const selectedAffairsStatuses = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAffairsStatuses) : this.commonService.getDefaultFormNullValue();
    const selectedProductsStatuses = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedProductsStatuses) : this.commonService.getDefaultFormNullValue();
    const selectedPerformances = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedPerformances) : this.commonService.getDefaultFormNullValue();
    const selectedWorksCompanies = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedWorksCompanies) : this.commonService.getDefaultFormNullValue();
    const selectedAffairsMonitoringTypes = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedAffairsMonitoringTypes) : this.commonService.getDefaultFormNullValue();
    const selectedTags = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedTags) : this.commonService.getDefaultFormNullValue();
    const affairMonitoringTypeLevel = formValue
      ? this.commonService.valueToFormNumber(formValue.affairMonitoringTypeLevel) : 1;
    const isAfterIntervention = formValue
      ? formValue.isAfterIntervention : null;
    const selectedProducts = formValue
      ? this.commonService.valueToFormSelect(formValue.selectedProducts) : this.commonService.getDefaultFormNullValue();
    const dateCreationMin = formValue
      ? this.commonService.valueToFormDate(formValue.dateCreationMin) : this.commonService.getDefaultFormNullValue();
    const dateCreationMax = formValue
      ? this.commonService.valueToFormDate(formValue.dateCreationMax) : this.commonService.getDefaultFormNullValue();
    const dateProductReceiptMin = formValue
      ? this.commonService.valueToFormDate(formValue.dateProductReceiptMin) : this.commonService.getDefaultFormNullValue();
    const dateProductReceiptMax = formValue
      ? this.commonService.valueToFormDate(formValue.dateProductReceiptMax) : this.commonService.getDefaultFormNullValue();
    const dateProductRealisationMin = formValue
      ? this.commonService.valueToFormDate(formValue.dateProductRealisationMin) : this.commonService.getDefaultFormNullValue();
    const dateProductRealisationMax = formValue
      ? this.commonService.valueToFormDate(formValue.dateProductRealisationMax) : this.commonService.getDefaultFormNullValue();
    const dateInterventionMin = formValue
      ? this.commonService.valueToFormDate(formValue.dateInterventionnMin) : this.commonService.getDefaultFormNullValue();
    const dateInterventionMax = formValue
      ? this.commonService.valueToFormDate(formValue.dateInterventionMax) : this.commonService.getDefaultFormNullValue();
    const page = formValue
      ? formValue.page : 1;
    const size = formValue
      ? formValue.page : 10;
    const search = formValue
      ? formValue.search : this.commonService.getDefaultFormStringValue();
    const sortField = formValue
      ? formValue.sortField : this.commonService.getDefaultFormNullValue();
    const sortOrder = formValue
      ? formValue.sortOrder : this.commonService.getDefaultFormNullValue();
    return this.formBuilder.group({
      selectedAgencies: [selectedAgencies],
      agenciesMarketsTypes: [agenciesMarketsTypes],
      agenciesCustomersTypes: [agenciesCustomersTypes],
      agenciesIndicatorsCustomers: [agenciesIndicatorsCustomers],
      selectedMarketsTypes: [selectedMarketsTypes],
      selectedAssistants: [selectedAssistants],
      selectedTechnicians: [selectedTechnicians],
      selectedMlAgencies: [selectedMlAgencies],
      selectedAffairsGoals: [selectedAffairsGoals],
      selectedAffairsStatuses: [selectedAffairsStatuses],
      selectedProductsStatuses: [selectedProductsStatuses],
      selectedProducts: [selectedProducts],
      selectedPerformances: [selectedPerformances],
      selectedWorksCompanies: [selectedWorksCompanies],
      selectedAffairsMonitoringTypes: [selectedAffairsMonitoringTypes],
      selectedTags: [selectedTags],
      isAfterIntervention: [isAfterIntervention],
      affairMonitoringTypeLevel: [affairMonitoringTypeLevel],
      dateCreationMin: [dateCreationMin],
      dateCreationMax: [dateCreationMax],
      dateProductReceiptMin: [dateProductReceiptMin],
      dateProductReceiptMax: [dateProductReceiptMax],
      dateProductRealisationMin: [dateProductRealisationMin],
      dateProductRealisationMax: [dateProductRealisationMax],
      dateInterventionMin: [dateInterventionMin],
      dateInterventionMax: [dateInterventionMax],
      size: size,
      page: page,
      search: search,
      sortField: sortField,
      sortOrder: sortOrder,
      individualSearch: new FormArray([]),
    });
  }

  public createAffairFieldForm(field: any, affair: Affair): FormGroup {
    // In case of field of type date,
    // we need to put the value to null.
    // Otherwise, the form will consider this input has invalid during the submit
    let defaultValue = field.value;
    if (field.value === '' && field.type === 'date') {
      defaultValue = null;
    }
    if (field.value === undefined && field.id === this.PERMIT_SIGNATORY_ID) {
      defaultValue = affair.agency.permitSignatory;
    }
    return this.formBuilder.group({
      id: [field.id, Validators.required],
      label: field.name,
      type: field.type,
      value: defaultValue,
    });
  }

  private formatForm(form: FormGroup): any {
    const values = this.formFormatterService.createFormCopy(form);
    values.agencyId = this.formFormatterService.formatSelectSingle(values.agencyId);
    values.mlAgencyId = this.formFormatterService.formatSelectSingle(values.mlAgencyId);
    values.affairsGoalsIds = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsGoalsIds);
    values.performances = this.formFormatterService.formatSelectMultipleToIntArray(values.performances);
    if (values.affairFields) {
      values.affairFields.forEach((value) => {
        if (value.type === 'date') {
          value.value = value.value ? new Date(value.value).toDateString() : null;
        }
      });
    }
    return values;
  }

  private formatCreateForInspectionForm(form: FormGroup): any {
    const values = this.formFormatterService.createFormCopy(form);
    values.agencyId = this.formFormatterService.formatSelectSingle(values.agencyId);
    return values;
  }

  private formatListForm(form: FormGroup): any {
    return {
      selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
      agenciesMarketsTypes: this.formFormatterService.formatSelectMultiple(form.value.agenciesMarketsTypes),
      agenciesCustomersTypes: this.formFormatterService.formatSelectMultiple(form.value.agenciesCustomersTypes),
      agenciesIndicatorsCustomers: this.formFormatterService.formatSelectMultiple(form.value.agenciesIndicatorsCustomers),
      selectedMarketsTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedMarketsTypes),
      selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
      selectedAssistants: this.formFormatterService.formatSelectMultiple(form.value.selectedAssistants),
      selectedTechnicians: this.formFormatterService.formatSelectMultiple(form.value.selectedTechnicians),
      selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
      selectedProductsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedProductsStatuses),
      selectedAffairsGoals: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsGoals),
      selectedAffairsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsStatuses),
      selectedAffairsMonitoringTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsMonitoringTypes),
      selectedTags: this.formFormatterService.formatSelectMultiple(form.value.selectedTags),
      affairMonitoringTypeLevel: form.value.affairMonitoringTypeLevel,
      isAfterIntervention: form.value.isAfterIntervention,
      dateCreationMin: this.formFormatterService.formatDate(form.value.dateCreationMin),
      dateCreationMax: this.formFormatterService.formatDate(form.value.dateCreationMax),
      dateProductReceiptMin: this.formFormatterService.formatDate(form.value.dateProductReceiptMin),
      dateProductReceiptMax: this.formFormatterService.formatDate(form.value.dateProductReceiptMax),
      dateProductRealisationMin: this.formFormatterService.formatDate(form.value.dateProductRealisationMin),
      dateProductRealisationMax: this.formFormatterService.formatDate(form.value.dateProductRealisationMax),
      dateInterventionMin: this.formFormatterService.formatDate(form.value.dateInterventionMin),
      dateInterventionMax: this.formFormatterService.formatDate(form.value.dateInterventionMax),
      page: form.value.page,
      size: form.value.size,
      search: form.value.search,
      sortField: form.value.sortField,
      sortOrder: form.value.sortOrder,
      individualSearch: form.value.individualSearch,
    };
  }

  public redrawDataTable(form: any): void {
    const affairDatatableParams: AffairDatatableParameters = {
      selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
      selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
    };
    super.redrawDataTable(affairDatatableParams);
  }

  public generateListExport(form: FormGroup, choice: number): Observable<HttpResponse<Blob>> {
    return this.apiService.postBlob(`${this.route}/list/export`, { ...this.formatListForm(form), choice: choice });
  }

  public reset(): void {
    this.affair$.next(null);
  }

  public resetForAdd(): void {
    this.affairForAdd$.next(null);
  }

  public checkIfDifferentId(id: number): boolean {
    const affairData = this.affair$.getValue();
    if ((Number.isNaN(id) || affairData != null) && (id === affairData.id)) {
      return false;
    }
    return true;
  }

  public getSelects(): void {
    this.apiService.get('/affairs/list/selects')
      .subscribe(
        (selects) => {
          this.affairSelects$.next(selects);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération des selections',
          });
        },
      );
  }

  public getSelectsForShow(affairId: number): Observable<any> {

    return this.apiService.get(`/affairs/show/${affairId}/selects`);
  }

  public checkIfAffairRoute(affairId: number, route: string): boolean {
    const affairRoutes = [
      `${this.route}/${affairId}/document-customer/import`,
      `${this.route}/${affairId}/document-delivered/import`,
    ];
    return affairRoutes.includes(route);
  }

  public getProgrammedStandardResponses(affairId: number): Observable<ProgrammedStandardResponse[]> {
    return this.apiService.get(`/affairs/${affairId}/programmed-standards-responses`);
  }

  public deleteProgrammedStandardResponses(affair: Affair, programmedStandardResponseId: number): Observable<any> {
    return this.apiService.delete(`/affairs/${affair.id}/programmed-standards-responses/${programmedStandardResponseId}`)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );

  }

  public getAudits(affairId: number): Observable<any> {
    return this.apiService.get(`/affairs/${affairId}/audits`);

  }

  public getForIncomingCallSheetList(params: IncomingCallSheetParameters): Observable<any> {
    return this.apiService.post('/affairs/calls/list', params)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      );
  }

  public getNoteBookProcessConditions(id: number): Observable<any> {
    return this.apiService.get(`/affairs/${id}/notebook-process/conditions`);
  }

  public getStudyDocumentPreview(mlRef: string): string {
    return `${this.apiService.getApiUrl()}${this.route}/${mlRef}/analysis/study-document`;
  }

  public getIdentitySheetPreview(mlRef: string): string {
    return `${this.apiService.getApiUrl()}${this.route}/${mlRef}/identity-sheet`;
  }

  public getRandomAffair(): Observable<Affair> {
    return this.apiService.get(`${this.route}/analysis/get-random-affair`);
  }

  public getAnalysisList(form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/analysis/list`, form.value);
  }

  public getAnalysis(mlRef: string): Observable<any> {
    return this.apiService.get(`${`${this.route}/${mlRef}`}/analysis/get`);
  }

  public getVerifications(id: number): Observable<any> {
    return this.apiService.get(`${`${this.route}/${id}`}/get-verifications`);
  }

  public getApplicationDomainVerifications(id: number): Observable<any> {
    return this.apiService.get(`${`${this.route}/${id}`}/documents-customers/verify-application-domain`);
  }

  public sendValidationResult(mlRef: string, payload: any): Observable<ErosResponse> {
    return this.apiService.put(`${`${this.route}/${mlRef}`}/analysis/send-validation-result`, payload);
  }

  public checkIfIsUnderQualityApproach(affairOfficeFile: AffairOfficeFile): boolean {
    if (affairOfficeFile && affairOfficeFile.officeFile.officesFilesIndicators) {
      // eslint-disable-next-line consistent-return
      affairOfficeFile.officeFile.officesFilesIndicators.forEach((indicator) => {
        if (indicator.key === 'CLIENT_DQ') {
          return indicator.pivot.value === 1;
        }
      });
    }
    return false;
  }

  public getForMrList(form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/mr/list`, this.formatListForm(form));
  }

  public updateTags(affairId: number, form: FormGroup): Observable<ErosResponse> {
    return this.apiService.put(`${this.route}/${affairId}/update-tags`, form.value);
  }

}
