import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErosResponse, IndicatorInterventionType, SelectModel } from '@libs/models/src';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { FormFormatterService } from './utilities/form-formatter.service';

@Injectable()
export class IndicatorInterventionTypeService extends ModelWithDatatableAndCrudObservable {

  public route = '/indicators-interventions-types';

  constructor(
    http: HttpClient,
    notifService: NotifService,
    submitButtonService: SubmitButtonService,
    swalService: SwalService,
    apiService: ApiService,
    router: Router,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private formFormatterService: FormFormatterService,
  ) {
    super(
      http,
      notifService,
      submitButtonService,
      swalService,
      apiService,
      router,
    );
  }

  public initDataTable(selector: string, columns: DataTableColumn[]): void {
    return super.initializeDataTable({
      url: `${this.route}/list`,
      selector: `#${selector}`,
      dataTableColumns: columns,
    });
  }

  public get(id: number): Observable<IndicatorInterventionType> {
    return super.get(id, this.route);
  }

  public getAll(): Observable<IndicatorInterventionType[]> {
    return super.getAll(this.route);
  }

  public getAllByMarketType(marketTypeId: number): Observable<IndicatorInterventionType[]> {
    return this.apiService.get(`${this.route}/markets-types/${marketTypeId}`);
  }

  public store(form: FormGroup): Observable<ErosResponse> {
    return super.store(this.formatForm(form), this.route);
  }

  public update(id: number, form: FormGroup): Observable<ErosResponse> {
    return super.update(id, this.formatForm(form), this.route);
  }

  public delete(id: number): Observable<ErosResponse> {
    return super.delete(id, this.route);
  }

  public getForm(indicatorInterventionType: IndicatorInterventionType, documentsCustomers: SelectModel[]): FormGroup {
    const name = indicatorInterventionType
      ? this.commonService.valueToFormString(indicatorInterventionType.name) : this.commonService.getDefaultFormStringValue();
    const marketsTypes = indicatorInterventionType
      ? indicatorInterventionType.marketsTypes.map((x) => { return { value: x.id, label: x.name }; }) : [];
    const isOrCondition = indicatorInterventionType
      ? this.commonService.valueToRadioFormString(indicatorInterventionType.isOrCondition) : this.commonService.getDefaultFormRadioValue();
    const documentsCustomersIds = indicatorInterventionType
      ? documentsCustomers.filter((x) => { return indicatorInterventionType.documentsCustomersIds.map((y) => { return y; }).includes(x.value); }) : [];
    const isRequired = indicatorInterventionType
      ? this.commonService.valueToRadioFormString(indicatorInterventionType.isRequired) : this.commonService.getDefaultFormRadioValue();

    return this.formBuilder.group({
      name: [name, Validators.required],
      marketsTypes: [marketsTypes, Validators.required],
      isOrCondition: [isOrCondition],
      documentsCustomersIds: [documentsCustomersIds],
      isRequired: [isRequired],
    });
  }

  private formatForm(form: FormGroup): any {
    const values = this.formFormatterService.createFormCopy(form);
    values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
    values.documentsCustomersIds = values.documentsCustomersIds.map((x) => { return x.value; });
    return values;

  }

}
