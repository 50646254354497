import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DashboardData, SelectModel } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { EventService, InterventionService, SwalService, UnsubscribeOnDestroy, UserService } from '@libs/services/src';
import { throwError, zip } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-technician',
  templateUrl: './dashboard-technician.component.html',
  styleUrls: ['./dashboard-technician.component.scss'],
})
export class DashboardTechnicianComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() marketTypes: MarketType[];
  @Input() days: [];
  public users: SelectModel[];
  public statsUncompleted: any;
  public statsNotCompleted: any;
  public waitingDocumentDeliveredList: DashboardData;

  constructor(
  private interventionService: InterventionService,
  private eventService: EventService,
  private swalService: SwalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initStatistics();
  }

  private initStatistics(): void {
    zip(
      this.interventionService.initUncompletedList(this.form),
      this.eventService.notCompletedList(this.form),
      this.interventionService.initWaitingDocumentDeliveredList(this.form),
    )
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe(([statsUncompleted, statsNotCompleted, waitingDocumentDeliveredList]) => {
        this.statsUncompleted = statsUncompleted;
        this.statsNotCompleted = statsNotCompleted;
        this.waitingDocumentDeliveredList = waitingDocumentDeliveredList;
      });
  }

}
