import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { InterventionListData } from '@libs/models/src';
import { InterventionService } from '@libs/services/src';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-interventions-list',
  templateUrl: './interventions-list.component.html',
  styleUrls: ['./interventions-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InterventionsListComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() marketTypeId: number;
  @Input() storageKey: string;
  @Input() refresh: boolean = false;
  @Output() refreshChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() reset: boolean = false;
  @Output() resetChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public interventionsData: InterventionListData[];
  public expandSet = new Set<number>();
  public isLoading = false;
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public total: number = 1;
  private isDestroyed$ = new Subject<void>();
  public inputSearchValue: string = '';
  public addressSearchValue: string = '';
  public marketTypeEnum = AffairMarketType;
  public listOfColumns = [];

  constructor(
    private interventionService: InterventionService,
    private storageService: FormLocalStorageService,
  ) { }

  ngOnInit(): void {
    this.initListOfColumns();
    this.initIndividualSearch(this.form, this.listOfColumns);
  }

  ngOnDestroy(): void {
    this.isDestroyed$?.next();
    this.isDestroyed$?.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.form && changes.form.previousValue !== changes.form.currentValue)) {
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.isDestroyed$),
        )
        .subscribe((form) => {
          this.storageService.store(this.storageKey, this.form);
          this.initList();
          this.inputSearchValue = this.form.value.search;
          this.addressSearchValue = this.form.value.addressSearch;
        });
    }

    if (changes.refresh && changes.refresh.previousValue !== changes.refresh.currentValue) {
      this.initList();
      this.refreshChange.emit(this.refresh);
    }
    if (changes.reset && changes.reset.previousValue !== changes.reset.currentValue) {
      this.initIndividualSearch(this.form, this.listOfColumns);
      this.resetChange.emit(this.reset);
    }
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.form.patchValue({
      search: newSearch,
    });
  }

  onInputAddressSearchChanged(newSearch: string): void {
    this.addressSearchValue = newSearch;
    this.form.patchValue({
      addressSearch: newSearch,
    });

  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  changePageIndex(event: number): void {
    this.pageIndex = event;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    const currentSort = params.sort.find((item) => { return item.value !== null; });
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.form.patchValue({
      page: this.pageIndex,
      size: this.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    });
  }

  initList(): void {
    this.isLoading = true;
    this.interventionService.initList(this.form)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (response) => {
          this.interventionsData = response.data;
          this.isLoading = false;
          this.total = response.meta.total;

          if (this.interventionsData.length === 0 && this.total > 0) {
            this.changePageIndex(1);
          }
        },
      );
  }

  onRedirectIntervention(interventionId: number): void {
    window.open(`interventions/${interventionId}/edit`);
  }

  onRefreshClicked(): void {
    this.refresh = true;
  }

  private initListOfColumns() {
    this.listOfColumns = [
      {
        title: 'Date',
        sortFn: true,
        columnKey: 'startDate',
        key: 'startDate',
        searchable: false,
        width: '100px',
      },
      {
        title: 'Client',
        key: 'customer',
        searchable: false,
      },
      {
        title: 'Affaire',
        key: 'affair',
        searchable: true,
      },
      {
        title: 'Adresse',
        key: 'address',
        searchable: true,
      },
      {
        title: 'Objet',
        key: 'name',
        searchable: true,
      },
      {
        title: 'Description',
        key: 'description',
        searchable: true,
        width: '200px',
      },
      {
        title: 'Statut',
        key: 'interventionStatus',
        searchable: false,
      },
      {
        title: 'En charge',
        key: 'assistant',
        searchable: false,
      },
      {
        title: 'Conformité',
        key: 'conformity',
        searchable: false,
      },
      {
        title: 'Données d\'entrées',
        key: 'documentsCustomers',
        searchable: false,
      },
      {
        title: 'Données de sorties',
        key: 'documentsDelivered',
        searchable: false,
      },
      {
        title: 'Suivi',
        key: 'followUp',
        searchable: false,
      },
    ];
    if (this.marketTypeId === this.marketTypeEnum.RENOVATION) {
      this.listOfColumns.push({
        title: 'Statut affaire',
        key: 'affairStatus',
        searchable: false,
      });
    }
    if (this.marketTypeId === this.marketTypeEnum.INSPECTION) {
      this.listOfColumns.push(
        {
          title: 'Lot',
          key: 'unitNumber',
          searchable: true,
        },
        {
          title: 'Date d\'engagement',
          key: 'commitmentDate',
          searchable: false,
        },
      );
    }
  }

  getIndividualSearchControls(): AbstractControl[] {
    return (this.form.get('individualSearch') as FormArray).controls;
  }

  getListOfColumnsVisibility(key: string): boolean {
    const column = this.listOfColumns.find((x) => { return x.key === key; });
    if (column) {
      return column.searchable;
    }
    return false;
  }

  initIndividualSearch(form: FormGroup, columns: any[]): void {
    const individualSearchArray = form.get('individualSearch') as FormArray;
    if (individualSearchArray.length > 0) {
      this.clearFormArray(individualSearchArray);
    }
    columns.forEach((column) => {
      individualSearchArray.push(new FormGroup({
        key: new FormControl(column.key),
        value: new FormControl(''),
      }));
    });
  }

  clearFormArray = (formArray: FormArray): void => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

}
