import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Affair } from '@libs/models/src';
import { MailService } from '@libs/services/src';
import { StringTool } from '_tools/string';

@Component({
  selector: 'app-affair-show-mails',
  templateUrl: './affair-show-mails.component.html',
  styleUrls: ['./affair-show-mails.component.scss'],
})
export class AffairShowMailsComponent implements OnInit, OnChanges {

  @Input() affair: Affair;
  public mailsData = [];

  constructor(
    private mailService: MailService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.initList();
  }

  private initList() {
    this.mailService.getByAffair(this.affair.id).subscribe((mails) => {
      this.mailsData = mails;
    });
  }

  public extractLastPartOfPath(path: string): string {
    return StringTool.extractLastPartOfPath(path);
  }

}
