import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'table-buttons',
  templateUrl: './table-buttons.component.html',
  styleUrls: ['./table-buttons.component.scss'],
})
export class TableButtonsComponent implements OnInit {

  @Input() canExport: boolean = false;
  @Output() onClear = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Output() onExport = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onClearClicked(): void {
    this.onClear.emit();
  }

  public onRefreshClicked(): void {
    this.onRefresh.emit();
  }

  public onExportClicked(): void {
    this.onExport.emit();
  }

}
