import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Region, SelectModel } from '@libs/models/src';
import { RegionService, StatisticInterventionService } from '@libs/services/src';
import { StatisticInterventionRevenueService } from '@libs/services/src/lib/statistic-intervention-revenue.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CHARTS_NAMES } from 'apps/eros-nrj/src/config/charts-names';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistics-revenues',
  templateUrl: './statistics-revenues.component.html',
  styleUrls: ['./statistics-revenues.component.scss'],
})
export class StatisticsRevenuesComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  private statsInterventionsFilters$: Subscription;
  public chartsNames = CHARTS_NAMES;
  public selectedChart = this.chartsNames.REVENUE_REGIONS;
  public technicians: SelectModel[];
  public regions: Region[];
  public formattedRegions: SelectModel[];
  public mlAgencies: SelectModel[];
  public products: SelectModel[];
  public marketsTypes: SelectModel[];
  private readonly storageKey = 'statistics-interventions-filters-form';
  public displayMode: number;

  constructor(
    private statisticIntervention: StatisticInterventionService,
    private statisticInterventionRevenue: StatisticInterventionRevenueService,
    private formLocalStorageService: FormLocalStorageService,
    private regionService: RegionService,

  ) {
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.statisticIntervention.getFilters();

    const sessionFormValue = this.formLocalStorageService.retrieve(this.storageKey);
    this.form = this.statisticIntervention.initForm(sessionFormValue);
    if (sessionFormValue) {
      this.updateCharts();
    }

    this.form.valueChanges.subscribe((form) => {
      this.formLocalStorageService.store(this.storageKey, this.form);
      this.updateCharts();
    });
  }

  ngOnDestroy(): void {
    this.statsInterventionsFilters$.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.selectedChart = changeEvent.nextId;
    this.updateCharts();
  }

  updateCharts(): void {
    if (this.form.valid) {
      if (this.selectedChart === this.chartsNames.REVENUE_REGIONS) {
        this.statisticInterventionRevenue.getStatsInterventionsRegions(this.form);
      } else if (this.selectedChart === this.chartsNames.REVENUE_TECHNICIANS) {
        this.statisticInterventionRevenue.getStatsInterventionsTechnicians(this.form);
      } else if (this.selectedChart === this.chartsNames.REVENUE_PRODUCTS) {
        this.statisticInterventionRevenue.getStatsInterventionsProducts(this.form);
      } else if (this.selectedChart === this.chartsNames.REVENUE_MAP) {
        this.statisticInterventionRevenue.getStatsInterventionsDepartments(this.form);
      }
      this.displayMode = this.form.value.displayMode;
    }
  }

  private initSubscriptions() {
    this.statsInterventionsFilters$ = this.statisticIntervention.statsInterventionsFilters$.subscribe(
      (data) => {
        if (data) {
          this.technicians = data.technicians;
          this.regions = data.regions;
          this.mlAgencies = data.mlAgencies;
          this.products = data.products;
          this.marketsTypes = data.marketsTypes;
          this.formattedRegions = this.regionService.getFormattedRegions(this.regions);
        }
      },
    );
  }

}
