// Angular Core
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Configs
import { CoreModule } from '@eros-front/core';
import { SharedModule } from '@libs/shared/src';
import { AuthGuardService, AuthModule } from '@eros-front/auth';
import { ApiModule } from '@eros-front/api';
import { InskyModule } from '@eros-front/insky';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { AngularLaravelEchoModule } from 'angular-laravel-echo';
import { LayoutsModule } from '@libs/layouts/src';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { APPKEY } from '@libs/core/src/lib/core.shared';
import { environment } from '../environments/environment';

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

// Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AffairShowInformationsComponent } from './affairs/affair-show/affair-show-informations/affair-show-informations.component';
import { AffairShowComponent } from './affairs/affair-show/affair-show.component';
import { StatisticsVolumesComponent } from './statistics/statistics-volumes/statistics-volumes.component';
import { StatisticFiltersComponent } from './statistics/statistic-filters/statistic-filters.component';
import { StatisticsVolumesRegionsComponent } from './statistics/statistics-volumes/statistics-volumes-regions/statistics-volumes-regions.component';
import { StatisticSettingsComponent } from './statistics/statistic-settings/statistic-settings.component';
import { StatisticsVolumesTechniciansComponent } from './statistics/statistics-volumes/statistics-volumes-technicians/statistics-volumes-technicians.component';
import { StatisticsVolumesProductsComponent } from './statistics/statistics-volumes/statistics-volumes-products/statistics-volumes-products.component';
import { StatisticsRevenuesRegionsComponent } from './statistics/statistics-revenues/statistics-revenues-regions/statistics-revenues-regions.component';
import { StatisticsRevenuesProductsComponent } from './statistics/statistics-revenues/statistics-revenues-products/statistics-revenues-products.component';
import { StatisticsRevenuesTechniciansComponent } from './statistics/statistics-revenues/statistics-revenues-technicians/statistics-revenues-technicians.component';
import { StatisticsRevenuesComponent } from './statistics/statistics-revenues/statistics-revenues.component';
import { StatisticsVolumesMapComponent } from './statistics/statistics-volumes/statistics-volumes-map/statistics-volumes-map.component';
import { StatisticsRevenuesMapComponent } from './statistics/statistics-revenues/statistics-revenues-map/statistics-revenues-map.component';
import { DashboardSettingsComponent } from './dashboard/dashboard-settings/dashboard-settings.component';
import { DashboardAffairsVolumeComponent } from './dashboard/dashboard-affairs-volume/dashboard-affairs-volume.component';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { IncomingCallSheetComponent } from './incoming-call-sheet/incoming-call-sheet.component';
import { IncomingCallSheetContactInformationsComponent } from './incoming-call-sheet/incoming-call-sheet-contact-informations/incoming-call-sheet-contact-informations.component';
import { IncomingCallSheetEmailsComponent } from './incoming-call-sheet/incoming-call-sheet-emails/incoming-call-sheet-emails.component';
import { IncomingCallSheetAffairsComponent } from './incoming-call-sheet/incoming-call-sheet-affairs/incoming-call-sheet-affairs.component';
import { InterventionTagComponent } from './incoming-call-sheet/intervention-tag/intervention-tag.component';
import { DashboardAssistantComponent } from './dashboard/dashboard-assistant/dashboard-assistant.component';
import { DashboardTechnicianComponent } from './dashboard/dashboard-technician/dashboard-technician.component';
import { InspectionsCustomerComponent } from './inspections/customer/inspections-customer.component';
import { InspectionsGlossaryComponent } from './inspections/glossary/inspections-glossary.component';
import { InspectionsPeriodComponent } from './inspections/period/inspections-period.component';
import { InspectionsPeriodEditModalComponent } from './inspections/period/inspections-period-edit-modal/inspections-period-edit-modal.component';
import { InspectionsBaseLotComponent } from './inspections/base-lot/inspections-base-lot/inspections-base-lot.component';
import { InspectionsHistoryComponent } from './inspections/history/inspections-history.component';
import { InspectionsPeriodRulesComponent } from './inspections/period/inspections-period-rules/inspections-period-rules.component';
import { InspectionsBetaComponent } from './inspections/inspections-beta/inspections-beta.component';
import { InspectionsModalCallComponent } from './inspections/customer/inspections-modal-call/inspections-modal-call.component';
import { BarTypeService } from './inspections/services/bar-type.service';
import { DateService } from './inspections/services/date.service';
import { InspectionsSetCreateComponent } from './inspections/set/create/inspections-set-create.component';
import { InspectionsSetManagerComponent } from './inspections/set/manager/inspections-set-manager.component';
import { ModelesComponent } from './inspections/modeles/modeles.component';
import { BarTypesIndexComponent } from './inspections/bar-types/bar-types-index/bar-types-index.component';
import { BarTypeModalComponent } from './inspections/bar-types/bar-type-modal/bar-type-modal.component';
import { InspectionsGridLotComponent } from './inspections/inspections-grid-lot/inspections-grid-lot.component';
import { BeneficiaryEditModalComponent } from './inspections/inspections-grid-lot/beneficiary-edit-modal/beneficiary-edit-modal.component';
import { OverviewComponent } from './overview/overview.component';
import { OverviewAssistantComponent } from './overview/overview-assistant/overview-assistant.component';
import { OverviewItemsListComponent } from './overview/overview-pages/overview-items-list/overview-items-list.component';
import { OverviewAffairDetailComponent } from './overview/overview-pages/overview-affair-detail/overview-affair-detail.component';
import { OverviewAffairInterventionsListComponent } from './overview/overview-pages/overview-affair-detail/overview-affair-interventions-list/overview-affair-interventions-list.component';
import { OverviewAffairEventsListComponent } from './overview/overview-pages/overview-affair-detail/overview-affair-events-list/overview-affair-events-list.component';
import { OverviewAffairProductsListComponent } from './overview/overview-pages/overview-affair-detail/overview-affair-products-list/overview-affair-products-list.component';
import { OverviewAffairMonitoringListComponent } from './overview/overview-pages/overview-affair-detail/overview-affair-monitoring-list/overview-affair-monitoring-list.component';
import { OverviewItemCardComponent } from './overview/overview-pages/overview-items-list/overview-item-card/overview-item-card.component';
import { OverviewAffairDocumentsCustomersListComponent } from './overview/overview-pages/overview-affair-detail/overview-affair-documents-customers-list/overview-affair-documents-customers-list.component';
import { OverviewAffairDocumentsDeliveredListComponent } from './overview/overview-pages/overview-affair-detail/overview-affair-documents-delivered-list/overview-affair-documents-delivered-list.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardSettingsComponent,
    AppWithSidebarComponent,
    AffairShowComponent,
    AffairShowInformationsComponent,
    StatisticsVolumesComponent,
    StatisticFiltersComponent,
    StatisticsVolumesRegionsComponent,
    StatisticsVolumesTechniciansComponent,
    StatisticsVolumesProductsComponent,
    StatisticsVolumesMapComponent,
    StatisticSettingsComponent,
    StatisticsRevenuesComponent,
    StatisticsRevenuesRegionsComponent,
    StatisticsRevenuesProductsComponent,
    StatisticsRevenuesTechniciansComponent,
    StatisticsRevenuesMapComponent,
    DashboardAffairsVolumeComponent,
    LoginLayoutComponent,
    IncomingCallSheetComponent,
    IncomingCallSheetContactInformationsComponent,
    IncomingCallSheetEmailsComponent,
    IncomingCallSheetAffairsComponent,
    InterventionTagComponent,
    DashboardAssistantComponent,
    DashboardTechnicianComponent,
    DashboardTechnicianComponent,
    InspectionsCustomerComponent,
    InspectionsGlossaryComponent,
    InspectionsPeriodComponent,
    InspectionsPeriodEditModalComponent,
    InspectionsBaseLotComponent,
    InspectionsHistoryComponent,
    InspectionsPeriodRulesComponent,
    InspectionsBetaComponent,
    InspectionsModalCallComponent,
    InspectionsBaseLotComponent,
    InspectionsSetCreateComponent,
    InspectionsSetManagerComponent,
    BarTypesIndexComponent,
    BarTypeModalComponent,
    ModelesComponent,
    InspectionsGridLotComponent,
    BeneficiaryEditModalComponent,
    OverviewComponent,
    OverviewAssistantComponent,
    OverviewItemsListComponent,
    OverviewAffairDetailComponent,
    OverviewAffairInterventionsListComponent,
    OverviewAffairEventsListComponent,
    OverviewAffairProductsListComponent,
    OverviewAffairMonitoringListComponent,
    OverviewItemCardComponent,
    OverviewAffairDocumentsCustomersListComponent,
    OverviewAffairDocumentsDeliveredListComponent,
  ],
  imports: [
    CoreModule,
    SharedModule,
    LayoutsModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    AuthModule.forRoot(environment.apiUrl),
    ApiModule.forRoot(environment.apiUrl),
    InskyModule.forRoot(environment.controleUrl),
    AngularLaravelEchoModule,
    NzUploadModule,
    NzSelectModule,
    NzCollapseModule,
    NzDividerModule,
    NzSpinModule,
    NzAutocompleteModule,
  ],
  providers: [
    { provide: APPKEY, useValue: environment.appKey },
    AuthGuardService,
    BarTypeService,
    DateService,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
