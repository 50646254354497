import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { AffairList } from '@libs/models/src';
import { AffairService, NzTableExpandWithIndividualSearch, SwalService } from '@libs/services/src';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { LocalStorageService } from 'ngx-webstorage';
import { throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-affairs-list',
  templateUrl: './affairs-list.component.html',
  styleUrls: ['./affairs-list.component.scss'],
})
export class AffairsListComponent extends NzTableExpandWithIndividualSearch implements OnInit, OnDestroy, OnChanges {

  @Input() form: FormGroup;
  @Input() marketTypeId: number;
  @Input() storageKey: string;
  @Input() storageColumnsKey: string;
  @Input() reset: boolean = false;
  @Input() refresh: boolean = false;
  public affairsData: AffairList[] = [];
  public marketTypeEnum = AffairMarketType;

  constructor(
    private affairService: AffairService,
    private storageService: FormLocalStorageService,
    private swalService: SwalService,
    private localStorageService: LocalStorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initListOfColumns();
    this.initIndividualSearch(this.form, this.listOfColumns);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.form && changes.form.previousValue !== changes.form.currentValue)) {
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.isDestroyed$),
        )
        .subscribe((form) => {
          this.storageService.store(this.storageKey, this.form);
          this.initList();
          this.inputSearchValue = this.form.value.search;
        });
    }
    if (changes.refresh && changes.refresh.previousValue !== undefined && changes.refresh.previousValue !== changes.refresh.currentValue) {
      this.initList();
    }
    if (changes.reset && changes.reset.previousValue !== changes.reset.currentValue) {
      this.initListOfColumns();
      this.initIndividualSearch(this.form, this.listOfColumns);
    }

  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.form.patchValue({
      search: newSearch,
    });
  }

  onRedirectAffair(id: number): void {
    window.open(`affairs/${id}/show`);
  }

  hideColumn(i: number): void {
    this.listOfColumns[i].visible = !this.listOfColumns[i].visible;
    this.initIndividualSearch(this.form, this.listOfColumns);
    localStorage.setItem(this.storageColumnsKey, JSON.stringify(this.listOfColumns));
  }

  getListOfDisplayColumns(): any {
    return this.listOfColumns.filter((x) => { return x.visible; });
  }

  isColumnDisplay(key: string): boolean {
    const column = this.listOfColumns.find((x) => { return x.key === key; });
    if (column) {
      return column.visible;
    }
    return false;
  }

  private initList(): void {
    this.isLoading = true;
    this.affairService.initList(this.form)
      .pipe(
        takeUntil(this.isDestroyed$),
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (response) => {
          this.affairsData = response.data;
          this.total = response.meta.total;

          if (this.affairsData.length === 0 && this.total > 0) {
            this.changePageIndex(1);
          }
        },
      );
  }

  private initListOfColumns() {
    if (localStorage.getItem(this.storageColumnsKey)) {
      this.listOfColumns = JSON.parse(localStorage.getItem(this.storageColumnsKey));
    } else {
      this.listOfColumns = [
        {
          title: 'Référence',
          columnKey: 'mlRef',
          key: 'mlRef',
          searchable: true,
          visible: true,
          width: '100px',
        },
        {
          title: 'Nom(s) et prénom(s)',
          columnKey: 'finalCustomersNames',
          key: 'finalCustomersNames',
          searchable: true,
          visible: true,
          width: '100px',
        },
        {
          title: 'Adresse',
          columnKey: 'workSiteAddress',
          key: 'workSiteAddress',
          searchable: true,
          visible: true,
          width: '100px',
        },
        {
          title: 'CP',
          columnKey: 'workSitePostalCode',
          key: 'workSitePostalCode',
          searchable: true,
          visible: true,
          width: '50px',
        },
        {
          title: 'Ville',
          columnKey: 'workSiteLocality',
          key: 'workSiteLocality',
          searchable: true,
          visible: true,
          width: '80px',
        },
        {
          title: 'Client',
          columnKey: 'agencyName',
          key: 'agencyName',
          searchable: false,
          visible: true,
          width: '80px',
        },
        {
          title: 'Référence(s) client',
          columnKey: 'customerRef',
          key: 'customerRef',
          searchable: true,
          visible: true,
          width: '80px',
        },
        {
          title: 'Référence devis',
          columnKey: 'costEstimateRef',
          key: 'costEstimateRef',
          searchable: true,
          visible: true,
          width: '50px',
        },
        {
          title: 'En charge',
          columnKey: 'inCharge',
          key: 'inCharge',
          searchable: false,
          visible: true,
          width: '100px',
        },
        {
          title: 'Produits',
          columnKey: 'products',
          key: 'products',
          searchable: false,
          visible: true,
          width: '100px',
        },
        {
          title: 'Suivi',
          columnKey: 'affairsMonitoring',
          key: 'affairsMonitoring',
          searchable: false,
          visible: true,
          width: '100px',
        },
      ];
      if (this.marketTypeId === this.marketTypeEnum.RENOVATION) {
        this.listOfColumns.push(
          {
            title: 'N°ANAH / ECFR',
            key: 'anahFolderNumber',
            searchable: true,
            visible: true,
          },
          {
            title: 'Statut affaire',
            key: 'affairStatus',
            searchable: false,
            visible: true,
          },
          {
            title: 'Note admin.',
            columnKey: 'administrativeInternalNote',
            key: 'administrativeInternalNote',
            searchable: true,
            visible: true,
            width: '100px',
          },
          {
            title: 'Note tech.',
            columnKey: 'technicalInternalNote',
            key: 'technicalInternalNote',
            searchable: true,
            visible: true,
            width: '100px',
          },
          {
            title: 'Date intervention',
            columnKey: 'interventionDate',
            key: 'interventionDate',
            searchable: false,
            visible: true,
            width: '100px',
          },
          {
            title: 'Technicien intervention',
            columnKey: 'interventionTechnician',
            key: 'interventionTechnician',
            searchable: true,
            visible: true,
            width: '100px',
          },

        );
      }
      if (this.marketTypeId === this.marketTypeEnum.INSPECTION) {
        this.listOfColumns.push(
          {
            title: 'Statut affaire',
            key: 'affairStatus',
            visible: true,
            searchable: false,
          },
          {
            title: 'N° Lot',
            columnKey: 'unitNumber',
            key: 'unitNumber',
            visible: true,
            searchable: true,
          },
        );
      }
    }

  }

}
