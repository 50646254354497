import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Contact, CustomerType, IndicatorCustomer, MlAgency, MlSociety, Product, SelectModel, User } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { AgencyService, BrandService, CustomerService, GroupService, NotifService, SelectService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, zip } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';
import Swal from 'sweetalert2';

type TAB_TYPE = 'INFO_GEN' | 'INVOICING' | 'PRICING' | 'TECHNICAL' | 'CONTACTS' | 'ORGANIZATION_CHART' | 'OFFICE_FILE' | 'SHUTTLE_SHEET' | 'SYNC' | 'APPLICATION_DOMAIN' | 'AGENCY_AREA';

@Component({
  selector: 'app-customers-show',
  templateUrl: './customers-show.component.html',
  styleUrls: ['./customers-show.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersShowComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public appKey: string;
  public customer: any;
  private customer$: Subscription;
  public entities: any[] = [];
  private entities$: Subscription;
  public products: Product[] = [];
  public companies: MlSociety[] = [];
  public mlAgencies: MlAgency[] = [];
  public assistants: User[] = [];
  public technicians: User[] = [];
  public marketsTypes: MarketType[] = [];
  public customersTypes: CustomerType[] = [];
  public indicatorsCustomers: IndicatorCustomer[] = [];
  public paymentDeadlines: SelectModel[] = [];
  public paymentDeadlinesTypes: SelectModel[] = [];
  public paymentsPreferences: SelectModel[];
  public paymentsModes: any[] = [];
  public billingPreferences = [];
  public payerPreferences = [];
  public librariesBuilder = [];
  public professions = [];
  public mlSocieties = [];
  public contacts: Contact[] = [];
  public payers: any[] = [];
  private payers$: Subscription;
  public informationsForm: FormGroup;
  public technicalForm: FormGroup;
  public pricesForm: FormGroup;
  private group$: Subscription;
  public group: any;
  public faHome = faHome;
  public activeTab: TAB_TYPE = 'INFO_GEN';
  public activeTabIndex: number = 0;
  private zip$: Subscription;
  public contactSearch: string = '';

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private selectService: SelectService,
    private customerService: CustomerService,
    private groupService: GroupService,
    private brandService: BrandService,
    private agencyService: AgencyService,
    private spinner: NgxSpinnerService,
    private notifService: NotifService,
  ) {
    super();
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.spinner.show('load-customer');
      this.customerService.getEntityByCustomerRef(params.get('id'));
      this.customerService.getSelectsForConsultCustomers();
      this.customerService.getGroupByCustomerRef(params.get('id'));

      const contactSearch = params.get('contactSearch');
      if (contactSearch) {
        this.activeTab = 'CONTACTS';
        this.activeTabIndex = 3;
        this.contactSearch = contactSearch;
      }
    });
    this.route.data.subscribe((data) => {
      this.appKey = data.appKey;
    });
  }

  ngOnDestroy(): void {
    this.customer$.unsubscribe();
    this.entities$.unsubscribe();
    this.payers$.unsubscribe();
    this.group$.unsubscribe();
    this.zip$.unsubscribe();
    this.customerService.reset();
  }

  isGroup(): boolean {
    if (this.customer != null && Object.prototype.hasOwnProperty.call(this.customer, 'ref')) {
      return this.customer.ref.includes('-00-00');
    }
    return false;
  }

  isBrand(): boolean {
    if (this.customer != null && Object.prototype.hasOwnProperty.call(this.customer, 'ref')) {
      return !this.isGroup() && this.customer.ref.includes('-00');
    }
    return false;
  }

  isAgency(): boolean {
    if (this.customer != null && Object.prototype.hasOwnProperty.call(this.customer, 'ref')) {
      return !this.isGroup() && !this.isBrand();
    }
    return false;
  }

  onTabChanged(event): void {
    this.activeTab = event;
  }

  isInformationsFormValid(): boolean {
    return this.informationsForm.valid;
  }

  isPricesFormValid(): boolean {
    return this.pricesForm.valid;
  }

  onSaveClicked(): void {
    if (this.isInformationsFormValid()) {
      if (this.isGroup()) {
        this.groupService.update(this.customer.id, this.informationsForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          },
        );
      } else if (this.isBrand()) {
        this.brandService.update(this.customer.id, this.informationsForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          },
        );
      } else if (this.isAgency()) {
        this.agencyService.update(this.customer.id, this.informationsForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          },
        );
      }
    }
  }

  onSavePrices(): void {
    if (this.isPricesFormValid()) {
      Swal.fire({
        title: 'Voulez-vous mettre à jour les tarifs des affaires non facturées ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.pricesForm.patchValue({ applyAll: result.value });
        }
        if (this.isGroup()) {
          this.groupService.updatePrices(this.customer.id, this.pricesForm).subscribe(
            (success) => {
              this.refreshAfterUpdate(success);
            },
          );
        } else if (this.isBrand()) {
          this.brandService.updatePrices(this.customer.id, this.pricesForm).subscribe(
            (success) => {
              this.refreshAfterUpdate(success);
            },
          );
        } else if (this.isAgency()) {
          this.agencyService.updatePrices(this.customer.id, this.pricesForm).subscribe(
            (success) => {
              this.refreshAfterUpdate(success);
            },
          );
        }
      });
    }
  }

  refreshAfterUpdate(success: any): void {
    this.notifService.showSuccessNotif(success);
    this.customerService.refreshEntityByCustomerRef(this.customer.ref);
  }

  private initSubscriptions() {
    this.customer$ = this.customerService.refreshEntityByCustomerRef$.subscribe(
      (entity: any) => {
        if (entity) {
          this.customer = entity;
          this.contacts = this.customer.contacts;
          this.initInformationsForm();
          this.initPricesForm();
          this.customerService.initMlSocietiesCheckboxes(this.informationsForm, this.mlSocieties, this.customer);
        }
      },
    );
    this.payers$ = this.selectService.selectPayersByAgency$.subscribe(
      (payers) => {
        if (payers) {
          this.payers = payers;
        }
      },
    );

    this.zip$ = zip(
      this.customerService.entityByCustomerRef$,
      this.customerService.selects$,
    ).subscribe(
      ([entity, selects]) => {
        if (entity && selects) {
          this.customer = entity;
          this.customerService.getEntitiesFromSourceRef(entity.ref);
          this.contacts = this.customer.contacts;
          if (this.isAgency()) {
            this.selectService.getPayersByAgencySelect(entity.id);
          }
          this.customerService.getGroupByCustomerRef(this.customer.ref);
          this.spinner.hide('load-customer');

          this.products = selects.products;
          this.companies = selects.mlCompanies;
          this.mlAgencies = selects.mlAgencies;
          this.assistants = selects.assistants;
          this.technicians = selects.technicians;
          this.marketsTypes = this.marketsAndCustomersTypesDataToArray(selects.marketsTypes);
          this.customersTypes = this.marketsAndCustomersTypesDataToArray(selects.customersTypes);
          this.indicatorsCustomers = this.indicatorsCustomersDataToArray(selects.indicatorsCustomers);
          this.paymentDeadlines = selects.paymentDeadlines;
          this.paymentDeadlinesTypes = selects.paymentDeadlineTypes;
          this.billingPreferences = selects.billingPreferences;
          this.payerPreferences = selects.payerPreferences;
          this.librariesBuilder = selects.librariesBuilder;
          this.professions = selects.professions;
          this.mlSocieties = selects.mlSocieties;
          this.paymentsModes = selects.paymentsModes;
          this.paymentsPreferences = selects.paymentsPreferences;
          this.initInformationsForm();
          this.initPricesForm();
          this.initTechnicalForm();
          this.titleService.setTitle(`Gestion client : ${entity.name}${tabSuffix}`);
        }
      },
    );
    this.entities$ = this.customerService.entitiesBySourceRef$.subscribe(
      (data) => {
        if (data) {
          this.entities = this.entitiesDataToArray(data);
        }
      },
    );
    this.group$ = this.customerService.groupByCustomerRef$.subscribe(
      (data) => {
        if (data) {
          this.group = data;
        }
      },
    );
  }

  private initInformationsForm(): void {
    if (this.isGroup()) {
      this.informationsForm = this.groupService.getInformationsForm(this.customer);
    } else if (this.isBrand()) {
      this.informationsForm = this.brandService.getInformationsForm(this.customer);
    } else if (this.isAgency()) {
      this.informationsForm = this.agencyService.getInformationsForm(this.customer);
    }
    this.customerService.initMlSocietiesCheckboxes(this.informationsForm, this.mlSocieties, this.customer);
  }

  private initPricesForm(): void {
    this.pricesForm = this.customerService.getPricesForm();
  }

  private initTechnicalForm(): void {
    if (this.isAgency()) {
      this.technicalForm = this.agencyService.getTechnicalForm(this.customer);
    }
  }

  private marketsAndCustomersTypesDataToArray(data: any[]): any[] {
    const result = [];
    data.forEach((item) => {
      result.push({
        value: item.id,
        label: item.name,
        icon: `tll fa-${item.tllIcon}`,
      });
    });
    return result;
  }

  private indicatorsCustomersDataToArray(data: any[]): any[] {
    const result = [];
    data.forEach((item) => {
      result.push({
        value: item.id,
        label: item.name,
        icon: item.svgIcon,
      });
    });
    return result;
  }

  private entitiesDataToArray(data: any): any[] {
    const result = [];
    if (this.customer) {
      if (Object.prototype.hasOwnProperty.call(data, 'brands')) {
        data.brands.forEach((item) => {
          result.push(
            this.getSelectItemWithIcon(item, 'far fa-copyright'),
          );
        });
      }
      if (Object.prototype.hasOwnProperty.call(data, 'agencies')) {
        data.agencies.forEach((item) => {
          result.push(
            this.getSelectItemWithIcon(item, 'fas fa-home', `, ${item.postalCode} ${item.locality}`),
          );
        });
      }
    }
    return result;
  }

  private getSelectItemWithIcon(item: any, icon: string, additionalName?: string): any {
    let label = item.name;
    if (additionalName != null) {
      label += additionalName;
    }
    return {
      value: item.ref,
      label: label,
      icon: icon,
    };
  }

}
