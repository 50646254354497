import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthentificationService } from '@libs/auth/src';
import { DashboardData, SelectModel } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { EventService, InterventionService, NotifParam, NotifService, SwalService, UnsubscribeOnDestroy, UserService } from '@libs/services/src';
import { throwError, zip } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { AffairDashboardService } from '@libs/services/src/lib/affairs/affair-dashboard.service';

@Component({
  selector: 'app-dashboard-assistant',
  templateUrl: './dashboard-assistant.component.html',
  styleUrls: ['./dashboard-assistant.component.scss'],
})
export class DashboardAssistantComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() form: FormGroup;
  @Input() marketTypes: MarketType[];
  @Input() days: SelectModel[];
  @Input() refresh: boolean;
  @Output() refreshChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public echo: any;
  public notifParam: NotifParam;

  public userInfos: any;
  public users: SelectModel[];
  public statsCompleted: DashboardData;
  public statsAffairProducts: DashboardData;
  public statsUnconfirmed: DashboardData;
  public statsUncompleted: DashboardData;
  public dashboardVolume: DashboardData;
  public statsNotBilled: DashboardData;
  public statsXmlNeeded: DashboardData;
  public statsWaitingProgramming: DashboardData;
  public statsWaitingProgrammingRenovation: DashboardData;
  public statsEventsCompleted: DashboardData;
  public withProblemsList: DashboardData;
  public withoutPurchaseOrdersList: DashboardData;
  public waitingPaymentList: DashboardData;
  public withRecallDateList: DashboardData;
  public waitingDocumentCustomerList: DashboardData;
  public waitingDocumentSendManualList: DashboardData;
  public waitingInformationEventsList: DashboardData;

  constructor(
    private interventionService: InterventionService,
    private swalService: SwalService,
    private authService: AuthentificationService,
    private notifService: NotifService,
    private eventService: EventService,
    private affairDashboardService: AffairDashboardService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.interventionService.initCompletedList(this.form);
    /* const SocketIoClient = io;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        host: environment.webUrl,
        client: SocketIoClient,
        auth: {
          headers: {
            Authorization: `Bearer ${this.authService.getToken()}`,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
    this.echo.channel('dashboard-events').listen('.intervention-event', (e) => {
      this.initStatistics();
    }); */
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refresh && changes.refresh.previousValue !== changes.refresh.currentValue) {
      this.refreshChange.emit(this.refresh);
      this.initStatistics();
    }

  }

  public initStatistics(): void {
    zip(
      this.interventionService.initAffairProductsList(this.form),
      this.interventionService.initUnconfirmedList(this.form),
      this.interventionService.initUncompletedList(this.form),
      this.interventionService.initNotBilledList(this.form),
      this.interventionService.initXMLNeededList(this.form),
      this.interventionService.initWaitingProgrammingList(this.form),
      this.interventionService.initWaitingProgrammingRenovationList(this.form),
      this.eventService.completedList(this.form),
      this.affairDashboardService.withProblemsList(this.form),
      this.affairDashboardService.withoutPurchaseOrdersList(this.form),
      this.interventionService.initWaitingPaymentList(this.form),
      this.affairDashboardService.withRecallDateList(this.form),
      this.interventionService.initWaitingDocumentCustomerList(this.form),
      this.eventService.waitingInformationList(this.form),
      this.interventionService.initWaitingDocumentSendManualList(this.form),
    )
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe(([statsAffairProducts, statsUnconfirmed, statsUncompleted, statsNotBilled, statsXmlNeeded,
        statsWaitingProgramming, statsWaitingProgrammingRenovation, completedList, withProblemsList,
        withoutPurchaseOrdersList, waitingPaymentList, withRecallDateList, waitingDocumentCustomerList, waitingInformationEventsList, waitingDocumentSendManualList]) => {
        this.statsAffairProducts = statsAffairProducts;
        this.statsUnconfirmed = statsUnconfirmed;
        this.statsUncompleted = statsUncompleted;
        this.statsNotBilled = statsNotBilled;
        this.statsXmlNeeded = statsXmlNeeded;
        this.statsWaitingProgramming = statsWaitingProgramming;
        this.statsWaitingProgrammingRenovation = statsWaitingProgrammingRenovation;
        this.statsEventsCompleted = completedList;
        this.withProblemsList = withProblemsList;
        this.withoutPurchaseOrdersList = withoutPurchaseOrdersList;
        this.waitingPaymentList = waitingPaymentList;
        this.withRecallDateList = withRecallDateList;
        this.waitingDocumentCustomerList = waitingDocumentCustomerList;
        this.waitingInformationEventsList = waitingInformationEventsList;
        this.waitingDocumentSendManualList = waitingDocumentSendManualList;
      });
    this.interventionService.statsCompleted$.subscribe(
      (stats) => {
        this.statsCompleted = stats;
      },
    );
  }

}
