import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { Affair, AffairProduct, ProductOffer, SelectModel } from '@libs/models/src';

@Component({
  selector: 'app-affair-product-array',
  templateUrl: './affair-product-array.component.html',
  styleUrls: ['./affair-product-array.component.scss'],
})
export class AffairProductArrayComponent implements OnInit, OnChanges {

  @Input() form: FormGroup;
  @Input() techniciansItems: SelectModel[];
  @Input() affairProducts: FormArray;
  @Input() formArrayName: string;
  @Input() productsStatuses: SelectModel[] = [];
  @Input() productsUrgenciesLevels: SelectModel[] = [];
  @Input() productsOffers: ProductOffer[] = [];
  @Input() isEditMode = false;
  @Input() appKey = '';
  @Input() affair: Affair;

  constructor() { }

  ngOnInit(): void {
    console.log('this.productsOffers', this.productsOffers);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isEditMode && this.isDisplayProductStatus()) {
      if (this.affairProducts.controls.length > 0) {
        this.affairProducts.controls.forEach((control) => {
          if (!control.get('productStatusId').value) {
            control.patchValue({ productStatusId: 1 });
          }
        });
      }
    }
  }

  isDisplayProductStatus(): boolean {
    return this.appKey === 'abm' && (this.affair.marketTypeId === AffairMarketType.COLLECTIVE || this.affair.marketTypeId === AffairMarketType.TERTIARY || this.affair.marketTypeId === AffairMarketType.RENOVATION);
  }

  isDisplayProductOffer(): boolean {
    return this.appKey === 'abm' && this.affair.marketTypeId === AffairMarketType.RENOVATION;
  }

}
