import { Component, Input, OnInit } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Intervention, InterventionNonconformity, NonconformityStatusConst, Solution, SubmitButton } from '@libs/models/src';
import { NotifService, SubmitButtonService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { InterventionInskyService } from '@libs/services/src/lib/intervention-insky.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-intervention-edit-insky-solution',
  templateUrl: './intervention-edit-insky-solution.component.html',
  styleUrls: ['./intervention-edit-insky-solution.component.scss'],
})
export class InterventionEditInskySolutionComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() interventionNonconformity: InterventionNonconformity;
  @Input() intervention: Intervention;
  public nonconformityStatusEnum = NonconformityStatusConst;
  public selectedSolution: Solution = null;
  public submitButton: SubmitButton;
  public files: File[] = [];
  public defaultText = '';
  public documentsCustomersFiles = [];
  public documentsCustomersMinimumFiles = [];
  public documentsDeliveredFiles = [];
  public documentsDeliveredMinimumFiles = [];

  constructor(
    private modal: NzModalRef,
    private interventionInskyService: InterventionInskyService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService,
    private swalService: SwalService,
    private spinnerService: NgxSpinnerService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.interventionNonconformity && this.interventionNonconformity.nonconformity.solutions.length === 1) {
      this.selectedSolution = this.interventionNonconformity.nonconformity.solutions[0];
      this.selectedSolution.documentsCustomers.forEach((element) => {
        this.documentsCustomersFiles[element.documentId] = [];
        this.documentsCustomersMinimumFiles[element.documentId] = element.min;
      });
      this.selectedSolution.documentsDelivered.forEach((element) => {
        this.documentsDeliveredFiles[element.documentId] = [];
        this.documentsDeliveredMinimumFiles[element.documentId] = element.min;
      });
    }
    this.setSubmitButton();
  }

  determineResultCodeColor(): string {
    switch (this.interventionNonconformity?.nonconformityStatus.id) {
      case this.nonconformityStatusEnum.NOT_VALID: return '#F87171';
      case this.nonconformityStatusEnum.WAITING_VALIDATION: return '#FBBF24';
      case this.nonconformityStatusEnum.VALID: return '#84CC16';
      default: return '#64748B';
    }
  }

  onSelectDocumentCustomer(event: any, documentCustomer: any): void {
    this.documentsCustomersFiles[documentCustomer.documentId].push(...event.addedFiles);
  }

  onRemoveDocumentCustomer(event: any, documentCustomer: any): void {
    this.documentsCustomersFiles[documentCustomer.documentId].splice(this.documentsCustomersFiles[documentCustomer.documentId].indexOf(event), 1);
  }

  onSelectDocumentDelivered(event: any, documentDelivered: any): void {
    this.documentsDeliveredFiles[documentDelivered.documentId].push(...event.addedFiles);
  }

  onRemoveDocumentDelivered(event: any, documentDelivered: any): void {
    this.documentsDeliveredFiles[documentDelivered.documentId].splice(this.documentsDeliveredFiles[documentDelivered.documentId].indexOf(event), 1);
  }

  public isValid(): boolean {
    return this.selectedSolution != null;
  }

  onSelectSolutionClicked(solution: Solution): void {
    this.selectedSolution = solution;
    this.selectedSolution.documentsCustomers.forEach((element) => {
      this.documentsCustomersFiles[element.documentId] = [];
      this.documentsCustomersMinimumFiles[element.documentId] = element.min;
    });
    this.selectedSolution.documentsDelivered.forEach((element) => {
      this.documentsDeliveredFiles[element.documentId] = [];
      this.documentsDeliveredMinimumFiles[element.documentId] = element.min;
    });
    if (this.selectedSolution.defaultText) {
      this.defaultText = this.selectedSolution.defaultText;
    }
  }

  checkMinimumFilesCustomer(documentId: number): boolean {
    return this.documentsCustomersFiles[documentId].length >= this.documentsCustomersMinimumFiles[documentId];
  }

  checkMinimumFilesDelivered(documentId: number): boolean {
    return this.documentsDeliveredFiles[documentId].length >= this.documentsDeliveredMinimumFiles[documentId];
  }

  checkAllMinimumFiles(): boolean {
    let result = true;
    this.selectedSolution.documentsCustomers.forEach((element) => {
      if (this.documentsCustomersFiles[element.documentId].length < this.documentsCustomersMinimumFiles[element.documentId]) {
        result = false;
      }
    });
    this.selectedSolution.documentsDelivered.forEach((element) => {
      if (this.documentsDeliveredFiles[element.documentId].length < this.documentsDeliveredMinimumFiles[element.documentId]) {
        result = false;
      }
    });
    return result;
  }

  public submitValidation(): void {
    this.submitButtonService.setDisabled(this.submitButton);
    this.interventionInskyService.sendSolution(this.interventionNonconformity.id, this.selectedSolution, this.documentsCustomersFiles, this.documentsDeliveredFiles, this.intervention)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.submitButtonService.setEnabled(this.submitButton); }),
      ).subscribe((response) => {
        if (response.data.canApplyMultiple) {
          const that = this;
          const swalOptions = this.swalService.getSwalConfirmOptions({
            text: 'La solution sélectionnée répond à plusieurs points non-conformes, souhaitez-vous l\'appliquer à tous ?',
          });
          Swal.fire(swalOptions).then((result) => {
            if (result.value) {
              this.interventionInskyService.applyAll(this.interventionNonconformity.id, this.selectedSolution, this.files)
                .subscribe((success) => {
                  this.notifService.showSuccessNotif(success);
                  this.modal.triggerOk();
                });
            } else {
              this.notifService.showSuccessNotif(response);
              this.modal.triggerOk();
            }
          });
        } else {
          this.notifService.showSuccessNotif(response);
          this.modal.triggerOk();
        }
      });
  }

  private setSubmitButton(): void {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'le point de contrôle',
      text: 'Valider',
      icon: faCheck,
    });
  }

}
