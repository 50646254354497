import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Affair, AffairAppointmentConsteo, AffairLogConsteo } from '@libs/models/src';
import { UnsubscribeOnDestroy } from '@libs/services/src';
import { AffairConsteoService } from '@libs/services/src/lib/affair-consteo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-affair-show-consteo-appointment',
  templateUrl: './affair-show-consteo-appointment.component.html',
  styleUrls: ['./affair-show-consteo-appointment.component.scss'],
})
export class AffairShowConsteoAppointmentComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

 @Input() affair: Affair;
 public appointmentsValidated: AffairAppointmentConsteo[];
 public appointmentsWaiting: AffairAppointmentConsteo[];
 public appointmentsRefused: AffairAppointmentConsteo[];
 public appointmentsCanceled: AffairAppointmentConsteo[];

 constructor(private consteoService: AffairConsteoService, private spinnerService: NgxSpinnerService) {
   super();
 }

 ngOnInit(): void {
   this.initAppointments();
 }

 ngOnChanges(changes: SimpleChanges): void {
   this.initAppointments();

 }

 initAppointments(): void {
   this.spinnerService.show('load-consteo-history');
   this.consteoService.getAppointmentsByAffairId(this.affair.id).pipe(
     takeUntil(this.isDestroyed$),
     finalize(() => { this.spinnerService.hide('load-consteo-history'); }),
   ).subscribe((appointments) => {
     this.appointmentsValidated = appointments.filter((appointment) => { return appointment.status === 'VALIDATED'; });
     this.appointmentsWaiting = appointments.filter((appointment) => { return appointment.status === 'WAITING'; });
     this.appointmentsRefused = appointments.filter((appointment) => { return appointment.status === 'REFUSED'; });
     this.appointmentsCanceled = appointments.filter((appointment) => { return appointment.status === 'CANCELED'; });
   });
 }

}
