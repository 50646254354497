import { ApiService } from '@eros-front/api';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, debounce, debounceTime, finalize } from 'rxjs/operators';
import { ErosResponse, SubmitButton } from '@libs/models/src';
import { Router } from '@angular/router';
import { NotifService } from './utilities/notif.service';
import { CommonService } from './utilities/common.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';

export interface SyncPricesParams {
    sourceRef: string;
    entitiesRefs: string[];
    marketsTypesIds: number[];
    productsIds: number[];
}

export interface SyncManagersParams {
    sourceRef: string;
    entitiesRefs: string[];
    mlCompanies: number[];
    mode: number;
}

export interface SyncIndicatorsTypesParams {
    sourceRef: string;
    entitiesRefs: string[];
    mode: number;
    syncMarketsTypes: boolean;
    syncCustomersTypes: boolean;
    syncIndicatorsCustomersTypes: boolean;
}

export interface SyncInternalNotesParams {
    sourceRef: string;
    entitiesRefs: string[];
    mode: number;
    syncAdministrativeInternalNote?: boolean;
    syncTechnicalInternalNote?: boolean;
}

export interface SyncRivalsParams {
    sourceRef: string;
    entitiesRefs: string[];
    mode: number;
    mlCompanies: string[];
}

@Injectable()
export class CustomerService {

  private route = '/customers';
  public groups$ = new BehaviorSubject<any>(undefined);
  public brands$ = new BehaviorSubject<any>(undefined);
  public agencies$ = new BehaviorSubject<any>(undefined);
  public graph$ = new BehaviorSubject<any>(undefined);
  public customerSelectFilters$ = new BehaviorSubject<any>(undefined);
  public groupByCustomerRef$ = new BehaviorSubject<any>(undefined);
  public entityByCustomerRef$ = new BehaviorSubject<any>(undefined);
  public entitiesBySourceRef$ = new BehaviorSubject<any>(undefined);
  public previewOfSyncPrices$ = new BehaviorSubject<any>(undefined);
  public previewOfSyncManagers$ = new BehaviorSubject<any>(undefined);
  public previewOfSyncIndicatorsTypes$ = new BehaviorSubject<any>(undefined);
  public previewOfSyncInternalNotes$ = new BehaviorSubject<any>(undefined);
  public previewOfSyncRivals$ = new BehaviorSubject<any>(undefined);
  public selects$ = new BehaviorSubject<any>(undefined);
  public customersSearch$ = new BehaviorSubject<any>(undefined);
  public submitButton: SubmitButton;
  public refreshEntityByCustomerRef$ = new BehaviorSubject<any>(undefined);

  constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private submitButtonService: SubmitButtonService,
        private swalService: SwalService,
        private router: Router,
        private formFormatterService: FormFormatterService,
  ) { }

  create(form: FormGroup): Observable<ErosResponse> {
    this.submitButtonService.setDisabled(this.submitButton);
    return this.apiService.post(this.route, this.formatForm(form));

  }

  getForFilters(): void {
    this.apiService.get('/customers/select-filters')
      .subscribe(
        (filters) => {
          this.customerSelectFilters$.next(filters);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération des groupes',
          });
        },
      );

  }

  getGraph(ref: string): void {
    this.apiService.post('/customers/graph', {
      ref: ref,
    })
      .subscribe(
        (graph) => {
          this.graph$.next(graph);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération des groupes',
          });
        },
      );
  }

  getBrands(
    selectedGroups = [],
  ): void {
    this.apiService.post('/customers/graph', {
      selectedGroups: selectedGroups,
    })
      .subscribe(
        (brands) => {
          this.brands$.next(brands);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération des groupes',
          });
        },
      );
  }

  getAgencies(
    selectedBrands = [],
  ): void {
    this.apiService.post('/agencies/select-by-ref', {
      selectedBrands: selectedBrands,
    })
      .subscribe(
        (agencies) => {
          this.agencies$.next(agencies);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération des groupes',
          });
        },
      );
  }

  getGroupByCustomerRef(ref: string): void {
    this.apiService.get(`/customers/groups/${ref}`)
      .subscribe(
        (group) => {
          this.groupByCustomerRef$.next(group);
        },
        () => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération du groupe',
          });
        },
      );
  }

  getEntityByCustomerRef(ref: string): void {
    this.apiService.get(`/customers/get/${ref}`)
      .subscribe(
        (entity: any) => {
          this.entityByCustomerRef$.next(entity);
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        },
      );
  }

  refreshEntityByCustomerRef(ref: string): void {
    this.apiService.get(`/customers/get/${ref}`)
      .subscribe(
        (entity: any) => {
          this.refreshEntityByCustomerRef$.next(entity);
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        },
      );
  }

  getEntitiesFromSourceRef(ref: string): void {
    this.apiService.post('/customers/get/entities/select', {
      sourceRef: ref,
    })
      .subscribe(
        (group) => {
          this.entitiesBySourceRef$.next(group);
        },
        () => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération des entités',
          });
        },
      );
  }

  getPreviewOfSyncPrices(params: SyncPricesParams) {
    this.apiService.post('/customers/preview/sync/prices', params)
      .subscribe(
        (group) => {
          this.previewOfSyncPrices$.next(group);
        },
        () => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération de l\'aperçu de la synchronisation des tarifs',
          });
        },
      );
  }

  applySyncPrices(params: SyncPricesParams): Observable<any> {
    return this.apiService.post('/customers/apply/sync/prices', params);
  }

  getPreviewOfSyncManagers(params: SyncManagersParams) {
    this.apiService.post('/customers/preview/sync/managers', params)
      .subscribe(
        (group) => {
          this.previewOfSyncManagers$.next(group);
        },
        () => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération de l\'aperçu de la synchronisation des responsables',
          });
        },
      );
  }

  getPreviewOfSyncIndicatorsTypes(params: SyncIndicatorsTypesParams) {
    this.apiService.post('/customers/preview/sync/types', params)
      .subscribe(
        (group) => {
          this.previewOfSyncIndicatorsTypes$.next(group);
        },
        () => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération de l\'aperçu de la synchronisation des types et indicateurs',
          });
        },
      );
  }

  appySyncIndicatorsTypes(params: SyncIndicatorsTypesParams): Observable<any> {
    return this.apiService.post('/customers/apply/sync/types', params);
  }

  getPreviewOfSyncInternalNotes(params: SyncInternalNotesParams) {
    this.apiService.post('/customers/preview/sync/internal-notes', params)
      .subscribe(
        (group) => {
          this.previewOfSyncInternalNotes$.next(group);
        },
        () => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération de l\'aperçu de la synchronisation des notes internes',
          });
        },
      );
  }

  applySyncInternalNotes(params: SyncInternalNotesParams): Observable<any> {
    return this.apiService.post('/customers/apply/sync/internal-notes', params);
  }

  getPreviewOfSyncRivals(params: SyncRivalsParams) {
    this.apiService.post('/customers/preview/sync/rivals', params)
      .subscribe(
        (group) => {
          this.previewOfSyncRivals$.next(group);
        },
        () => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération de l\'aperçu de la synchronisation des concurrents',
          });
        },
      );
  }

  getSelectsForConsultCustomers(): void {
    this.apiService.get('/customers/consult/selects')
      .subscribe(
        (selects) => {
          console.log(selects);
          this.selects$.next(selects);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: 'Une erreur est survenue lors de la récupération des selects',
          });
        },
      );
  }

  search(search: string): Observable<any> {
    return this.apiService.get(`${this.route}/search?q=${search}`);
  }

  getCreateForm(): FormGroup {
    return this.formBuilder.group({
      name: [this.commonService.getDefaultFormStringValue(), Validators.required],
      marketsTypes: [this.commonService.getDefaultFormNullValue()],
      customersTypes: [this.commonService.getDefaultFormNullValue()],
      indicatorsCustomers: [this.commonService.getDefaultFormNullValue()],
      brandName: [this.commonService.getDefaultFormStringValue(), Validators.required],
      agencyName: [this.commonService.getDefaultFormStringValue(), Validators.required],
      agencyStreetNumber: this.commonService.getDefaultFormStringValue(),
      agencyAddress: [this.commonService.getDefaultFormStringValue(), Validators.required],
      agencyAdditionalAddress: this.commonService.getDefaultFormStringValue(),
      agencyPostalCode: [this.commonService.getDefaultFormStringValue(), Validators.required],
      agencyLocality: [this.commonService.getDefaultFormStringValue(), Validators.required],

    });
  }

  getPricesForm(): FormGroup {
    return this.formBuilder.group({
      prices: new FormArray([]),
      applyAll: [false],
    });
  }

  setSubmitButton(button: SubmitButton): void {
    this.submitButton = button;
  }

  determinateSubmitButton(object: any, objectName: string): SubmitButton {
    return this.submitButtonService.getSubmitButtonInstance({
      isAdd: !object,
      objectName: objectName,
    });
  }

  reset(): void {
    this.entityByCustomerRef$.next(null);
  }

  private formatForm(form: FormGroup) {
    const values = this.formFormatterService.createFormCopy(form);
    values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
    values.customersTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.customersTypes);
    return values;
  }

  public initMlSocietiesCheckboxes(form: FormGroup, mlSocieties: any, customer?: any, defaultMlSocietyId?: number): void {
    const mlSocietiesFormArray = form.get('mlSocieties') as FormArray;
    mlSocieties.forEach((mlSociety) => {
      let checked = false;
      if (defaultMlSocietyId) {
        checked = mlSociety.id === defaultMlSocietyId;
      } else if (customer) {
        checked = customer.mlSocieties.map((x) => { return x.id; }).includes(mlSociety.id);
      }
      mlSocietiesFormArray.push(new FormGroup({
        mlSocietyId: new FormControl(mlSociety.id),
        checked: new FormControl(checked),
      }));
    });
  }

  public initPricesInputs(form: FormGroup, marketsTypes: any[], products: any[], customer?: any): void {
    const productsFormArray = form.get('prices') as FormArray;
    marketsTypes.forEach((marketType) => {
      productsFormArray.push(new FormGroup({
        marketTypeId: new FormControl(marketType.id),
        products: this.initProductsInputs(products, marketType, customer),
      }));
    });
  }

  private initProductsInputs(products: any[], marketType: any, customer?: any): FormArray {
    const formArray = new FormArray([]);
    products.forEach((product) => {
      let price = 0;
      if (customer) {
        const found = customer.products.find((x) => { return x.marketTypeId === marketType.id && x.productId === product.id; });
        if (found) {
          price = found.price;
        }
      }
      formArray.push(new FormGroup({
        marketTypeId: new FormControl(marketType.id),
        productId: new FormControl(product.id),
        price: new FormControl(price),
      }));
    });
    return formArray;
  }

}
