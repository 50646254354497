import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AffairModalStandardResponseComponent } from '@libs/components/src/lib/affair-modal-standard-response/affair-modal-standard-response.component';
import { Affair, Contact } from '@libs/models/src';
import { AffairService, IncomingCallSheetParameters, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-telephony-aircall-tabs-affairs',
  templateUrl: './telephony-aircall-tabs-affairs.component.html',
  styleUrls: ['./telephony-aircall-tabs-affairs.component.scss'],
})
export class TelephonyAircallTabsAffairsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() contact: Contact = null;
  @Output() onTotalAffairs = new EventEmitter<number>();
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public total: number = 0;
  public loading: boolean;
  public loadingName = 'load-aircall-other';
  private expandSet = new Set<string>();
  public inputSearchValue = '';
  public incomingCallSheetParams: IncomingCallSheetParameters = {
    contactId: null,
    contactType: null,
    contactEmail: null,
    size: 10,
    search: '',
    page: 1,
  };

  public affairsList = [];

  constructor(private affairService: AffairService, private spinnerService: NgxSpinnerService, private modalService: NzModalService) { super(); }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.initAffairsList();
  }

  expandSetHas(id: string): boolean {
    return this.expandSet.has(id);
  }

  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.initAffairsList();
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.initAffairsList();
  }

  onRedirectAffairClicked(affairId: number): void {
    window.open(`affairs/${affairId}/show`);
  }

  // onRedirectInterventionClicked(interventionId: number): void {
  //   window.open(`interventions/${interventionId}/edit`);
  // }

  private initAffairsList(): void {
    if (this.contact) {
      this.incomingCallSheetParams = {
        contactId: this.contact.id,
        contactType: this.contact.type,
        contactEmail: this.contact.email,
        size: this.pageSize,
        page: this.pageIndex,
        search: this.inputSearchValue,
      };
      this.spinnerService.show(this.loadingName);
      this.loading = true;
      this.affairService.getForIncomingCallSheetList(this.incomingCallSheetParams)
        .pipe(
          takeUntil(this.isDestroyed$),
          finalize(() => {
            this.spinnerService.hide(this.loadingName);
            this.loading = false;
          }),
        ).subscribe(
          (response) => {
            this.affairsList = response.data;
            this.total = response.meta.total;
            this.onTotalAffairs.emit(this.total);
          },
        );
    } else {
      this.affairsList = [];
      this.total = 0;
      this.onTotalAffairs.emit(this.total);
    }
  }

  onModalStandardResponseShow(affair: Affair): void {
    const modalInstance = this.modalService.create({
      nzTitle: 'Envoi d\'une réponse standardisée',
      nzWidth: '80%',
      nzContent: AffairModalStandardResponseComponent,
      nzComponentParams: {
        affair: affair,
        affairSendSettings: affair.affairOfficeFile.affairsSendSettings,
        affairContacts: affair.affairContacts,
      },
      nzStyle: { top: '2vh' },
    });
  }

}
