/* eslint-disable no-undef */
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { Agency, Brand, ErosResponse, SelectModel, SubmitButton } from '@libs/models/src';
import { catchError, finalize } from 'rxjs/operators';
import { SelectService } from './utilities/select.service';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';

export interface AgencySelectParameters {
    selectedAgencies: string;
    selectedMarketsTypes: string;
    selectedCustomersTypes: string;
    selectedIndicatorsCustomers: string;
    selectedRivals: string;
    selectedManagers: string;
}

export interface AgencyDatatableParameters {
    selectedAgencies: any[];
}

export interface AgencyFormParameters {
    companies: any[];
    marketsTypes: any[];
    products: any[];
}

@Injectable()
export class AgencyService extends ModelWithDatatableAndCrudObservable {

  private route = '/agencies';
  public dtInstance: DataTables.Api;
  private columns: DataTableColumn[];
  public selectedAgencies = new Subject<any>();
  public agency$ = new BehaviorSubject<any>(undefined);
  public agencies$ = new BehaviorSubject<any>(undefined);
  public agenciesSelect$ = new BehaviorSubject<any>(undefined);
  public selectedAgencies$ = this.selectedAgencies.asObservable();
  public ajaxParameters: any;
  public agenciesSelectParams: AgencySelectParameters;
  public submitCreateButton: SubmitButton;
  public submitInformationsButton: SubmitButton;
  public submitInvoicingButton: SubmitButton;
  public submitPricesButton: SubmitButton;

  constructor(
    http: HttpClient,
    notifService: NotifService,
    submitButtonService: SubmitButtonService,
    swalService: SwalService,
    apiService: ApiService,
    router: Router,
        private formBuilder: FormBuilder,
        private selectService: SelectService,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
  ) {
    super(
      http,
      notifService,
      submitButtonService,
      swalService,
      apiService,
      router,
    );
  }

  public setDataTableColumns(columns: DataTableColumn[]): void {
    this.columns = columns;
  }

  public initDataTable(selector: string, columns: DataTableColumn[]): void {
    return super.initializeDataTable({
      url: `${this.route}/list`,
      selector: `#${selector}`,
      dataTableColumns: columns,
    });
  }

  public redrawDataTable(params: AgencyDatatableParameters): void {
    super.redrawDataTable(params);
  }

  public getForSelect(): Observable<SelectModel[]> {
    return this.apiService.get(`${this.route}/select`);
  }

  public getForSelectModel(): Observable<Agency[]> {
    return this.apiService.get(`${this.route}/select-model`);
  }

  public getForSelectWithParams(form: FormGroup): Observable<any> {
    return this.apiService.post(`${this.route}/select-with-parameters`, this.formatFormSelectWithParams(form));
  }

  public applyFilters(form: FormGroup, selectedagencys: any): void {
    this.agenciesSelectParams = {
      selectedAgencies: selectedagencys.map((x) => { return x.value; }),
      selectedMarketsTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedMarketsTypes),
      selectedCustomersTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedCustomersTypes),
      selectedIndicatorsCustomers: this.formFormatterService.formatSelectMultiple(form.value.selectedIndicatorsCustomers),
      selectedRivals: this.formFormatterService.formatSelectMultiple(form.value.selectedRivals),
      selectedManagers: this.formFormatterService.formatSelectMultiple(form.value.selectedManagers),
    };

    this.selectService.getForSelectWithParams(this.agenciesSelect$, this.route, this.agenciesSelectParams);
  }

  public getAll(): Observable<Agency[]> {
    return super.getAll(this.route);

  }

  public get(id: number): Observable<Agency> {
    return super.get(id, this.route);
  }

  public getByRef(ref: string): void {
    this.apiService.get(`/customers/get/${ref}`)
      .subscribe(
        (object) => {
          this.agency$.next(object);
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        },
      );
  }

  public store(form: FormGroup): Observable<any> {
    this.submitButtonService.setDisabled(this.submitCreateButton);
    return this.apiService.post(this.route, this.formatForm(form)).pipe(
      catchError((error) => {
        this.swalService.showSwalError(error);
        return throwError(error);
      }),
      finalize(() => {
        this.submitButtonService.setEnabled(this.submitCreateButton);
      }),
    );
  }

  public update(id: number, form: FormGroup): Observable<any> {
    this.submitButtonService.setDisabled(this.submitInformationsButton);
    return this.apiService.put(`${this.route}/${id}`, this.formatForm(form)).pipe(
      catchError((error) => {
        this.swalService.showSwalError(error);
        return throwError(error);
      }),
      finalize(() => {
        this.submitButtonService.setEnabled(this.submitInformationsButton);
      }),
    );
  }

  public updateInvoicingInformations(id: number, form: FormGroup): void {
    this.submitButtonService.setDisabled(this.submitInvoicingButton);
    this.apiService.put(`${this.route}/${id}/invoicing`, form.value)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.submitButtonService.setEnabled(this.submitInvoicingButton);
        }),
      )
      .subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
        },
      );
  }

  public updateTechnicalInformations(id: number, form: FormGroup): Observable<ErosResponse> {
    return this.apiService.put(`${this.route}/${id}/technical`, form.value);
  }

  public updatePrices(id: number, form: FormGroup): Observable<any> {
    this.submitButtonService.setDisabled(this.submitPricesButton);
    return this.apiService.put(`${this.route}/${id}/prices`, form.value)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.submitButtonService.setEnabled(this.submitPricesButton);
        }),
      );
  }

  public importLogo(id: number, form: FormGroup): Observable<any> {
    this.submitButtonService.setDisabled(this.submitButton);
    const formData = new FormData();
    formData.append('logo', form.get('logo').value);
    formData.append('width', form.get('width').value);
    formData.append('height', form.get('height').value);
    return this.apiService.importFile(`${this.route}/${id}/import-logo`, formData)
      .pipe(
        catchError((error) => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.submitButtonService.setEnabled(this.submitButton);
        }),
      );
  }

  public updateConsteoToken(id: number, form: FormGroup): Observable<any> {
    return this.apiService.put(`${this.route}/${id}/consteo-token`, form.value);
  }

  public getForm(params: AgencyFormParameters, agency?: Agency): FormGroup {
    const name = agency
      ? this.commonService.valueToFormString(agency.name) : this.commonService.getDefaultFormStringValue();
    const streetNumber = agency
      ? this.commonService.valueToFormString(agency.streetNumber) : this.commonService.getDefaultFormStringValue();
    const address = agency
      ? this.commonService.valueToFormString(agency.address) : this.commonService.getDefaultFormStringValue();
    const additionalAddress = agency
      ? this.commonService.valueToFormString(agency.additionalAddress) : this.commonService.getDefaultFormStringValue();
    const postalCode = agency
      ? this.commonService.valueToFormString(agency.postalCode) : this.commonService.getDefaultFormStringValue();
    const locality = agency
      ? this.commonService.valueToFormString(agency.locality) : this.commonService.getDefaultFormStringValue();
    const mlAgencyId = agency
      ? this.commonService.valueToFormSelect(agency.mlAgencyId) : this.commonService.getDefaultFormNullValue();
    const userTechnicianId = agency
      ? this.commonService.valueToFormSelect(agency.userTechnicianId) : this.commonService.getDefaultFormNullValue();
    const userAssistantId = agency
      ? this.commonService.valueToFormSelect(agency.userAssistantId) : this.commonService.getDefaultFormNullValue();
    const siretNumber = agency
      ? this.commonService.valueToFormString(agency.siretNumber) : this.commonService.getDefaultFormStringValue();
    const accountingCode = agency
      ? this.commonService.valueToFormString(agency.accountingCode) : this.commonService.getDefaultFormStringValue();
    const paymentDeadline = agency
      ? this.commonService.valueToFormSelect(agency.paymentDeadline) : this.commonService.getDefaultFormNullValue();
    const paymentDeadlineType = agency
      ? this.commonService.valueToFormSelect(agency.paymentDeadlineType) : this.commonService.getDefaultFormNullValue();
    const billingPreference = agency
      ? this.commonService.valueToFormSelect(agency.billingPreference) : this.commonService.getDefaultFormNullValue();
    const ebbBillingPreference = agency
      ? this.commonService.valueToFormSelect(agency.ebbBillingPreference) : this.commonService.getDefaultFormNullValue();
    const payerPreference = agency
      ? this.commonService.valueToFormSelect(agency.payerPreference) : this.commonService.getDefaultFormNullValue();
    const payerSelected = agency
      ? this.commonService.valueToFormSelect(agency.payerSelected) : this.commonService.getDefaultFormNullValue();
    const ebbLibraryCustomerId = agency
      ? this.commonService.valueToFormSelect(agency.ebbLibraryCustomerId) : this.commonService.getDefaultFormNullValue();
    const ebbBillingCustomerId = agency
      ? this.commonService.valueToFormSelect(agency.ebbBillingCustomerId) : this.commonService.getDefaultFormNullValue();
    const isBihCustomer = agency
      ? this.commonService.valueToCheckboxFormNumber(agency.isBihCustomer) : this.commonService.getDefaultFormCheckboxValue();
    const isVmcSizing = agency
      ? this.commonService.valueToCheckboxFormNumber(agency.isVmcSizing) : this.commonService.getDefaultFormCheckboxValue();
    const administrativeInternalNote = agency
      ? this.commonService.valueToFormString(agency.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
    const technicalInternalNote = agency
      ? this.commonService.valueToFormString(agency.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
    const marketsTypes = agency
      ? agency.marketsTypes.map((x) => { return { value: x.id, label: x.name }; }) : [];
    const customersTypes = agency
      ? agency.customersTypes.map((x) => { return { value: x.id, label: x.name }; }) : [];
    const indicatorsCustomers = agency
      ? agency.indicatorsCustomers.map((x) => { return { value: x.id, label: x.name }; }) : [];
    const products = [];
    // Products
    params.marketsTypes.forEach((marketType) => {
      const subArray = [];
      params.products.forEach((product) => {
        let productValue = 0;
        if (agency && agency.products && agency.products[marketType.value]) {
          const productObj = agency.products[marketType.value][product.id];
          if (productObj != null && typeof productObj === 'object') {
            productValue = productObj.pivot.price;
          }
        }
        subArray.push(
          this.formBuilder.group({ [`product${product.id}`]: productValue }),
        );
      });
      products.push(this.formBuilder.array(subArray));
    });
    return this.formBuilder.group({
      name: [name, Validators.required],
      streetNumber: streetNumber,
      address: [address, Validators.required],
      additionalAddress: additionalAddress,
      postalCode: [postalCode, Validators.required],
      locality: [locality, Validators.required],
      mlAgencyId: [mlAgencyId],
      userTechnicianId: [userTechnicianId],
      userAssistantId: [userAssistantId],
      siretNumber: siretNumber,
      accountingCode: accountingCode,
      paymentDeadline: paymentDeadline,
      paymentDeadlineType: paymentDeadlineType,
      billingPreference: billingPreference,
      ebbBillingPreference: ebbBillingPreference,
      payerPreference: payerPreference,
      payerSelected: payerSelected,
      ebbLibraryCustomerId: ebbLibraryCustomerId,
      ebbBillingCustomerId: ebbBillingCustomerId,
      isBihCustomer: isBihCustomer,
      isVmcSizing: isVmcSizing,
      administrativeInternalNote: administrativeInternalNote,
      technicalInternalNote: technicalInternalNote,
      marketsTypes: [marketsTypes],
      customersTypes: [customersTypes],
      indicatorsCustomers: [indicatorsCustomers],
      products: this.formBuilder.array(products),
    });
  }

  public getCreateForm(brand: Brand) {
    return this.formBuilder.group({
      name: [this.commonService.getDefaultFormStringValue(), Validators.required],
      brandId: [brand.id, Validators.required],
      streetNumber: this.commonService.getDefaultFormStringValue(),
      address: [this.commonService.getDefaultFormStringValue(), Validators.required],
      additionalAddress: this.commonService.getDefaultFormStringValue(),
      postalCode: [this.commonService.getDefaultFormStringValue(), Validators.required],
      locality: [this.commonService.getDefaultFormStringValue(), Validators.required],
      mlAgencyId: [this.commonService.getDefaultFormNullValue()],
      userTechnicianId: [this.commonService.getDefaultFormNullValue()],
      userAssistantId: [this.commonService.getDefaultFormNullValue()],
      administrativeInternalNote: [brand.administrativeInternalNote],
      technicalInternalNote: [brand.technicalInternalNote],
      marketsTypes: [brand.marketsTypes.map((x) => { return { value: x.id, label: x.name }; })],
      customersTypes: [brand.customersTypes.map((x) => { return { value: x.id, label: x.name }; })],
      indicatorsCustomers: [brand.indicatorsCustomers.map((x) => { return { value: x.id, label: x.name }; })],
      mlSocieties: new FormArray([]),
    });

  }

  public getInformationsForm(agency?: Agency): FormGroup {
    const name = agency
      ? this.commonService.valueToFormString(agency.name) : this.commonService.getDefaultFormStringValue();
    const siretNumber = agency
      ? this.commonService.valueToFormString(agency.siretNumber) : this.commonService.getDefaultFormStringValue();
    const streetNumber = agency
      ? this.commonService.valueToFormString(agency.streetNumber) : this.commonService.getDefaultFormStringValue();
    const address = agency
      ? this.commonService.valueToFormString(agency.address) : this.commonService.getDefaultFormStringValue();
    const additionalAddress = agency
      ? this.commonService.valueToFormString(agency.additionalAddress) : this.commonService.getDefaultFormStringValue();
    const postalCode = agency
      ? this.commonService.valueToFormString(agency.postalCode) : this.commonService.getDefaultFormStringValue();
    const locality = agency
      ? this.commonService.valueToFormString(agency.locality) : this.commonService.getDefaultFormStringValue();
    const mlAgencyId = agency
      ? this.commonService.valueToFormSelect(agency.mlAgencyId) : this.commonService.getDefaultFormNullValue();
    const userTechnicianId = agency
      ? this.commonService.valueToFormSelect(agency.userTechnicianId) : this.commonService.getDefaultFormNullValue();
    const userAssistantId = agency
      ? this.commonService.valueToFormSelect(agency.userAssistantId) : this.commonService.getDefaultFormNullValue();
    const administrativeInternalNote = agency
      ? this.commonService.valueToFormString(agency.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
    const technicalInternalNote = agency
      ? this.commonService.valueToFormString(agency.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
    const marketsTypes = agency
      ? agency.marketsTypes.map((x) => { return { value: x.id, label: x.name }; }) : [];
    const customersTypes = agency
      ? agency.customersTypes.map((x) => { return { value: x.id, label: x.name }; }) : [];
    const indicatorsCustomers = agency
      ? agency.indicatorsCustomers.map((x) => { return { value: x.id, label: x.name }; }) : [];
    const inAccountingLitigation = agency
      ? this.commonService.valueToCheckboxFormNumber(agency.inAccountingLitigation) : this.commonService.getDefaultFormCheckboxValue();
    return this.formBuilder.group({
      name: [name, Validators.required],
      siretNumber: [siretNumber],
      streetNumber: streetNumber,
      address: [address, Validators.required],
      additionalAddress: additionalAddress,
      postalCode: [postalCode, Validators.required],
      locality: [locality, Validators.required],
      mlAgencyId: [mlAgencyId],
      userTechnicianId: [userTechnicianId],
      userAssistantId: [userAssistantId],
      administrativeInternalNote: administrativeInternalNote,
      technicalInternalNote: technicalInternalNote,
      marketsTypes: [marketsTypes],
      customersTypes: [customersTypes],
      indicatorsCustomers: [indicatorsCustomers],
      inAccountingLitigation: [inAccountingLitigation],
      mlSocieties: new FormArray([]),
    });
  }

  public getTechnicalForm(agency?: Agency): FormGroup {
    const ebbLibraryCustomerId = agency
      ? this.commonService.valueToFormSelect(agency.ebbLibraryCustomerId) : this.commonService.getDefaultFormNullValue();
    const ebbBillingCustomerId = agency
      ? this.commonService.valueToFormSelect(agency.ebbBillingCustomerId) : this.commonService.getDefaultFormNullValue();
    const isBihCustomer = agency
      ? this.commonService.valueToCheckboxFormNumber(agency.isBihCustomer) : this.commonService.getDefaultFormCheckboxValue();
    const isVmcSizing = agency
      ? this.commonService.valueToCheckboxFormNumber(agency.isVmcSizing) : this.commonService.getDefaultFormCheckboxValue();
    const isConsteoCustomer = agency
      ? this.commonService.valueToCheckboxFormNumber(agency.isConsteoCustomer) : this.commonService.getDefaultFormCheckboxValue();
    const isDomboxCustomer = agency
      ? this.commonService.valueToCheckboxFormNumber(agency.isDomboxCustomer) : this.commonService.getDefaultFormCheckboxValue();
    const permitSignatory = agency
      ? this.commonService.valueToFormString(agency.permitSignatory) : this.commonService.getDefaultFormStringValue();
    const reservationHeight = agency
      ? agency.reservationHeight : this.commonService.getDefaultFormNullValue();
    return this.formBuilder.group({
      ebbLibraryCustomerId: [ebbLibraryCustomerId],
      ebbBillingCustomerId: [ebbBillingCustomerId],
      isBihCustomer: isBihCustomer,
      isVmcSizing: isVmcSizing,
      isConsteoCustomer: [isConsteoCustomer],
      isDomboxCustomer: [isDomboxCustomer],
      permitSignatory: [permitSignatory],
      reservationHeight: [reservationHeight],
    });

  }

  public initPayersFormArray(form: FormGroup, agency: Agency): FormArray {
    const payersFormArray = form.get('payers') as FormArray;
    agency.payers.forEach((payer) => {
      payersFormArray.push(new FormGroup({
        payerId: new FormControl(payer.payerId),
        isSelected: new FormControl(payer.isSelected),
      }));
    });
    return payersFormArray;
  }

  private formatForm(form: FormGroup): any {
    const values = this.formFormatterService.createFormCopy(form);
    values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
    values.customersTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.customersTypes);
    values.indicatorsCustomers = this.formFormatterService.formatSelectMultipleToIntArray(values.indicatorsCustomers);
    values.mlSocieties = this.formFormatterService.formatSelectMultipleToIntArray(this.formFormatterService.filterCheckedValues(values.mlSocieties), 'mlSocietyId');
    return values;
  }

  private formatFormSelectWithParams(form: FormGroup) {
    const values = this.formFormatterService.createFormCopy(form);
    values.selectedBrands = this.formFormatterService.formatSelectMultipleToIntArray(values.selectedBrands);
    return values;
  }

  public setCreateSubmitButton(button: SubmitButton): void {
    this.submitCreateButton = button;

  }

  public setSubmitInformationsButton(button: SubmitButton): void {
    this.submitInformationsButton = button;
  }

  public setSubmitInvoicingButton(button: SubmitButton): void {
    this.submitInvoicingButton = button;
  }

  public setSubmitPricesButton(button: SubmitButton): void {
    this.submitPricesButton = button;
  }

}
