import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Contact } from '@libs/models/src';
import { UnsubscribeOnDestroy } from '@libs/services/src';
import { IncomingCallSheetInvoiceParameters, InvoiceService } from '@libs/services/src/lib/invoice.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-telephony-aircall-tabs-invoices',
  templateUrl: './telephony-aircall-tabs-invoices.component.html',
  styleUrls: ['./telephony-aircall-tabs-invoices.component.scss'],
})
export class TelephonyAircallTabsInvoicesComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

@Input() contact: Contact;
@Output() onTotalInvoices = new EventEmitter<number>();
public pageSize: number = 10;
public pageIndex: number = 1;
public total: number = 0;
public loading: boolean;
public loadingName = 'load-aircall-other';
public incomingCallSheetParams: IncomingCallSheetInvoiceParameters = {
  contactId: null,
  contactType: null,
  contactEmail: null,
  size: 10,
  search: '',
  page: 1,
};

public invoicesData = [];
public inputSearchValue: string = '';

constructor(private invoiceService: InvoiceService, private spinnerService: NgxSpinnerService) {
  super();
}

ngOnInit(): void {
}

ngOnChanges(): void {
  this.initInvoicessList();
}

onInputSearchChanged(newSearch: string): void {
  this.inputSearchValue = newSearch;
  this.initInvoicessList();
}

onQueryParamsChange(params: NzTableQueryParams): void {
  this.pageIndex = params.pageIndex;
  this.pageSize = params.pageSize;
  this.initInvoicessList();
}

private initInvoicessList(): void {
  if (this.contact) {
    this.incomingCallSheetParams = {
      contactId: this.contact.id,
      contactType: this.contact.type,
      contactEmail: this.contact.email,
      size: this.pageSize,
      page: this.pageIndex,
      search: this.inputSearchValue,
    };
    this.loading = true;
    this.spinnerService.show(this.loadingName);
    this.invoiceService.getForIncomingCallSheetList(this.incomingCallSheetParams)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.spinnerService.hide(this.loadingName);
          this.loading = false;
        }),
      ).subscribe(
        (response) => {
          this.invoicesData = response.data;
          this.total = response.meta.total;
          this.onTotalInvoices.emit(this.total);
        },
      );
  } else {
    this.invoicesData = [];
    this.total = 0;
    this.onTotalInvoices.emit(this.total);
  }
}

public onRedirectInvoiceClicked(invoiceId: number): void {
  window.open(`invoices/${invoiceId}/show`);
}

}
