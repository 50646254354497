import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AffairAdditionalFieldConsteo, AffairAppointmentConsteo, AffairDocumentConsteo, AffairLogConsteo } from '@eros-front/models';
import { Observable } from 'rxjs';
import { ApiService } from '@libs/api/src';
import { takeUntil, tap } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from './classes/unsuscribe-on-destroy';

@Injectable()
export class AffairConsteoService extends UnsubscribeOnDestroy {

  public logs$: Observable<AffairDocumentConsteo[]>;

  constructor(private apiService: ApiService) {
    super();
  }

  public getDocumentsCustomersByAffairId(affairId: number): Observable<AffairDocumentConsteo[]> {
    return this.apiService.get<AffairDocumentConsteo[]>(`/affairs/${affairId}/consteo/documents-customers`).pipe(
      takeUntil(this.isDestroyed$),
    );
  }

  public getDocumentsDeliveredByAffairId(affairId: number): Observable<AffairDocumentConsteo[]> {
    return this.apiService.get<AffairDocumentConsteo[]>(`/affairs/${affairId}/consteo/documents-delivered`).pipe(
      takeUntil(this.isDestroyed$),
    );
  }

  public getLogsByAffairId(affairId: number): Observable<AffairLogConsteo[]> {
    return this.apiService.get<AffairLogConsteo[]>(`/affairs/${affairId}/consteo/logs`).pipe(
      takeUntil(this.isDestroyed$),
    );
  }

  public getAppointmentsByAffairId(affairId: number): Observable<AffairAppointmentConsteo[]> {
    return this.apiService.get<AffairAppointmentConsteo[]>(`/affairs/${affairId}/consteo/appointments`).pipe(
      takeUntil(this.isDestroyed$),
    );
  }

  public getAdditionalFieldsByAffairId(affairId: number): Observable<AffairAdditionalFieldConsteo[]> {
    return this.apiService.get<AffairAdditionalFieldConsteo[]>(`/affairs/${affairId}/consteo/additional-fields`).pipe(
      takeUntil(this.isDestroyed$),
    );
  }

  public downloadDeliveredMultiple(affairId: number, documents: AffairDocumentConsteo[]): Observable<HttpResponse<Blob>> {
    return this.apiService.postBlob(`/affairs/${affairId}/consteo/documents-delivered/files`, {
      files: documents.map((doc) => { return doc.id; }),
    });
  }

  public downloadCustomersMultiple(affairId: number, documents: AffairDocumentConsteo[]): Observable<HttpResponse<Blob>> {
    return this.apiService.postBlob(`/affairs/${affairId}/consteo/documents-customers/files`, {
      files: documents.map((doc) => { return doc.id; }),
    });
  }

  public downloadDelivered(affairId: number, document: AffairDocumentConsteo): Observable<Blob> {
    return this.apiService.getFile(`/affairs/${affairId}/consteo/documents-delivered/file/${document.id}`);
  }

  public downloadCustomers(affairId: number, document: AffairDocumentConsteo): Observable<Blob> {
    return this.apiService.getFile(`/affairs/${affairId}/consteo/documents-customers/file/${document.id}`);
  }

}
