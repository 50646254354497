import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UnsubscribeOnDestroy } from '@libs/services/src';
import { AircallService, IncomingCallRecordsSheetParameters } from '@libs/services/src/lib/aircall.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-telephony-aircall-history',
  templateUrl: './telephony-aircall-history.component.html',
  styleUrls: ['./telephony-aircall-history.component.scss'],
})
export class TelephonyAircallHistoryComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() phoneNumber: string;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  public incomingCallSheetParams: IncomingCallRecordsSheetParameters = {
    phoneNumber: null,
    size: 10,
    search: '',
    page: 1,
  };

  public searchResults: Array<any> = [];
  public allLoaded: boolean = false;
  public loading: boolean = false;
  public loadingName = 'loadingTelephonyHistory';

  constructor(private aircallService: AircallService, private spinnerService: NgxSpinnerService) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.resetForChanges();
    this.incomingCallSheetParams.phoneNumber = this.phoneNumber;
    this.nextSearchPage(this.incomingCallSheetParams);
  }

  resetForChanges(): void {
    this.allLoaded = false;
    this.searchResults = [];
    this.loadingName = 'loadingTelephonyHistory';
    this.loading = false;
    this.incomingCallSheetParams = {
      phoneNumber: null,
      size: 10,
      search: '',
      page: 1,
    };
  }

  nextSearchPage(params: IncomingCallRecordsSheetParameters): void {
    if (params.phoneNumber && !this.allLoaded && !this.loading) {
      this.spinnerService.show(this.loadingName);
      this.loading = true;
      this.aircallService.getRecordsForIncomingCallSheetList(params)
        .pipe(
          takeUntil(this.isDestroyed$),
          finalize(() => {
            this.spinnerService.hide(this.loadingName);
            this.loadingName = 'loadingScroll';
            this.loading = false;
          }),
        )
        .subscribe((pagedResults) => {
          this.searchResults = this.searchResults.concat(pagedResults.data);
          // eslint-disable-next-line no-plusplus
          this.incomingCallSheetParams.page++;
          if (pagedResults.data.length < this.incomingCallSheetParams.size) {
            this.allLoaded = true;
          }
        });
    }
  }

  nextBatch(index: number): void {
    const { end } = this.virtualScroll.getRenderedRange();
    const total = this.virtualScroll.getDataLength();
    if (end === total && !this.loading) {
      this.nextSearchPage(this.incomingCallSheetParams);
    }
  }

  trackById = (index: number, historyItem: any): string => {
    return historyItem.callUuid;
  };

}
