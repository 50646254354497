import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Region, SelectModel } from '@libs/models/src';
import { StatisticInterventionService, StatisticInterventionVolume, UnsubscribeOnDestroy } from '@libs/services/src';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CHARTS_NAMES } from 'apps/eros-nrj/src/config/charts-names';
import { isThisHour } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistics-volumes',
  templateUrl: './statistics-volumes.component.html',
  styleUrls: ['./statistics-volumes.component.scss'],
})
export class StatisticsVolumesComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public form: FormGroup;
  private statsInterventionsFilters$: Subscription;
  public chartsNames = CHARTS_NAMES;
  public selectedChart = this.chartsNames.VOLUME_REGIONS;
  public technicians: SelectModel[];
  public regions: Region[];
  public formattedRegions: SelectModel[];
  public mlAgencies: SelectModel[];
  public products: SelectModel[];
  public marketsTypes: SelectModel[];
  private readonly storageKey = 'statistics-interventions-filters-form';
  public displayMode: number;

  constructor(
    private statisticIntervention: StatisticInterventionService,
    private statisticInterventionVolume: StatisticInterventionVolume,
    private formLocalStorageService: FormLocalStorageService,
  ) {
    super();
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.statisticIntervention.getFilters();
    const sessionFormValue = this.formLocalStorageService.retrieve(this.storageKey);
    this.form = this.statisticIntervention.initForm(sessionFormValue);

    if (sessionFormValue) {
      this.updateCharts();
    }

    this.form.valueChanges.subscribe((form) => {
      this.formLocalStorageService.store(this.storageKey, this.form);
      this.updateCharts();
    });
  }

  ngOnDestroy(): void {
    this.statsInterventionsFilters$.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.selectedChart = changeEvent.nextId;
    this.updateCharts();
  }

  updateCharts(): void {
    if (this.form.valid) {
      if (this.selectedChart === this.chartsNames.VOLUME_REGIONS) {
        this.statisticInterventionVolume.getStatsInterventionsRegions(this.form);
      } else if (this.selectedChart === this.chartsNames.VOLUME_TECHNICIANS) {
        this.statisticInterventionVolume.getStatsInterventionsTechnicians(this.form);
      } else if (this.selectedChart === this.chartsNames.VOLUME_PRODUCTS) {
        this.statisticInterventionVolume.getStatsInterventionsProducts(this.form);
      } else if (this.selectedChart === this.chartsNames.VOLUME_MAP) {
        this.statisticInterventionVolume.getStatsInterventionsDepartments(this.form);
      }
      this.displayMode = this.form.value.displayMode;
    }

  }

  getFormattedRegions(): void {
    this.formattedRegions = [];
    this.regions.forEach((region) => {
      this.formattedRegions.push({
        value: region.id,
        label: region.name,
      });
    });
  }

  private initSubscriptions(): void {
    this.statsInterventionsFilters$ = this.statisticIntervention.statsInterventionsFilters$.subscribe(
      (data) => {
        if (data) {
          this.technicians = data.technicians;
          this.regions = data.regions;
          this.mlAgencies = data.mlAgencies;
          this.products = data.products;
          this.marketsTypes = data.marketsTypes;
          this.getFormattedRegions();
        }
      },
    );
  }

}
