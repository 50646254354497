import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AffairService, UnsubscribeOnDestroy } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { Subscription, zip } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Affair, AffairDropzone } from '@libs/models/src';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-affair-document-customer-import',
  templateUrl: './affair-document-customer-import.component.html',
  styleUrls: ['./affair-document-customer-import.component.scss'],
})
export class AffairDocumentCustomerImportComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public affair: Affair;
  public dropzones: AffairDropzone[] = [];

  constructor(
    public affairDocumentService: AffairDocumentCustomerService,
    private affairService: AffairService,
    private titleService: Title,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,

  ) {
    super();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      this.spinnerService.show('load-dropzones');
      this.affairService.getForAdd(id)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (affair) => {
            this.affair = affair;
            this.initWhenAffair();
          },
        );
    });

  }

  private initWhenAffair() {
    this.titleService.setTitle(`Import de données d'entrées pour l'affaire : ${this.affair.mlRef}`);
    this.affairDocumentService.getDropzones(this.affair.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => { this.spinnerService.hide('load-dropzones'); }),
      )
      .subscribe(
        (dropzones) => {
          this.dropzones = dropzones;
        },
      );
  }

}
