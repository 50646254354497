import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Affair, AffairAdditionalFieldConsteo } from '@libs/models/src';
import { UnsubscribeOnDestroy } from '@libs/services/src';
import { AffairConsteoService } from '@libs/services/src/lib/affair-consteo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-affair-show-consteo-input',
  templateUrl: './affair-show-consteo-input.component.html',
  styleUrls: ['./affair-show-consteo-input.component.scss'],
})
export class AffairShowConsteoInputComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() affair: Affair;
  public fieldsToValidate: AffairAdditionalFieldConsteo[] = [];
  public fieldsWaiting: AffairAdditionalFieldConsteo[] = [];
  public fieldsRefused: AffairAdditionalFieldConsteo[] = [];
  public fieldsValidated: AffairAdditionalFieldConsteo[] = [];

  constructor(private consteoService: AffairConsteoService, private spinnerService: NgxSpinnerService) {
    super();
  }

  ngOnInit(): void {
    this.initAdditionalFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initAdditionalFields();
  }

  initAdditionalFields(): void {
    this.spinnerService.show('load-consteo-input');
    this.consteoService.getAdditionalFieldsByAffairId(this.affair.id).pipe(
      takeUntil(this.isDestroyed$),
      finalize(() => { this.spinnerService.hide('load-consteo-input'); }),
    ).subscribe((fields) => {
      this.fieldsWaiting = fields.filter((field) => { return !field.answers.length; });
      this.fieldsToValidate = fields.filter((field) => { return field.answers.length && field.answers[field.answers.length - 1].status === 0; });
      this.fieldsRefused = fields.filter((field) => { return field.answers.length && field.answers[field.answers.length - 1].status === 2; });
      this.fieldsValidated = fields.filter((field) => { return field.answers.length && field.answers[field.answers.length - 1].status === 1; });
    });
  }

}
