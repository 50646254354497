import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { InterventionStatusIdEnum } from '@libs/enum/src';
import { Affair, DashboardTile, Intervention } from '@libs/models/src';
import { InterventionService, NotifService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SlideInOutAnimation } from '@animations/slide-in-out';
import { InterventionEditDeprogramModalComponent } from '@libs/interventions/src/_components/interventions/intervention-edit/intervention-edit-informations/intervention-edit-deprogram-modal/intervention-edit-deprogram-modal.component';

@Component({
  selector: 'app-overview-affair-interventions-list',
  templateUrl: './overview-affair-interventions-list.component.html',
  styleUrls: ['./overview-affair-interventions-list.component.scss'],
  animations: [SlideInOutAnimation],
})
export class OverviewAffairInterventionsListComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() affair: Affair;
  @Input() dashboardTile: DashboardTile;
  @Output() actionDone = new EventEmitter<any>();
  public interventionStatusIdEnum = InterventionStatusIdEnum;
  public isHoverInterventionId = null;
  public isDivVisible = true;

  constructor(
    private swalService: SwalService,
    private interventionService: InterventionService,
    private notifService: NotifService,
    private router: Router,
    private modalService: NzModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isDivVisible = this.dashboardTile && this.dashboardTile.entity === 'intervention';
  }

  confirm(intervention: Intervention): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut confirmé.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.confirm(intervention.id)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
            }),
          ).subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.actionDone.emit();
          });
      }
    });
  }

  markAsPaid(intervention: Intervention): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention ne sera plus en attente règlement.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.markAsPaid(intervention.id)
          .pipe(
            takeUntil(this.isDestroyed$),
            finalize(() => {
            }),
          ).subscribe((success) => {
            this.notifService.showSuccessNotif(success);
            this.actionDone.emit();
          });
      }
    });
  }

  showProgramModal(intervention: Intervention): void {
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditDeprogramModalComponent,
      nzComponentParams: {
        intervention: intervention,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.actionDone.emit();
    });
  }

  onMouseEnter(intervention: Intervention): void {
    this.isHoverInterventionId = intervention.id;
  }

  onMouseLeave(): void {
    this.isHoverInterventionId = null;
  }

  onRedirectIntervention(intervention: Intervention): void {
    window.open(`/interventions/${intervention.id}/edit`, '_blank');
  }

  toggleDiv(): void {
    this.isDivVisible = !this.isDivVisible;
  }

}
