import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DashboardCategory, DashboardTile, ListResponse, Role, SelectModel } from '@libs/models/src';
import { DashboardCategoryService, DashboardTileService, MarketTypeService, NzTableAjaxSimple, RoleService, SwalService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DashboardTileModalComponent } from '../dashboard-tile-modal/dashboard-tile-modal.component';

@Component({
  selector: 'app-dashboard-tiles-list',
  templateUrl: './dashboard-tiles-list.component.html',
  styleUrls: ['./dashboard-tiles-list.component.scss'],
})
export class DashboardTilesListComponent extends NzTableAjaxSimple implements OnInit {

  public dashboardTiles: DashboardTile[] = [];
  public marketsTypes: SelectModel[] = [];
  public dashboardCategories: DashboardCategory[] = [];
  public roles: Role[] = [];
  public form: FormGroup;

  constructor(
    private dashboardTileService: DashboardTileService,
    private dashboardCategoryService: DashboardCategoryService,
    private marketTypeService: MarketTypeService,
    private nzModalService: NzModalService,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private swalService: SwalService,

  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.initList();
    this.marketTypeService.getForSelect()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((marketsTypes: SelectModel[]) => {
        this.marketsTypes = marketsTypes;
      });
    this.dashboardCategoryService.getForSelect()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((dashboardCategories: DashboardCategory[]) => {
        this.dashboardCategories = dashboardCategories;
      });
    this.roleService.getAll()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((roles) => {
        this.roles = roles;
      });
  }

  onAddEditClicked(dashboardTile?: DashboardTile): void {
    this.nzModalService.create({
      nzContent: DashboardTileModalComponent,
      nzComponentParams: {
        dashboardTile: dashboardTile,
        marketsTypes: this.marketsTypes,
        dashboardCategories: this.dashboardCategories,
        roles: this.roles,
      },
      nzOnOk: () => {
        this.initList();
      },
    });

  }

  onDeleteClicked(dashboardTile: DashboardTile): void {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'La tuile sera définitivement supprimée.',
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.dashboardTileService.delete(dashboardTile.id).subscribe(() => {
          this.initList();
        });
      }
    });
  }

  initList(): void {
    this.dashboardTileService.getAll(this.form).subscribe((dashboardTiles: ListResponse) => {
      this.dashboardTiles = dashboardTiles.data;
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      page: 1,
      size: 10,
    });

  }

}
