import { RootLayout } from '@pages/layouts/root/root.component';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'eros-front-root',
  template: `
  <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends RootLayout implements OnInit {

  title = 'eros-nrj';

  ngOnInit() {

  }

}
