/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifService } from '@libs/services/src';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'eros-front-inspections-beta',
  templateUrl: './inspections-beta.component.html',
  styleUrls: ['./inspections-beta.component.css'],
})
export class InspectionsBetaComponent implements OnInit {

  public form: FormGroup;
  public beneficiaries: any[];
  public forms: object[] = [
    { name: 'Inspection BAR-EN-101 et BAT-EN-101 V8', id: '769714' },
    { name: 'Inspection BAR-EN-102 et BAT-EN-102 V9', id: '795273' },
    { name: 'Inspection BAR-EN-103 et BAT-EN-103 V8', id: '769716' },
  ];

  constructor(private _http: HttpClient, private _notifService: NotifService) {
    this.form = this._buildForm();
  }

  ngOnInit(): void {
    this._http.get<any[]>(`${environment.serviceEUrl}/set/beneficiary`).subscribe({
      next: (data) => {
        this.beneficiaries = data;
      },
    });
  }

  public submitForm(): void {
    const payload = {
      internal_ref: this.form.get('internalRef').value,
      form_id: this.form.get('formId').value,
      data_id: this.form.get('dataId').value,
    };

    this._http.patch<any>(`${environment.serviceEUrl}/set/beneficiary/betatool`, payload).subscribe({
      next: (_) => {
        this._notifService.showSuccessNotif('Votre demande a bien été prise en compte');
      },
    });
  }

  /**
   * Builds the FormGroup and returns its valÒue
   * All fields are required
   */
  private _buildForm(): FormGroup {
    return new FormGroup({
      internalRef: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      formId: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      dataId: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
    });
  }

}
