import { Component, Input, OnInit } from '@angular/core';
import { Affair } from '@libs/models/src';
import { SlideInOutAnimation } from '@animations/slide-in-out';

@Component({
  selector: 'app-overview-affair-products-list',
  templateUrl: './overview-affair-products-list.component.html',
  styleUrls: ['./overview-affair-products-list.component.scss'],
  animations: [SlideInOutAnimation],
})
export class OverviewAffairProductsListComponent implements OnInit {

  @Input() affair: Affair;
  isDivVisible = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleDiv(): void {
    this.isDivVisible = !this.isDivVisible;
  }

}
