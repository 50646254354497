/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Affair, Intervention } from '@libs/models/src';
import { AuthentificationService } from '@libs/auth/src';
import { environment } from '../../../environments/environment';
import { DateService } from '../services/date.service';
import { Log } from '../types/log.type';
import { InspectionsModalCallComponent } from './inspections-modal-call/inspections-modal-call.component';
import { CallReason, CallReasons, ReasonType } from '../_const/call-reason';

@Component({
  selector: 'eros-front-customer',
  templateUrl: './inspections-customer.component.html',
  styleUrls: ['./inspections-customer.component.css'],
})
export class InspectionsCustomerComponent implements OnInit {

  private _logs: Log[];
  public form: FormGroup;
  public isVisibleKizeo = false;
  public kizeoDate = null;
  private _customer: any;
  private _beneficiaryId: string;
  public interventionCreated: Intervention = null;
  public userInfos: any = null;

  constructor(
    private _activateRoute: ActivatedRoute,
    private _http: HttpClient,
    private dateService: DateService,
    private _notifService: NotifService,
    private modalService: NzModalService,
    private authService: AuthentificationService,
    private router: Router,
  ) {
    this.form = this._buildForm();
    this._customer = [] as any[];
  }

  ngOnInit(): void {
    this._activateRoute.paramMap.subscribe((params: ParamMap) => {
      this._beneficiaryId = params.get('id');
      this._http.get<any>(`${environment.serviceEUrl}/set/beneficiary/${this._beneficiaryId}/formatted`).subscribe({
        next: (data) => {
          this._customer = data;
          // Moulinette for tel-1 tel-2 if "/"" in tel-1
          if (this._customer['tel-1'] != null && this._customer['tel-1'].includes('/')) {
            const splitArray = this._customer['tel-1'].split('/');
            this._customer['tel-1'] = splitArray[0];
            this._customer['tel-2'] = splitArray[1];
          }
          this._http.get<Log[]>(`${environment.serviceEUrl}/set/beneficiary/log/${data.id}`).subscribe({
            next: (logData) => {
              this._logs = logData.reverse();
            },
          });
        },
        error: (_) => {
          this._customer = [] as any[];
        },
      });
    });
    this.userInfos = this.authService.getUserInfos();
  }

  /**
   * Show call modal
   */
  public showCallModal(): void {
    this.modalService.create({
      nzWidth: 1600,
      nzStyle: {
        top: '2vh',
      },
      nzComponentParams: {
        beneficiaryId: this._beneficiaryId,
        customer: this.customer,
      },
      nzContent: InspectionsModalCallComponent,
      nzOnOk: (comp: InspectionsModalCallComponent) => {
        if (comp.isCreateAffair) {
          this.createLog(comp.reason, 'Affaire créée', comp.intervention);
        } else if (comp.isContactAppointment) {
          this.createLog(comp.reason, comp.comment, null, comp.contactForm.value, comp.affair);
        } else {
          this.createLog(comp.reason, comp.comment, null);
        }
      },
    });
  }

  /**
   * Get the call reason denomination
   */
  public getReasonDenomination(reasonType: ReasonType): string {
    const name = CallReasons.find((r: CallReason) => { return r.value === reasonType; });
    return (name != null) ? name.label : 'Inconnu';
  }

  /**
   * Handle when user clicks on Ok button on modal
   */
  public handleOkKizeo(): void {
    this.kizeoPush();
    this.isVisibleKizeo = false;
  }

  /**
   * Show the modal
   */
  public showModalKizeo(): void {
    this.isVisibleKizeo = true;
  }

  /**
   * Return the list of logs
   */
  public get logs(): Log[] {
    return this._logs;
  }

  /**
   * Create a log for the beneficiary
   */
  public createLog(reason: ReasonType, comment: string, intervention?: Intervention, contactValues?: any, affair?: Affair): void {
    const date = this.dateService.getDateAndHour(new Date());
    let payload = {
      date: date,
      reason: reason,
      comment: comment,
      interventionId: intervention?.id,
      affairId: affair?.id,
      assistant: this.userInfos ? `${this.userInfos.firstname} ${this.userInfos.lastname}` : null,
    };
    if (contactValues) {
      payload = {
        ...payload,
        ...contactValues,
      };
    }

    this._http.post<Log>(`${environment.serviceEUrl}/set/beneficiary/log/${this._customer.id}`, payload).subscribe({
      next: (data) => {
        this._logs.unshift(data);
        this._logs = [...this._logs];
        this._notifService.showSuccessNotif("L'événement a bien été créé");
        this.form.reset();
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });

  }

  /**
   * Handle when user clicks on Cancel button on modal
   */
  public handleCancelKizeo(): void {
    this.isVisibleKizeo = false;
  }

  /**
   * Builds the FormGroup and returns its valÒue
   * All fields are required
   */
  private _buildForm(): FormGroup {
    return new FormGroup({
      technicianId: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
    });
  }

  /**
   * Return the list of customers
   */
  public get customer(): any[] {
    return this._customer;
  }

  /**
   * Return the value of _logs
   */
  public get isLoadingLogs(): boolean {
    return this._logs === undefined;
  }

  public onRedirectIntervention(data): void {
    this.router.navigateByUrl(`interventions/${data.intervention_id}/edit`);
  }

  public onRedirectAffair(data): void {
    this.router.navigateByUrl(`affairs/${data.affair_id}/show`);
  }

  /**
   * Push a form to a technician for a specific date
   */
  public kizeoPush(): void {
    const technicianId = this.form.get('technicianId').value;

    const payload = {
      technician_id: technicianId,
    };
    this._http.post<any>(`${environment.serviceEUrl}/kizeo/${this._beneficiaryId}`, payload).subscribe({
      next: (data) => {
        this._notifService.showSuccessNotif(data.message);
      },
      error: (err) => {
        this._notifService.showErrorNotif(err.error.message);
      },
    });
  }

}
