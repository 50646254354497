import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { Observable } from 'rxjs';
import { FormFormatterService } from '../utilities/form-formatter.service';

@Injectable()
export class AffairDashboardService {

  public route = '/affairs/dashboard';

  constructor(
    private apiService: ApiService,
    private formFormatterService: FormFormatterService,
  ) { }

  public withProblemsList(form: any): Observable<any> {
    return this.apiService.post(`${this.route}/with-problems`, this.formatFiltersStatusForm(form));
  }

  public withoutPurchaseOrdersList(form: any): Observable<any> {
    return this.apiService.post(`${this.route}/without-purchase-orders`, this.formatFiltersStatusForm(form));
  }

  public withRecallDateList(form?: any): Observable<any> {
    let data = null;
    if (form) {
      data = this.formatFiltersStatusForm(form);
    }
    return this.apiService.post(`${this.route}/with-recall-date`, data);
  }

  public missingPlansList(): Observable<any> {
    return this.apiService.post(`${this.route}/missing-plans`, {});
  }

  private formatFiltersStatusForm(form: any) {
    const values = this.formFormatterService.createFormCopy(form);
    if (typeof values.selectedMarketsTypes !== 'undefined') {
      values.userId = this.formFormatterService.formatSelectSingle(values.userId);
      values.dayNumber = this.formFormatterService.formatSelectSingle(values.dayNumber);
      values.selectedMarketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.selectedMarketsTypes);
    }
    return values;
  }

}
