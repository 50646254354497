import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AffairModalStandardResponseComponent } from '@libs/components/src/lib/affair-modal-standard-response/affair-modal-standard-response.component';
import { AffairTagsModalComponent } from '@libs/components/src/lib/affair-tags-modal/affair-tags-modal.component';
import { DashboardTileKeyEnum, InterventionStatusEnum } from '@libs/enum/src';
import { Affair, DashboardTile, Tag } from '@libs/models/src';
import { AffairService, UnsubscribeOnDestroy } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-overview-affair-detail',
  templateUrl: './overview-affair-detail.component.html',
  styleUrls: ['./overview-affair-detail.component.scss'],
})
export class OverviewAffairDetailComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {

  @Input() affairId: number;
  @Input() dashboardTile: DashboardTile;
  @Input() tags: Tag[] = [];
  public affair: Affair;
  public loadingName = 'overview-affair-detail';
  @Output() folderNext: EventEmitter<number> = new EventEmitter<any>();
  public dashboardTileEnum = DashboardTileKeyEnum;
  public interventionStatusEnum = InterventionStatusEnum;

  constructor(
    private affairService: AffairService,
    private spinnerService: NgxSpinnerService,
    private modalService: NzModalService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    // this.initAffair();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.affairId && changes.affairId.currentValue) {
      this.affairId = changes.affairId.currentValue;
      this.initAffair();
    }
  }

  initAffair(): void {
    this.spinnerService.show(this.loadingName);
    this.affairService.getForOverview(this.affairId)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(this.loadingName);
        }),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((affair) => {
        this.affair = affair;
        this.spinnerService.hide(this.loadingName);
      });

  }

  onImportOtherDocumentClicked(): void {
    window.open(`/affairs/${this.affairId}/document-customer/import`, '_blank');
  }

  onModalStandardResponseShow(): void {
    const modalInstance = this.modalService.create({
      nzTitle: 'Envoi d\'une réponse standardisée',
      nzWidth: '80%',
      nzContent: AffairModalStandardResponseComponent,
      nzComponentParams: {
        affair: this.affair,
        affairSendSettings: this.affair.affairOfficeFile.affairsSendSettings,
        affairContacts: this.affair.affairContacts,
      },
      nzStyle: { top: '2vh' },
    });
    modalInstance.afterClose.subscribe(() => {
      this.initAffair();
    });
  }

  onFolderNextClicked(): void {
    this.folderNext.emit(this.affairId);
  }

  onOpenAffairClicked(): void {
    window.open(`/affairs/${this.affairId}/show`, '_blank');
  }

  openAffairTagsModal(): void {
    this.modalService.create({
      nzContent: AffairTagsModalComponent,
      nzComponentParams: {
        affair: this.affair,
        tags: this.tags,
      },
      nzOnOk: () => {
        this.initAffair();
      },
    });
  }

}
