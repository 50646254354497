import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AffairMarketType } from '@libs/enum/src';
import { SelectModel } from '@libs/models/src';
import { AffairService, FileUtilsService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';
import { AffairsIndexExportChoiceComponent } from './affairs-index-export-choice/affairs-index-export-choice.component';

@Component({
  selector: 'app-affairs-index',
  templateUrl: './affairs-index.component.html',
  styleUrls: ['./affairs-index.component.scss'],
})
export class AffairsIndexComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() marketType: number;
  @Input() mlAgencies: SelectModel[];
  @Input() agencies: SelectModel[];
  @Input() assistants: SelectModel[];
  @Input() technicians: SelectModel[];
  @Input() products: SelectModel[];
  @Input() productsStatuses: SelectModel[];
  @Input() affairsStatuses: SelectModel[];
  @Input() affairsGoals: SelectModel[];
  @Input() affairsMonitoringTypes: SelectModel[];
  @Input() marketsTypes: SelectModel[];
  @Input() indicatorsCustomers: SelectModel[];
  @Input() performances: SelectModel[];
  @Input() customersTypes: SelectModel[];
  @Input() tags: SelectModel[];
  public form: FormGroup;
  public storageKey = null;
  public storageColumnsKey = null;
  public affairsData: any[];
  public refresh = false;
  public reset = false;
  public affairMarketTypeEnum = AffairMarketType;

  constructor(
    private titleService: Title,
    private dashboardService: DashboardService,
    private storageService: FormLocalStorageService,
    private affairService: AffairService,
    private swalService: SwalService,
    private fileUtilsService: FileUtilsService,
    private modalService: NzModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setStorageKey();
    this.setStorageColumnsKey();
    let storageFormValue = this.dashboardService.retrieveDashboardFilters({
      assistants: this.assistants,
      productsStatuses: this.productsStatuses,
    });
    if (storageFormValue == null) {
      storageFormValue = this.storageService.retrieve(this.storageKey);
    }
    this.dashboardService.resetFilters();
    this.form = this.affairService.getFiltersForm(storageFormValue, this.marketType);
    this.setTitle();
  }

  resetForm(): void {
    this.reset = !this.reset;
    const resetForm = this.affairService.getFiltersForm(null, this.marketType);
    this.dashboardService.resetFilters();
    this.form.reset(resetForm.value);
    localStorage.removeItem(this.storageColumnsKey);
  }

  onRefreshClicked(): void {
    this.refresh = !this.refresh;
  }

  public getListIcon(): string {
    if (this.marketType === this.affairMarketTypeEnum.STANDALONE_HOUSE) {
      return 'tll fa-mi-market';
    } if (this.marketType === this.affairMarketTypeEnum.COLLECTIVE) {
      return 'tll fa-collective-market';
    } if (this.marketType === this.affairMarketTypeEnum.RENOVATION) {
      return 'tll fa-home-renovation';
    } if (this.marketType === this.affairMarketTypeEnum.INSPECTION) {
      return 'fas fa-search';
    }
    return 'fas fa-list';

  }

  public getListTitle(): string {
    if (this.marketType === this.affairMarketTypeEnum.STANDALONE_HOUSE) {
      return 'Maison Individuelle : Liste des affaires';
    } if (this.marketType === this.affairMarketTypeEnum.COLLECTIVE) {
      return 'Collectif/Tertiaire : Liste des affaires';
    } if (this.marketType === this.affairMarketTypeEnum.RENOVATION) {
      return 'Rénovation : Liste des affaires';
    } if (this.marketType === this.affairMarketTypeEnum.INSPECTION) {
      return 'Inspection : Liste des affaires';
    }
    return 'Tous marchés : Liste des affaires';

  }

  private setStorageKey(): void {
    if (this.marketType === this.affairMarketTypeEnum.STANDALONE_HOUSE) {
      this.storageKey = 'affairs-individual-house-filters-form';
    } else if (this.marketType === this.affairMarketTypeEnum.COLLECTIVE) {
      this.storageKey = 'affairs-collective-filters-form';
    } else if (this.marketType === this.affairMarketTypeEnum.RENOVATION) {
      this.storageKey = 'affairs-renovation-filters-form';
    } else if (this.marketType === this.affairMarketTypeEnum.INSPECTION) {
      this.storageKey = 'affairs-inspection-filters-form';
    } else {
      this.storageKey = 'affairs-all-filters-form';
    }
  }

  private setStorageColumnsKey(): void {
    if (this.marketType === this.affairMarketTypeEnum.STANDALONE_HOUSE) {
      this.storageColumnsKey = 'affairs-individual-house-columns';
    } else if (this.marketType === this.affairMarketTypeEnum.COLLECTIVE) {
      this.storageColumnsKey = 'affairs-collective-columns';
    } else if (this.marketType === this.affairMarketTypeEnum.RENOVATION) {
      this.storageColumnsKey = 'affairs-renovation-columns';
    } else if (this.marketType === this.affairMarketTypeEnum.INSPECTION) {
      this.storageColumnsKey = 'affairs-inspection-columns';
    } else {
      this.storageColumnsKey = 'affairs-all-columns';
    }
  }

  private setTitle(): void {
    if (this.marketType === this.affairMarketTypeEnum.STANDALONE_HOUSE) {
      this.titleService.setTitle(`Maison Individuelle : Liste des affaires${tabSuffix}`);
    } else if (this.marketType === this.affairMarketTypeEnum.COLLECTIVE) {
      this.titleService.setTitle(`Col - Ter : Liste des affaires${tabSuffix}`);
    } else if (this.marketType === this.affairMarketTypeEnum.RENOVATION) {
      this.titleService.setTitle(`Rénovation : Liste des affaires${tabSuffix}`);
    } else if (this.marketType === this.affairMarketTypeEnum.INSPECTION) {
      this.titleService.setTitle(`Inspection : Liste des affaires${tabSuffix}`);
    } else {
      this.titleService.setTitle(`Tous marchés : Liste des affaires${tabSuffix}`);
    }
  }

  public generateExport(): void {
    this.modalService.create({
      nzContent: AffairsIndexExportChoiceComponent,
      nzComponentParams: {
        form: this.form,
      },
    });

  }

}
