export class MapsTools {

  public static buildGoogleMapsDirectionsUrl(postalCodes: [], cityList: string[], origin: string, originCity: string): string | null {
    if (cityList.length !== postalCodes.length) {
      return null;
    }

    // Supprimer les doublons dans les villes
    const uniqueCities = Array.from(new Set(cityList));

    // Encodage des noms de villes avec encodeURI
    const encodedCities = uniqueCities.map((city) => { return encodeURI(city); });

    // Créer un objet pour stocker les paires de rue + ville + code postal et vérifier les doublons
    const uniqueAddresses = {};
    const encodedAddresses = [];

    for (let i = 0; i < cityList.length; i++) {
      const city = cityList[i];
      const postalCode = postalCodes[i];
      const encodedCity = encodeURIComponent(city || '');
      const encodedPostalCode = encodeURIComponent(postalCode || '');

      const key = `${encodedCity},${encodedPostalCode}`;

      // Vérifier si la paire existe déjà
      if (!uniqueAddresses[key]) {
        uniqueAddresses[key] = true;
        encodedAddresses.push(key);
      }
    }
    if (origin !== null && originCity !== null) {
      encodedAddresses.unshift(origin);
      encodedAddresses.push(origin);
      encodedCities.unshift(originCity);
      encodedCities.push(originCity);
    }

    // Convertir la liste de villes encodées en une chaîne de caractères séparée par des barres obliques ("/")
    const waypoints = encodedCities.slice(1, -1).join('/');

    // Construire l'URL avec les points de départ et d'arrivée, ainsi que les points intermédiaires
    const apiUrl = `https://www.google.com/maps/dir/${encodedAddresses[0]}/${waypoints}/${encodedAddresses[encodedAddresses.length - 1]}`;

    return apiUrl;
  }

}
