export enum DashboardTileKeyEnum {
     WAITING_XML_KEY = 'WAITING_XML',
     WAITING_CONFIRMATION_KEY = 'WAITING_CONFIRMATION',
     WAITING_PROGRAMMATION_KEY = 'WAITING_PROGRAMMATION',
     WAITING_PAYMENT_KEY = 'WAITING_PAYMENT',
     WAITING_MANUAL_SEND_KEY = 'WAITING_MANUAL_SEND',
     INVOICE_TODO_MI_KEY = 'INVOICE_TODO_MI',
     INVOICE_TODO_CO_KEY = 'INVOICE_TODO_CO',
     INVOICE_TODO_INSP_KEY = 'INVOICE_TODO_INSP',
     SEND_TODO_KEY = 'SEND_TODO_MI',
     SEND_TODO_CO_KEY = 'SEND_TODO_CO',
     SEND_TODO_INSP_KEY = 'SEND_TODO_INSP',
     EVENT_TO_CLOSE = 'EVENT_TO_CLOSE',
     EVENT_WAITING_INFORMATION = 'EVENT_WAITING_INFORMATION',
     AFFAIR_WITH_PROBLEMS_KEY = 'AFFAIR_WITH_PROBLEMS',
     AFFAIR_WITHOUT_PURCHASE_ORDER_KEY = 'AFFAIR_WITHOUT_PURCHASE_ORDER',
     AFFAIR_WITH_RECALL_DATE_KEY = 'AFFAIR_WITH_RECALL_DATE',
     NONCONFORMITY_WAITING_ACTION_CLIENT_KEY = 'NONCONFORMITY_WAITING_ACTION_CLIENT',
     NONCONFORMITY_WAITING_VALIDATION_KEY = 'NONCONFORMITY_WAITING_VALIDATION',
}
