import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Affair, AffairLogConsteo } from '@libs/models/src';
import { AffairConsteoService } from '@libs/services/src/lib/affair-consteo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-affair-show-consteo-history',
  templateUrl: './affair-show-consteo-history.component.html',
  styleUrls: ['./affair-show-consteo-history.component.scss'],
})
export class AffairShowConsteoHistoryComponent implements OnInit, OnChanges {

  @Input() affair: Affair;
  public logs$: Observable<AffairLogConsteo[]>;

  constructor(private consteoService: AffairConsteoService, private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.initLogs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initLogs();
  }

  initLogs(): void {
    this.spinnerService.show('load-consteo-history');
    this.logs$ = this.consteoService.getLogsByAffairId(this.affair.id).pipe(finalize(() => { this.spinnerService.hide('load-consteo-history'); }));
  }

}
